const initialState = {
    listaTags: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    tagcamion: null,
    listaTagSelect:[]
  };
  
  export const tagCamionesReducer = (state = { ...initialState }, action) => {
  
    switch (action.type) {
      case "OBTENER_TAGSCAMION":
        return {
          ...state,
          listaTags: action.payload,
          listaTagSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          tagcamion: null,
        };
      case "AGREGAR_TAGCAMION":
        return {
          ...state,
          addOk: action.payload,
        };
      case "ELIMINAR_TAGCAMION":
        return {
          ...state,
          deleteOk: action.payload,
        };
      case "OBTENER_TAGCAMION":
        return {
          ...state,
          tagcamion: action.payload,
        };
      case "EDITAR_TAGCAMION":
        return {
          ...state,
          editOk: action.payload,
        };
  
      default:
        return state;
    }
  };
  