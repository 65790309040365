import React, { useState, useEffect } from "react";

import { agregarUsuario,editarUsuario } from "../../redux/actions/usuario";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//import { obtenerTagsCamionFiltrados } from "../../redux/actions/tagcamion";
import { obtenerEmpresasFiltrados } from "../../redux/actions/clienteempresa";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  name: "",
  password: "",
  email: "",
  perfil: "",
  activo: "ACTIVO",
  idempresa: "",
};

const initDataFormErr = {
  name: "",
  password: "",
  email: "",
  perfil: "",
  idempresa: "",
};

const errorsInit = {
  ...initDataFormErr,
};

const ModalUsuarios = ({ mensajeError, usuario, editar = false, Editar }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk,editOk } = state.usuario;
  const history = useHistory();
  const [perfiluser, setperfilUser] = useState(localStorage.getItem("perfil"));
  const [idempresaX, setIdEmpresa] = useState(
    localStorage.getItem("idempresa")
  );
  const [nombreempresa, setNombreEmpre] = useState(
    localStorage.getItem("nombreempresa")
  );
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [soloLectura, setSoloLectura] = useState(false);
  const [show, setShow] = useState(false);

  const { listaEmpClienteSelect } = state.clienteempre;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    if (addOk) {
      history.push("/usuarios");
    }
  }, [addOk]);
  useEffect(() => {
    if (editOk) {
      history.push("/usuarios");
    }
  }, [editOk]);

  const nuevo = (objeto) => {
    dispatch(agregarUsuario(objeto));
  };
  const editarU = (objeto) => {
    dispatch(editarUsuario(objeto));
  };

  useEffect(() => {
    if (editar) {
      delete usuario.datos_edicion;
      delete usuario.datos_creacion;
      delete usuario.fecha_creacion;
      delete usuario.hora_creacion;
      console.log("aqio el usuario",usuario)
      setDataForm({ ...usuario });
      setSoloLectura(true);
    } else {
      if (perfiluser != "superadmin") {
        setDataForm({ ...dataForm, idempresa: idempresaX });
      }
    }

    dispatch(obtenerEmpresasFiltrados());
  }, [usuario, editar]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if ([e.target.name] == "idempresa" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      console.log(e.nativeEvent.target[index].label);
      setNombreEmpre(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      
      if (key !== "activo") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key] === "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      if (editar) {
        const dataForm1 = { ...dataForm, nombreempresa };
       // Editar(dataForm1);
       editarU(dataForm1)
      } else {
        const dataForm1 = { ...dataForm, nombreempresa };
        nuevo(dataForm1);
      }
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
        {
          editar? <FormattedMessage id="table_editar" />: <FormattedMessage id="Add" />
        }
       
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title> 
          {
          editar? <FormattedMessage id="user_edit" />: <FormattedMessage id="user_add" />
        }
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label  htmlFor="email">
                <FormattedMessage id="user_account" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="email"
                  value={dataForm.email || ""}
                  onChange={handleChange}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.email}
                  </span>
                )}
              </Col>
              <Col xs={6}>
                <Form.Label  htmlFor="name">
                <FormattedMessage id="user_fullname" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="name"
                  value={dataForm.name || ""}
                  onChange={handleChange}
                />

                {errors.name && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.name}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
             

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="password">
                <FormattedMessage id="user_password" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="password"
                  value={dataForm.password || ""}
                  onChange={handleChange}
                />

                {errors.password && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.password}
                  </span>
                )}
              </Col>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="perfil">
                <FormattedMessage id="user_perfil" />
                </Form.Label>

                <Form.Select
                  name="perfil"
                  value={dataForm.perfil || ""}
                  onChange={handleChange}
                >
                  <option value="-1"><FormattedMessage id="user_option_select" /></option>
                  <option value="Administrador">  <FormattedMessage id="user_option_administrador" /></option>
                  <option value="UsuarioWeb"><FormattedMessage id="user_option_web" /></option>
                  <option value="UsuarioMovil"><FormattedMessage id="user_option_movil" /></option>
                </Form.Select>

                {errors.perfil && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.perfil}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
          

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="activo">
                <FormattedMessage id="user_position" />
                </Form.Label>

                <Form.Select
                  name="activo"
                  value={dataForm.activo || ""}
                  onChange={handleChange}
                >
                  <option value="ACTIVO"><FormattedMessage id="user_option_active" /></option>
                  <option value="INACTIVO"> <FormattedMessage id="user_option_inactive" /></option>
                </Form.Select>

                {errors.activo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.activo}
                  </span>
                )}
              </Col>
              {
                perfiluser=="superadmin" &&
                <Col xs={6}>
                <Form.Label className="my-5" htmlFor="idempresa">
                  Empresa:
                </Form.Label>

                <Form.Select
                  name="idempresa"
                  value={dataForm.idempresa || ""}
                  onChange={handleChange}
                >
                
                  {listaEmpClienteSelect.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>

                {errors.idempresa && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.idempresa}
                  </span>
                )}
              </Col>
              }
            
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            <FormattedMessage id="cancelar" />
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
             {
          editar? <FormattedMessage id="Edit" />: <FormattedMessage id="Add" />
        }
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalUsuarios;
