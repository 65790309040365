import React, { useEffect, useContext } from "react";
import Formulario from "../../components/Formulario/NuevoVehiculo";

import { editarVehiculo, obtenerVehiculo } from "../../redux/actions/vehiculo";
import { useDispatch, useSelector } from "react-redux";

const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { vehiculo, editOk } = state.vehiculo;

  useEffect(() => {
    // console.log(props.match.params.id);
    dispatch(obtenerVehiculo(props?.match?.params?.id));
  }, []);

  useEffect(() => {
   
    console.log(editOk);
    if (editOk) {
      props.history.push("/vehiculos");
    }
  }, [editOk]);

  const edicion = (camion) => {
    // editar producto;
    dispatch(editarVehiculo(camion));
  };

  if (!vehiculo) return <p>Cargando...</p>;
  return (
    <div>
      <h1>Editar Vehiculo</h1>
      <Formulario vehiculo={vehiculo} editar={true} editarVehiculo={edicion} />
    </div>
  );
};

export default Editar;
