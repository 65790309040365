import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoFurgon";

import { agregarFurgon } from "../../redux/actions/furgon";
import { useDispatch, useSelector } from "react-redux";

const Agregar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.furgon;
  
  useEffect(() => {

    if (addOk) {

      props.history.push("/furgones");
    }
  }, [addOk]);

  const nuevo = (camion) => {
    dispatch(agregarFurgon(camion));
    // agregar producto provider
  };
  return (
    <div>
      <Formulario agregarNuevoFurgon={nuevo} />
    </div>
  );
};

export default Agregar;