import Navbar from "../components/Header/Navbar";
import Header from "../components/Header/header3";
//import Sidebar from "../components/Header/sidebar";
import { Route, Redirect } from "react-router-dom";
import './App.css';

const RutaPrivada = (props) => {

  const token = localStorage.getItem("token");
  var estasAutenticado = true;
  let { datos, component: Component, ...rest } = props;

  if (!token) {
    estasAutenticado = false;
  }

  return (
    <div className='App'>
     
    <div className="App-content">
      <Header />
      <main className="MainContent" >
      <Navbar datos={datos} />

        <Route
          {...rest}
          component={(props) => {
            return estasAutenticado ? (
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
      </main>
    </div>
  </div>

  
  );
};

export default RutaPrivada;
