import React, { useEffect, useState } from "react";
import Popup from "../../components/Piloto/PopUpPiloto";
import PopupEmpa from "../../components/Emparejamientos";
import CardRfid from "../../components/Rfid/CardRfidEmpa";
import { useDispatch, useSelector } from "react-redux";
import Contenedor from "../../components/Contenedor";
import {
  obtenerEmparejamiento,
  cerrarViajePrecinto,
} from "../../redux/actions/emparejamiento";
import CardCamion from "../../components/Camion/CardCamion";
import CardFurgon from "../../components/Furgon/CardFurgon";
import { Link } from "react-router-dom";
import CardChasis from "../../components/Camion/CardChasis";
import Vehiculo from "../../images/vinculacionReal.png";

import NameCircle from "../../helpers/NameCircle";
import VinculacionEventos from "../../components/Emparejamientos/VinculacionEventos";
import { FormattedMessage } from "react-intl";
import Card from "../../components/Emparejamientos/Card";

const initDataForm = {
  id: "",
  idPiloto: "",

 
  contenedor_datos: [],
  descripcionCierre: "",
};

const Detalle = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { emparejamiento, editOk } = state.emparejamiento;
  const [contenedor_datos, setcontedatos] = useState([]);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [esperaPi, setEsperaPi] = useState(false);
  const [seed, setSeed] = useState(1);
  const [mostrarPopUp, setMostrarPopUp] = useState(false);

  const numero_emparejamiento = props?.match.params.id;

  useEffect(() => {
    dispatch(obtenerEmparejamiento(numero_emparejamiento));
  }, []);

  useEffect(() => {
    if (editOk) {
      dispatch(obtenerEmparejamiento(numero_emparejamiento));
    }
  }, [editOk]);

  useEffect(() => {
    if (emparejamiento) {
      if (emparejamiento.id) {
        setDataForm({ ...emparejamiento });
        setEsperaPi(true);
      }
    }
  }, [emparejamiento]);

  const actualizarPosicionRfids = () => {
    dispatch(obtenerEmparejamiento(numero_emparejamiento));
    //setSeed(Math.random());
  };

  if (!emparejamiento) return <p>Cargando ...</p>;

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <Card
            dataForm={dataForm}
            actualizarPosicionRfids={actualizarPosicionRfids}
          />
        </div>
      </div>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 p-10 pb-0 mb-10">
            <div className="card-header">
              <div className="card-title d-flex flex-column">
                <span
                  className="card-label fw-bolder"
                  style={{ color: "#0A5189" }}
                >
                  <FormattedMessage id="vinculacion_details" />
                </span>
              </div>

              <div className="card-title d-flex align-items-center gap-3">
                {esperaPi && <Popup id={dataForm?.idPiloto} />}
                {
                  <Link
                    className="btn btn-sm"
                    to={`/emparejamientos`}
                    style={{
                      color: "#0A5189",
                      fontWeight: "bolder",
                      backgroundColor: "#e9f6ff",
                    }}
                  >
                    <FormattedMessage id="ir_lista" />
                  </Link>
                }
                {dataForm.viaje === "VIAJE" && (
                  <PopupEmpa id={dataForm?.id} mostrarPopUp={mostrarPopUp} />
                )}
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table className="table border border-0">
                  <tbody>
                    <tr>
                      {dataForm.tipo_vehiculo_nombre === "Furgon" && (
                        <td>
                          {dataForm.vehiculo?.id === "" ? (
                            <p>Cargando ...</p>
                          ) : (
                            <CardFurgon id={dataForm.vehiculo?.id} />
                          )}
                        </td>
                      )}

                      {dataForm.vehiculo?.id &&
                        (dataForm.tipo_vehiculo_nombre === "Camioneta" ||
                        dataForm.tipo_vehiculo_nombre === "Chasis" ||
                          dataForm.tipo_vehiculo_nombre === "Trailer") && (
                          <td>
                            <CardCamion
                              id={
                                dataForm.vehiculo?.id
                              }
                            />
                          </td>
                        )}

              
                    </tr>
                    <tr>
                      {dataForm.contenedor_datos?.length > 0 &&
                        dataForm.contenedor_datos.map((item, index) => {
                          return (
                            <td key={index}>
                              <Contenedor
                                id={item.id}
                                numero={item.numero}
                                key={index}
                              />
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>
                        {emparejamiento.precintos?.length > 0 &&
                          emparejamiento.precintos.map((item, index) => (
                            <CardRfid
                              key={index}
                              modalCerrar={index}
                              id={item?.numero_precinto}
                              rfid={item}
                              emparejamiento={emparejamiento}
                            />
                          ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card p-10 mb-10">
            <div className="card-header">
              <p
                className="card-title fs-3 fw-bolder"
                style={{ color: "#0A5189" }}
              >
                <FormattedMessage id="vinculacion_tiempo_real" />
              </p>
            </div>

            <div className="card-body pt-5">
              <div className="timeline-label">
                <div className="timeline-item">
                  <div className="d-flex gap-3 w-100">
                    <div
                      className="rounded-circle p-4 align-self-start"
                      style={{ backgroundColor: "#E9F6FF" }}
                    >
                      <img src={Vehiculo} alt="oh" width={30} />
                    </div>

                    <div className="timeline-content d-flex flex-column">
                      <span className="fw-bold">
                        <FormattedMessage id="vinculacion_evento" /> 1:{" "}
                        <FormattedMessage id="vinculacion_evento_creacion" />
                      </span>
                      <span className="text-muted">
                        <FormattedMessage id="vinculacion_datetime" />{" "}
                        {dataForm?.fecha_creacion +
                          " " +
                          dataForm?.hora_creacion}
                      </span>

                      <div className="border border-dark rounded-2 d-flex align-items-center justify-content-between px-10 py-4 mt-4">
                        <span>
                          <FormattedMessage id="vinculacion_detalle_evento" />
                        </span>
                        <div className="d-flex align-items-center gap-2">
                          <span>
                            <FormattedMessage id="vinculacion_participantes" />:
                          </span>
                          <NameCircle
                            name={dataForm?.datos_creacion?.nombreUsuario || ""}
                          />
                        </div>

                        <span className="badge py-3 px-4 fs-7 badge-light-success">
                          <FormattedMessage id="vinculacion_completado" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {dataForm?.datos_edicion?.length > 0 &&
                  dataForm?.datos_edicion.map((item, index) => {
                    return (
                      <VinculacionEventos
                        descripcionCierre={dataForm.descripcionCierre}
                        datos={item}
                        tipo_vehiculo_nombre={
                          dataForm?.vehiculo?.tipo_vehiculo_nombre
                        }
                        precintoX={emparejamiento?.precintos.find(
                          (objeto) =>
                            objeto?.numero_precinto === item?.numero_precinto
                        )}
                        indice={index + 2}
                        key={index}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detalle;
