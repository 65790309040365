import React, { useEffect, useContext } from "react";
import Formulario from "../../components/Formulario/NuevoUsuario";

import { editarUsuario, obtenerUsuario } from "../../redux/actions/usuario";
import { useDispatch, useSelector } from "react-redux";


const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { usuario, editOk } = state.usuario;

  useEffect(() => {
    // console.log(props.match.params.id);
    dispatch(obtenerUsuario(props?.match?.params?.id));
  }, []);

  useEffect(() => {
   
  
    if (editOk) {
      props.history.push("/usuarios");
    }
  }, [editOk]);

  const edicion = (usuario) => {
    // editar producto;
    dispatch(editarUsuario(usuario));
  };

  if (!usuario) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar Usuario</h1>
      <Formulario usuario={usuario} editar={true} Editar={edicion} />
    </div>
  );
};

export default Editar;
