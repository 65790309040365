import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoUsuario";

import { agregarUsuario } from "../../redux/actions/usuario";
import { useDispatch, useSelector } from "react-redux";

const Agregar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.usuario;
 
  useEffect(() => {

    if (addOk) {

      props.history.push("/usuarios");
    }
  }, [addOk]);

  const nuevo = (objeto) => {
    dispatch(agregarUsuario(objeto));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Agregar Usuario</h1>
      <Formulario agregarNuevo={nuevo} />
    </div>
  );
};

export default Agregar;