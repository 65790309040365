import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import Usuario from "../../images/sidebarIcons/usuarioIcon.svg";
import Piloto from "../../images/sidebarIcons/pilotoIcon.svg";
import Contenedor from "../../images/sidebarIcons/contenedorIcon.svg";
import RFID from "../../images/sidebarIcons/rfidIcon.svg";
import Vinculacion from "../../images/sidebarIcons/vinculacionIcon.png";
import Empresas from "../../images/sidebarIcons/empresasIcon.svg";
import Vehiculo from "../../images/sidebarIcons/vehiculoIcon.png";
import Camion from "../../images/sidebarIcons/iconTagCamion.png";

import LogoPrincipalBlanco from "../../images/logoGlinsealFinalBlanco.png";
//import Glinsealico from "../../images/glinsealico.jpeg";
import Glinsealico from "../../images/icoglinseal.png";
import LogoVMI from "../../images/logovmi.png";
import LogoElogistic from "../../images/watermark_custom_v2.png";
import LogoUnoTrans from "../../images/unotranslogo.png";
import LogoDacoTrans from "../../images/logoDacotrans.png";
import LogoSupliServiciosCostaRica from "../../images/SupliServ-CostaRica.png";
import LogoTidadsuColombia from "../../images/Tidadsu_col.png";
import LogoTidadsuEcuador from "../../images/Tidadsu_ecu.png";

import { FormattedMessage } from "react-intl";
import "./header.css";
import Ruta from "./Ruta";
const Header = () => {
  const dispatch = useDispatch();
  const [perfiluser, setperfilUser] = useState(localStorage.getItem("perfil"));
  const nombreempresa = localStorage.getItem("nombreempresa");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [claseRuta, setClaseRuta] = useState(
    "fw-bold rounded p-3 d-flex align-items-center gap-2 rutaSinNombre"
  );
  const handleToggleCollapse = () => {
   
    if (isCollapsed) {
      setClaseRuta("fw-bold rounded p-3 d-flex align-items-center gap-2");
    } else {
      setClaseRuta(
        "fw-bold rounded p-3 d-flex align-items-center gap-2 rutaSinNombre"
      );
    }
    setIsCollapsed(!isCollapsed);
  };
  const logoEmpresa = (nombreempresa) => {
    switch (nombreempresa.toUpperCase()) {
      case "EMPRESAPRUEBAS":
        return (
          <div className="mx-auto" style={{ width: "65%" }}>
            <img alt="Logo VMI" src={LogoVMI} className="w-100" />
          </div>
        );

      case "SUNAT":
        return (
          <div className="mx-auto" style={{ width: "65%" }}>
            <img alt="Logo Elogistic" src={LogoVMI} className="w-100" />
          </div>
        );
      case "ELOGISTICS":
        return (
          <div className="mx-auto" style={{ width: "65%" }}>
            <img alt="Logo Elogistic" src={LogoElogistic} className="w-100" />
          </div>
        );
      case "UNOTRANS":
        return (
          <div className="mx-auto" style={{ width: "65%" }}>
            <img alt="Logo UnoTrans" src={LogoUnoTrans} className="w-100" />
          </div>
        );
      case "DACOTRANS":
        return (
          <div className="mx-auto" style={{ width: "65%" }}>
            <img alt="Logo DacoTrans" src={LogoDacoTrans} className="w-100" />
          </div>
        );
        case "SUPLISERVICIOS COSTA RICA":
          return (
            <div className="mx-auto" style={{ width: "65%" }}>
              <img alt="Logo supliservicios costa rica" src={LogoSupliServiciosCostaRica} className="w-100" />
            </div>
          );
          case "TITADSUECUADOR":
          return (
            <div className="mx-auto" style={{ width: "65%" }}>
              <img alt="Logo Tidadsu Ecuador" src={LogoTidadsuEcuador} className="w-100" />
            </div>
          );
          case "TITADSUCOLOMBIA":
          return (
            <div className="mx-auto" style={{ width: "65%" }}>
              <img alt="Logo Tidadsu Colombia" src={LogoTidadsuColombia} className="w-100" />
            </div>
          );
      default:
        break;
    }
  };

  return (
    <>
      <div
        className={`d-flex flex-column rounded-end-3 sidebar ${
          isCollapsed ? "collapsed" : ""
        }`}
        style={{ backgroundColor: "#0A5189" }}
        onMouseEnter={handleToggleCollapse}
        onMouseLeave={handleToggleCollapse}
      >
        <div className="mx-auto mt-4" style={{ width: "65%" }}>
          <Link to="/dashboard">
            <img
              alt="Logo"
              src={isCollapsed ? Glinsealico : LogoPrincipalBlanco}
              className="w-100"
            />
          </Link>
        </div>

        <div className="w-75 bg-white h-1px mx-auto my-4"></div>

        <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
          <div className="app-sidebar-wrapper d-flex flex-column justify-content-between h-100">
            <div className="menu menu-column menu-rounded menu-sub-indention px-10">
              <div className="d-flex flex-column gap-1 hover__sidelink">
                <NavLink to="/dashboard" className="text-white"   activeClassName="active">
                  <div className="fw-bold rounded p-3">
                    {" "}
                    {!isCollapsed && <FormattedMessage id="sidebar_inicio" />}
                  </div>
                </NavLink>
                <Ruta
                  ruta={"/rfids"}
                  claseRuta={claseRuta}
                  imgSrc={RFID}
                  isCollapsed={isCollapsed}
                  idFormattedMessage={"sidebar_rfid"}
                />

                <NavLink
                  className="text-white"
                  activeClassName="active"
                  to="/emparejamientos"
                  exact
                >
                  <div className={claseRuta}>
                    <img src={Vinculacion} width={25} alt="..." />
                    {!isCollapsed && (
                      <FormattedMessage id="sidebar_vinculaciones" />
                    )}
                  </div>
                </NavLink>

                <NavLink
                  className="text-white  "
                  activeClassName="active"
                  to="/pilotos"
                  exact
                >
                  <div className={claseRuta}>
                    <img src={Piloto} width={25} alt="..." />
                    {!isCollapsed && (
                      <FormattedMessage id="sidebar_conductor" />
                    )}
                  </div>
                </NavLink>

                <NavLink
                  className="text-white"
                  activeClassName="active"
                  to="/vehiculos"
                  exact
                >
                  <div className={claseRuta}>
                    <img src={Vehiculo} width={25} alt="..." />
                    {!isCollapsed && <FormattedMessage id="sidebar_vehiculo" />}
                  </div>
                </NavLink>

                <NavLink
                  className="text-white"
                  activeClassName="active"
                  to="/contenedores"
                  exact
                >
                  <div className={claseRuta}>
                    <img src={Contenedor} width={25} alt="..." />
                    {!isCollapsed && (
                      <FormattedMessage id="sidebar_contenedor" />
                    )}
                  </div>
                </NavLink>

                <NavLink
                  className="text-white"
                  activeClassName="active"
                  to="/empresatrans"
                  exact
                >
                  <div className={claseRuta}>
                    <img src={Empresas} width={25} alt="..." />
                    {!isCollapsed && (
                      <FormattedMessage id="sidebar_empresatrans" />
                    )}
                  </div>
                </NavLink>

                <NavLink
                  className="text-white"
                  activeClassName="active"
                  to="/tagscamion"
                  exact
                >
                  <div className={claseRuta}>
                    <img src={Camion} width={25} alt="..." />
                    {!isCollapsed && (
                      <FormattedMessage id="sidebar_tagvehiculo" />
                    )}
                  </div>
                </NavLink>

                {(perfiluser === "superadmin" ||
                  perfiluser === "Administrador") && (
                  <NavLink
                    className="text-white"
                    activeClassName="active"
                    to="/usuarios"
                    exact
                  >
                    <div className={claseRuta}>
                      <img src={Usuario} width={25} alt="..." />
                      {!isCollapsed && (
                        <FormattedMessage id="sidebar_usuarios" />
                      )}
                    </div>
                  </NavLink>
                )}

                {perfiluser.toUpperCase() === "superadmin" &&
                  nombreempresa.toUpperCase() === "SUNAT" && (
                    <NavLink
                      className="text-white"
                      activeClassName="active"
                      to="/config"
                      exact
                    >
                      <div className={claseRuta}>Configuraciones</div>
                    </NavLink>
                  )}

                {perfiluser === "superadmin" && (
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/clienteempre"
                    exact
                  >
                    <div className="fw-bold rounded p-3">Cliente Empresas</div>
                  </NavLink>
                )}
              </div>
            </div>

            <div>
              {logoEmpresa(nombreempresa)}
              <div className="w-75 bg-white h-1px mx-auto my-3"></div>
              <p className="text-white text-center my-8 text-light">
                Glinseal &copy; 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
