import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from 'react-intl';
import { inicisarSesion } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";

import AsideEmbarcaciones from "../../images/barco.webp";
import LogoPrincipal from "../../images/logoGlinsealFinal.png";

const userLogin = localStorage.getItem("userLogin") ?? "";
const initialValues = {
  email: userLogin,
  password: "",
};

const Login = () => {
  const intl =useIntl();
  const [procesar, setProcesar] = useState(false);
  const [mensaje, setMensaje] = useState(false);
  //  const [userLogin, setUserLogin] = useState(localStorage.getItem("userLogin"));
  //const [autenticarUsuario] = useMutation(AUTENTICAR_USUARIO);
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { isAuthenticated } = state.auth;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().required(intl.formatMessage({id:"login_error_user"})),
      password: Yup.string()
        .required(intl.formatMessage({id:"login_error_pass"}))
        .min(5, intl.formatMessage({id:"login_error_passmin"})),
    }),
    onSubmit: async (valores) => {
      const { email, password } = valores;
      dispatch(inicisarSesion(email, password));
      setProcesar(true);
    },
  });

  const mostrarMensaje = () => {
    return <div className="alert alert-danger">"Error"</div>;
  };

  return (
    <div id="kt_body" className="vh-100">
      <div className="d-flex flex-column flex-root vh-100" id="kt_app_root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid py-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10">
                <form
                  onSubmit={formik.handleSubmit}
                  className="form w-100"
                  id="kt_sign_in_form"
                >
                  <div className="text-center mb-11">
                    <img
                      src={LogoPrincipal}
                      width="280"
                      height="150"
                      className="card-img-top"
                      alt="..."
                    />

                    <h1 className="text-dark fw-bolder mb-3">
                    <FormattedMessage id="login_inicio" />
                    
                      </h1>
                  </div>

                  <div className="row g-3 mb-9">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"></div>
                  </div>
                  <div className="fv-row">
                    <input
                      type="text"
                      placeholder={ intl.formatMessage({id:"login_placeholder_cuenta"})  ||"Cuenta"}
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="form-control fw-light bg-transparent mb-3"
                    />

                    {formik.errors.userName && (
                      <span style={{ fontSize: "14px", color: "red" }}>
                        {formik.errors.userName}
                      </span>
                    )}
                  </div>

                  <div className="fv-row">
                    <input
                      id="password"
                      type="password"
                      name="password"
                      placeholder={ intl.formatMessage({id:"login_placeholder_pass"})  ||"Password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className="form-control fw-light bg-transparent mb-8"
                    />

                    <div style={{ height: "25px" }}>
                      {formik.errors.password && (
                        <span style={{ fontSize: "14px", color: "red" }}>
                          {formik.errors.password}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-grid my-10">
                    <button
                      type="submit"
                      className="btn"
                      style={{ backgroundColor: "#0A5189", color: "white" }}
                    >
                      {procesar ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>
                          <FormattedMessage id="boton_login" />
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="d-flex flex-lg-row-fluid w-lg-50 order-1 order-lg-2 fondoBarco">
            <img
              className="d-none d-lg-block mx-auto img-fluid barcoLogin"
              src={AsideEmbarcaciones}
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
