import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { obtenerRfids2 } from "../../redux/actions/rfid";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import moment from "moment";

import { agregarRfid } from "../../redux/actions/rfid";
import { useHistory } from "react-router-dom";

const initDataForm = {
  tipo: "",
  numero_precinto: "",
  TID: "",
  TID2: "",
  clase: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalAddRFID = ({ rfid, editar = false, editarRfid }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [mostrarTID, setMostrarTID] = useState(true);
  const [mostrarTID2, setMostrarTID2] = useState(true);
 
  const [status, setStatus] = useState("STOCK");
  const [show, setShow] = useState(false);

  const { listaRfids, deleteOk } = state.rfid;
  const { yaExiste } = state.rfid;
  const { addOk, mensajeError } = state.rfid;
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    if (yaExiste) {
      toast("El Rfid Ya Existe");
    }
  }, [yaExiste]);

  useEffect(() => {
    if (editar) {
      setDataForm({ ...rfid });
    }
  }, []);

  useEffect(() => {
    if (deleteOk) {
      dispatch(obtenerRfids2(status));
    }
  }, [deleteOk]);

  useEffect(() => {
    if (addOk) {
      history.push("/rfids");
    }
  }, [addOk]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
    renderSwitch(e.target.value);
  };
  const mostrarClaseComp = (param) => {
    switch (param) {
      case "TRESESTADOS":
        return (
          <>
            {" "}
            <option>MYRMIDON</option>
            <option>SOTER</option>
          </>
        );
      case "DOSESTADOS":
        return (
          <>
            {" "}
            <option value="NEPTUNE">NEPTUNE ANTITAMPER</option>
            <option value="CABLE" >CABLE ANTITAMPER</option>
          </>
        );
      case "UNESTADO":
        return (
          <>
            {" "}
            <option value="NEPTUNE">NEPTUNE LOGISTICO</option>
            <option value="CABLE">CABLE LOGISTICO</option>
          </>
        );

      default:
        return;
    }
  };
  const renderSwitch = (param) => {
    switch (param) {
      case "TRESESTADOS":
        setMostrarTID(true);
        setMostrarTID2(true);
  
        return;
      case "DOSESTADOS":
        setMostrarTID(true);
        setMostrarTID2(false);
        return;
      case "UNESTADO":
        setMostrarTID(false);
        setMostrarTID2(false);
        return <div></div>;

      default:
        return;
    }
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() === "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
          if (dataForm.tipo == "DOSESTADOS" && key == "TID2") {
            respuesta = true;
          }
          if (dataForm.tipo == "UNESTADO" && (key == "TID2" || key == "TID")) {
            respuesta = true;
          }
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    var condicion = "ABIERTO";
    var estado = "OK";

    switch (dataForm.tipo) {
      case "DOSESTADOS":
        condicion = "DOSESTADOS";
        break;
      case "UNESTADOS":
        condicion = "UNESTADO";
        estado = "UNESTADO";
        break;

      default:
        break;
    }

    var dataForm1 = {};

    if (dataForm.tipo == "DOSESTADOS") {
      dataForm1 = {
        ...dataForm,
        viaje: "Stock",
        estado: estado,
        condicion: condicion,
        TID2: dataForm.TID,
      };
    }

    if (dataForm.tipo == "UNESTADO") {
      dataForm1 = {
        ...dataForm,
        viaje: "Stock",
        estado: estado,
        condicion: condicion,
        TID: dataForm.numero_precinto,
        TID2: dataForm.numero_precinto,
      };
    }
    if (dataForm.tipo == "TRESESTADOS") {
      dataForm1 = {
        ...dataForm,
        viaje: "Stock",
        estado: estado,
        condicion: condicion,
       
      };
    }
console.log(dataForm1)
    e.preventDefault();

    if (isValid()) {
      if (editar) {
        editarRfid(dataForm);
      } else {
        dispatch(agregarRfid(dataForm1));
      }
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
        <FormattedMessage id="rfid_agregar" />
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Agregar precintos RFID</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={3}>
                <Form.Label className="mb-5" htmlFor="numero_precinto">
                  Tipo de Precinto:
                </Form.Label>

                <Form.Select
                  name="tipo"
                  value={dataForm.tipo}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  <option>TRESESTADOS</option>
                  <option>DOSESTADOS</option>
                  <option>UNESTADO</option>
                </Form.Select>

                {errors.tipo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.tipo}
                  </span>
                )}
              </Col>
              <Col xs={3}>
                <Form.Label className="mb-5" htmlFor="numero_precinto">
                  Clase:
                </Form.Label>

                <Form.Select
                  name="clase"
                  value={dataForm.clase}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  {mostrarClaseComp(dataForm.tipo)}
                </Form.Select>

                {errors.tipo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.tipo}
                  </span>
                )}
              </Col>
              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="numero_precinto">
                  N° de Precinto:
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="numero_precinto"
                  value={dataForm.numero_precinto}
                  onChange={handleChange}
                />

                {errors.numero_precinto && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precinto}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              {mostrarTID && (
                <Col>
                  <Form.Label className="my-5" htmlFor="TID">
                    TID 1:
                  </Form.Label>

                  <Form.Control
                    type="text"
                    className="bg-body-secondary border border-0"
                    name="TID"
                    value={dataForm.TID}
                    onChange={handleChange}
                  />

                  {errors.TID && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors.TID}
                    </span>
                  )}
                </Col>
              )}

              {mostrarTID2 && (
                <Col>
                  <Form.Label className="my-5" htmlFor="TID2">
                    TID 2:
                  </Form.Label>

                  <Form.Control
                    type="text"
                    className="bg-body-secondary border border-0"
                    name="TID2"
                    value={dataForm.TID2}
                    onChange={handleChange}
                  />

                  {errors.TID2 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors.TID2}
                    </span>
                  )}
                </Col>
              )}
            </Row>

            {mensajeError && (
              <Form.Label className="badge py-3 px-4 fs-5 badge-light-danger mt-5">
                El Precinto ya existe, ingrese otro.
              </Form.Label>
            )}
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            Agregar
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalAddRFID;
