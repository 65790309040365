import React from "react";



const RowTable = (props) => {


  const {
   nombre,
   password,
   usuario,
    perfil,
   activo,
    id
  
  } = props.usuario;
  console.log(props.usuario)
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      {id && <td>{id}</td>}
    
      <td>
    {nombre}
      </td>
      <td>
    {password}
      </td>
      <td>
        {usuario}
      </td>
   
      <td>
        {perfil}
      </td>
      <td>
        {activo}
      </td>
    </tr>
  );
};

export default RowTable;
