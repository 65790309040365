import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
var locale = navigator.language;

//const locale='es-PE'
try {
  var messages = require(`./translations/${locale}.json`);
} catch (error) {
  if(!messages){
     messages = require(`./translations/es-PE.json`);
  }
}
const root = ReactDOM.createRoot(document.getElementById("kt_app_body"));
root.render(
  <IntlProvider locale={locale} messages={messages}>
    <App />
  </IntlProvider>
);

reportWebVitals();
