import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { cerrarViajePrecinto } from "../../redux/actions/emparejamiento";
import { useDispatch } from "react-redux";
import { data } from "jquery";
import { FormattedMessage } from "react-intl";
const initDataForm = {
  numero_doc_precinto: "",
  numero_precinto: "",
  condicion: "",
  estado: "",
  nombre_usuario: "",
  descripcion: "",
  tipo_cierre_precinto: "0",
};
const initDatoCierre = {
  nombre_usuario: "",
  id_usuario: "",
  descripcion: "",
  fecha: "",
  hora: "",
};
const Modales = ({ mostrarPopUp, rfid }) => {
  const dispatch = useDispatch();

  
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [datosCierre, setDatosCierre] = useState(initDatoCierre);
  const [userLogin, setuserlogin] = useState(localStorage.getItem("userLogin"));
  const [nombre_usuario, setNombreU] = useState(localStorage.getItem("displayname"));
  const[email,setEmail]= localStorage.getItem("email");
  const handleShow = () => setShow(true);
  /* useEffect(() => {
    setShow(true);
    setDataForm({ ...dataForm, ...rfid, nombre_usuario: localStorage.getItem("displayname")});
    setDatosCierre({fecha: moment().format("YYYY-MM-DD"), hora:moment().format("HH:mm"),email:localStorage.getItem("email")
    ,nombre_usuario: localStorage.getItem("displayname")})
   
  }, [mostrarPopUp]);*/
  useEffect(() => {
    if (show) {
      console.log("el precinto", rfid)
      setDataForm({
        ...dataForm,
        ...rfid,
        nombre_usuario
      });
      setDatosCierre({
        fecha: moment().format("YYYY-MM-DD"),
        hora: moment().format("HH:mm"),
        email,
        nombre_usuario
      });
    }
  }, [show]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setDatosCierre({ ...datosCierre, [e.target.name]: e.target.value });
  };

  function aprobar(e, rfid) {
    e.preventDefault();
    rfid.viaje = "Terminado";

    //  rfid.numero_doc_precinto=id;

    rfid.datosCierre = datosCierre;
 
    dispatch(
      cerrarViajePrecinto(rfid._id,
        rfid.numero_precinto,
        rfid.descripcion,
        userLogin,
        rfid.tipo_cierre_precinto,
        nombre_usuario
      )
    );
    handleClose();
  }
  const handleClose = () => setShow(false);

  return (
    <>
      <Button variant="danger" onClick={handleShow} size="sm">
        <i className="bi bi-toggles"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="vinculacion_precinto_evento_importante" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
              <FormattedMessage id="rfid_column_precinto" />
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm?.numero_precinto}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
              <FormattedMessage id="rfid_integridad" />
              </Form.Label>
              <Col sm="4">
                <Form.Control plaintext readOnly value={dataForm?.estado} />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-2"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
              <FormattedMessage id="usuario" />
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm.nombre_usuario}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label column sm="2">
              <FormattedMessage id="vinculacion_tipo_cierre" />
              </Form.Label>
              <Form.Select
                name="tipo_cierre_precinto"
                value={dataForm.tipo_cierre_precinto}
                onChange={handleChange}
                aria-label="Default select example"
              >
                <option value="0"><FormattedMessage id="vinculacion_tipo_cierre_destino" /></option>
                <option value="1"><FormattedMessage id="vinculacion_tipo_cierre_inspeccion" /></option>
                <option value="2"><FormattedMessage id="vinculacion_tipo_cierre_prec_manipulado" /></option>
                <option value="3"><FormattedMessage id="vinculacion_tipo_cierre_no_encontrado" /></option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label><FormattedMessage id="vinculacion_cierre_descripcion" /></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={handleChange}
                value={dataForm.descripcion}
                name="descripcion"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {
            <Button variant="primary" onClick={handleClose}>
            <FormattedMessage id="cancelar" />
            </Button>
          }
          <Button variant="primary" onClick={(e) => aprobar(e, dataForm)}>
          <FormattedMessage id="guardar" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modales;
