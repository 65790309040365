import React, { useEffect } from "react";
import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import { obtenerEmpresas } from "../../redux/actions/empresatrans";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

const EmpTrans = () => {
  const intl=useIntl();
const listaColumnasx = [
  { accessor: "nombre", label: intl.formatMessage({id:"empresatrans_name"})},
  { accessor: "nit", label:  intl.formatMessage({id:"empresatrans_nit"}) },
  { accessor: "telefono", label:  intl.formatMessage({id:"empresatrans_phone"}) },
  { accessor: "email", label:  intl.formatMessage({id:"empresatrans_email"}) },
];

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaEmpTrans } = state.empresatrans;

  useEffect(() => {
    dispatch(obtenerEmpresas());
  }, []);

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("empresatrans")}

              <div className="card-title d-flex gap-5">
                <Link to="/empresatrans/uploadfile">
                  <button
                    className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                    style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  >
                   <FormattedMessage id="importar_archivo" />
                  </button>
                </Link>

                {ModalAddGlinseal("empresatrans")}
              </div>
            </div>
            {listaEmpTrans.length > 0 && (
              <App
                nombretabla="empresatrans"
                rows={listaEmpTrans}
                columns={listaColumnasx}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpTrans;
