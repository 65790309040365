// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .sidebar {
    width: 250px;
    height: 100%;
 
    transition: width 0.3s;
    overflow-x: hidden;
  }
  
  .sidebar.collapsed {
    width: 5.5rem;
  }
  .rutaSinNombre{
    margin-left: -1.5rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/Header/header.css"],"names":[],"mappings":"EAAE;IACE,YAAY;IACZ,YAAY;;IAEZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;EACA;IACE,oBAAoB;EACtB","sourcesContent":["  .sidebar {\n    width: 250px;\n    height: 100%;\n \n    transition: width 0.3s;\n    overflow-x: hidden;\n  }\n  \n  .sidebar.collapsed {\n    width: 5.5rem;\n  }\n  .rutaSinNombre{\n    margin-left: -1.5rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
