import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editarEmpresaTrans } from "../../redux/actions/empresatrans";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";

const initDataForm = {
  nombre: "",
  nit: "",
  nombre_contacto: "",
  telefono: "",
  email: "",
  direccion: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalEditEmpresas = ({ datos }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const state = useSelector((state) => state);
  const { editOk } = state.contenedor;
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    if (editOk) {
      history.push("/contenedores");
    }
  }, [editOk, history]);

  useEffect(() => {
    if (show) {
      delete datos.datos_edicion;
      setDataForm({ ...datos });
    }
  }, [show, datos]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() === "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }
    setErrors({ ...localErrors });

    return respuesta;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      const dataForm1 = { ...dataForm };
      dispatch(editarEmpresaTrans(dataForm1));
    }
  };

  return (
    <>
      <Button
        className="btn-secondary fw-bolder"
        style={{ color: "#0A5189" }}
        onClick={handleShow}
      >
        Editar
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Empresa</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={8}>
                <Form.Label className="mb-5">Razón Social:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.nombre || ""}
                  name="nombre"
                  onChange={handleChange}
                />
              </Col>

              <Col xs={4}>
                <Form.Label className="mb-5">NIT:</Form.Label>
                <Form.Control
                  name="nit"
                  onChange={handleChange}
                  defaultValue={dataForm?.nit || ""}
                  className="bg-body-secondary border border-0"
                />
              </Col>
            </Row>

            <Row>
              <Col xs={8}>
                <Form.Label className="my-5">Dirección:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.direccion || ""}
                  name="direccion"
                  onChange={handleChange}
                />
              </Col>

              <Col xs={4}>
                <Form.Label className="my-5">Email:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.email || ""}
                  name="email"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={8}>
                <Form.Label className="my-5">Nombre Contacto:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.nombre_contacto || ""}
                  name="nombre_contacto"
                  onChange={handleChange}
                />
              </Col>

              <Col xs={4}>
                <Form.Label className="my-5">Teléfono:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.telefono || ""}
                  name="telefono"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            onClick={HandleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            Editar
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEditEmpresas;
