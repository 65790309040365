import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { obtenerVehiculo, obtenerAcoplado } from "../../redux/actions/vehiculo";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  empresa_trans: "",
  numero_tag: "",
  placa_vehiculo: "",
  chasis_cabezal: "",
  viaje: "",
  tipo_vehiculo_nombre: "",
};
const CardCamion = (props) => {
  const { id, idAco } = props;

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { vehiculo } = state.vehiculo;
  // let id = props?.match?.params?.id;
  //let idAco = props?.match?.params?.idAco;
  /*if (!id) {
    id = props.id;
  }
  if (!idAco) {
    
    idAco=props.idAco;
  }*/
  const [dataForm, setDataForm] = useState(initDataForm);
  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id) {
      dispatch(obtenerVehiculo(id));
    }
  }, [id]);

  useEffect(() => {
    if (vehiculo) {
      setDataForm({ ...vehiculo });
    }
  }, [vehiculo]);

  const renderSwitch = (param) => {
    switch (param) {
      case "Trailer":
        return require("../../images/trailer2.png");
      default:
        return "";
      case "Chasis":
        return require("../../images/Chasis.png");

      case "Semi Acoplado Chasis":
        return require("../../images/chasis2.jpeg");

      case "Camioneta":
        return require("../../images/camioneta2.png");

      case "Furgon":
        return require("../../images/chasis2.jpeg");
    }
  };
  return (
    <div className="card fs-3 py-5 d-flex flex-row justify-content-between">
      <div className="card-title d-flex flex-row align-items-center gap-3">
        <img
          width={50}
          src={renderSwitch(dataForm.tipo_vehiculo)}
          alt="User Avatar"
        />

        <div className="card-title d-flex flex-column">
          <span className="fw-bolder">{dataForm.placa_vehiculo}</span>
          <span className="text-gray-400 fw-light fs-6">
            {dataForm.tipo_vehiculo}
          </span>
        </div>
      </div>

      <div className="card-title d-flex flex-column">
        <span className="fw-bolder">{dataForm.empresa_trans || ""}</span>
        <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="vehiculo_empresatrans" /></span>
      </div>

      <div className="card-title d-flex flex-column">
        <span className="fw-bolder">{dataForm.numero_tag || ""}</span>
        <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="tag_number" /></span>
      </div>

      <div hidden className="input-group flex-nowrap">
        <div className="input-group-prepend">
          <span className="input-group-text bg-info" id="addon-wrapping">
            Chasis Cabezal
          </span>
        </div>
        <input
          readOnly
          //   onChange={handleChange}
          value={dataForm.chasis_cabezal || ""}
          name="chasis_cabezal"
          type="text"
          className="form-control"
          aria-label="Username"
          aria-describedby="addon-wrapping"
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <i className="fa fa-light fa-truck"></i>
          </div>
        </div>
      </div>

      <div className="card-title d-flex flex-column">
        <span className="fw-bolder">{dataForm.marca || ""}</span>
        <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="vehiculo_marca" /></span>
      </div>

      <div className="card-title d-flex flex-column">
        <span className="fw-bolder">{dataForm.modelo || ""}</span>
        <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="vehiculo_modelo" /></span>
      </div>
    </div>
  );
};

export default CardCamion;
