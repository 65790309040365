import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
export const inicisarSesion = (email, password) => {
  
  return async (dispatch) => {
    try {
      const origen = "web";
      var isAuthenticated = false;
      //   var URL2=process.env.URL;
      var listado = await axios.post(
        URL + "/auth/login",
        { email, password, origen },
        { headers: { "Content-Type": "application/json" } }
      );

      if (listado?.status === 200) {
        if (listado?.data.ok) {
          var {
            id,
            name,
            perfil,
            idempresa,
            nombreempresa = "",
          } = parseJwt(listado.data.tokens.accessToken);

          if (perfil === "UsuarioMovil") {
            isAuthenticated = false;
          } else {
            isAuthenticated = true;

            localStorage.setItem("token", listado.data.tokens.accessToken);
            localStorage.setItem(
              "refresh_token",
              listado.data.tokens.refreshToken
            );
          
            localStorage.setItem("perfil", perfil);
            localStorage.setItem("displayname", name);
            localStorage.setItem("userId", id);
            localStorage.setItem("idempresa", idempresa);
            localStorage.setItem("nombreempresa", nombreempresa);
            localStorage.setItem("userLogin", email);
          }
        }
      }

      dispatch({
        type: "LOGIN",
        payload: email,
        payload2: isAuthenticated,
      });

      //    dispatch(login(email,isAuthenticated));
    } catch (error) {
      console.log(error);
    }
  };
};

export const agregarUsuario = (usuario) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/auth/register",
        { usuario },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_USUARIO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_USUARIO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cerrarSesion = () => {
  return async (dispatch) => {
    try {
     
      localStorage.setItem("email", "");
      localStorage.setItem("useruid", "");
      localStorage.setItem("token", "");
      localStorage.setItem("displayname", "");
      localStorage.setItem("idempresa", "");
      localStorage.setItem("nombreempresa", "");
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.log(error);
    }
  };
};
export const iniciarServicioSunat = (enabled) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/auth/iniciarServicioSunat",
        { enabled },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "ENCENDER_SERVICIO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ENCENDER_SERVICIO",
        payload: false,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
};
export const detenerServicioSunat = (enabled) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/auth/detenerServicioSunat",
        { enabled },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "APAGAR_SERVICIO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "APAGAR_SERVICIO",
        payload: false,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
};
const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem("refresh_token");

  // Realiza una solicitud al servidor para renovar el token de acceso
  // Puedes usar una biblioteca como axios para realizar solicitudes HTTP
  axios
    .post("/auth/get-new-access-token", { refreshToken })
    .then((response) => {
      const newAccessToken = response.data.tokens.accessToken;
      const newRefreshToken = response.data.tokens.refreshToken;
      localStorage.setItem("token", newAccessToken);
      localStorage.setItem("refresh_token", newRefreshToken);
    })
    .catch((error) => {
      // Maneja el error de renovación del token
    });
};
const isAccessTokenExpired = () => {
  const accessToken = localStorage.getItem("token");
  const accessTokenExpiration = Date(); // Obtén la fecha de expiración del token de acceso
  return Date.now() > accessTokenExpiration;
};





export const login = (email, isAuthenticated) => {
  return {
    type: "LOGIN",
    payload: {
      email,
      isAuthenticated,
    },
  };
};

export const obtenerUsuarios = (status) => {
  return async (dispatch) => {
    try {
      const rfids = [];

      var token = localStorage.getItem("token");
      const URL_FInal = URL + "/usuario/getall";
      var lista = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },

        params: {
          status,
        },
      });

      if (lista?.status === 200 && lista?.data?.ok) {
        lista.data.data.forEach((item) => {
          var fecha_creacion = moment(
            item.datos_creacion.fecha_creacion
          ).format("YYYY-MM-DD");
          var hora_creacion = moment(item.datos_creacion.fecha_creacion).format(
            "HH:mm:ss"
          );
          //  rfids.push({...item });
          rfids.push({ ...item, fecha_creacion, hora_creacion });
        });
      }

      dispatch({
        type: "OBTENER_USUARIOS",
        payload: rfids,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerUsuario = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      const URL_FInal = URL + `/usuario/detalle/${id}`;
    
      var lista = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      let objeto = {};
      if (lista?.status === 200 && lista?.data?.ok) {
        objeto = {
          ...lista.data.data,
          //fecha_creacion, hora_creacion
        };
      }

    
      dispatch({
        type: "OBTENER_USUARIO",
        payload: objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
