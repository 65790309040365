import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { obtenerVehiculo } from "../../redux/actions/vehiculo";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  empresa_trans: "",
  placa_vehiculo: "",
  licencia: "",
  nombre_completo: "",
  viaje: "",
};

const Card = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { vehiculo } = state.vehiculo;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }
  const [dataForm, setDataForm] = useState(initDataForm);

  useEffect(() => {
    dispatch(obtenerVehiculo(id));
    setDataForm({ ...vehiculo });
  }, [id]);

  useEffect(() => {
    setDataForm({ ...vehiculo });
  }, [vehiculo]);

  if (!vehiculo) return <h1>Cargando ...</h1>;

  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/vehiculos`}>
       <FormattedMessage id="ir_lista" />
      </Link>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
            <div className="card-title d-flex flex-column">
              <span className="fw-bolder" style={{ color: "#0A5189" }}>
              <FormattedMessage id="vehiculo_details" />
              </span>
            </div>

            <div className="card-title d-flex flex-row align-items-center gap-3">
              <img
                width={50}
                src={require("../../images/driver.png")}
                alt="User Avatar"
              />
              <div className="card-title d-flex flex-column">
                <span className="fw-bolder">{dataForm?.tipo_vehiculo}</span>
                <span className="text-gray-400 fw-light fs-6"> <FormattedMessage id="vehiculo_tipo" /></span>
              </div>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.placa_vehiculo}</span>
              <span className="text-gray-400 fw-light fs-6">
              <FormattedMessage id="vehiculo_placa" />
              </span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.empresa_trans || ""}</span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="vehiculo_empresatrans" /></span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.marca}</span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="vehiculo_marca" /></span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.modelo}</span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="vehiculo_modelo" /></span>
            </div>

            <div className="card-title d-flex flex-column">
              <span className="fw-bolder"> <FormattedMessage id={dataForm?.status || "LIBRE"}/></span>
              <span className="text-gray-400 fw-light fs-6 text-center">
              <FormattedMessage id="vehiculo_status" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
