import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerTagsCamionFiltrados } from "../../redux/actions/tagcamion";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";
import moment from 'moment';
import { Link } from "react-router-dom";
const initDataForm = {

  id_empresa_trans: "",
  chasis_cabezal: "",
  placa_furgon: "",
marca:"",
modelo:""

};

const errorsInit = {
  ...initDataForm,
};

const NuevoFurgon = ({ history,
  agregarNuevoCamion,
  furgon,
  editar = false,
  editarFurgon,
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [empresa_trans, setEmpresaTrans] = useState("");
  const [numero_tag, setNumeroTag] = useState("");
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { listaTagSelect } = state.tagcamion;
  const { listaEmpTransSelect } = state.empresatrans;
  useEffect(() => {

  //  dispatch(obtenerTagsCamionFiltrados());
    dispatch(obtenerEmpresasFiltrados());

  }, []);
  useEffect(() => {
    if (editar) {
      setDataForm({ ...furgon });
     
      setEmpresaTrans(furgon?.empresa_trans);
     
    }
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if ([e.target.name] == "id_empresa_trans" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      setEmpresaTrans(e.nativeEvent.target[index].label);

    }
    if ([e.target.name] == "numero_tag_id" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      setNumeroTag(e.nativeEvent.target[index].label);

    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;
  
    for (let key in dataForm) {
      if (key !== "id" && key !== "numero_tag_id" && key !== "numero_tag" && key!=="usuario") {

        if (dataForm[key].trim() === "" || dataForm[key].length === 2) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {

    e.preventDefault();

    const fecha = moment().format("YYYY-MM-DD");

    const hora = moment().format("HH:mm");
    var dataForm1 = { ...dataForm };

   
    if (isValid()) {
    
      if (editar) {

        dataForm1 = { ...dataForm1, fecha_edicion: fecha, hora_edicion: hora, empresa_trans, numero_tag };
        editarFurgon(dataForm1);
      } else {
        dataForm1 = { ...dataForm1, viaje: "Libre", fecha_creacion: fecha, hora_creacion: hora, empresa_trans, numero_tag };
        agregarNuevoCamion(dataForm1);
      }
      // limpiar los campos
      setDataForm(initDataForm);
    }
  };
  const cancelar = (e) => {
    e.preventDefault();
    history.push("/furgones");
  }
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">

          <div className="col-md-8 ">

            <div className="card card-primary shadow-lg p-3 mb-5 bg-white rounded" style={{ padding: "0em" }}>
              <div className="card-header">
              {editar ?( <h1 className="card-title">Editar Furgon</h1>):( <h1 className="card-title">Agregar Furgon</h1>)}
               
          
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="placa_furgon">Placa Furgon</label>
                    <input
                      type="text"
                      className="form-control"
                      name="placa_furgon"
                      value={dataForm.placa_furgon}
                      onChange={handleChange}
                    />
                    {errors.placa_furgon && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.placa_furgon}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="chasis_cabezal">Chasis Cabezal</label>
                    <input
                      type="text"
                      className="form-control"
                      name="chasis_cabezal"
                      value={dataForm.chasis_cabezal}
                      onChange={handleChange}
                    />
                    {errors.chasis_cabezal && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.chasis_cabezal}
                      </span>
                    )}
                  </div>
                  
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="id_empresa_trans">Empresa Transporte</label>
                      <select

                        type="text"
                        className="form-control"
                        name="id_empresa_trans"
                        value={dataForm.id_empresa_trans}
                        onChange={handleChange}
                      >
                        {
                          listaEmpTransSelect?.map((item, index) =>

                            <option key={index} value={item.value} label={item.label} ></option>

                          )
                        }
                      </select>
                      {errors.id_empresa_trans && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors.id_empresa_trans}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-4">
                    <label htmlFor="marca">Marca</label>
                    <input
                      type="text"
                      className="form-control"
                      name="marca"
                      value={dataForm.marca}
                      onChange={handleChange}
                    />
                    {errors.marca && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.marca}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="modelo">Modelo</label>
                    <input
                      type="text"
                      className="form-control"
                      name="modelo"
                      value={dataForm.modelo}
                      onChange={handleChange}
                    />
                    {errors.modelo && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.modelo}
                      </span>
                    )}
                  </div>
                  </div>
                </div>
</div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar Furgon" : "Agregar Furgon"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/furgones`}>
                    Cancelar
      </Link>
                </div>






              </form >
            </div></div></div></div>
    </section>);
};

export default NuevoFurgon;
