import React from "react";



const RowTable = (props) => {


  const {
   idPiloto,
    nombre_completo,
    licencia,
   identificacion,
    id_empresa_trans
  
  } = props.piloto;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      {idPiloto && <td>{idPiloto}</td>}
      <td>
    {identificacion}
      </td>
      <td>
    {nombre_completo}
      </td>
      <td>
        {licencia}
      </td>
   
      <td>
        {id_empresa_trans}
      </td>
    </tr>
  );
};

export default RowTable;
