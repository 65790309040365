import React, { useEffect, useContext } from "react";
import Formulario from "../../components/Formulario/NuevoFurgon";

import { editarFurgon, obtenerFurgon } from "../../redux/actions/furgon";
import { useDispatch, useSelector } from "react-redux";

const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { furgon, editOk } = state.furgon;

  useEffect(() => {
    // console.log(props.match.params.id);
    dispatch(obtenerFurgon(props?.match?.params?.id));
  }, []);

  useEffect(() => {
   
   
    if (editOk) {
      props.history.push("/furgones");
    }
  }, [editOk]);

  const edicion = (furgon) => {
    // editar producto;
    dispatch(editarFurgon(furgon));
  };

  if (!furgon) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar Furgon</h1>
      <Formulario furgon={furgon} editar={true} editarFurgon={edicion} />
    </div>
  );
};

export default Editar;
