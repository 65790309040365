const initialState = {
    listaContenedores: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    contenedor: null,
    mensajeError:"",
    listaContenedoresSelect:[],
    listaConteExists:[]
  };
  
  export const contenedoresReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_CONTENEDORES":
        return {
          ...state,
          listaContenedores: action.payload,
          listaContenedoresSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          listaConteExists:[]
      
        };
      case "AGREGAR_CONTENEDOR":
        return {
          ...state,
          addOk: action.payload,
          mensajeError:action.payload2,
        };
        case "AGREGAR_CONTENEDOR_LISTA":
          return {
            ...state,
            addOk: action.payload,
            listaConteExists: action.payload2,
          };
        
      case "ELIMINAR_CONTENEDOR":
        return {
          ...state,
          deleteOk: action.payload,
        };
        
      case "OBTENER_CONTENEDOR":
        return {
          ...state,
          contenedor: action.payload,
        };
        case "OBTENER_CONTENEDORES_SELECT":
          return {
            ...state,
            listaContenedoresSelect: action.payload,
          };
      case "EDITAR_CONTENEDOR":
        return {
          ...state,
          editOk: action.payload,
        };
  
      default:
        return state;
    }
  };
  