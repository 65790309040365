import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoPiloto";

import { editarPiloto, obtenerPiloto } from "../../redux/actions/piloto";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { editarCamion } from "../../redux/actions/camion";

const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { piloto, editOk } = state.piloto;
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    // console.log(id);
    dispatch(obtenerPiloto(id));
  }, []);

  useEffect(() => {
    // console.log(editOk);
    if (editOk) {
      history.push("/pilotos");
    }
  }, [editOk]);

  const edicion = (piloto) => {
    // editar producto;
    dispatch(editarPiloto(piloto));
  };

  if (!piloto) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar Piloto</h1>
      <Formulario piloto={piloto} editar={true} editarPiloto={edicion} />
    </div>
  );
};

export default Editar;
