import { useState } from "react";
import next from "../../images/arr071.svg";
import previous from "../../images/arr074.svg";
import first from "../../images/arr079.svg";
import last from "../../images/arr080.svg";
import { FormattedMessage } from "react-intl";

const Pagination = ({
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
  numberPageUser,
  setNumberPageUser,
}) => {
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;

  return (
    <>
      <div className="d-flex justify-content-evenly">
        <button
          className="btn btn-sm text-white"
          style={{ backgroundColor: "#0A5189" }}
          disabled={activePage === 1}
          onClick={() => setActivePage(1)}
        >
          <img src={first} alt="sadas" />
          <FormattedMessage id="table_pag_primero" />
        </button>

        <button
          className="btn btn-sm text-white"
          style={{ backgroundColor: "#0A5189" }}
          disabled={activePage === 1}
          onClick={() => setActivePage(activePage - 1)}
        >
          <img src={previous} alt="sadas" />
          <FormattedMessage id="table_pag_anterior" />
        </button>

        <button
          className="btn btn-sm text-white"
          style={{ backgroundColor: "#0A5189" }}
          disabled={activePage === totalPages}
          onClick={() => setActivePage(activePage + 1)}
        >
         <FormattedMessage id="table_pag_sig" /> <img src={next} alt="..." />
        </button>

        <button
          className="btn btn-sm text-white d-flex justify-content-between align-items-center"
          style={{ backgroundColor: "#0A5189" }}
          disabled={activePage === totalPages}
          onClick={() => setActivePage(totalPages)}
        >
          <FormattedMessage id="table_pag_ultimo" /> <img src={last} alt="..." />
        </button>
      </div>

      <p>
      <FormattedMessage id="table_pag_total" /> {activePage} <FormattedMessage id="table_pag_total_de" /> {totalPages}
      </p>

      <p>
      <FormattedMessage id="table_pag_registro" />{beginning === end ? end : `${beginning} -`}{" "}
        <input
          type="text"
          value={numberPageUser}
          className="text-center"
          style={{ width: "24px" }}
          onChange={(e) => setNumberPageUser(e.target.value)}
        />{" "}
        <FormattedMessage id="table_pag_registro_de" /> {count}
      </p>
    </>
  );
};
export default Pagination;
