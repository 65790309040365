import React, { useEffect, useContext } from "react";
import Formulario from "../../components/Formulario/NuevoClienteEmpre";

import { editarEmpresaCliente, obtenerEmpresa } from "../../redux/actions/clienteempresa";
import { useDispatch, useSelector } from "react-redux";


const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { clienteempre, editOk } = state.clienteempre;

  useEffect(() => {
    // console.log(props.match.params.id);
    dispatch(obtenerEmpresa(props?.match?.params?.id));
  }, []);

  useEffect(() => {
   
    console.log(editOk);
    if (editOk) {
      props.history.push("/clienteempre");
    }
  }, [editOk]);

  const edicion = (clienteempre) => {
    // editar producto;
    dispatch(editarEmpresaCliente(clienteempre));
  };

  if (!clienteempre) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar Cliente Empresa</h1>
      <Formulario clienteempre={clienteempre} editar={true} editarEmpresa={edicion} />
    </div>
  );
};

export default Editar;
