const initialState = {
    listaPilotos: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    piloto: null,
    listaPilotosSelect:[],
    listaPilotoExists:[],
    mensajeError:"",
  };
  
  export const pilotosReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_PILOTOS":
        return {
          ...state,
          listaPilotos: action.payload,
          listaPilotosSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          piloto: null,
          listaPilotoExists:[]
        };
      case "AGREGAR_PILOTO":
        return {
          ...state,
          addOk: action.payload,
          mensajeError: action.payload2
        };
        case "AGREGAR_PILOTO_LISTA":
          return {
            ...state,
            addOk: action.payload,
            listaPilotoExists: action.payload2,
  
          };
          case "BUSCAR_EMPRESA_LISTA":
            return {
              ...state,            
              listaPilotos: action.payload,
    
            };
      case "ELIMINAR_PILOTO":
        return {
          ...state,
          deleteOk: action.payload,
        };
        
      case "OBTENER_PILOTO":
        return {
          ...state,
          piloto: action.payload,
        };
        case "OBTENER_PILOTOS_SELECT":
          return {
            ...state,
            listaPilotosSelect: action.payload,
          };
      case "EDITAR_PILOTO":
        return {
          ...state,
          editOk: action.payload,
        };
  
      default:
        return state;
    }
  };
  