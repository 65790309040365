import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerTagsSelect } from "../../redux/actions/tagcamion";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";
import { obtenerTipoVehiculo } from "../../redux/actions/vehiculo";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { agregarVehiculo,editarVehiculo } from "../../redux/actions/vehiculo";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  id_empresa_trans: "",
  tipo_vehiculo: "",
  placa_vehiculo: "",
  marca: "",
  modelo: "",
  numero_precintos: "",
};

const initVehDataForm = {
  placa_furgon: "",
  placa_camioneta: "",
  placa_trailer: "",
  placa_aco_chasis: "",
  placa_aco_furgon: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalAddVehiculos = ({
 
  vehiculo,
  editar = false,
  
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [vehDataForm, seVehtDataForm] = useState(initVehDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [empresa_trans, setEmpresaTrans] = useState("");
  const [tipo_vehiculo_nombre, setTipoVehNom] = useState("");
  const [numero_tag, setNumeroTag] = useState("");
  const [numero_tag_id, setNumeroTagId] = useState("");
  const [placa_vehiculo, setPlaca] = useState("");
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { listaTagSelect } = state.tagcamion;
  const { listaEmpTransSelect } = state.empresatrans;
  const { listaTipoVehiculoSelect } = state.vehiculo;
  const { addOk, dataError,editOk } = state.vehiculo;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  const RenderTag = () => {
    return (
      <>
        <Form.Select
          value={numero_tag_id}
          onChange={(selectValue) => handleChange2(selectValue)}
        >
            <option value="-1"><FormattedMessage id="Seleccione" /></option>
          {listaTagSelect?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Select>
      </>
    );
  };

  const renderSwitch = (param) => {
   
    switch (param) {
      default:
        return <div></div>;
      case "Acoplado Furgon":
        return (
          <>
            <Col xs={6}>
              <Form.Label className="my-5" htmlFor="placa_aco_furgon">
                Placa
              </Form.Label>

              <Form.Control
                type="text"
                className=" bg-body-secondary border border-0"
                name="placa_aco_furgon"
                value={dataForm.placa_aco_furgon}
                onChange={handleChange}
              />

              {errors.placa_aco_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_furgon}
                </span>
              )}
            </Col>

            <Col xs={6}>
              <Form.Label className="my-5" htmlFor="numero_precintos">
                Numero Precintos
              </Form.Label>

              <Form.Select
                name="numero_precintos"
                value={dataForm.numero_precintos}
                onChange={handleChange}
              >
                <option>Seleccione</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Select>

              {errors.numero_precintos && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_precintos}
                </span>
              )}
            </Col>
          </>
        );

      case "Semi Furgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_semi_furgon">
                  Placa Semi Furgon
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_semi_furgon"
                  value={dataForm.placa_semi_furgon}
                  onChange={handleChange}
                />

                {errors.placa_semi_furgon && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_semi_furgon}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                  Numero Precintos
                </label>

                <select
                  className="form-select"
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Acoplado Chasis":
        return (
          <>
            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_chasis">
                  Placa
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_aco_chasis"
                  value={dataForm.placa_aco_chasis}
                  onChange={handleChange}
                />

                {errors.placa_aco_chasis && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_aco_chasis}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Semi Chasis":
        return (
          <>
            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                  Placa Semi Chasis:
                </label>
                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                />

                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Trailer":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                  Placa Trailer
                </label>
                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                />
                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Chasis":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                  Placa Chasis
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                />
                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Semi Remolque":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_trailer">Placa Trailer</label>
              <input
                type="text"
                className="form-control"
                name="placa_trailer"
                value={dataForm.placa_trailer}
                onChange={handleChange}
              />
              {errors.placa_trailer && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_trailer}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="placa_semi">Placa Semi</label>
              <input
                type="text"
                className="form-control"
                name="placa_semi"
                value={dataForm.placa_semi}
                onChange={handleChange}
              />
              {errors.placa_semi && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi}
                </span>
              )}
            </div>
          </>
        );

      case "Camioneta":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                <FormattedMessage id="vehiculo_placa" />
                </label>

                <input
                  required
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                />

                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                <FormattedMessage id="vehiculo_precintos" />
                </label>

                <select
                  required
                  className="form-select"
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                <FormattedMessage id="vehiculo_tag" />
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Balancin":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_balancin">Placa Balancin</label>
              <input
                type="text"
                className="form-control"
                name="placa_balancin"
                value={dataForm.placa_balancin}
                onChange={handleChange}
              />
              {errors.placa_balancin && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_balancin}
                </span>
              )}
            </div>
          </>
        );

      case "Acoplado":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              <input
                type="text"
                className="form-control"
                name="placa_furgon"
                value={dataForm.placa_furgon}
                onChange={handleChange}
              />
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="chasis_cabezal">Chasis Cabezal</label>
              <input
                type="text"
                className="form-control"
                name="chasis_cabezal"
                value={dataForm.chasis_cabezal}
                onChange={handleChange}
              />
              {errors.chasis_cabezal && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.chasis_cabezal}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="placa_remolque">Placa Remolque</label>
              <input
                type="text"
                className="form-control"
                name="placa_remolque"
                value={dataForm.placa_remolque}
                onChange={handleChange}
              />
              {errors.placa_remolque && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_remolque}
                </span>
              )}
            </div>
          </>
        );
      case "Furgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_furgon">
                  Placa Furgon
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                />

                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                  Numero Precintos
                </label>

                <select
                  className="form-select"
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    if (addOk) {
      history.push("/vehiculos");
    }
  }, [addOk]);
  useEffect(() => {
    if (editOk) {
      history.push("/vehiculos");
    }
  }, [editOk]);

  const nuevo = (veh) => {
    dispatch(agregarVehiculo(veh));
  };

  useEffect(() => {
    if (!editar) {
      //  dispatch(obtenerTagsCamionFiltrados(""));
    }
    dispatch(obtenerTagsSelect("LIBRE"));
    dispatch(obtenerTipoVehiculo());
    dispatch(obtenerEmpresasFiltrados());
  }, []);

  useEffect(() => {
    if (editar) {
      let mivehiculo = { ...vehiculo };
      delete mivehiculo.datos_creacion;
      delete mivehiculo.datos_edicion;
      delete mivehiculo.__v;
      delete mivehiculo.fecha_creacion;
      delete mivehiculo.hora_creacion;
     
      setDataForm({ ...mivehiculo });
      seVehtDataForm({
        ...mivehiculo,
        placa_camioneta: vehiculo.placa_vehiculo,
      });
      setTipoVehNom(vehiculo?.tipo_vehiculo);
      setPlaca(vehiculo?.placa_vehiculo);
      setEmpresaTrans(vehiculo?.empresa_trans);
      setNumeroTag(vehiculo?.numero_tag);
    }
  }, [vehiculo,editar]);

  const handleChange2 = (selectValue) => {
    setNumeroTagId(selectValue.value);

    setNumeroTag(selectValue.label);
  };

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    seVehtDataForm({ ...vehDataForm, [e.target.name]: e.target.value });
    if ([e.target.name] == "id_empresa_trans" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      setEmpresaTrans(e.nativeEvent.target[index].label);
    }
    if ([e.target.name] == "numero_tag_id" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      setNumeroTag(e.nativeEvent.target[index].label);
    }
    if ([e.target.name] == "tipo_vehiculo" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      setTipoVehNom(e.nativeEvent.target[index].label);
    }
    if (
      [e.target.name] == "placa_camioneta" ||
      [e.target.name] == "placa_aco_furgon" ||
      [e.target.name] == "placa_aco_chasis" ||
      [e.target.name] == "placa_semi_chasis" ||
      [e.target.name] == "placa_semi_furgon"
    ) {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "placa_furgon") {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "placa_balancin") {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "chasis_cabezal") {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "placa_trailer") {
      setPlaca(e.target.value);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      console.log(key);
      if (
        key !== "_id" &&
        key !== "numero_tag_id" &&
        key !== "numero_tag" &&
        key !== "usuario" &&
        key !== "numero_precintos"
      ) {
        if (dataForm[key].trim() === "" || dataForm[key].length === 2) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var dataForm1 = { ...dataForm };
    if (!dataForm1.numero_precintos) {
      dataForm1.numero_precintos = "1";
    }

    if (isValid()) {
      if (editar) {
        dataForm1 = {
          ...dataForm1,
          //   ...vehDataForm,
          empresa_trans,
          numero_tag,
        };
        dispatch(editarVehiculo(dataForm1));
      } else {
        dataForm1 = {
          ...dataForm1,
          //   ...vehDataForm,

          empresa_trans,
          numero_tag,
          numero_tag_id,
          //   placa_vehiculo,
          tipo_vehiculo_nombre,
        };
        nuevo(dataForm1);
      }
      // limpiar los campos
     // setDataForm(initDataForm);
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
        {
          editar? <FormattedMessage id="table_editar" />: <FormattedMessage id="Add" />
        }
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
          {
          editar? <FormattedMessage id="vehiculo_edit" />: <FormattedMessage id="vehiculo_add" />
        }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="tipo_vehiculo">
                <FormattedMessage id="vehiculo_tipo" />
                </Form.Label>

                <Form.Select
                  name="tipo_vehiculo"
                  value={dataForm.tipo_vehiculo}
                  onChange={handleChange}
                >
                  <option value="-1"><FormattedMessage id="Seleccione" /></option>
                  <option value="Camioneta">Camioneta</option>
                  <option>Chasis</option>
                  <option>Furgon</option>
                  <option>Trailer</option>
                  <option>Semi Chasis</option>
                  <option>Semi Furgon</option>
                  <option>Acoplado Chasis</option>
                  <option>Acoplado Furgon</option>
                </Form.Select>

                {errors.tipo_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.tipo_vehiculo}
                  </span>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="id_empresa_trans">
                <FormattedMessage id="vehiculo_empresatrans" />
                </Form.Label>

                <Form.Select
                  name="id_empresa_trans"
                  value={dataForm.id_empresa_trans}
                  onChange={handleChange}
                >
                   <option value="-1"><FormattedMessage id="Seleccione" /></option>
                  {listaEmpTransSelect?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>

                {errors.id_empresa_trans && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.id_empresa_trans}
                  </span>
                )}
              </Col>
            </Row>

            <Row>{renderSwitch(tipo_vehiculo_nombre)}</Row>

            <Row>
              <Col>
                <Form.Label className="my-5" htmlFor="marca">
                <FormattedMessage id="vehiculo_marca" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className=" bg-body-secondary border border-0"
                  name="marca"
                  value={dataForm.marca}
                  onChange={handleChange}
                />

                {errors.marca && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.marca}
                  </span>
                )}
              </Col>

              <Col>
                <Form.Label className="my-5" htmlFor="modelo">
                <FormattedMessage id="vehiculo_modelo" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className=" bg-body-secondary border border-0"
                  name="modelo"
                  value={dataForm.modelo}
                  onChange={handleChange}
                />

                {errors.modelo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.modelo}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
           <FormattedMessage id="cancelar" />
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
             {
          editar? <FormattedMessage id="table_editar" />: <FormattedMessage id="Add" />
        }
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalAddVehiculos;
