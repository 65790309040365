import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";

export const agregarPiloto = (entidad) => {
  return async (dispatch) => {
    try {
      const nombre_completo = entidad.nombre_completo;
      const licencia = entidad.licencia;
      const identificacion = entidad.identificacion;
      const id_empresa_trans = entidad.id_empresa_trans;

      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + "/piloto/create",
        { nombre_completo, licencia, id_empresa_trans, identificacion },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("resultado", rfidRec);
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "AGREGAR_PILOTO",
          payload: true,
          payload2: "",
        });
        return;
      }
      dispatch({
        type: "AGREGAR_PILOTO",
        payload: false,
        payload2: rfidRec?.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerPilotos = () => {
  return async (dispatch) => {
    try {
      const arrayLista = [];

      var token = localStorage.getItem("token");
      var lista = await axios.get(URL + "/piloto/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (lista?.status === 200 && lista?.data?.ok) {
        lista.data.data.forEach((item) => {
          arrayLista.push({ ...item, id: item._id });
        });
      }

      dispatch({
        type: "OBTENER_PILOTOS",
        payload: arrayLista,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerPiloto = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var Objeto = await axios.get(`${URL}/piloto/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      let objeto = {};

      if (Objeto?.status === 200 && Objeto.data?.ok) {
        //  var fecha_creacion=moment( Objeto.data.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
        //  var hora_creacion = moment(Objeto.data.datos_creacion.fecha_creacion).format("HH:mm:ss");
        objeto = {
          ...Objeto.data.data,
          //fecha_creacion, hora_creacion
        };
      }

      dispatch({
        type: "OBTENER_PILOTO",
        payload: objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerEmpresasFiltrados = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OBTENER_EMPRESAS",
        //  payload: empresas, payload2:listaEmpresasSelect
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const eliminarPiloto = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/piloto/delete/${id}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok && rfidRec.data.data) {
        dispatch({
          type: "ELIMINAR_PILOTO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ELIMINAR_PILOTO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerPilotosFiltrados = (viaje) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      const arrayLista = [
        { label: "Seleccione una Piloto", value: -1, selected: true },
      ];
      var lista = await axios.get(URL + "/piloto/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          viaje,
        },
      });

      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          arrayLista.push({
            key: item._id,
            label: item.nombre_completo,
            value: item._id,
            selected: false,
          });
        });
      }

      dispatch({
        type: "OBTENER_PILOTOS_SELECT",
        payload: arrayLista,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const editarPiloto = (entidad) => {
  return async (dispatch) => {
    try {
      const nombre_completo = entidad.nombre_completo;
      const licencia = entidad.licencia;
      const identificacion = entidad.identificacion;
      const id_empresa_trans = entidad.id_empresa_trans;
      const viaje = entidad.viaje;
      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + `/piloto/update/${entidad._id}`,
        { nombre_completo, licencia, id_empresa_trans, identificacion, viaje },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("resultado", rfidRec);

      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "EDITAR_PILOTO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "EDITAR_PILOTO",
        payload: false,
        payload2: rfidRec?.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (pilotos) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      console.log("carga masiva", pilotos)
      var rfidRec = await axios.post(
        URL + "/piloto/insertmany",
        { pilotos },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("carga masiva respuesta", rfidRec)
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_PILOTO_LISTA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_PILOTO_LISTA",
        payload: false,
        payload2: rfidRec?.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const buscarEmprePorNombre = (camion) => {
  return async (dispatch) => {
    try {
      /* const camionExisten = [];
      let nombreMod = "";
      for (const domi of camion) {
        nombreMod = domi.empresa_trans;
        nombreMod = nombreMod.replace("/", "");

        let colec1 = db
          .collection("empresatrans")
          .where("nombre", "==", nombreMod);
        const doc = await colec1.get();
        //    if(doc?.docs[0]?.id){

        camionExisten.push({ ...domi, id_empresa_trans: doc?.docs[0]?.id });
        //   }
      }
      console.log("desde buscar empresa", camionExisten);*/
      dispatch({
        type: "BUSCAR_EMPRESA_LISTA",

        //    payload: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
/*
import moment from "moment";

export const obtenerPilotosFiltrados = () => {
  return async (dispatch) => {
    try {
  
      let colec1 = db.collection("pilotos")  
        .where("viaje","==","Libre");
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();
      
      const pilotos = [];
      const listaPilotosSelect=[{label: "Seleccione un Piloto", value: 0, selected:true}];

      doc.forEach((item) => {
        
        pilotos.push({ id: item.id, ...item.data() });
        listaPilotosSelect.push({ key: item.id,label: item.data().nombre_completo, value: item.id, selected:false })
      }); 
      
      dispatch({
        type: "OBTENER_PILOTOS",
        payload: pilotos, payload2:listaPilotosSelect
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};


export const obtenerPilotos = () => {
  return async (dispatch) => {
    try {
      const pilotos = [];
      
    
      const doc = await db.collection("pilotos").get();
     // let colec1 = db.collection("rfids")
      //.where("viaje", "==", true);

   
      //console.log("aqui el doc",doc)
      
     doc.forEach((item) => pilotos.push({ id: item.id, ...item.data() }));
      dispatch({
        type: "OBTENER_PILOTOS",
        payload: pilotos,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarPiloto = (piloto) => {
  return async (dispatch) => {
    try {
      await db.collection("pilotos").add(piloto);
      dispatch({
        type: "AGREGAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const eliminarPiloto = (id) => {
  return async (dispatch) => {
    try {
      await db.collection("pilotos").doc(id).delete();
      dispatch({
        type: "ELIMINAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerPiloto = (id) => {
  return async (dispatch) => {
    try {
      const doc = await db.collection("pilotos").doc(id).get();
      let piloto = {
        id: id,
        ...doc.data(),
      };
      dispatch({
        type: "OBTENER_PILOTO",
        payload: piloto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarPiloto = (piloto) => {
  return async (dispatch) => {
    try {
     
      const pilotoUpdate = { ...piloto };
      delete pilotoUpdate.id;
    
      await db.collection("pilotos").doc(piloto.id).update(pilotoUpdate);
      dispatch({
        type: "EDITAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (piloto) => {
  return async (dispatch) => {
    try {
      const camionExisten = [];
      for(const domi of piloto){
        let colec1 = db.collection("pilotos")
        .where("licencia", "==", domi.licencia);
      const doc = await colec1.get();
      if(doc?.docs[0]?.id){
     
        camionExisten.push({...domi, idPiloto: doc?.docs[0]?.id})
      }
    
      }
     
      piloto=piloto.filter((value)=>
    !camionExisten.find(y=>y.licencia == value.licencia)
    );
    
     let erroneos=false
       var batch = db.batch()
       const fecha = moment().format("YYYY-MM-DD");
        const hora = moment().format("HH:mm");
        const usuario={};
        usuario["uid"]=localStorage.getItem("useruid");
        usuario["email"]=localStorage.getItem("email");
        piloto.forEach((doc) => {
      
        doc.fecha_creacion=fecha;
        doc.hora_creacion=hora;
        doc.usuario=usuario;
      erroneos=true;
      
        var docRef = db.collection("pilotos").doc(); //automatically generate unique id
        batch.set(docRef, doc);
      });
      batch.commit()
     // await db.collection("rfids").add(rfid);
      dispatch({
        type: "AGREGAR_PILOTO_LISTA",
        payload: erroneos,
        payload2:camionExisten
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const buscarEmprePorNombre = (camion) => {
  return async (dispatch) => {
    try {
      const camionExisten = [];
      let nombreMod = "";
      for (const domi of camion) {
        nombreMod = domi.empresa_trans;
        nombreMod = nombreMod.replace("/", "");

        let colec1 = db
          .collection("empresatrans")
          .where("nombre", "==", nombreMod);
        const doc = await colec1.get();
        //    if(doc?.docs[0]?.id){

        camionExisten.push({ ...domi, id_empresa_trans: doc?.docs[0]?.id });
        //   }
      }
      console.log("desde buscar empresa", camionExisten);
      dispatch({
        type: "BUSCAR_EMPRESA_LISTA",

        payload: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};*/
