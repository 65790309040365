import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { cargaMasiva } from "../../redux/actions/empresatrans";
import { useDispatch, useSelector } from "react-redux";
import Tabla from "../../components/TablaRfids/TablaUpload";

const UploadFileEmpresa = (props) => {
  const [mostrarButton, setMostrarButton] = useState(true);

  const [listaColumnas, setListaColumnas] = useState([]);
  const [listaEmpresa, setListaEmpresa] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, listaEmpresaExists } = state.empresatrans;
  const csvFileToArray = (string) => {
    //  console.log("el string", string)
    setListaColumnas(string.slice(0, string.indexOf("\r")).split(","));
    //  setListaRfids(string.slice(string.indexOf("\n") + 1).split("\n"));
    const csvHeader = string.slice(0, string.indexOf("\r")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\r\n");
    //console.log(csvHeader)
    const xxx = csvRows.filter((value) => {
      return value != "";
    });

    const array = xxx.map((i) => {
      const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];

        return object;
      }, {});
      return obj;
    });
    console.log(array);
    setListaEmpresa(array);
    // setArray(array);
  };
  const onDrop = useCallback((acceptedFiles) => {
    setMostrarButton(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const text = reader.result;
        console.log("antes de tarns", text);
        csvFileToArray(text);
        // Do whatever you want with the file contents
        //  const binaryStr = reader.result
      };
      reader.readAsText(file, "ISO-8859-1");
      //  reader.readAsArrayBuffer(file)
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (addOk) {
      props.history.push("/empresatrans");
    }
  }, [addOk]);
  useEffect(() => {
    if (listaEmpresaExists.length > 0) {
      setMostrarButton(false);
      setListaEmpresa(listaEmpresaExists);
      toast("Estos Registros Ya existen");
      listaColumnas.unshift("ID Empresa Asignado");
    }
  }, [listaEmpresaExists]);
  const nuevoEmpresa = (empresa) => {
    dispatch(cargaMasiva(empresa));
    // agregar producto provider
  };

  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/empresatrans`}>
        Atrás
      </Link>

      <ToastContainer />
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div
              {...getRootProps()}
              className="py-10 d-flex justify-content-center flex-column align-items-center gap-3"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p
                  className="fw-bold rounded fs-3 text-center py-10"
                  style={{ border: "3px dashed #0A5189", width: "90%" }}
                >
                  Suelte Sus Archivos AQUI ...
                </p>
              ) : (
                <p
                  className="fw-bold rounded fs-3 text-center py-10"
                  style={{ border: "3px dashed #0A5189", width: "90%" }}
                >
                  Suelte Sus Archivos AQUI, o clic para Elegir su archivo
                </p>
              )}

              <button
                className="btn text-white"
                style={{ backgroundColor: "#0A5189" }}
              >
                <i class="bi bi-cloud-arrow-up-fill text-white"></i>
                Seleccionar Archivo
              </button>
            </div>

            {listaEmpresa.length > 0 && (
              <Tabla
                mostrarButton={mostrarButton}
                insertarLista={nuevoEmpresa}
                nombretabla="empresaupload"
                listaDatos={listaEmpresa}
                listaColumnas={listaColumnas}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFileEmpresa;
