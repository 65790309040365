import React, { useEffect } from "react";
import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import { obtenerTags } from "../../redux/actions/tagcamion";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import { useIntl } from "react-intl";


const TagsCamion = () => {
  const intl =useIntl();
  const listaColumnasx = [
    { accessor: "numero_tag", label:intl.formatMessage({id:"tag_number"}) },
    { accessor: "estadoTag", label: intl.formatMessage({id:"tag_status"})},
    { accessor: "fecha_asignado", label: intl.formatMessage({id:"tag_date_assigned"}) },
    { accessor: "hora_asignado", label: intl.formatMessage({id:"tag_time_assigned"}) },
    { accessor: "placa_vehiculo", label: intl.formatMessage({id:"tag_vehicle"}) },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaTags } = state.tagcamion;

  useEffect(() => {
    dispatch(obtenerTags());
  }, []);

  if (!listaTags) return <p>Cargando ...</p>;

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("tagcamion")}

              <div className="card-title d-flex gap-5">
                {ModalAddGlinseal("tagcamion")}
              </div>
            </div>
            {listaTags.length > 0 && (
              <App
                nombretabla="tagcamion"
                rows={listaTags}
                columns={listaColumnasx}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsCamion;
