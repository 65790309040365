import React ,{useEffect}from "react";
import Tabla from "../../components/TablaRfids";
import App from "../../components/TablaRfids/App";
import {  useSelector, useDispatch } from "react-redux";
import { obtenerCamiones } from "../../redux/actions/camion";
const listaColumnasx = [
  { accessor: 'empresa_trans', label: 'Empresa Trans' },
  { accessor: 'placa_cabezal', label: 'Placa del Cabezal' },
  { accessor: 'chasis_cabezal', label: 'Chasis del Cabezal' },
  { accessor: 'numero_tag', label: 'Numero de Tag' },
  { accessor: 'viaje', label: 'Estado' },
]
const listaColumnas=[
    "Empresa Trans",
    "Placa del Cabezal",
            "Chasis del Cabezal",         
           "Numero de Tag",          
           "Estado"
           
  ];
const Camiones = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaCamiones } = state.camion;



useEffect(() => {
  dispatch(obtenerCamiones());
}, []);
  return (
    <>
   
      <h1>Camiones</h1>
      {listaCamiones.length >0 &&   <App nombretabla="camion" rows={listaCamiones} columns={listaColumnasx} />}
   </>
  );
};

export default Camiones;
