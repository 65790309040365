import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";
import { editarPiloto } from "../../redux/actions/piloto";
import { Stack, Button, Modal, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  nombre_completo: "",
  licencia: "",
  identificacion: "",
};

const errorsInit = {
  ...initDataForm,
};

const Modales = ({ datos, mostrarPopUp }) => {
  const [show, setShow] = useState(false);
  const [empresa_trans, setEmpresaTrans] = useState("");
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, mensajeError } = state.piloto;
  const { editOk } = state.piloto;
  const { listaEmpTransSelect } = state.empresatrans;

  useEffect(() => {
    if (show) {
      delete datos.datos_edicion;
      setDataForm({ ...datos });
    }
  }, [show, datos]);

  useEffect(() => {
    dispatch(obtenerEmpresasFiltrados());
  }, [dispatch]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });

    if ([e.target.name] == "id_empresa_trans") {
      let index = e.nativeEvent.target.selectedIndex;

      setEmpresaTrans(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const HandleCloseReload = () => {
    handleClose();
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() === "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      const dataForm1 = { ...dataForm, empresa_trans };
      dispatch(editarPiloto(dataForm1));
    }
  };

  return (
    <>
      <Button
        className="btn btn-secondary fw-bolder"
        style={{ color: "#0A5189" }}
        onClick={handleShow}
      >
         <FormattedMessage id="table_editar" />
      </Button>

      <Modal show={show} onHide={HandleCloseReload} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Conductor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={4}>
                <Form.Label className="mb-5">Identificación:</Form.Label>

                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="identificacion"
                  onChange={handleChange}
                  value={dataForm?.identificacion || ""}
                />
              </Col>

              <Col xs={8}>
                <Form.Label className="mb-5">Nombre Completo:</Form.Label>

                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="nombre_completo"
                  onChange={handleChange}
                  value={dataForm?.nombre_completo || ""}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <Form.Label className="my-5">Licencia:</Form.Label>

                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="licencia"
                  onChange={handleChange}
                  value={dataForm?.licencia || ""}
                />
              </Col>

              <Col xs={8}>
                <Form.Label className="my-5">Empresa Transporte:</Form.Label>
                <Form.Select
                  name="id_empresa_trans"
                  className="form-select"
                  value={dataForm.id_empresa_trans || ""}
                  onChange={handleChange}
                  aria-label="Default select example"
                >
                  {listaEmpTransSelect?.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      label={item.label}
                    ></option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            onClick={HandleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            Editar Conductor
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default Modales;
