import React, { useEffect, useState } from "react";

import Popup from "../../components/Piloto/PopUpPiloto";
import CardRfid from "../../components/Rfid/CardRfidEmpa";
import { useDispatch, useSelector } from "react-redux";
import PosicionRfids from "../../components/Emparejamientos/ListaPosicionRfids";
import Contenedor from "../../components/Contenedor";
import { obtenerEmparejamientoPrecinto } from "../../redux/actions/emparejamiento";
import CardCamion from "../../components/Camion/CardCamion";
import CardFurgon from "../../components/Furgon/CardFurgon";
import { Link } from "react-router-dom";
import CardChasis from "../../components/Camion/CardChasis";

const initDataForm = {
  idPiloto: "",
  idCamion: "",
  idContenedor: "",
  idFurgon: "",
  idTrailer: "",
  idCamioneta: "",
  idChasis: "",
  idAcoChasis: "",
  idAcoFurgon: "",
  idSemiChasis: "",
  idSemiFurgon: "",
  contenedor_datos: [],
};

const Detalle = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { emparejamiento } = state.emparejamiento;
  const [contenedor_datos, setcontedatos] = useState([]);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [esperaPi, setEsperaPi] = useState(false);
  const numero_emparejamiento = props?.match.params.id2;
  const numero_precinto = props?.match.params.id;

  useEffect(() => {
    dispatch(
      obtenerEmparejamientoPrecinto(numero_emparejamiento, numero_precinto)
    );
  }, []);

  useEffect(() => {
    if (emparejamiento) {
      if (emparejamiento.id) {
        setDataForm({ ...emparejamiento });

        setEsperaPi(true);
      }
    }
  }, [emparejamiento]);

  if (!emparejamiento) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Detalle Precinto</h1>
      <div className="card bg-primary">
        <div className="card-body">
          <div className="form-group">
            <label className="col-md-3" htmlFor="numero_precintos">
              Origen: {dataForm.origen?.toUpperCase()} <br />
              Destino: {dataForm.destino?.toUpperCase()}
            </label>

            <label className="col-md-3" htmlFor="numero_precintos">
              Tipo Vehiculo: {dataForm.vehiculo?.tipo_vehiculo_nombre} <br />{" "}
              Numero Precintos: {dataForm.numero_precintos}
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        {(dataForm.idCamion ||
          dataForm.idTrailer ||
          dataForm.idCamioneta ||
          dataForm.idChasis) && (
          <div className="col-md-4">
            <CardCamion
              id={
                dataForm.idCamion ||
                dataForm.idTrailer ||
                dataForm.idCamioneta ||
                dataForm.idChasis
              }
            />
          </div>
        )}

        {dataForm.idFurgon && (
          <div className="col-md-4">
            {dataForm.idFurgon == "" ? (
              <p>Cargando ...</p>
            ) : (
              <CardFurgon id={dataForm.idFurgon} />
            )}
          </div>
        )}

        {(dataForm.idAcoChasis ||
          dataForm.idSemiChasis ||
          dataForm.idSemiFurgon ||
          dataForm.idAcoFurgon) && (
          <div className="col-md-4">
            <CardChasis
              idAco={
                dataForm.idAcoChasis ||
                dataForm.idSemiFurgon ||
                dataForm.idAcoFurgon ||
                dataForm.idSemiChasis
              }
            />
          </div>
        )}

        {dataForm.contenedor_datos?.length > 0 &&
          dataForm.contenedor_datos.map((item, index) => {
            return (
              <div className="col-md-4">
                <Contenedor id={item.id} numero={item.numero} key={index} />
              </div>
            );
          })}

        {emparejamiento.precintosFinal?.length > 0 &&
          emparejamiento.precintosFinal.map((item, index) => (
            <div className="col-md-4">
              <CardRfid key={index} id={item.numero_precinto} rfid={item} />
              {emparejamiento.precintosFinal?.length > 1 && (
                <Link
                  className="btn btn-success "
                  to={`/emparejamiento/posicion/${item._id}`}
                >
                  Detalle Posicion
                </Link>
              )}{" "}
            </div>
          ))}
      </div>
      <div className="col-md-4">
        {esperaPi && <Popup id={dataForm.idPiloto} />}
        {
          <Link className="btn btn-warning ml-2 mb-3" to={`/emparejamientos`}>
            Ir a la Lista
          </Link>
        }
      </div>
      {emparejamiento.precintosFinal?.length == 1 &&
        emparejamiento.precintosFinal.map((item, index) => (
          <div className="col-md-12">
            <PosicionRfids
              key={index}
              param1="NOMOSTRAR"
              numero_doc_precinto={item._id}
            />
          </div>
        ))}
    </div>
  );
};

export default Detalle;
