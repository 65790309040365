import React, { useState } from "react";

import { eliminarRfid } from "../../redux/actions/rfid";
import { useDispatch } from "react-redux";
import Popup from "../../components/Viajes/PopUpInfo";
import PopupErroneo from "../../components/Rfid/PopUpEnvioLecErr";
import { FormattedMessage } from "react-intl";
import MapModal from "../../components/Mapas/MapModal";
import { Button } from "react-bootstrap";
import 'leaflet/dist/leaflet.css';
const RowTable = (props) => {
  const dispatch = useDispatch();
  const [mostrarPopUp, setMostrarPopUp] = useState(false);

  const { condicion, estado, fecha, hora, latitud, longitud } = props.posicion;

  const { id, indice, nombreEmpresa, perfil, tipo } = props;
  const [showModal, setShowModal] = useState(false);
  const mostrarPopUpF = () => {
    setMostrarPopUp(true);
    // setMostrarPopUp(false);
  };
  const openModal = () => {
    // Set the latitude and longitude here based on your data source
   // Example longitude (New York)
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <tr className="align-middle">
      <th scope="row">{indice + 1}</th>
      {tipo === "TRESESTADOS" && (
        <td>
          {condicion === "ABIERTO" ? (
            <p className="badge py-3 px-4 fs-7 badge-light-warning">
              <FormattedMessage id={condicion} />
            </p>
          ) : condicion === "TERMINO" ? (
            <p className="btn btn-info" style={{ marginTop: "0px" }}>
              <FormattedMessage id={condicion} />
            </p>
          ) : (
            <p className="badge py-3 px-4 fs-7 badge-light-primary">
              <FormattedMessage id={condicion} />
            </p>
          )}
        </td>
      )}

      <td>
        {estado === "OK" ? (
          <p className="badge py-3 px-4 fs-7 badge-light-success">{estado}</p>
        ) : estado === "TERMINO" ? (
          <p className="btn btn-info" style={{ marginTop: "0px" }}>
            {  <FormattedMessage id={estado} />}
          </p>
        ) : (
          <p className="badge py-3 px-4 fs-7 badge-light-danger">{  <FormattedMessage id={estado} />}</p>
        )}
      </td>
      <td>{fecha}</td>
      <td>{hora}</td>
      <td>
      {/*   Lat: {latitud} <br />
        Lon:{longitud}{" "} */}
        { (latitud!="" ) && 
          <>
            <Button variant="success" onClick={openModal}>
            <FormattedMessage id="open_map" />
            </Button>

            <MapModal
              show={showModal}
              onHide={closeModal}
              latitude={latitud}
              longitude={longitud}
            />
          </>
        }
      </td>
      <td className="d-flex justify-content-around ">
        {
          <div>
            {<Popup datos={props.posicion} mostrarPopUp={mostrarPopUp} />}
          </div>
        }
        {
          <div>
            {nombreEmpresa == "Sunat" && perfil == "Administrador" && (
              <PopupErroneo
                id={id}
                datos={props.posicion}
                mostrarPopUp={mostrarPopUp}
              />
            )}
          </div>
        }
      </td>
    </tr>
  );
};

export default RowTable;
