import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";

import { cargaMasiva, buscarEmprePorNombre } from "../../redux/actions/camion";

import { useDispatch, useSelector } from "react-redux";
import Tabla from "../../components/TablaRfids/TablaUpload";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FormattedMessage } from "react-intl";

const downloadExcelTemplate = () => {
  const sheetName = "contenedores";
  const templateData = [
    ["Tipo", "Empresa", "Patente", "Estado"],
    ["", "", "", ""],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(templateData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  const excelBuffer = XLSX.write(workbook, { bookType: "csv", type: "array" });
  const fileName = "Vehículos.csv";
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  FileSaver.saveAs(blob, fileName);
};

const UploadFileCamion = (props) => {
  const [mostrarButton, setMostrarButton] = useState(true);

  const [listaColumnas, setListaColumnas] = useState([]);
  const [listaCamion, setListaCamion] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, listaCamionExists, listaCamiones } = state.camion;
  const csvFileToArray = (string) => {
    //  console.log("el string", string)
    setListaColumnas(string.slice(0, string.indexOf("\r")).split(","));
    //  setListaRfids(string.slice(string.indexOf("\n") + 1).split("\n"));
    const csvHeader = string.slice(0, string.indexOf("\r")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\r\n");
    //console.log(csvHeader)
    const xxx = csvRows.filter((value) => {
      return value != "";
    });

    const array = xxx.map((i) => {
      const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        object["viaje"] = "libre";
        //  object["empresa_trans_id"]=buscarEmpresa()
        return object;
      }, {});
      return obj;
    });
    dispatch(buscarEmprePorNombre(array));
  };
  const onDrop = useCallback((acceptedFiles) => {
    setMostrarButton(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const text = reader.result;
        console.log("antes de tarns", text);
        csvFileToArray(text);
      };
      reader.readAsText(file, "ISO-8859-1");
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (addOk) {
      props.history.push("/camiones");
    }
  }, [addOk]);

  useEffect(() => {
    if (listaCamiones.length > 0) {
      console.log("tiene le entrara", listaCamiones);
      setMostrarButton(true);
      setListaCamion(listaCamiones);
    }
  }, [listaCamiones]);
  useEffect(() => {
    if (listaCamionExists.length > 0) {
      setMostrarButton(false);
      setListaCamion(listaCamionExists);
      toast("Estos Registros Ya existen");
      listaColumnas.unshift("ID Camion Asignado");
    }
  }, [listaCamionExists]);
  const nuevoCamion = (camion) => {
    dispatch(cargaMasiva(camion));
  };

  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/vehiculos`}>
        <FormattedMessage id="Back" />
      </Link>

      <button
        className="btn btn-warning ms-5 mb-5"
        onClick={downloadExcelTemplate}
      >
         <FormattedMessage id="bajar_plantilla" />
      </button>

      <ToastContainer />
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div
              {...getRootProps()}
              className="py-10 d-flex justify-content-center flex-column align-items-center gap-3"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p
                  className="fw-bold rounded fs-3 text-center py-10"
                  style={{ border: "3px dashed #0A5189", width: "90%" }}
                >
                  Suelte Sus Archivos AQUI ...
                </p>
              ) : (
                <p
                  className="fw-bold rounded fs-3 text-center py-10"
                  style={{ border: "3px dashed #0A5189", width: "90%" }}
                >
                  <FormattedMessage id="upload_message_file" />
                </p>
              )}

              <button
                className="btn text-white"
                style={{ backgroundColor: "#0A5189" }}
              >
                <i className="bi bi-cloud-arrow-up-fill text-white"></i>
                <FormattedMessage id="seleccionar_archivo" />
              </button>
            </div>

            {listaCamion.length > 0 && (
              <Tabla
                mostrarButton={mostrarButton}
                insertarLista={nuevoCamion}
                nombretabla="camionupload"
                listaDatos={listaCamion}
                listaColumnas={listaColumnas}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFileCamion;
