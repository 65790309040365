import React from "react";



const RowTable = (props) => {


  const {
   idEmpresa,
    nombre,
    nit,
    telefono,
    email, 
    direccion
   
  
  } = props.empresa;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      {idEmpresa && <td>{idEmpresa}</td>}
    
      <td>
    {nombre}
      </td>
      <td>
    {direccion}
      </td>
      <td>
        {nit}
      </td>
      <td>
        {email}
      </td> 
      <td>
        {telefono}
      </td>
    
   
    </tr>
  );
};

export default RowTable;
