import { useState } from "react";

const UserProfile = () => {
  
  const [displayname,setdisplay]=useState(localStorage.getItem("displayname"))
  const display1= displayname?.split(" ")[0]?.split("")[0];
  const display2= displayname?.split(" ")[1]?.split("")[0];
 /*  {localStorage.displayname.split(" ")[0].split("")[0]}
  {localStorage.displayname.split(" ")[1].split("")[0]} */
  return (
    <div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container container-xxl">
          <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9 pb-0">
              <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <p
                      className="rounded p-5 text-white fw-bolder fs-1"
                      style={{ backgroundColor: "#0A5189" }}
                    >
                     {display1  }
                     { display2 }
                    </p>

                    <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                  </div>
                </div>

                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <span className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">
                          {displayname}
                        </span>

                        <span>
                          <i className="ki-duotone ki-verify fs-1 text-primary">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                        <a
                          href="#ssafascascas"
                          className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                        >
                          <i className="ki-duotone ki-profile-circle fs-4 me-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                          {localStorage.perfil}
                        </a>
                        <a
                          href="#ssafascascas"
                          className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                        >
                          <i className="ki-duotone ki-geolocation fs-4 me-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Lima, Perú
                        </a>
                        <a
                          href="#ssafascascas"
                          className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                        >
                          <i className="ki-duotone ki-sms fs-4 me-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {localStorage.userLogin}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Detalles del Usuario</h3>
              </div>
            </div>

            <div className="card-body p-9">
              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">
                  Nombre Completo
                </label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">
                    {localStorage.displayname}
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">Empresa</label>
                <div className="col-lg-8 fv-row">
                  <span className="fw-semibold text-gray-800 fs-6">
                    {localStorage.nombreempresa}
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">
                  Número de Contacto
                </label>
                <div className="col-lg-8 d-flex align-items-center">
                  <span className="fw-bold fs-6 text-gray-800 me-2">
                    999 999 999
                  </span>
                </div>
              </div>

              <div className="row mb-7">
                <label className="col-lg-4 fw-semibold text-muted">País</label>
                <div className="col-lg-8">
                  <span className="fw-bold fs-6 text-gray-800">Perú</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
