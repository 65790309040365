import moment from "moment";
import axios from "axios";

//import https from "https";
import { URL } from "../../services/VariablesEntorno";
export const obtenerDashboard = () => {
    return async (dispatch) => {
      try {
        var rfid = {};
  
        var token = localStorage.getItem("token");
        const URL_FInal = URL + "/dashboard/getall";
        var listaRfids = await axios.get(`${URL_FInal}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
  
     
        });
    
        if (listaRfids?.status === 200 && listaRfids?.data?.ok) {
       rfid=listaRfids?.data?.data
        }
       
        dispatch({
          type: "OBTENER_DASHBOARD",
          payload: rfid,
        });
      } catch (error) {
        console.log(error);
      }
    };
  };