import ModalContenedor from "../components/Contenedor/ModalContenedor";
import ModalEmparejamientos from "../components/Emparejamientos/ModalEmparejamientos";
import ModalEmpresaTrans from "../components/EmpresTrans/ModalEmpresaTrans";
import ModalAddRFID from "../components/TablaRfids/ModalAddRFID";
import ModalAddPilotos from "../views/Pilotos/ModalAddPilotos";
import ModalTagCamion from "../views/TagCamion/ModalTagCamion";
import ModalUsuarios from "../views/Usuarios/ModalUsuarios";
import ModalAddVehiculos from "../views/Vehiculo/ModalAddVehiculos";
import { FormattedMessage } from "react-intl";
import ActualizarImg from "../images/actualizar.svg";
//? CardTitle
export const CardTitle = (title, precintosCant, actualizarLista) => {
  switch (title) {
    case "rfid":
      return (
        <div className="card-title d-flex flex-column">
          <div className="d-flex gap-3 align-items-center">
            {" "}
    
            <button onClick={actualizarLista} className="btn">
            <img src={ActualizarImg} alt="Actualizar" />
            </button>
            <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
              <FormattedMessage id="precintos" />
            </span>
          </div>

          <span className="text-gray-400 fw-light fs-6">
            <FormattedMessage id="total_precintos" />: {precintosCant}
          </span>
        </div>
      );

    case "emparejamiento":
      return (
        <div className="card-title d-flex flex-column">
       
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="vinculaciones" />
          </span>
          {/* <span className="text-gray-400 fw-light fs-6">
            Total de Vinculaciones
          </span> */}
        </div>
      );

    case "piloto":
      return (
        <div className="card-title d-flex flex-column">
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="conductores" />
          </span>
          {/* <span className="text-gray-400 fw-light fs-6">Total de Conductores</span> */}
        </div>
      );

    case "vehiculo":
      return (
        <div className="card-title d-flex flex-column">
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="vehiculos" />
          </span>
          {/* <span className="text-gray-400 fw-light fs-6">
            Total de vehículos
          </span> */}
        </div>
      );

    case "contenedor":
      return (
        <div className="card-title d-flex flex-column">
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="contenedores" />
          </span>
          {/* <span className="text-gray-400 fw-light fs-6">
            Total de Contenedores
          </span> */}
        </div>
      );

    case "empresatrans":
      return (
        <div className="card-title d-flex flex-column">
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="empresatrans" />
          </span>
          {/* <span className="text-gray-400 fw-light fs-6">Total de Empresas</span> */}
        </div>
      );

    case "tagcamion":
      return (
        <div className="card-title d-flex flex-column">
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="tagvehiculos" />
          </span>

          {/* <span className="text-gray-400 fw-light fs-6">Total de Camiones</span> */}
        </div>
      );

    case "usuarios":
      return (
        <div className="card-title d-flex flex-column">
          <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
            <FormattedMessage id="usuarios" />
          </span>
          {/* <span className="text-gray-400 fw-light fs-6">Total de Usuarios</span> */}
        </div>
      );

    default:
      break;
  }
};

//? ModalAddGlinseal
export const ModalAddGlinseal = (titleModal) => {
  switch (titleModal) {
    case "rfid":
      return <ModalAddRFID />;

    case "emparejamiento":
      return <ModalEmparejamientos />;

    case "piloto":
      return <ModalAddPilotos />;

    case "vehiculo":
      return <ModalAddVehiculos />;

    case "contenedor":
      return <ModalContenedor />;

    case "empresatrans":
      return <ModalEmpresaTrans />;

    case "tagcamion":
      return <ModalTagCamion />;

    case "usuarios":
      return <ModalUsuarios />;

    default:
      break;
  }
};
