import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { obtenerAcoplado } from "../../redux/actions/vehiculo";

const initDataForm = {
  empresa_trans: "",
  numero_tag: "",
  placa_vehiculo: "",
  chasis_cabezal: "",
  viaje: "",
  tipo_vehiculo_nombre: "",
};
const CardChasis = (props) => {
  const { idAco } = props;

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { acochasis } = state.vehiculo;
  // let id = props?.match?.params?.id;
  //let idAco = props?.match?.params?.idAco;
  /*if (!id) {
    id = props.id;
  }
  if (!idAco) {
    
    idAco=props.idAco;
  }*/
  const [dataForm, setDataForm] = useState(initDataForm);
  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (idAco) {
      console.log("al id aco", idAco);
      // dispatch(obtenerVehiculo(idAco));
      dispatch(obtenerAcoplado(idAco));
    }
  }, [idAco]);

  useEffect(() => {
    if (acochasis) {
      console.log("entrando al aco", acochasis);
      setDataForm({ ...acochasis });
    }
  }, [acochasis]);
  const renderSwitch = (param) => {
    switch (param) {
      case "Trailer":
        return require("../../images/trailer2.png");

      default:
        return "";

      case "Chasis":
        return require("../../images/Chasis.png");

      case "Semi Acoplado Chasis":
        return require("../../images/chasis2.jpeg");

      case "Camioneta":
        return require("../../images/camioneta2.png");

      case "Semi Furgon":
        return require("../../images/semifurgon.png");

      case "Semi Chasis":
        return require("../../images/semichasis.jpeg");

      case "Acoplado Chasis":
        return require("../../images/acopladochasis.jpeg");

      case "Acoplado Furgon":
        return require("../../images/acopladofurgon.jpeg");
    }
  };
  return (
    <div className="col-md-12">
      <div
        className="card card-widget widget-user-2"
        style={{ padding: "0em" }}
      >
        <div className="widget-user-header bg-warning">
          <div className="widget-user-image">
            <img
              className="img-circle elevation-2"
              src={renderSwitch(dataForm.tipo_vehiculo)}
              alt="User Avatar"
            />
          </div>

          <h3 className="widget-user-username">{dataForm.placa_vehiculo}</h3>
          <h5 className="widget-user-desc">{dataForm.tipo_vehiculo}</h5>
        </div>
        <div className="card-body">
          <div className="input-group flex-nowrap">
            <div className="input-group-prepend">
              <span className="input-group-text bg-info" id="addon-wrapping">
                Empresa
              </span>
            </div>
            <input
              onChange={handleChange}
              value={dataForm.empresa_trans || ""}
              name="empresa_trans"
              type="text"
              className="form-control"
              aria-label="Username"
              aria-describedby="addon-wrapping"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <i className="fa fa-duotone fa-car"></i>
              </div>
            </div>
          </div>

          <div className="input-group flex-nowrap ">
            <div className="input-group-prepend">
              <span className="input-group-text bg-info" id="addon-wrapping">
                Marca
              </span>
            </div>
            <input
              onChange={handleChange}
              value={dataForm.marca || ""}
              name="marca"
              type="text"
              className="form-control"
              aria-label="marca"
              aria-describedby="addon-wrapping"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <i className="fa fa-light fa-truck"></i>
              </div>
            </div>
          </div>
          <div className="input-group flex-nowrap mb-5">
            <div className="input-group-prepend">
              <span className="input-group-text bg-info" id="addon-wrapping">
                Modelo
              </span>
            </div>
            <input
              onChange={handleChange}
              value={dataForm.modelo || ""}
              name="modelo"
              type="text"
              className="form-control"
              aria-label="modelo"
              aria-describedby="addon-wrapping"
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <i className="fa fa-light fa-truck"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardChasis;
