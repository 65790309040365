import {NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Ruta=({ruta, claseRuta, imgSrc, isCollapsed, idFormattedMessage})=>{
    return(   <NavLink
        className="text-white"
        activeClassName="active"
        to={ruta}
        exact
      >
        <div className={claseRuta}>
          <img src={imgSrc} width={25} alt="..." />
          {
            !isCollapsed &&
            <FormattedMessage id={idFormattedMessage} />
          }
        
        </div>
      </NavLink>)
}

export default Ruta