import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoEmpreTrans";

import { agregarEmpresa } from "../../redux/actions/empresatrans";
import { useDispatch, useSelector } from "react-redux";

const AgregarEmpresa = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.empresatrans;

  useEffect(() => {

    if (addOk) {

      props.history.push("/empresatrans");
    }
  }, [addOk]);

  const nuevoEmpresa = (empresa) => {
    dispatch(agregarEmpresa(empresa));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Agregar Empresa</h1>
      <Formulario agregarNuevoEmpresa={nuevoEmpresa} />
    </div>
  );
};

export default AgregarEmpresa;