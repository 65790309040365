// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name-circle {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: lightblue;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.name-circle.hovered {
  background-color: lightgreen;
}

.full-name {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0.8;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/helpers/NameCircle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;EAC3B,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".name-circle {\n  display: grid;\n  place-items: center;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: lightblue;\n  font-size: 20px;\n  font-weight: bold;\n  cursor: pointer;\n  position: relative;\n  transition: background-color 0.3s;\n}\n\n.name-circle.hovered {\n  background-color: lightgreen;\n}\n\n.full-name {\n  position: absolute;\n  top: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: black;\n  color: white;\n  padding: 5px;\n  border-radius: 5px;\n  font-size: 14px;\n  opacity: 0.8;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
