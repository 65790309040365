import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoContenedor";

import { agregarContenedor } from "../../redux/actions/contenedor";
import { useDispatch, useSelector } from "react-redux";

const AgregarContenedor = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, mensajeError } = state.contenedor;

  useEffect(() => {
    if (addOk) {
      props.history.push("/contenedores");
    }
  }, [addOk]);

  const nuevoContenedor = (contenedor) => {
    dispatch(agregarContenedor(contenedor));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Agregar Contenedor</h1>
      <Formulario
        agregarNuevoContenedor={nuevoContenedor}
        mensajeError={mensajeError}
      />
    </div>
  );
};

export default AgregarContenedor;
