
import moment from "moment";
export const obtenerCamionesParaTagFiltrados = (filtro) => {
  return async (dispatch) => {
    try {
/*      const camiones = [];
      const listaCamionesSelect = [
        { label: "Seleccione un Camion", value: -1, selected: true },
      ];
      let colec1 = db.collection("camiones").where("numero_tag", "==", "");
      if (filtro) {
        let colec2 = db
          .collection("camiones")
          .where("numero_tag", "==", filtro);
        const doc1 = await colec2.get();
        doc1.forEach((item) => {
          camiones.push({ id: item.id, ...item.data() });
          listaCamionesSelect.push({
            key: item.id,
            label: item.data().placa_cabezal,
            value: item.id,
            selected: false,
          });
        });
      }

      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();

      doc.forEach((item) => {
        camiones.push({ id: item.id, ...item.data() });
        listaCamionesSelect.push({
          key: item.id,
          label: item.data().placa_cabezal,
          value: item.id,
          selected: false,
        });
      });

      dispatch({
        type: "OBTENER_CAMIONES",
        payload: camiones,
        payload2: listaCamionesSelect,
      });*/
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerCamionesFiltrados = () => {
  return async (dispatch) => {
    try {
  /*    let colec1 = db.collection("camiones"); //.where("numero_tag", "==", "");
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();
      let colec2 = db.collection("camiones").where("numero_tag", "!=", "");
      const doc2 = await colec2.get();
      const camiones = [];
      const listaCamionesQuitar = [];
      let listaCamionesSelect = [
        { label: "Seleccione un Camion", value: -1, selected: true },
      ];
      doc2.forEach((item) => {
        listaCamionesQuitar.push({
          key: item.id,
          label: item.data().placa_cabezal,
          value: item.id,
          selected: false,
        });
      });
      doc.forEach((item) => {
        camiones.push({ id: item.id, ...item.data() });
        listaCamionesSelect.push({
          key: item.id,
          label: item.data().placa_cabezal,
          value: item.id,
          selected: false,
        });
      });
      if (listaCamionesQuitar.length > 0) {
        listaCamionesSelect = listaCamionesSelect.filter((valor) => {
          console.log(valor);
          return !listaCamionesQuitar.find((y) => y.value == valor.value);
        });
      }
      dispatch({
        type: "OBTENER_CAMIONES",
        payload: camiones,
        payload2: listaCamionesSelect,
      });*/
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const obtenerCamion = (id) => {
  return async (dispatch) => {
    try {
  /*    const doc = await db.collection("camiones").doc(id).get();
      let camion = {
        id: id,
        ...doc.data(),
      };

      dispatch({
        type: "OBTENER_CAMION",
        payload: camion,
      });*/
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerCamiones = () => {
  return async (dispatch) => {
    try {
     /* const camiones = [];

      const doc = await db.collection("camiones").get();

      doc.forEach((item) => camiones.push({ id: item.id, ...item.data() }));
      dispatch({
        type: "OBTENER_CAMIONES",
        payload: camiones,
      });*/
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarCamion = (camion) => {
  return async (dispatch) => {
    try {
   /*   await db.collection("camiones").add(camion);
      if (camion.numero_tag_id != 1 || camion.numero_tag_id != null) {
        const fecha = moment().format("YYYY-MM-DD");
        const hora = moment().format("HH:mm");
        const tagUpdate = {
          estadoTag: "Asignado",
          placa_cabezal: camion.placa_cabezal,
          fecha_asignado: fecha,
          hora_asignado: hora,
        };
        delete tagUpdate.id;

        await db
          .collection("tagscamion")
          .doc(camion.numero_tag_id)
          .update(tagUpdate);
      }
      dispatch({
        type: "AGREGAR_CAMION",
        payload: true,
      });*/
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarCamion = (camion) => {
  return async (dispatch) => {
    try {
    
      dispatch({
        type: "EDITAR_CAMION",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const cargaMasiva = (camion) => {
  return async (dispatch) => {
    try {
    
    } catch (error) {
      console.log(error);
    }
  };
};

export const buscarEmprePorNombre = (camion) => {
  return async (dispatch) => {
    try {
      const camionExisten = [];
      let nombreMod = "";
    
      console.log("desde buscar empresa", camionExisten);
      dispatch({
        type: "BUSCAR_CAMION_LISTA",

      //  payload: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
