const initialState = {
    listaFurgones: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    furgon: null,
    listaFurgonesSelect:[],
    listaFurgonExists:[]
  };
  
  export const furgonesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_FURGONES":
        return {
          ...state,
          listaFurgones: action.payload,
          listaFurgonesSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          furgon: null,
        };
      case "AGREGAR_FURGON":
        return {
          ...state,
          addOk: action.payload,
        };
        case "AGREGAR_FURGON_LISTA":
          return {
            ...state,
            addOk: action.payload,
            listaFurgonExists: action.payload2,
  
          };
          case "BUSCAR_EMPRESA_LISTA":
            return {
              ...state,            
              listaFurgones: action.payload,
    
            };

  
      default:
        return state;
    }
  };
  