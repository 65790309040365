import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoVehiculo";

import { agregarVehiculo } from "../../redux/actions/vehiculo";
import { useDispatch, useSelector } from "react-redux";

const Agregar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.vehiculo;
  
  useEffect(() => {

    if (addOk) {

      props.history.push("/vehiculos");
    }
  }, [addOk]);

  const nuevo = (veh) => {
    dispatch(agregarVehiculo(veh));
   
  };
  return (
    <div>
      <Formulario agregarNuevo={nuevo} />
    </div>
  );
};

export default Agregar;