import moment from "moment";
import axios from "axios";

//import https from "https";
import { URL } from "../../services/VariablesEntorno";

const axiosInstance = axios.create({
  baseURL: URL, // Replace with your API base URL
  headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`, // Replace with your actual bearer token
    'Content-Type': 'application/json',
  },

});

export const api = {
  get: (url, params) => axiosInstance.get(url, { 
    status:"STOCK",
    tipo:"TRESESTADOS",
   }),
};

export const enviarLecturaError = (rfid) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/rfid/lecturaError/${rfid.id}`,
        {rfid},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok ) {
        dispatch({
          type: "LECTURA_ERRONEA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "LECTURA_ERRONEA",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const deletemany = (idempresa) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/rfid/deletemany",
        { idempresa },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
     
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "ELIMINAR_RFID",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ELIMINAR_RFID",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const eliminarRfid = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/rfid/delete/${id}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok && rfidRec.data.data) {
        dispatch({
          type: "ELIMINAR_RFID",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ELIMINAR_RFID",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerRfidsViajePosicionFiltrados = (numero_doc_precinto) => {
  return async (dispatch) => {
    try {
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = []; // await colec1.get();
      const rfids = [];
      var token = localStorage.getItem("token");
      var listaRfids = await axios.get(URL + "/rfid/getall", {
        headers: { token },
      });

      const viajes = [];

      doc.forEach((item) => {
        let newObj = { ...item.data() };
        if (newObj.id) {
          delete newObj.id;
        }
        var fechaOrder = new Date(item.data().fecha + " " + item.data().hora);

        viajes.push({ id: item.id, fechaOrder, ...newObj });
      });
      viajes.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.fechaOrder) - new Date(a.fechaOrder);
      });
      dispatch({
        type: "OBTENER_POSICION_RFIDS_DETALLE",
        payload: viajes,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerRfidsViajeFiltrados = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OBTENER_RFIDS",
        //   payload: rfids, payload2: listaRfidsSelect
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerRfidsFiltrados = () => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var listaRfids = await axios.get("http://localhost:3000/listaRfids", {
        headers: { token },
      });
      listaRfids = await listaRfids.data.data;

      const rfids = [];
      const listaRfidsSelect = [
        { label: "Seleccione un RFid", value: 0, selected: true },
      ];

      /*  doc.forEach((item) => {
        console.log("aqui los docsss", item.id);
        rfids.push({ id: item.id, ...item.data() });
        listaRfidsSelect.push({ key: item.id, label: item.data().numero_precinto, value: item.id, selected: false })
     }); */

      dispatch({
        type: "OBTENER_RFIDS",
        payload: rfids,
        payload2: false,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const obtenerRfids2 = (status, tipo) => {
  return async (dispatch) => {
    try {
      const rfids = [];

      var token = localStorage.getItem("token");
      const URL_FInal = URL + "/rfid/getall";
      var listaRfids = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },

        params: {
          status,
          tipo,
        },
      });

      if (listaRfids?.status === 200 && listaRfids?.data?.ok) {
        listaRfids.data.data.forEach((item) => {
          var fecha_creacion = moment(
            item.datos_creacion.fecha_creacion
          ).format("YYYY-MM-DD");
          var hora_creacion = moment(item.datos_creacion.fecha_creacion).format(
            "HH:mm:ss"
          );

          rfids.push({
            ...item,
            fecha_creacion,
            hora_creacion,
          });
        });
      }
     
      dispatch({
        type: "OBTENER_RFIDS",
        payload: rfids,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarRfid = (rfid) => {
  return async (dispatch) => {
    try {
      const tipo = rfid.tipo;
      const numero_precinto = rfid.numero_precinto;
      const TID = rfid.TID;
      const TID2 = rfid.TID2;
      const estado = rfid.estado;
      const clase = rfid.clase;
      const condicion = rfid.condicion;
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/rfid/create",
        { numero_precinto, TID, TID2, estado, condicion, tipo, clase },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
     
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_RFID",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_RFID",
        payload: false,
        payload2: rfidRec.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerRfid = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var Rfid = await axios.get(`${URL}/rfid/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      let rfid = {};
      const posiciones = [];

      if (Rfid?.status === 200 && Rfid?.data.ok) {
        var fecha_creacion = moment(Rfid.fecha_creacion).format("YYYY-MM-DD");
        var hora_creacion = moment(Rfid.fecha_creacion).format("HH:mm:ss");

        rfid = {
          ...Rfid.data.data,
         
          fecha_creacion,
          hora_creacion,
        };

        rfid.posicion?.forEach((item) => {
          var fecha = moment(item.fecha).format("YYYY-MM-DD");
          var hora = moment(item.fecha).format("HH:mm:ss");

          posiciones.push({ ...item, fecha, hora });
        });
      }

      dispatch({
        type: "OBTENER_RFID",
        payload: rfid,
        payload2: posiciones,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarRfid = (rfid) => {
  return async (dispatch) => {
    try {
      const rfidUpdate = { ...rfid };
      const rfidUpdateOtros = { viaje: "Viaje" };

      dispatch({
        type: "EDITAR_RFID",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarRfidViaje = (rfid) => {
  return async (dispatch) => {
    try {
      //  await db.collection("viajesrfid").add(rfid);

      const rfidUpdate = { condicion: rfid.condicion, estado: rfid.estado };

      //   await db.collection("rfids").doc(rfid.numero_doc_precinto).update(rfidUpdate);
      //  const doc = await colec1.get();

      //  await editarRfid(rfid);
      dispatch({
        type: "AGREGAR_RFID_VIAJE",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarArrayRfid = (rfids) => {
  return async (dispatch) => {
    try {
      //  const fecha = moment().format("YYYY-MM-DD");
      //     const hora = moment().format("HH:mm");
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/rfid/insertmany",
        { rfids },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_RFID_LISTA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_RFID_LISTA",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
