import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const initDataForm = {
  nombre: "",
  nit: "",
  nombre_contacto: "",
  telefono: "",
  email: "",
  direccion: "",
};

const errorsInit = {
  ...initDataForm,
};

const NuevoEmpresa = ({
  agregarNuevoEmpresa,
  empresa,
  editar = false,
  editarEmpresa,
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);

  useEffect(() => {
    if (editar) {
      setDataForm({ ...empresa });
    }
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      console.log("xxxx", key);
      if (key !== "id" && key !== "usuario") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const dataForm1 = { ...dataForm };
    e.preventDefault();
    if (isValid()) {
      // mandar la data al proveedor
      if (editar) {
        editarEmpresa(dataForm);
      } else {
        agregarNuevoEmpresa(dataForm1);
      }
      // limpiar los campos
      setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                {editar ? (
                  <h1 className="card-title">Editar Empresa</h1>
                ) : (
                  <h1 className="card-title">Agregar Empresa</h1>
                )}
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre"
                      value={dataForm.nombre}
                      onChange={handleChange}
                    />
                    {errors.nombre && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.nombre}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="nit">NIT</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nit"
                      value={dataForm.nit}
                      onChange={handleChange}
                    />
                    {errors.nit && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.nit}
                      </span>
                    )}
                  </div>
                  
                  <div className="form-group col-md-4">
                    <label htmlFor="nombre_contacto">Nombre Contacto</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre_contacto"
                      value={dataForm.nombre_contacto}
                      onChange={handleChange}
                    />
                    {errors.nombre_contacto && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.nombre_contacto}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="telefono">Telefono</label>
                    <input
                      type="text"
                      className="form-control"
                      name="telefono"
                      value={dataForm.telefono}
                      onChange={handleChange}
                    />
                    {errors.telefono && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.telefono}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={dataForm.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="direccion">Direccion</label>
                    <input
                      type="text"
                      className="form-control"
                      name="direccion"
                      value={dataForm.direccion}
                      onChange={handleChange}
                    />
                    {errors.direccion && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.direccion}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar Empresa" : "Agregar Empresa"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/empresatrans`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoEmpresa;
