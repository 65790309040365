import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerTagsSelect } from "../../redux/actions/tagcamion";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";
import { obtenerTipoVehiculo } from "../../redux/actions/vehiculo";
/* import VirtualizedSelect from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import moment from "moment"; */
import { Link } from "react-router-dom";

const initDataForm = {
  id_empresa_trans: "",

  tipo_vehiculo: "",

  marca: "",
  modelo: "",
};
const initVehDataForm = {
  placa_furgon: "",
  placa_camioneta: "",
  placa_trailer: "",
  placa_aco_chasis: "",
  placa_aco_furgon: "",
  numero_precintos: "",
};

const errorsInit = {
  ...initDataForm,
};

const NuevoVehiculo = ({
  history,
  agregarNuevo,
  vehiculo,
  editar = false,
  editarVehiculo,
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [vehDataForm, seVehtDataForm] = useState(initVehDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [empresa_trans, setEmpresaTrans] = useState("");
  const [tipo_vehiculo_nombre, setTipoVehNom] = useState("");
  const [numero_tag, setNumeroTag] = useState("");
  const [numero_tag_id, setNumeroTagId] = useState("");
  const [placa_vehiculo, setPlaca] = useState("");

  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { listaTagSelect } = state.tagcamion;
  const { listaEmpTransSelect } = state.empresatrans;
  const { listaTipoVehiculoSelect } = state.vehiculo;
  const RenderTag = () => {
    return (<></>
/*       <VirtualizedSelect
        value={numero_tag_id}
        onChange={(selectValue) => handleChange2(selectValue)}
        options={listaTagSelect?.map((item, index) => ({
          label: item.label,
          value: item.value,
        }))}
      ></VirtualizedSelect> */
    );
  };

  const renderSwitch = (param) => {
    switch (param) {
      default:
        return <div></div>;
      case "Acoplado Furgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_aco_furgon">Placa</label>
              <input
                type="text"
                className="form-control"
                name="placa_aco_furgon"
                value={dataForm.placa_aco_furgon}
                onChange={handleChange}
              />
              {errors.placa_aco_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_precintos">Numero Precintos</label>
              <select
                className="form-control"
                name="numero_precintos"
                value={dataForm.numero_precintos}
                onChange={handleChange}
              >
                <option>Seleccione</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              {errors.numero_precintos && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_precintos}
                </span>
              )}
            </div>
          </>
        );

      case "Semi Furgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_semi_furgon">Placa Semi Furgon</label>
              <input
                type="text"
                className="form-control"
                name="placa_semi_furgon"
                value={dataForm.placa_semi_furgon}
                onChange={handleChange}
              />
              {errors.placa_semi_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_precintos">Numero Precintos</label>
              <select
                className="form-control"
                name="numero_precintos"
                value={dataForm.numero_precintos}
                onChange={handleChange}
              >
                <option>Seleccione</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              {errors.numero_precintos && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_precintos}
                </span>
              )}
            </div>
          </>
        );
      case "Acoplado Chasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_aco_chasis">Placa</label>
              <input
                type="text"
                className="form-control"
                name="placa_aco_chasis"
                value={dataForm.placa_aco_chasis}
                onChange={handleChange}
              />
              {errors.placa_aco_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_chasis}
                </span>
              )}
            </div>
          </>
        );
      case "Semi Chasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_semi_chasis">Placa Semi Chasis</label>
              <input
                type="text"
                className="form-control"
                name="placa_semi_chasis"
                value={dataForm.placa_semi_chasis}
                onChange={handleChange}
              />
              {errors.placa_semi_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi_chasis}
                </span>
              )}
            </div>
          </>
        );

      case "Trailer":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_trailer">Placa Trailer</label>
              <input
                type="text"
                className="form-control"
                name="placa_trailer"
                value={dataForm.placa_trailer}
                onChange={handleChange}
              />
              {errors.placa_trailer && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_trailer}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_tag_id">Numero TAG</label>

              <RenderTag />
              {errors.numero_tag_id && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_tag_id}
                </span>
              )}
            </div>
          </>
        );

      case "Chasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_balancin">Placa Chasis</label>
              <input
                type="text"
                className="form-control"
                name="placa_balancin"
                value={dataForm.placa_balancin}
                onChange={handleChange}
              />
              {errors.placa_balancin && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_balancin}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_tag_id">Numero TAG</label>

              <RenderTag />
              {errors.numero_tag_id && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_tag_id}
                </span>
              )}
            </div>
          </>
        );
      case "Semi Remolque":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_trailer">Placa Trailer</label>
              <input
                type="text"
                className="form-control"
                name="placa_trailer"
                value={dataForm.placa_trailer}
                onChange={handleChange}
              />
              {errors.placa_trailer && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_trailer}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="placa_semi">Placa Semi</label>
              <input
                type="text"
                className="form-control"
                name="placa_semi"
                value={dataForm.placa_semi}
                onChange={handleChange}
              />
              {errors.placa_semi && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi}
                </span>
              )}
            </div>
          </>
        );
      case "Camioneta":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_camioneta">Placa Camioneta</label>
              <input
                required
                type="text"
                className="form-control"
                name="placa_camioneta"
                value={vehDataForm.placa_camioneta}
                onChange={handleChange}
              />
              {errors.placa_camioneta && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_camioneta}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_precintos">Numero precintos</label>
              <select
                required
                className="form-control"
                name="numero_precintos"
                value={vehDataForm.numero_precintos}
                onChange={handleChange}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
              {errors.numero_precintos && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_precintos}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_tag_id">Numero TAG</label>

              <RenderTag />
              {errors.numero_tag_id && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_tag_id}
                </span>
              )}
            </div>
          </>
        );
      case "Balancin":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_balancin">Placa Balancin</label>
              <input
                type="text"
                className="form-control"
                name="placa_balancin"
                value={dataForm.placa_balancin}
                onChange={handleChange}
              />
              {errors.placa_balancin && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_balancin}
                </span>
              )}
            </div>
          </>
        );
      case "Acoplado":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              <input
                type="text"
                className="form-control"
                name="placa_furgon"
                value={dataForm.placa_furgon}
                onChange={handleChange}
              />
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="chasis_cabezal">Chasis Cabezal</label>
              <input
                type="text"
                className="form-control"
                name="chasis_cabezal"
                value={dataForm.chasis_cabezal}
                onChange={handleChange}
              />
              {errors.chasis_cabezal && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.chasis_cabezal}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="placa_remolque">Placa Remolque</label>
              <input
                type="text"
                className="form-control"
                name="placa_remolque"
                value={dataForm.placa_remolque}
                onChange={handleChange}
              />
              {errors.placa_remolque && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_remolque}
                </span>
              )}
            </div>
          </>
        );
      case "Furgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              <input
                type="text"
                className="form-control"
                name="placa_furgon"
                value={dataForm.placa_furgon}
                onChange={handleChange}
              />
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_precintos">Numero Precintos</label>
              <select
                className="form-control"
                name="numero_precintos"
                value={dataForm.numero_precintos}
                onChange={handleChange}
              >
                <option>Seleccione</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
              {errors.numero_precintos && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_precintos}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero_tag_id">Numero TAG</label>

              <RenderTag />
              {errors.numero_tag_id && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.numero_tag_id}
                </span>
              )}
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    if (!editar) {
      //  dispatch(obtenerTagsCamionFiltrados(""));
    }
    dispatch(obtenerTagsSelect("LIBRE"));
    dispatch(obtenerTipoVehiculo());
    dispatch(obtenerEmpresasFiltrados());
  }, []);

  useEffect(() => {
    if (editar) {
      //  dispatch(obtenerTagsCamionFiltrados(camion.placa_cabezal));
      let mivehiculo = { ...vehiculo };
      delete mivehiculo.datos_creacion;
      delete mivehiculo.datos_edicion;
      delete mivehiculo.__v;
      //delete mivehiculo._id;
      delete mivehiculo.fecha_creacion;
      delete mivehiculo.hora_creacion;
      setDataForm({ ...mivehiculo });
      seVehtDataForm({
        ...mivehiculo,
        placa_camioneta: vehiculo.placa_vehiculo,
      });
      setTipoVehNom(vehiculo?.tipo_vehiculo);
      setPlaca(vehiculo?.placa_vehiculo);
      setEmpresaTrans(vehiculo?.empresa_trans);
      setNumeroTag(vehiculo?.numero_tag);
    }
  }, []);

  const handleChange2 = (selectValue) => {
    setNumeroTagId(selectValue.value);

    setNumeroTag(selectValue.label);
  };

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    seVehtDataForm({ ...vehDataForm, [e.target.name]: e.target.value });
    if ([e.target.name] == "id_empresa_trans" && e.target.value != -1) {
      let index = e.natieEvent.target.selectedIndex;
      setEmpresaTrans(e.ativeEvent.target[index].label);
    }
    if ([e.target.name] == "numero_tag_id" && e.target.value != -1) {
      let index = e.natieEvent.target.selectedIndex;
      setNumeroTag(e.natveEvent.target[index].label);
    }
    if ([e.target.name] == "tipo_vehiculo" && e.target.value != -1) {
      let index = e.natieEvent.target.selectedIndex;
      setTipoVehNom(e.naiveEvent.target[index].label);
    }
    if (
      [e.target.name] == "placa_camioneta" ||
      [e.target.name] == "placa_aco_furgon" ||
      [e.target.name] == "placa_aco_chasis" ||
      [e.target.name] == "placa_semi_chasis" ||
      [e.target.name] == "placa_semi_furgon"
    ) {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "placa_furgon") {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "placa_balancin") {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "chasis_cabezal") {
      setPlaca(e.target.value);
    }
    if ([e.target.name] == "placa_trailer") {
      setPlaca(e.target.value);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (
        key != "_id" &&
        key != "numero_tag_id" &&
        key != "numero_tag" &&
        key != "usuario"
      ) {
        if (dataForm[key].trim() === "" || dataForm[key].length === 2) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var dataForm1 = { ...dataForm };
    if (!dataForm1.numero_precintos) {
      dataForm1.numero_precintos = 1;
    }

    if (isValid()) {
      if (editar) {
        dataForm1 = {
          ...dataForm1,
          ...vehDataForm,
          empresa_trans,
          numero_tag,
        };
        editarVehiculo(dataForm1);
      } else {
        dataForm1 = {
          ...dataForm1,
          ...vehDataForm,

          empresa_trans,
          numero_tag,
          numero_tag_id,
          placa_vehiculo,
          tipo_vehiculo_nombre,
        };
        agregarNuevo(dataForm1);
      }
      // limpiar los campos
      setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                {editar ? (
                  <h1 className="card-title">Editar Vehiculo</h1>
                ) : (
                  <h1 className="card-title">Agregar Vehiculo</h1>
                )}
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="card-body">
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="tipo_vehiculo">Tipo Vehiculo</label>
                      <select
                        type="text"
                        className="form-control"
                        name="tipo_vehiculo"
                        value={dataForm.tipo_vehiculo}
                        onChange={handleChange}
                      >
                        <option value="-1">Seleccione</option>
                        <option>Camioneta</option>
                        <option>Chasis</option>
                        <option>Furgon</option>
                        <option>Trailer</option>
                        <option>Semi Chasis</option>
                        <option>Semi Furgon</option>
                        <option>Acoplado Chasis</option>
                        <option>Acoplado Furgon</option>
                      </select>
                      {errors.tipo_vehiculo && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors.tipo_vehiculo}
                        </span>
                      )}
                    </div>

                    {renderSwitch(tipo_vehiculo_nombre)}

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="id_empresa_trans">
                          Empresa Transporte
                        </label>
                        <select
                          type="text"
                          className="form-control"
                          name="id_empresa_trans"
                          value={dataForm.id_empresa_trans}
                          onChange={handleChange}
                        >
                          {listaEmpTransSelect?.map((item, index) => (
                            <option
                              key={index}
                              value={item.value}
                              label={item.label}
                            ></option>
                          ))}
                        </select>
                        {errors.id_empresa_trans && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errors.id_empresa_trans}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="marca">Marca</label>
                        <input
                          type="text"
                          className="form-control"
                          name="marca"
                          value={dataForm.marca}
                          onChange={handleChange}
                        />
                        {errors.marca && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errors.marca}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="modelo">Modelo</label>
                        <input
                          type="text"
                          className="form-control"
                          name="modelo"
                          value={dataForm.modelo}
                          onChange={handleChange}
                        />
                        {errors.modelo && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errors.modelo}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary">
                    {editar ? "Editar Vehiculo" : "Agregar Vehiculo"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/vehiculos`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoVehiculo;
