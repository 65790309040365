import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoEmparejamiento";

import { agregarEmparejamiento } from "../../redux/actions/emparejamiento";
import { useDispatch, useSelector } from "react-redux";

const Agregar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.emparejamiento;
  
  useEffect(() => {

    if (addOk) {

      props.history.push("/emparejamientos");
    }
  }, [addOk]);

  const nuevo = (emp) => {
    dispatch(agregarEmparejamiento(emp));
  
  };
  return (
    <div>
      <Formulario agregarNuevoEmparejamiento={nuevo} />
    </div>
  );
};

export default Agregar;