import React ,{useEffect}from "react";
import Tabla from "../../components/TablaRfids";
import App from "../../components/TablaRfids/App";
import {  useSelector, useDispatch } from "react-redux";
import { obtenerEmpresas } from "../../redux/actions/clienteempresa";
import { Link } from "react-router-dom";
const listaColumnasx = [
  { accessor: 'nombre', label: 'Nombre' },
  { accessor: 'nit', label: 'NIT' },
  { accessor: 'telefono', label: 'Telefono' },
  { accessor: 'email', label: 'Email' },

]
const listaColumnas=[
    "Nombre",
            "NIT",         
    //       "Nombre Contacto",
           "Telefono",
           "Email"
           
  ];
const EmpTrans = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaEmpCliente } = state.clienteempre;



useEffect(() => {
  dispatch(obtenerEmpresas());
}, []);
  return (
    <>
   
     
      <h1 className="d-inline">Cliente Empresa</h1>
     <Link className="btn btn-primary mb-2 ml-3" to={`/clienteempre/nuevo`}>
              Agregar
            </Link>
    {listaEmpCliente.length >0 &&   <App  nombretabla="clienteempresa"  rows={listaEmpCliente} columns={listaColumnasx} />}
    </>
  );
};

export default EmpTrans;
