const initialState = {
    listaVehiculos: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    furgon: null,
    vehiculo:null,
    listaTipoVehiculoSelect:[],
    listaFurgonExists:[],
    camioneta:[],
    semichasis:[],
    chasis:[],
    furgon:[],
    semifurgon:[],
    trailer:[],
    acochasis:[],
    acofurgon:[],
    dataError: {}
    };
  
  export const vehiculosReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_VEHICULOS":
        return {
          ...state,
          listaVehiculos: action.payload,
          vehiculo:null,
          addOk: false,
          deleteOk: false,
          editOk: false,
          furgon: null,
          dataError:{}
        };
        case "OBTENER_VEHICULO_POR_TIPO":
          return {
            ...state,
            listaVehiculos: action.payload,
            camioneta:action.payload1,
            semichasis:action.payload2,
            chasis:action.payload3,
            furgon:action.payload4,
            semifurgon:action.payload5,
            trailer:action.payload6,
            acochasis:action.payload7,
            acofurgon:action.payload8
          
          };
        case "OBTENER_TIPO_VEHICULO":
          return {
            ...state,
            listaTipoVehiculoSelect: action.payload,
        
          
          };
      case "AGREGAR_VEHICULO":
        return {
          ...state,
          addOk: action.payload,
          dataError: action.payload2
        };
        case "EDITAR_VEHICULO":
          return {
            ...state,
            editOk: action.payload,
          };
        case "AGREGAR_FURGON_LISTA":
          return {
            ...state,
            addOk: action.payload,
            listaFurgonExists: action.payload2,
  
          };
          case "BUSCAR_EMPRESA_LISTA":
            return {
              ...state,            
              listaFurgones: action.payload,
    
            };

        case "OBTENER_VEHICULO":
          return {
            ...state,
            vehiculo: action.payload,
          };
        case "OBTENER_ACOPLADO":
          return {
            ...state,
            acochasis: action.payload,
          };
 
  
      default:
        return state;
    }
  };
  