import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { obtenerEmpresas } from "../../redux/actions/empresatrans";
import { cargaMasiva, buscarEmprePorNombre } from "../../redux/actions/piloto";
import { useDispatch, useSelector } from "react-redux";
import Tabla from "../../components/TablaRfids/TablaUpload";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const downloadExcelTemplate = () => {
  const sheetName = "contenedores";
  const templateData = [
    ["identificacion", "nombre_completo", "licencia", "empresa_trans"],
    ["", "", "", ""],
    ["", "", "", ""],
    ["", "", "", ""],
  ];
  
  const worksheet = XLSX.utils.aoa_to_sheet(templateData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  const excelBuffer = XLSX.write(workbook, { bookType: "csv", type: "array" });
  const fileName = "conductores.csv";
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  FileSaver.saveAs(blob, fileName);
};
var listaPilotoEmpresaTrans=[];

const UploadFileEmpresa = (props) => {
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [idempresa, setIdempresa] = useState(localStorage.getItem("idempresa"));
  const [mostrarButton, setMostrarButton] = useState(true);

  const [listaColumnas, setListaColumnas] = useState([]);
  const [listaPiloto, setListaPiloto] = useState([]);
 

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, listaPilotoExists } = state.piloto;
  const {  listaEmpTrans}=state.empresatrans
  const csvFileToArray = (string) => {
    
    setListaColumnas(string.slice(0, string.indexOf("\r")).split(","));
    //  setListaRfids(string.slice(string.indexOf("\n") + 1).split("\n"));
    const csvHeader = string.slice(0, string.indexOf("\r")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\r\n");
    //console.log(csvHeader)
    const xxx = csvRows.filter((value) => {
      return value != "";
    });
    const dateedit = moment.utc(moment()).format();
    const array = xxx.map((i) => {
      const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
    
      const idempresatrans=listaPilotoEmpresaTrans.find((item)=> item.nombre==values[3])
     
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        object["viaje"] = "libre";
        object["id_empresa_trans"] = idempresatrans?._id ? idempresatrans?._id : "" ;
        object["idempresa"] = idempresa;
        object["datos_creacion"] = {
          userId: userId,
          fecha_creacion: dateedit,
        };
        return object;
      }, {});
      return obj;
    });
    
   setListaPiloto(array)
   // dispatch(buscarEmprePorNombre(array));
    // setListaEmpresa(array);
    // setArray(array);
  };
  const onDrop = useCallback((acceptedFiles) => {
    
   
    setMostrarButton(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const text = reader.result;

        csvFileToArray(text);
        // Do whatever you want with the file contents
        //  const binaryStr = reader.result
      };
      reader.readAsText(file, "ISO-8859-1");
      //  reader.readAsArrayBuffer(file)
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
   
    dispatch(obtenerEmpresas())

  }, []);
  useEffect(() => {
   console.log("no se debe ejcutar")
    if(listaEmpTrans?.length>0){
      console.log("no se debe ejcutar 2")
      listaPilotoEmpresaTrans=listaEmpTrans
    
    }
   
     }, [listaEmpTrans]);
  
  useEffect(() => {
    if (addOk) {
      props.history.push("/pilotos");
    }
  }, [addOk]);

   useEffect(() => {
    if (listaPilotoExists?.length > 0) {
      setMostrarButton(false);
      setListaPiloto(listaPilotoExists);
      toast("Estos Registros Ya existen");
    //  listaColumnas.unshift("ID Empresa Asignado");
    }
  }, [listaPilotoExists]); 

  const nuevoEmpresa = (empresa) => {
    dispatch(cargaMasiva(empresa));
  };

  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/pilotos`}>
        <FormattedMessage id="Back" />
      </Link>

      <button
        className="btn btn-warning ms-5 mb-5"
        onClick={downloadExcelTemplate}
      >
        <FormattedMessage id="bajar_plantilla" />
      </button>

      <ToastContainer />
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div
              {...getRootProps()}
              className="py-10 d-flex justify-content-center flex-column align-items-center gap-3"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p
                  className="fw-bold rounded fs-3 text-center py-10"
                  style={{ border: "3px dashed #0A5189", width: "90%" }}
                >
                  Suelte Sus Archivos AQUI ...
                </p>
              ) : (
                <p
                  className="fw-bold rounded fs-3 text-center py-10"
                  style={{ border: "3px dashed #0A5189", width: "90%" }}
                >
                  <FormattedMessage id="upload_message_file" />
                </p>
              )}
              <button
                className="btn text-white"
                style={{ backgroundColor: "#0A5189" }}
              >
                <i className="bi bi-cloud-arrow-up-fill text-white"></i>
                <FormattedMessage id="seleccionar_archivo" />
              </button>
            </div>
            {(listaPiloto?.length > 0  )&& (
              <Tabla
                mostrarButton={mostrarButton}
                insertarLista={nuevoEmpresa}
                nombretabla="pilotoupload"
                listaDatos={listaPiloto}
                listaColumnas={listaColumnas}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFileEmpresa;
