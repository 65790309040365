import React, { useState } from 'react';
import './NameCircle.css'; // Create a CSS file for styling

const NameCircle = ({ name }) => {
  const [isHovered, setIsHovered] = useState(false);

  const initials = name
    .split(' ')
    .map(part => part.charAt(0))
    .join('');

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className={`name-circle ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      {initials}
      {isHovered && <span className="full-name">{name}</span>}
    </div>
  );
};

export default NameCircle;
