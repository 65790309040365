import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editarVehiculo } from "../../redux/actions/vehiculo";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const initDataForm = {
  id_empresa_trans: "",
  tipo_vehiculo: "",
  marca: "",
  modelo: "",
};

const initVehDataForm = {
  placa_furgon: "",
  placa_camioneta: "",
  placa_trailer: "",
  placa_aco_chasis: "",
  placa_aco_furgon: "",
  numero_precintos: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalEditarVehiculo = ({ datos }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [vehDataForm, seVehtDataForm] = useState(initVehDataForm);
  const [tipoVehiculoNombre, setTipoVehiculoNombre] = useState(
    datos?.tipo_vehiculo
  );
  const [empresa_trans, setEmpresaTrans] = useState(datos.empresa_trans);
  const [numero_tag, setNumeroTag] = useState("");
  const [numero_tag_id, setNumeroTagId] = useState("");
  const [errors, setErrors] = useState(errorsInit);
  const { listaTagSelect } = state.tagcamion;
  const { listaEmpTransSelect } = state.empresatrans;
  const history = useHistory();
  const { editOk } = state.vehiculo;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) {
      delete datos.datos_edicion;
      if (!numero_tag_id) {
      }

      setDataForm({ ...datos });
    }
  }, [show, datos]);

  useEffect(() => {
    dispatch(obtenerEmpresasFiltrados());
  }, [dispatch]);
  useEffect(() => {
    if (editOk) {
      history.push("/vehiculos");
    }
  }, [editOk]);
  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });

    if ([e.target.name] == "id_empresa_trans") {
      let index = e.nativeEvent.target.selectedIndex;

      setEmpresaTrans(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const handleChange2 = (selectValue) => {
    setNumeroTagId(selectValue.value);

    setNumeroTag(selectValue.label);
  };

  const HandleCloseReload = () => {
    handleClose();
  };

  const RenderTag = () => {
    return (
      <>
        <select
          className="form-select"
          value={numero_tag_id}
          onChange={(selectValue) => handleChange2(selectValue)}
        >
          {listaTagSelect?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </>
    );
  };

  const renderSwitch = (param) => {
    switch (param) {
      default:
        return <div></div>;
      case "Acoplado Furgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_furgon">
                  Placa
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_aco_furgon"
                  value={dataForm.placa_aco_furgon}
                  onChange={handleChange}
                />

                {errors.placa_aco_furgon && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_aco_furgon}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                  Numero Precintos
                </label>

                <select
                  className="form-select"
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Semi Furgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_semi_furgon">
                  Placa Semi Furgon
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_semi_furgon"
                  value={dataForm.placa_semi_furgon}
                  onChange={handleChange}
                />

                {errors.placa_semi_furgon && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_semi_furgon}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                  Numero Precintos
                </label>

                <select
                  className="form-select"
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Acoplado Chasis":
        return (
          <>
            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_chasis">
                  Placa
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_aco_chasis"
                  value={datos?.placa_vehiculo || ""}
                  onChange={handleChange}
                />

                {errors.placa_aco_chasis && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_aco_chasis}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Semi Chasis":
        return (
          <>
            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_semi_chasis">
                  Placa Semi Chasis:
                </label>
                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_semi_chasis"
                  value={dataForm.placa_semi_chasis}
                  onChange={handleChange}
                />

                {errors.placa_semi_chasis && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_semi_chasis}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Trailer":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_trailer">
                  Placa Trailer
                </label>
                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_trailer"
                  value={dataForm.placa_trailer}
                  onChange={handleChange}
                />
                {errors.placa_trailer && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_trailer}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Chasis":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                  Placa Chasis
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                />
                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Semi Remolque":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_trailer">Placa Trailer</label>
              <input
                type="text"
                className="form-control"
                name="placa_trailer"
                value={dataForm.placa_trailer}
                onChange={handleChange}
              />
              {errors.placa_trailer && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_trailer}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="placa_semi">Placa Semi</label>
              <input
                type="text"
                className="form-control"
                name="placa_semi"
                value={dataForm.placa_semi}
                onChange={handleChange}
              />
              {errors.placa_semi && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi}
                </span>
              )}
            </div>
          </>
        );

      case "Camioneta":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_camioneta">
                  Placa Camioneta:
                </label>

                <input
                  required
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_camioneta"
                  value={datos.placa_vehiculo}
                  onChange={handleChange}
                />

                {errors.placa_camioneta && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_camioneta}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                  Numero precintos:
                </label>

                <select
                  required
                  className="form-select"
                  name="numero_precintos"
                  value={vehDataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG:
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Balancin":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_balancin">Placa Balancin</label>
              <input
                type="text"
                className="form-control"
                name="placa_balancin"
                value={dataForm.placa_balancin}
                onChange={handleChange}
              />
              {errors.placa_balancin && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_balancin}
                </span>
              )}
            </div>
          </>
        );

      case "Acoplado":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              <input
                type="text"
                className="form-control"
                name="placa_furgon"
                value={dataForm.placa_furgon}
                onChange={handleChange}
              />
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="chasis_cabezal">Chasis Cabezal</label>
              <input
                type="text"
                className="form-control"
                name="chasis_cabezal"
                value={dataForm.chasis_cabezal}
                onChange={handleChange}
              />
              {errors.chasis_cabezal && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.chasis_cabezal}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="placa_remolque">Placa Remolque</label>
              <input
                type="text"
                className="form-control"
                name="placa_remolque"
                value={dataForm.placa_remolque}
                onChange={handleChange}
              />
              {errors.placa_remolque && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_remolque}
                </span>
              )}
            </div>
          </>
        );

      case "Furgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_furgon">
                  Placa Furgon
                </label>

                <input
                  type="text"
                  className="form-control bg-body-secondary border border-0"
                  name="placa_furgon"
                  value={datos?.placa_vehiculo}
                  onChange={handleChange}
                />

                {errors.placa_furgon && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_furgon}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_precintos">
                  Numero Precintos
                </label>

                <select
                  className="form-select"
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </div>
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="numero_tag_id">
                  Numero TAG
                </label>

                <RenderTag />

                {errors.numero_tag_id && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_tag_id}
                  </span>
                )}
              </div>
            </div>
          </>
        );
    }
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id" && key !== "numero_tag_id" && key !== "numero_tag") {
        console.log("isvalid", key);
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() == "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      const dataForm1 = { ...dataForm, empresa_trans };

      dispatch(editarVehiculo(dataForm1));
    }
  };

  return (
    <>
      <Button
        className="btn btn-secondary fw-bolder"
        style={{ color: "#0A5189" }}
        onClick={handleShow}
      >
        Editar
      </Button>

      <Modal show={show} onHide={HandleCloseReload} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Vehiculo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label className="mb-5">Tipo de Vehículo:</Form.Label>
                <Form.Select
                  name="tipo_vehiculo"
                  value={dataForm?.tipo_vehiculo || ""}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  <option>Camioneta</option>
                  <option>Chasis</option>
                  <option>Furgon</option>
                  <option>Trailer</option>
                  <option>Semi Chasis</option>
                  <option>Semi Furgon</option>
                  <option>Acoplado Chasis</option>
                  <option>Acoplado Furgon</option>
                </Form.Select>
              </Col>

              <Col xs={6}>
                <Form.Label className="mb-5">Empresa Transporte:</Form.Label>
                <Form.Select
                  name="id_empresa_trans"
                  value={dataForm?.id_empresa_trans || ""}
                  onChange={handleChange}
                >
                  {listaEmpTransSelect?.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      label={item.label}
                    ></option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <Row>{renderSwitch(tipoVehiculoNombre)}</Row>

            <Row>
              <Col xs={6}>
                <Form.Label className="my-5">Marca:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="marca"
                  value={dataForm?.marca || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col xs={6}>
                <Form.Label className="my-5">Modelo:</Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="modelo"
                  value={dataForm?.modelo || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="my-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            onClick={HandleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            Editar Vehículo
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEditarVehiculo;
