import React from "react";

import { eliminarPiloto } from "../../redux/actions/piloto";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTablePiloto = (props) => {
  const dispatch = useDispatch();

  const { id,identificacion, licencia, nombre_completo, empresa_trans, viaje } = props.piloto;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{identificacion} </td>
     
      <td>{nombre_completo} </td>
      <td>{licencia} </td>
      <td>{empresa_trans} </td>
      <td>{viaje} </td>
      <td className="d-flex justify-content-around ">
      
      <Link className="btn btn btn-success" style={{marginTop:"0px"}} to={`/piloto/detalle/${id}`}>
          Detalle
        </Link>

      <Link className="btn btn btn-warning" style={{marginTop:"0px"}} to={`/piloto/editar/${id}`}>
          Editar
        </Link>
    
      </td>
    </tr>
  );
};

export default RowTablePiloto;
