import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";

import { Link } from "react-router-dom";

const initDataForm = {
  id_empresa_trans: "",
  nombre_completo: "",
  identificacion: "",
  licencia: "",
};

const errorsInit = {
  ...initDataForm,
};

const NuevoPiloto = ({
  mensajeError,
  agregarNuevoPiloto,
  piloto,
  editar = false,
  editarPiloto,
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [empresa_trans, setEmpresaTrans] = useState("");
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { listaEmpTransSelect } = state.empresatrans;

  useEffect(() => {
    dispatch(obtenerEmpresasFiltrados());
  }, []);

  useEffect(() => {
    if (editar) {
      setDataForm({ ...piloto });
    }
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if ([e.target.name] === "empresa_trans_id") {
      let index = e.nativeEvent.target.selectedIndex;
      setEmpresaTrans(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      if (editar) {
        const dataForm1 = { ...dataForm };
        editarPiloto(dataForm1);
      } else {
        const dataForm1 = { ...dataForm };

        agregarNuevoPiloto(dataForm1);
      }
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                <h1 className="card-title">
                  {editar ? "Editar Piloto" : "Agregar Piloto"}
                </h1>
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="identificacion">Identificacion</label>
                    <input
                      type="text"
                      className="form-control"
                      name="identificacion"
                      value={dataForm.identificacion}
                      onChange={handleChange}
                    />
                    {errors.identificacion && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.identificacion}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="nombre_completo">Nombre Completo</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nombre_completo"
                      value={dataForm.nombre_completo}
                      onChange={handleChange}
                    />
                    {errors.nombre_completo && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.nombre_completo}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="licencia">Licencia</label>
                    <input
                      type="text"
                      className="form-control"
                      name="licencia"
                      value={dataForm.licencia}
                      onChange={handleChange}
                    />
                    {errors.licencia && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.licencia}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-8">
                    <label htmlFor="id_empresa_trans">Empresa Transporte</label>
                    <select
                      type="text"
                      className="form-control"
                      name="id_empresa_trans"
                      value={dataForm.id_empresa_trans}
                      onChange={handleChange}
                    >
                      {listaEmpTransSelect?.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          label={item.label}
                        ></option>
                      ))}
                    </select>
                    {errors.id_empresa_trans && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.id_empresa_trans}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  {mensajeError !== "" && <p>{mensajeError}</p>}
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar Piloto" : "Agregar Piloto"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/pilotos`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoPiloto;
