import React, { useEffect, useState } from "react";
import Tabla from "../../components/TablaRfids";
import Popup from '../../components/Modal';

import { useSelector, useDispatch } from "react-redux";
import { obtenerRfidsViajeEstadoFiltrados, obtenerRfidsTiempoReal, terminarViajeOK } from "../../redux/actions/viajes";
const initDataForm = {
  estado: "Terminado",

};
const listaColumnas = [
  "Marchamo N",
  "Condicion",
  "Estado",
  "Fecha Registro",
  "N Declaracion",
  "Contenedor"
];
const Rfids = () => {
  const [dataForm, setDataForm] = useState(initDataForm);
  
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaPosicionRfids, editOk, rfid, terminado } = state.viajeRfid;
  //const { editOk } = state.posicionRfid;
  //console.log("EL VALOR DEL EDIT OK", editOk);

 
  useEffect(() => {
  //  dispatch(obtenerRfidsViajeEstadoFiltrados(dataForm.estado));
  
  dispatch(obtenerRfidsTiempoReal(initDataForm.estado))
  }, []);


  
 
  return (
    <>
   
     
      <h1>Lista Viajes Terminados</h1>

      {listaPosicionRfids.length >0 && <Tabla  nombretabla="viaje" listaDatos={listaPosicionRfids} listaColumnas={listaColumnas} />}

    </>
  );
};

export default Rfids;
