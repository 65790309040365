import moment from "moment";
export const buscarEmprePorNombre = (camion) => {
  return async (dispatch) => {
    try {
      const camionExisten = [];
      let nombreMod = "";
    
      console.log("desde buscar empresa", camionExisten);
      dispatch({
        type: "BUSCAR_CAMION_LISTA",

      //  payload: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerRfidsViajePosicionFiltrados = (numero_doc_precinto) => {
  return async (dispatch) => {
    try {
  
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc =[];// await colec1.get();

      const viajes = [];

      doc.forEach((item) => {
        let newObj={...item.data()};
        if(newObj.id){
          delete newObj.id;
        }
        var fechaOrder=new Date((item.data().fecha)+" "+ item.data().hora);
        
        
        viajes.push({ id: item.id,fechaOrder, ...newObj });
      });
      viajes.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.fechaOrder) - new Date(a.fechaOrder);
      });
      dispatch({
        type: "OBTENER_POSICION_RFIDS_DETALLE",
        payload: viajes,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const obtenerRfidsViajeFiltrados = (id) => {
  return async (dispatch) => {
    try {
    /*  const doc = await db.collection("viajesrfid").doc(id).get();
      let rfid = {
        id: id,
        ...doc.data(),
      };*/
      dispatch({
        type: "OBTENER_VIAJE",
       // payload: rfid,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerRfidsTiempoReal = (filtro) => {
  return async (dispatch) => {
    try {
     /* const rfids = [];
      let allDocs = [];
      let solicitudes = [];
      let losrfids = [];
      let abrirPopup = false;
      var rfidvalue = {};
      let removido = false;
      const viajes2 = [];
      const handleMagazinesChanges = (snapshot) => {
        const changes = snapshot.docChanges();
      //  console.log("rastreo inicial");
        // Accumulate differences
        let difference = "";
        changes.map((change, index) => {
          let item = change.doc;

          allDocs.push(change.doc.data());

          if (change.type === "added") {
           // console.log("rastreo Agregado");
            if (
              item.data().condicion == "CERRADO" &&
              item.data().estado == "MANIPULADO"
            ) {
              rfidvalue = { ...rfidvalue, ...item.data() };
              abrirPopup = true;
            } else {
              abrirPopup = false;
            }
            difference = "APROBADO";
          }
          if (change.type === "removed") {
         //   console.log("rastreo REMOVED");
            removido = true;
            abrirPopup = false;
            difference = "CANCELADO";
          }
          if (change.type === "modified") {
            difference = "MODIFICADO";
        //    console.log("rastreo 1");
            solicitudes = solicitudes.filter((value) => {
              return value.id !== item.id;
            });

            if (item.data()?.viaje == "Terminado") {
              return;
            }
            console.log("rastreo 2");
            rfidvalue = { ...rfidvalue, ...item.data() };

            if (
              item.data().condicion == "CERRADO" &&
              item.data().estado == "MANIPULADO"
            ) {
              abrirPopup = true;
            } else {
              abrirPopup = false;
            }
            // solicitudes.push({Key:index, id: item.id, numero_precinto: item.data().numero_precinto, condicion: item.data().condicion, estado: item.data().estado, fecha: item.data().fecha, hora: item.data().hora});

            //Aki estaba el dispact inicial
            dispatch({
              type: "LANZAR_POPUP",
              payload: rfidvalue,
              payload2: abrirPopup,
              payload3: solicitudes,
            });
          }
          if (removido) {
            solicitudes = solicitudes.filter((value) => {
              return value.numero_precinto != item.data().numero_precinto;
            });
       
          } else {
            var fechaOrder=new Date((item.data().fecha)+" "+ item.data().hora);
            solicitudes.push({ ...item.data(),fechaOrder, key: index, id: item.id });
          }

          //  solicitudes.push({ Key: index, id: item.id, numero_precinto: item.data().numero_precinto, condicion: item.data().condicion, estado: item.data().estado, fecha: item.data().fecha, hora: item.data().hora });

        //  console.log("abrir pop up", abrirPopup);
        solicitudes.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.fechaOrder) - new Date(a.fechaOrder);
        });
          if (!abrirPopup) {
            dispatch({
              type: "OBTENER_POSICION_RFIDS",
              payload: solicitudes,
            });
          } else {
            dispatch({
              type: "LANZAR_POPUP",
              payload: rfidvalue,
              payload2: abrirPopup,
              payload3: solicitudes,
            });
          }
        });

        // Use the setState callback
      };
      //const doc = await db.collection("rfids").get();
      let colec1 = db.collection("rfids").where("viaje", "==", filtro);
    //  console.log("rastreo final -1");
      colec1.onSnapshot(handleMagazinesChanges, (err) => console.log(err));
     // console.log("rastreo final");

      dispatch({
        type: "LANZAR_POPUP",
        payload: rfidvalue,
        payload2: abrirPopup,
        payload3: solicitudes,
      });
      /*     dispatch({
        type: "OBTENER_POSICION_RFIDS",
        payload: solicitudes,
      });*/
    } catch (error) {
      console.log(error);
    }
  };
};
export const terminarViajeOK = (rfid) => {
  return async (dispatch) => {
    try {
  
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerViaje = (rfid) => {
  return async (dispatch) => {
    try {
  
    } catch (error) {
      console.log(error);
    }
  };
};
