const initialState = {
    listaRfids: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    rfid: null,
    listaRfidsSelect:[],
    yaExiste:false,
    rfidoc:null,
    listaRfidsExists:[],
    loading: true,
    listaPosicionRfidsDetalle:[],
    mensajeError: null
  };
  
  export const rfidsReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_RFIDS":
        return {
          ...state,
          listaRfids: action.payload,
        
       //   listaRfidsSelect:action.payload2,
         addOk: false,
         deleteOk: false,
         editOk: false,
         mensajeError:null
     //     listaRfidsExists:[]
       
        };
        case "OBTENER_POSICION_RFIDS_DETALLE":
          return {
            ...state,
            listaPosicionRfidsDetalle: action.payload,
    
          };
      case "AGREGAR_RFID":
        return {
          ...state,
          addOk: action.payload,
         mensajeError:action.payload2

        };
        case "AGREGAR_RFID_LISTA":
          return {
            ...state,
            addOk: action.payload,
          
  
          };
        case "AGREGAR_RFID_VIAJE":
          return {
            ...state,
            addOk: action.payload,
          };
      case "ELIMINAR_RFID":
        return {
          ...state,
          deleteOk: action.payload,
        };
      case "OBTENER_RFID":
        return {
          ...state,
          rfid: action.payload,
          listaPosicionRfidsDetalle:action.payload2
        };
      case "EDITAR_RFID":
        return {
          ...state,
          editOk: action.payload,
        }; 
        case "LANZAR_POPUP":
        return {
          ...state,
          rfid: action.payload,
          editOk: action.payload2,
          listaRfids:action.payload3
        };
  
      default:
        return state;
    }
  };
  