//import {toAbsoluteUrl} from '../../../_metronic/helpers'
//import {PageTitle} from '../../../_metronic/layout/core'
import { CardsWidget20 } from "./CardsWidget20";
import { CardsWidget17 } from "./CardsWidget7";
import { MixedWidget8 } from "./MixedWidget8";
import { FormattedMessage, useIntl } from "react-intl";
const DashboardPage = ({ informacion }) => {
  const intl = useIntl();
  return (
    <div className="card-body">
      <div className="row ">
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 ">
          <CardsWidget20
            informacion={informacion}
            className="ml-5 pb-6"
            description={intl.formatMessage({ id: "rfid_stock" })}
            description2={intl.formatMessage({ id: "rfid_terminado" })}
            color="#F1416C"
            //  img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3  ">
          <CardsWidget17
            informacion={informacion}
            className="mb-xl-10 ml-5"
            description="Usuarios"
            icon={false}
            stats={35}
            labelColor="dark"
            textColor="gray-300"
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-xxl-12">
        <MixedWidget8
            informacion={informacion}
            className="card-xxl-stretch mb-xl-3"
            chartColor="success"
            chartHeight="150px"
          />
        </div>
      </div>
    </div>
  );
};

const DashboardWrapper = ({ informacion }) => {
  return (
    <>
      <DashboardPage informacion={informacion} />
    </>
  );
};

export { DashboardWrapper };
