import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const RutaPublica = ({ estasAutenticado, component: Component, ...rest }) => {
  const state = useSelector((state) => state);
  var { isAuthenticated } = state.auth;


  return (
    <Route
      {...rest}
      component={(props) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/rfids" />
      }
    />
  );
};

export default RutaPublica;
