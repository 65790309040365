import React from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import {
  detenerServicioSunat,
  iniciarServicioSunat,
} from "../../redux/actions/auth";
import { useEffect } from "react";


const Configuraciones = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { iniciarOk, detenerOk } = state.auth;
  const notify = (message) => toast(message);
  const detenerServicio = () => {
    dispatch(detenerServicioSunat());
  };
  const iniciarServicio = () => {
    dispatch(iniciarServicioSunat());
  };
  useEffect(() => {
    if (iniciarOk) {
        notify("Servicio Iniciado")
  
    }
  }, [iniciarOk]);
  useEffect(() => {
    if (detenerOk) {
        notify("Servicio Apagado")
 
    }
  }, [detenerOk]);
  return (
    <>
 
      <h1 className="d-inline">Configuraciones</h1>
  
      <ToastContainer />
      <div className="card bg-primary">
        <div className="card-body">
          <div className="form-group">
           <button className="btn btn-success" onClick={iniciarServicio}> Encender Servicio</button>
           <button className="btn btn-danger ml-3"onClick={detenerServicio} > Apagar Servicio</button>
          
         
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuraciones;
