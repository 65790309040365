import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";
export const eliminarContenedor = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/contenedor/delete/${id}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "ELIMINAR_CONTENEDOR",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ELIMINAR_CONTENEDOR",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerContenedoresFiltrados = () => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      const arrayLista = [
        { label: "Seleccione una Contenedor", value: "-1", selected: true },
      ];
      var lista = await axios.get(URL + "/contenedor/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      var numero_precintos = 1;
      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          if (item.numero_precintos) {
            numero_precintos = item.numero_precintos;
          }
          arrayLista.push({
            key: item._id,
            numero_precintos: numero_precintos,
            label: item.numero,
            value: item._id,
            selected: false,
          });
        });
      }

      dispatch({
        type: "OBTENER_CONTENEDORES_SELECT",
        payload: arrayLista,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const obtenerContenedores = () => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      const arrayLista = [];
      var lista = await axios.get(URL + "/contenedor/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      //   console.log("respuesta ", lista.data);

      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          //  console.log("dato fechas", item.datos_creacion.fecha_creacion);
          /*  var fecha_creacion = moment(
            item.datos_creacion.fecha_creacion
          ).format("YYYY-MM-DD");
          var hora_creacion = moment(item.datos_creacion.fecha_creacion).format(
            "HH:mm:ss"
          );*/

          arrayLista.push({
            ...item,
            id: item._id,
            //  fecha_creacion,
            // hora_creacion,
          });
        });
      }

      dispatch({
        type: "OBTENER_CONTENEDORES",
        payload: arrayLista,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarContenedor = (entidad) => {
  return async (dispatch) => {
    try {
      const naviera = entidad.naviera;
      const numero = entidad.numero;
      const medida = entidad.medida;
      const tipo = entidad.tipo;
      const numero_precintos = entidad.numero_precintos;
      var token = localStorage.getItem("token");
      const viaje = "Libre";

      var insertRecord = await axios.post(
        URL + "/contenedor/create",
        { naviera, numero, medida, tipo, numero_precintos },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (insertRecord?.status === 200 && insertRecord?.data.ok) {
        dispatch({
          type: "AGREGAR_CONTENEDOR",
          payload: true,
          payload2: "",
        });
        return;
      }
      dispatch({
        type: "AGREGAR_CONTENEDOR",
        payload: false,
        payload2: insertRecord?.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarContenedor = (entidad) => {
  return async (dispatch) => {
    try {
      const naviera = entidad.naviera;
      const numero = entidad.numero;
      const medida = entidad.medida;
      const tipo = entidad.tipo;
      const numero_precintos = entidad.numero_precintos;
      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + `/contenedor/update/${entidad._id}`,
        { naviera, numero, medida, tipo, numero_precintos },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
console.log("respuesta conte", rfidRec)
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "EDITAR_CONTENEDOR",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "EDITAR_CONTENEDOR",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerContenedor = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var Objeto = await axios.get(`${URL}/contenedor/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      let objeto = {};

      if (Objeto?.status === 200 && Objeto?.data.ok) {
        // var fecha_creacion = moment(Objeto.fecha_creacion).format("YYYY-MM-DD");
        //  var hora_creacion = moment(Objeto.fecha_creacion).format("HH:mm:ss");
        objeto = {
          ...Objeto.data.data,
          //   fecha_creacion,
          //   hora_creacion,
        };
      }

      dispatch({
        type: "OBTENER_CONTENEDOR",
        payload: objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (contenedores) => {
  return async (dispatch) => {
    try {
      //  const fecha = moment().format("YYYY-MM-DD");
      //     const hora = moment().format("HH:mm");
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/contenedor/insertmany",
        { contenedores },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("no se subieron", rfidRec);
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_CONTENEDOR_LISTA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_CONTENEDOR_LISTA",
        payload: false,
        payload2: rfidRec?.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };
};
