import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";

export const obtenerTags = () => {
  return async (dispatch) => {
    try {
      const tags = [];

      var token = localStorage.getItem("token");
      const URL_FInal = URL + "/tagvehiculo/getall";
      var listaR = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (listaR?.status === 200 && listaR?.data?.ok) {
        listaR.data.data.forEach((item) => {
          var fecha_asignado = item.datos_asignacion?.fecha_asignacion
            ? moment(item.datos_asignacion?.fecha_asignacion).format(
                "YYYY-MM-DD"
              )
            : "";
          var hora_asignado = item.datos_asignacion?.fecha_asignacion
            ? moment(item.datos_asignacion?.fecha_asignacion).format("HH:mm:ss")
            : "";
          var placa_vehiculo = item.datos_asignacion?.placa_vehiculo;
          tags.push({ ...item, fecha_asignado, hora_asignado, placa_vehiculo });
        });
      }
      dispatch({
        type: "OBTENER_TAGSCAMION",
        payload: tags,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerTagsSelect = (estadoTag) => {
  return async (dispatch) => {
    try {
      const tags = [
      //  { label: "Seleccione una Tag", value: -1, selected: true }
      ];
      var token = localStorage.getItem("token");
      const URL_FInal = URL + "/tagvehiculo/getall";
      var listaR = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          estadoTag,
        },
      });

      if (listaR?.status === 200 && listaR?.data?.ok) {
        listaR.data.data.forEach((item) => {
          tags.push({
            key: item._id,
            label: item.numero_tag,
            value: item._id,
            selected: false,
          });
        });
      }
      dispatch({
        type: "OBTENER_TAGSCAMION",
        payload2: tags,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarTag = (tag) => {
  return async (dispatch) => {
    try {
      const numero_tag = tag.numero_tag;
      const estadoTag = tag.estadoTag;
      const placa_vehiculo = tag.placa_vehiculo;
      const id_vehiculo = tag.id_vehiculo;
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/tagvehiculo/create",
        { numero_tag, estadoTag, placa_vehiculo, id_vehiculo },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("tag response", rfidRec);
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_TAGCAMION",
          payload: true,
        });
        return;
      }

      dispatch({
        type: "AGREGAR_TAGCAMION",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerTag = (id) => {
  return async (dispatch) => {
    try {
      let tag = {};
      var token = localStorage.getItem("token");
      var Rfid = await axios.get(`${URL}/tagvehiculo/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (Rfid?.status === 200 && Rfid?.data.ok) {
        var item = Rfid.data.data;
        var fecha_asignado = item.datos_asignacion?.fecha_asignacion
          ? moment(item.datos_asignacion?.fecha_asignacion).format("YYYY-MM-DD")
          : "";
        var hora_asignado = item.datos_asignacion?.fecha_asignacion
          ? moment(item.datos_asignacion?.fecha_asignacion).format("HH:mm:ss")
          : "";
        var placa_vehiculo = item.datos_asignacion?.placa_vehiculo;
        var id_vehiculo = item.datos_asignacion?.id_vehiculo;
        tag = {
          ...item,
          fecha_asignado,
          hora_asignado,
          placa_vehiculo,
          id_vehiculo,
        };
      }

      dispatch({
        type: "OBTENER_TAGCAMION",
        payload: tag,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const editarTag = (tag) => {
  return async (dispatch) => {
    try {
      const numero_tag = tag.numero_tag;
      const id_vehiculo = tag.id_vehiculo;
      const placa_vehiculo = tag.placa_vehiculo;
      const estadoTag = tag.estadoTag;

      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + `/tagvehiculo/update/${tag._id}`,
        { numero_tag, id_vehiculo, placa_vehiculo, estadoTag },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("resultado", rfidRec);
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "EDITAR_TAGCAMION",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "EDITAR_TAGCAMION",
        payload: false,
        payload2: rfidRec?.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
/*
export const obtenerTagsCamionFiltrados = (filtro) => {
  return async (dispatch) => {
    try {
      const listaTagsSelect=[];
      console.log("el filtro es",filtro)
      if(filtro!=""){
       
        let colec2 = db.collection("tagscamion")  
        .where("placa_cabezal","==",filtro);
        const doc1 = await colec2.get();
       // console.log("de firebase",doc1.docs[0].id)
        if (doc1?.docs[0]?.id) {
          listaTagsSelect.push({ label: doc1?.docs[0]?.data().numero_tag, value: doc1?.docs[0]?.id, selected:true });
          listaTagsSelect.push({label: "Quitar Tag", value: -2, selected:false});
     
        }else{
         
          listaTagsSelect.push({label: "Seleccione un Tag", value: -1, selected:true});
       }
      }
     
      let colec1 = db.collection("tagscamion")  
        .where("estadoTag","==","Libre");
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();
      
      const tags = [];
     

      doc.forEach((item) => {
        
        tags.push({ id: item.id, ...item.data() });
        listaTagsSelect.push({ key: item.id,label: item.data().numero_tag, value: item.id, selected:false })
      }); 
      
      dispatch({
        type: "OBTENER_TAGSCAMION",
        payload: tags, payload2:listaTagsSelect
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};


export const obtenerTags = () => {
  return async (dispatch) => {
    try {
      const tags = [];
      
    
      const doc = await db.collection("tagscamion").get();
     // let colec1 = db.collection("rfids")
      //.where("viaje", "==", true);

   
      //console.log("aqui el doc",doc)
      
     doc.forEach((item) => tags.push({ id: item.id, ...item.data() }));
      dispatch({
        type: "OBTENER_TAGSCAMION",
        payload: tags,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarTag = (tag) => {
  return async (dispatch) => {
    try {
    const doc11=  await db.collection("tagscamion").add(tag);
    
       if(tag.idCamion){
        const camionUpdate = { numero_tag:tag.numero_tag, numero_tag_id: doc11.id};
       // delete camionUpdate.id;
      
        await db.collection("camiones").doc(tag.idCamion).update(camionUpdate);
       }
     
      dispatch({
        type: "AGREGAR_TAGCAMION",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerTag = (id) => {
  return async (dispatch) => {
    try {

      const doc = await db.collection("tagscamion").doc(id).get();
      let tag = {
        id: id,
        ...doc.data(),
      };

      dispatch({
        type: "OBTENER_TAGCAMION",
        payload: tag,
      });
    } catch (error) {
      console.log(error);
    }
  };
};


*/
