import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerEmpresasFiltrados } from "../../redux/actions/empresatrans";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";

import { agregarPiloto,editarPiloto } from "../../redux/actions/piloto";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  id_empresa_trans: "",
  nombre_completo: "",
  identificacion: "",
  licencia: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalAddPilotos = ({ datos, editar = false }) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [empresa_trans, setEmpresaTrans] = useState("");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const { addOk, mensajeError } = state.piloto;
  const { editOk } = state.piloto;
  const { listaEmpTransSelect } = state.empresatrans;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    if(editar){
     
     delete datos?.datos_edicion;
     
      setDataForm({ ...datos });
    }
   
  }, []);

  useEffect(() => {
    if (editOk) {
      history.push("/pilotos");
    }
  }, [editOk]);

  useEffect(() => {
    dispatch(obtenerEmpresasFiltrados());
  }, []);

  useEffect(() => {
    if (addOk) {
      history.push("/pilotos");
    }
  }, [addOk]);

  const nuevoPiloto = (piloto) => {
    dispatch(agregarPiloto(piloto));
  };


  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if ([e.target.name] === "empresa_trans_id") {
      let index = e.nativeEvent.target.selectedIndex;
      setEmpresaTrans(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
   
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
     
      if (key !== "id") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      if (editar) {
        const dataForm1 = { ...dataForm };
        dispatch(editarPiloto(dataForm1));
      } else {
        const dataForm1 = { ...dataForm };

        nuevoPiloto(dataForm1);
      }
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
         {
          editar? <FormattedMessage id="table_editar" />: <FormattedMessage id="Add" />
        }
       
         
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title> 
          {
          editar? <FormattedMessage id="edit_driver" />: <FormattedMessage id="add_driver" />
        }
            
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={4}>
                <Form.Label className="mb-5" htmlFor="identificacion">
                <FormattedMessage id="driver_dni" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="identificacion"
                  value={dataForm.identificacion}
                  onChange={handleChange}
                />

                {errors.identificacion && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.identificacion}
                  </span>
                )}
              </Col>

              <Col xs={8}>
                <Form.Label className="mb-5" htmlFor="nombre_completo">
                <FormattedMessage id="driver_fullname" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="nombre_completo"
                  value={dataForm.nombre_completo}
                  onChange={handleChange}
                />

                {errors.nombre_completo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.nombre_completo}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <Form.Label className="my-5" htmlFor="licencia">
                <FormattedMessage id="driver_license" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="licencia"
                  value={dataForm.licencia}
                  onChange={handleChange}
                />

                {errors.licencia && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.licencia}
                  </span>
                )}
              </Col>

              <Col xs={8}>
                <Form.Label className="my-5" htmlFor="id_empresa_trans">
                <FormattedMessage id="transport_company_combo" />
                </Form.Label>

                <Form.Select
                  name="id_empresa_trans"
                  value={dataForm.id_empresa_trans}
                  onChange={handleChange}
                >
                  <option value={-1}>    <FormattedMessage id="Select" /></option>
                  {listaEmpTransSelect?.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      label={item.label}
                    ></option>
                  ))}
                </Form.Select>

                {errors.id_empresa_trans && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.id_empresa_trans}
                  </span>
                )}
              </Col>
            </Row>

            {mensajeError && (
              <Form.Label className="badge py-3 px-4 fs-5 badge-light-danger mt-5">
                El Conductor ya existe, ingrese otro.
              </Form.Label>
            )}
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
           <FormattedMessage id="cancelar" />
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
             {
          editar? <FormattedMessage id="Edit" />: <FormattedMessage id="Add" />
        }
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalAddPilotos;
