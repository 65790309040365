const initialState = {
    listaEmpCliente: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    clienteempre: null,
    listaEmpClienteSelect: [],
    errormessage:""
 
  };
  
  export const empreClienteReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_EMPRESAS_CLIENTE":
        return {
          ...state,
          listaEmpCliente: action.payload,
          addOk: false,
          deleteOk: false,
          editOk: false,
          errormessage:"",
          clienteempre:null
        
        };
      case "AGREGAR_EMPRESA_CLIENTE":
        return {
          ...state,
          addOk: action.payload,
        };
        
        case "OBTENER_EMPRESAS_CLIENTE_SELECT":
          return {
            ...state,
            listaEmpClienteSelect: action.payload,
            errormessage:action.payload2
          };
        
          case "OBTENER_EMPRESA_CLIENTE":
            return {
              ...state,
              clienteempre: action.payload,
            };

      case "EDITAR_EMPRESA_CLIENTE":
        return {
          ...state,
          editOk: action.payload,
        };
  
      default:
        return state;
    }
  };
  