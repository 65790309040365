import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { limpiarVars, obtenerViaje } from "../../redux/actions/viajes";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  datosCierre: {
    email: "",
    fecha: "",
    hora: "",
    nombre_usuario: "",
  },
};
const Modales = ({ datos, mostrarPopUp }) => {
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  //const { posicion } = state.viajeRfid;

  useEffect(() => {
    if (show) {
      setDataForm({ ...datos });
      // dispatch(obtenerViaje(id));
    }
  }, [show]);
  /*useEffect(() => {
    console.log(posicion)
    if (posicion) {
      setDataForm({ ...posicion });
    }
  }, [posicion]);*/
  const HandleCloseReload = () => {
    handleClose();
  };
  return (
    <>
      <Button className="mb-3 ml-3" variant="primary" onClick={handleShow}>
        Info
      </Button>
      <Modal show={show} onHide={HandleCloseReload}>
        <Modal.Header closeButton>
          <Modal.Title> <FormattedMessage id="popup_scan_info_register" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
              <FormattedMessage id="User" />
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm?.userLogin || ""}
                />
              </Col>
            </Form.Group>
            {dataForm?.imeicode != "" && (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                <FormattedMessage id="popup_scan_info_device_id" />
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    plaintext
                    readOnly
                    value={dataForm?.imeicode || ""}
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
              <FormattedMessage id="DATE" />
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm?.fecha || dataForm?.fecha || ""}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
              <FormattedMessage id="TIME" />
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm?.hora || dataForm?.hora || ""}
                />
              </Col>
            </Form.Group>
            {
              dataForm.enviadoSunat &&
              <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Enviado Sunat
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm?.enviadoSunat || ""}
                />
              </Col>
            </Form.Group>
            }
          
            { dataForm.tipo_cierre_precinto &&
              <Form.Group className="mb-2">
                <Form.Label column sm="2">
                  Tipo de Cierre
                </Form.Label>
                <Form.Select
                  name="tipo_cierre_precinto"
                  value={dataForm.tipo_cierre_precinto || ""}
                  aria-label="Default select example"
                >
                  <option value="0">Destino</option>
                  <option value="1">Inspeccion</option>
                  <option value="2">Prec Manipulado</option>
                  <option value="3">No Encontrado</option>
                </Form.Select>
              </Form.Group>
            }

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>   <FormattedMessage id="popup_scan_info_description" /></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                readOnly
                value={dataForm?.descripcion || ""}
                name="descripcion"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-primary"
            variant="secondary"
            onClick={HandleCloseReload}
          >
           <FormattedMessage id="CLOSE" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modales;
