import React, { useEffect } from "react";
import Tabla from "../TablaRfids";
import { io } from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";
import { obtenerRfid } from "../../redux/actions/rfid";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";

import RFIDAbierto from "../../images/rfidsImg/rfidAbierto.svg";
import RFIDCerrado from "../../images/rfidsImg/rfidCerrado.svg";
import RFIDManipulado from "../../images/rfidsImg/rfidManipulado.svg";
import ActualizarImg from "../../images/actualizar.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { URL } from "../../services/VariablesEntorno";
import moment from "moment";
const PosicionRfids = (props) => {
  const intl = useIntl();
  const condicionTresEstados = intl.formatMessage({
    id: "rfid_column_condicion",
  });
  const listaColumnas = [
    intl.formatMessage({ id: "rfid_column_integridad" }),
    intl.formatMessage({ id: "rfid_column_fecharegistro" }),
    intl.formatMessage({ id: "rfid_column_horaregistro" }),
    intl.formatMessage({ id: "rfid_column_coordenadas" }),
  ];
  const [mostrar, setMostrar] = useState(false);
  const [idempresa,setIdempresa]=useState(localStorage.getItem('idempresa'))
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaPosicionRfidsDetalle, rfid } = state.rfid;
  const [listaPosicionRfidsDetalleF, setData] = useState([]);
  let { numero_doc_precinto, param1 } = props;
  const params = useParams();
  listaColumnas.unshift(condicionTresEstados);
  const id = params.id;
  // const id = props?.match?.params?.id;

  if (!numero_doc_precinto) {
    numero_doc_precinto = id;
  }

  useEffect(() => {
   
    if (listaPosicionRfidsDetalle.length>0) {
      setData(listaPosicionRfidsDetalle)
    }else{
      setData([])
    }
  }, [listaPosicionRfidsDetalle]);
  useEffect(() => {
   
    if (params.id) {
      dispatch(obtenerRfid(params.id));
    }
  }, []);
  function callSocket(){
    var URL2 = URL.replace('api','');
    const socket = io(URL2); // Replace with your server URL
    // socket.emit("getDataRfid", dataEnviar);
    console.log("la url", URL2);
  
    
    socket.on(`api/lecturas/${idempresa}`, (data) => {
      
      if (data?.ok) {
        const posiciones = [];
        if(data?.data._id==id){
          data?.data.posicion?.forEach((item) => {
            var fecha = moment(item.fecha).format("YYYY-MM-DD");
            var hora = moment(item.fecha).format("HH:mm:ss");
  
            posiciones.push({ ...item, fecha, hora });
          });
          setData(posiciones);
        }
       
      }
    });
    
    return () => {
      socket.disconnect();
    };
  }
  useEffect(() => {
 

  }, []);
  useEffect(() => {
   
    if (rfid) {
      setMostrar(true);
    }
  }, [rfid]);
  const Actualizar = () => {
    
    if (params.id) {
      dispatch(obtenerRfid(params.id));
    }
  };
  const conditionStatus = (params) => {
    switch (params) {
      case "ABIERTO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-warning">
            <FormattedMessage id={params} />
          </span>
        );

      case "CERRADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-primary">
            <FormattedMessage id={params} />
          </span>
        );

      case "OK":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-success">
            <FormattedMessage id={params} />
          </span>
        );

      case "MANIPULADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-danger">
            <FormattedMessage id={params} />
          </span>
        );

      case "TERMINADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-danger">
            <FormattedMessage id={params} />
          </span>
        );

      case "STOCK":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-success">
            <FormattedMessage id={params} />
          </span>
        );

      case "VIAJE":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-primary">
            <FormattedMessage id={params} />
          </span>
        );

      default:
        break;
    }
  };

  const conditionStatusRFID = (params) => {
    if (params === "ABIERTO") {
      return <img width={50} src={RFIDAbierto} alt="RFIF ABIERTO" />;
    } else if (params === "CERRADO" && rfid.estado === "OK") {
      return <img width={50} src={RFIDCerrado} alt="RFID CERRADO" />;
    } else if (params === "CERRADO" && rfid.estado === "MANIPULADO") {
      return <img width={50} src={RFIDManipulado} alt="RFID CERRADO" />;
    }
  };
  if (!mostrar) return <>Cargando</>;
  return (
    <>
      {param1 !== "NOMOSTRAR" && (
        <div className="ms-9 mb-5">
          <Link
            className="btn btn-warning"
            to={`/emparejamiento/detalle/${params.id2}`}
          >
            <FormattedMessage id="Back" />
          </Link>
        </div>
      )}

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
            <div className="card-title d-flex flex-column">
              <span
                className="card-label fw-bolder"
                style={{ color: "#0A5189" }}
              >
                <button className="btn" onClick={Actualizar}>
                  <img src={ActualizarImg} />
                </button>
                <FormattedMessage id="rfid_detail" />
              </span>
              <span className="text-gray-400 fw-light fs-6">{rfid?.clase}</span>
            </div>

            <div className="card-title d-flex flex-row align-items-center gap-3">
              {conditionStatusRFID(rfid?.condicion)}

              <div className="d-flex flex-column">
                <span className="fw-bolder">{rfid?.numero_precinto}</span>
                <span className="text-gray-400 fw-light fs-6">
                  <FormattedMessage id="rfid_precinto" />
                </span>
              </div>
            </div>
            {(rfid?.condicion == "ABIERTO" || rfid?.condicion == "CERRADO") && (
              <div className="card-title d-flex flex-column">
                {conditionStatus(rfid?.condicion)}
                <span className="text-gray-400 fw-light fs-6 text-center">
                  <FormattedMessage id="rfid_column_condicion" />
                </span>
              </div>
            )}

            <div className="card-title d-flex flex-column">
              {conditionStatus(rfid?.estado)}
              <span className="text-gray-400 fw-light fs-6 text-center">
                <FormattedMessage id="rfid_column_integridad" />
              </span>
            </div>

            <div className="card-title d-flex flex-column">
              <span className="fw-bolder">{conditionStatus(rfid?.status)}</span>
              <span className="text-gray-400 fw-light fs-6 text-center">
                <FormattedMessage id="rfid_label_position" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {listaPosicionRfidsDetalleF.length >= 0 && (
        <Tabla
          tipo={rfid?.tipo}
          nombretabla="lecturas"
          listaDatos={listaPosicionRfidsDetalleF}
          listaColumnas={listaColumnas}
          numero_doc_precinto={id}
        />
      )}
    </>
  );
};

export default PosicionRfids;
