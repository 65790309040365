import React, { useEffect, useState } from "react";
import Tabla from "../../components/TablaRfids";
import Popup from '../../components/Modal';

import { useSelector, useDispatch } from "react-redux";
import { obtenerRfids2 } from "../../redux/actions/rfid";
const initDataForm = {
  status: "VIAJE",

};

const listaColumnas = [
  "Marchamo N",
  "Condicion",
  "Estado",
  "Fecha Registro",
  "N Declaracion",
  "Contenedor"
];
const Rfids = () => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [mostrarPopUp, setMostrarPopUp] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaRfids} = state.rfid;
 
  //const { editOk } = state.posicionRfid;
  //console.log("EL VALOR DEL EDIT OK", editOk);

 
  useEffect(() => {
  //  dispatch(obtenerRfidsViajeEstadoFiltrados(dataForm.estado));
  //dispatch(obtenerU)
  dispatch(obtenerRfids2(initDataForm.status))
  }, []);


  
 
  return (
    <>
    
     
      <h1>Lista Viajes</h1>

      {listaRfids.length >0 && <Tabla nombretabla="viaje" listaDatos={listaRfids} listaColumnas={listaColumnas} />}

    </>
  );
};

export default Rfids;
