import React, { useEffect } from "react";
import Formulario from "../../components/Formulario";

import { editarRfid } from "../../redux/actions/rfid";
import { useDispatch, useSelector } from "react-redux";

const AgregarRfid = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { editOk } = state.rfid;
  
  useEffect(() => {

    if (editOk) {

      props.history.push("/viajes");
    }
  }, [editOk]);

  const nuevoRfid = (rfid) => {
    dispatch(editarRfid(rfid));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Iniciar Viaje</h1>
      <Formulario agregarNuevoRfid={nuevoRfid} />
    </div>
  );
};

export default AgregarRfid;