import React from "react";

import { editarCamion } from "../../redux/actions/camion";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTableCamion = (props) => {
  const dispatch = useDispatch();

  const { id, placa_cabezal, chasis_cabezal, numero_tag, empresa_trans, viaje } = props.camion;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{empresa_trans} </td>

      <td>{placa_cabezal} </td>
      <td>{chasis_cabezal} </td>
      <td>{numero_tag} </td>
      <td>{viaje} </td>
      <td className="d-flex justify-content-around ">


      <Link className="btn btn-success" style={{marginTop:"0px"}} to={`/camion/detalle/${id}`}>
          Detalle
        </Link>
        <Link className="btn btn-warning" style={{marginTop:"0px"}} to={`/camion/editar/${id}`}>
          Editar
        </Link>
      
      </td>
    </tr>
  );
};

export default RowTableCamion;
