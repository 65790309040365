import React from "react";

import { editarCamion } from "../../redux/actions/camion";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTableTagCamion = (props) => {
  const dispatch = useDispatch();
  console.log(props.tagcamion)
  const { id, placa_vehiculo,numero_tag, estadoTag, fecha_asignado, hora_asignado, idCamion, viaje } = props.tagcamion;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{numero_tag} </td>
     
      <td>{estadoTag} </td>
   
      <td>{fecha_asignado} </td>
      <td>{hora_asignado} </td>
      <td>{placa_vehiculo} </td>
      <td className="d-flex justify-content-around ">
      


      <Link className="btn btn btn-warning" style={{marginTop:"0px"}} to={`/tag/editar/${id}`}>
          Editar
        </Link>
        <button
        style={{marginTop:"0px"}}
          disabled
          className="btn btn-danger"
          onClick={() => dispatch(editarCamion(numero_tag))}
        >
          Eliminar
        </button>
      </td>
    </tr>
  );
};

export default RowTableTagCamion;
