import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";

export const eliminarEmparejamiento = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/emparejamiento/delete/${id}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok && rfidRec.data.data) {
        dispatch({
          type: "ELIMINAR_EMPAREJAMIENTO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ELIMINAR_EMPAREJAMIENTO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarEmparejamiento = (entidad) => {
  return async (dispatch) => {
    try {
      const datos_creacion = entidad.datos_creacion;
      const origen = entidad.origen;
      const destino = entidad.destino;
      const vehiculo = entidad.vehiculo;
      const numero_precintos = entidad.numero_precintos;
      const piloto_datos = entidad.piloto_datos;
      const contenedor_datos = entidad.contenedor_datos;
      const viaje = "VIAJE";
      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + "/emparejamiento/create",
        {
          origen,
          viaje,
          destino,
          vehiculo,
          numero_precintos,
          piloto_datos,
          contenedor_datos,
          datos_creacion,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("el empa respuesta", rfidRec);
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "AGREGAR_EMPAREJAMIENTO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_EMPAREJAMIENTO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerEmparejamiento = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      var doc = await axios.get(`${URL}/emparejamiento/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      var emparejamiento = {};

      if (doc?.status === 200) {
        doc = doc.data;
        //   var numero_precinto_sunat="021PE"+ doc.data.numero_precinto.substring(doc.data.numero_precinto.length-6);
        const fecha_creacion = moment(
          doc.data.datos_creacion.fecha_creacion
        ).format("YYYY-MM-DD");

        const hora_creacion = moment(
          doc.data.datos_creacion.fecha_creacion
        ).format("HH:mm:ss");

        emparejamiento = {
          //    numero_precinto_sunat,
          id: id,
          idPiloto: doc.data.piloto_datos.idPiloto,
          tipo_vehiculo_nombre: doc.data.vehiculo?.tipo_vehiculo_nombre,
          fecha_creacion,
          hora_creacion,
          ...doc.data,
        };
      }

      console.log("el epa", emparejamiento);
      dispatch({
        type: "OBTENER_EMPAREJAMIENTO",
        payload: emparejamiento,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerEmparejamientoPrecinto = (id, numero_precinto) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      var doc = await axios.get(`${URL}/emparejamiento/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      var emparejamiento = {};

      if (doc?.status === 200 && doc?.data.ok) {
        doc = doc.data;
        var fecha_creacion = moment(
          doc.data.datos_creacion.fecha_creacion
        ).format("YYYY-MM-DD");
        var hora_creacion = moment(
          doc.data.datos_creacion.fecha_creacion
        ).format("HH:mm:ss");
        var precintosF = doc.data.precintos?.filter(
          (item) => item.numero_precinto == numero_precinto
        );

        emparejamiento = {
          id: id,
          idPiloto: doc.data.piloto_datos.idPiloto,
          idCamion: doc.data.vehiculo.Camion?.id,
          idFurgon: doc.data.vehiculo.Furgon?.id,
          idContenedor: doc.data.contenedor_datos?.id,
          idTrailer: doc.data.vehiculo.Trailer?.id,
          idCamioneta: doc.data.vehiculo.Camioneta?.id,
          idChasis: doc.data.vehiculo.Chasis?.id,
          idAcoChasis: doc.data.vehiculo.AcoChasis?.id,
          idAcoFurgon: doc.data.vehiculo.AcoFurgon?.id,
          idSemiChasis: doc.data.vehiculo.SemiChasis?.id,
          idSemiFurgon: doc.data.vehiculo.SemiFurgon?.id,
          fecha_creacion,
          hora_creacion,
          precintosFinal: precintosF,
          ...doc.data,
        };
      }

      dispatch({
        type: "OBTENER_EMPAREJAMIENTO",
        payload: emparejamiento,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerEmparejamientos = (viaje) => {
  return async (dispatch) => {
    try {
      const arrayLista = [];
      var token = localStorage.getItem("token");

      var lista = await axios.get(URL + "/emparejamiento/getallgeneral", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        params: {
          viaje,
        },
      });

      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
        
          var fecha_creacion = moment(
            item.datos_creacion.fecha_creacion
          ).format("YYYY-MM-DD");
          var hora_creacion = moment(item.datos_creacion.fecha_creacion).format(
            "HH:mm:ss"
          );
          fecha_creacion = fecha_creacion + " " + hora_creacion;
          arrayLista.push({
            ...item,
            id: item._id,
            fecha_creacion,
            hora_creacion,
            nombre_piloto: item.piloto_datos?.nombre_completo,
            placa_vehiculo: item.vehiculo.placa_vehiculo,
            tipo_vehiculo: item.vehiculo.tipo_vehiculo_nombre,
          });
        });
      }
     
      dispatch({
        type: "OBTENER_EMPAREJAMIENTOS",
        payload: arrayLista,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cerrarViajePrecinto = (numero_doc_precinto,
  numero_precinto,
  descripcion,
  userLogin,
  tipo_cierre_precinto,
  nombreUsuario
) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + "/emparejamiento/cerrarviaje/precinto",
        {
          numero_doc_precinto,
          numero_precinto,
          descripcion,
          userLogin,
          tipo_cierre_precinto,
          nombreUsuario,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "CERRAR_VIAJE_PRECINTO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "CERRAR_VIAJE_PRECINTO",
        payload: false,
        //   payload2: rfidRec.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cerrarEmparejamiento = (
  numero_emparejamiento,
  descripcion,
  nombreUsuario
) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      if (descripcion == "") {
        descripcion = "SIN DESCRIPCION";
      }
      var rfidRec = await axios.post(
        URL + "/emparejamiento/cerrarviaje/emparejamiento",
        { numero_emparejamiento, descripcion, nombreUsuario },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "CERRAR_EMPAREJAMIENTO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "CERRAR_EMPAREJAMIENTO",
        payload: false,
        //   payload2: rfidRec.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };
};
/*
export const obtenerEmparejamientosSelect = () => {
  return async (dispatch) => {
    try {
  
      let colec1 = db.collection("emparejamientos") ;
    const doc = await colec1.get();
      
      const empresas = [];
      const listaEmpresasSelect=[{label: "Seleccione un Emparejamiento", value: -1, selected:true}];

      doc.forEach((item) => {
        
        empresas.push({ id: item.id, ...item.data() });
        listaEmpresasSelect.push({ key: item.id,label: item.data().nombre, value: item.id, selected:false })
      }); 
      
      dispatch({
        type: "OBTENER_EMPAREJAMIENTOS",
        payload: empresas, 
        payload2:listaEmpresasSelect
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};





export const eliminarPiloto = (id) => {
  return async (dispatch) => {
    try {
      await db.collection("empresatrans").doc(id).delete();
      dispatch({
        type: "ELIMINAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerEmparejamiento = (id) => {
  return async (dispatch) => {
    try {
      const doc = await db.collection("emparejamientos").doc(id).get();
      let empresa = {
        id: id,
        ...doc.data(),
      };
      dispatch({
        type: "OBTENER_EMPAREJAMIENTO",
        payload: empresa,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerEmpresaPorNombre = (nombre) => {
  return async (dispatch) => {
    try {
      let colec1 = db.collection("empresatrans")
      .where("nombre", "==", nombre);
    const doc = await colec1.get();
    //if(doc?.docs[0]?.id){
   
      let empresa = {
        id: doc?.docs[0]?.id
       
      };
    
    
      dispatch({
        type: "OBTENER_EMPRESA",
        payload: empresa,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const editarEmpresaTrans = (empresa) => {
  return async (dispatch) => {
    try {
     
      const empresaUpdate = { ...empresa };
      delete empresaUpdate.id;
    
      await db.collection("empresatrans").doc(empresa.id).update(empresaUpdate);
      dispatch({
        type: "EDITAR_EMPRESA",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (empresas) => {
  return async (dispatch) => {
    try {
      const empresaExisten = [];
      for(const domi of empresas){
        let colec1 = db.collection("emparejamientos")
        .where("nit", "==", domi.nit);
      const doc = await colec1.get();
      if(doc?.docs[0]?.id){
     
        empresaExisten.push({...domi, idCamion: doc?.docs[0]?.id})
      }
    
      }
     
      empresas=empresas.filter((value)=>
    !empresaExisten.find(y=>y.nit == value.nit)
    );
    
     let erroneos=false
       var batch = db.batch()
       const fecha = moment().format("YYYY-MM-DD");
        const hora = moment().format("HH:mm");
        const usuario={};
        usuario["uid"]=localStorage.getItem("useruid");
        usuario["email"]=localStorage.getItem("email");
        empresas.forEach((doc) => {
      
        doc.fecha_creacion=fecha;
        doc.hora_creacion=hora;
        doc.usuario=usuario;
      erroneos=true;
      
        var docRef = db.collection("empresatrans").doc(); //automatically generate unique id
        batch.set(docRef, doc);
      });
      batch.commit()
     // await db.collection("rfids").add(rfid);
      dispatch({
        type: "AGREGAR_EMPRESA_LISTA",
        payload: erroneos,
        payload2:empresaExisten
      });
    } catch (error) {
      console.log(error);
    }
  };
};*/
