/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
var locale = navigator.language;

//import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

function getCSSVariableValue(variableName) {
  let hex = getComputedStyle(document.documentElement).getPropertyValue(
    variableName
  );
  if (hex && hex.length > 0) {
    hex = hex.trim();
  }

  return hex;
}
function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString(locale, {
    month: "short",
  });
}
const MixedWidget8 = ({ className, chartColor, chartHeight, informacion }) => {
  const intl = useIntl();
  const chartRef = useRef(null);
  var countArray = [];
  var indiceMeses = [];

  informacion?.resultCierrePrecinto?.map((item) => {
    countArray.push(item.count);
    indiceMeses.push(getMonthName(item._id.month));
  });

  // setCountMeses(informacion.resultCierrePrecinto);
  //setMeses();
  // const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart1 = new ApexCharts(
      chartRef.current,
      chart1Options(chartColor, chartHeight, indiceMeses, countArray)
    );
    if (chart1) {
      chart1.render();
    }

    return chart1;
  };
  useEffect(() => {
    const chart1 = refreshChart();

    return () => {
      if (chart1) {
        chart1.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indiceMeses]);
  /*   useEffect(() => {
    const chart1 = refreshChart();

    return () => {
      if (chart1) {
        chart1.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef]); */

  return (
    <div
      className={`card ${className}`}
      style={{
        marginLeft: "3rem",
        marginRight: "3rem",
      }}
    >
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            <FormattedMessage id="cierre_viaje_precinto" />
          </span>

          <span className="text-muted fw-semibold fs-7">
            {" "}
            <FormattedMessage id="latest_year" />
          </span>
        </h3>

 
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body d-flex flex-column">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-5-chart card-rounded-top"
        ></div>
        {/* end::Chart */}

        {/* begin::Items */}
        <div className="mt-5">
          {/* begin::Item */}
          <div className="d-flex flex-stack mb-5">
            {/* begin::Section */}
            <div className="d-flex align-items-center me-2">
              {/* begin::Symbol */}
              <div className="symbol symbol-50px me-3">
                <div className="symbol-label bg-light">
                  <i
                    className="ki-duotone ki-black-right-line"
                    style={{ fontSize: "2rem" }}
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div>
                <a
                  href="#"
                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                >
                  <FormattedMessage id="inspeccion" />
                </a>
                <div className="fs-7 text-muted fw-semibold mt-1"></div>
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}

            {/* begin::Label */}
            <div className="badge badge-light fw-semibold py-4 px-3">
              {informacion?.countRfidInspeccion}
            </div>
            {/* end::Label */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className="d-flex flex-stack mb-5">
            {/* begin::Section */}
            <div className="d-flex align-items-center me-2">
              {/* begin::Symbol */}
              <div className="symbol symbol-50px me-3">
                <div className="symbol-label bg-light">
                  <i
                    className="ki-duotone ki-disconnect"
                    style={{ fontSize: "2rem" }}
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                  </i>
                </div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div>
                <a
                  href="#"
                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                >
                  <FormattedMessage id="precinto_manipulado" />
                </a>
                <div className="fs-7 text-muted fw-semibold mt-1"></div>
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}

            {/* begin::Label */}
            <div className="badge badge-light fw-semibold py-4 px-3">
              {informacion?.countRfidManipulado}
            </div>
            {/* end::Label */}
          </div>
          {/* end::Item */}

          {/* begin::Item */}
          <div className="d-flex flex-stack">
            {/* begin::Section */}
            <div className="d-flex align-items-center me-2">
              {/* begin::Symbol */}
              <div className="symbol symbol-50px me-3">
                <div className="symbol-label bg-light">
                  <i
                    className="ki-duotone ki-eye-slash"
                    style={{ fontSize: "2rem" }}
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div className="py-1">
                <a
                  href="#"
                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                >
                  <FormattedMessage id="no_encontrado" />
                </a>

                <div className="fs-7 text-muted fw-semibold mt-1"></div>
              </div>
              {/* end::Title */}
            </div>
            {/* end::Section */}

            {/* begin::Label */}
            <div className="badge badge-light fw-semibold py-4 px-3">
              {informacion?.countRfidNoEncontrado}
            </div>
            {/* end::Label */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  );
};

const chart1Options = (chartColor, chartHeight, indiceMeses, countArray) => {
  const labelColor = getCSSVariableValue("--bs-gray-800");
  const strokeColor = getCSSVariableValue("--bs-gray-300");
  const baseColor = getCSSVariableValue("--bs-" + chartColor);
  const lightColor = getCSSVariableValue("--bs-" + chartColor + "-light");

  return {
    series: [
      {
        name: "Total",
        data: countArray,
        // data: [30, 38, 60, 25, 22, 40],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    fill1: {
      type: "gradient",
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0.375,
        stops: [25, 50, 100],
        colorStops: [],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      // categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      categories: indiceMeses,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 65,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return "" + val + "";
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      // @ts-ignore
      strokeColor: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget8 };
