import { useState, useEffect } from "react";
import moment from "moment";
import { agregarContenedor,editarContenedor } from "../../redux/actions/contenedor";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  naviera: "",
  numero: "",
  medida: "",
  tipo: "",
  numero_precintos: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalContenedor = ({ contenedor, editar = false }) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, mensajeError,editOk } = state.contenedor;
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    if (addOk) {
      history.push("/contenedores");
    }
  }, [addOk, history]);
  useEffect(() => {
    if (editOk) {
      history.push("/contenedores");
    }
  }, [editOk]);

  const nuevoContenedor = (contenedor) => {
    dispatch(agregarContenedor(contenedor));
  };

  useEffect(() => {
    if (editar) {
      console.log("el conte", contenedor)
      delete contenedor.datos_creacion;
      delete contenedor.datos_edicion;
      delete contenedor.__v;
      setDataForm({ ...contenedor });
    }
  }, [contenedor, editar]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].trim() === "-1" ||
          dataForm[key].length === 0
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const fecha = moment().format("YYYY-MM-DD");
    const hora = moment().format("HH:mm");
    var dataForm1 = { ...dataForm };

    e.preventDefault();
    if (isValid()) {
      if (editar) {
      //  dataForm1 = { ...dataForm1, fecha_edicion: fecha, hora_edicion: hora };
       dispatch(editarContenedor(dataForm1))
      } else {
        dataForm1 = {
          ...dataForm1,
          viaje: "Libre",
          fecha_creacion: fecha,
          hora_creacion: hora,
        };
        nuevoContenedor(dataForm1);
      }
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
        {editar ? (
          <FormattedMessage id="Edit" />
        ) : (
          <FormattedMessage id="Add" />
        )}
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
          {editar ? (
          <FormattedMessage id="contenedor_edit" />
        ) : (
          <FormattedMessage id="contenedor_add" />
        )}

          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={4}>
                <Form.Label className="mb-5" htmlFor="naviera">
                <FormattedMessage id="contenedor_naviera" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="naviera"
                  value={dataForm?.naviera}
                  onChange={handleChange}
                />

                {errors.naviera && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.naviera}
                  </span>
                )}
              </Col>

              <Col xs={4}>
                <Form.Label className="mb-5" htmlFor="numero">
                <FormattedMessage id="contenedor_numero" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="numero"
                  value={dataForm?.numero}
                  onChange={handleChange}
                />

                {errors.numero && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero}
                  </span>
                )}
              </Col>

              <Col xs={4}>
                <Form.Label className="mb-5" htmlFor="medida">
                <FormattedMessage id="contenedor_medida" />
                </Form.Label>

                <Form.Select
                  name="medida"
                  value={dataForm?.medida}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  <option value="20 Pies">20 Pies</option>
                  <option value="40 Pies">40 pies</option>
                  <option value="53 Pies">53 pies</option>
                </Form.Select>

                {errors.medida && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.medida}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={9}>
                <Form.Label className="my-5" htmlFor="tipo">
                <FormattedMessage id="contenedor_tipo" />
                </Form.Label>

                <Form.Select
                  name="tipo"
                  value={dataForm.tipo}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  <option>Dray (Seco)</option>
                  <option>Flat Rack</option>
                  <option>High Cube</option>
                  <option>Open Top</option>
                  <option>Plataforma</option>
                  <option>Frigorifico (Reefer)</option>
                  <option>Tanque (7 tipos)</option>
                </Form.Select>

                {errors.tipo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.tipo}
                  </span>
                )}
              </Col>

              <Col xs={3}>
                <Form.Label className="my-5" htmlFor="numero_precintos">
                <FormattedMessage id="contenedor_precintos" />
                </Form.Label>

                <Form.Select
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Form.Select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            <FormattedMessage id="cancelar" />
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            {editar ? (
              <FormattedMessage id="Edit" />
            ) : (
              <FormattedMessage id="Add" />
            )}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalContenedor;
