import React, { useState, useEffect } from "react";
import { obtenerRfidsFiltrados } from "../../redux/actions/rfid";
import { useDispatch, useSelector } from "react-redux";
import { obtenerPilotosFiltrados } from "../../redux/actions/piloto";
import { obtenerCamionesFiltrados } from "../../redux/actions/camion";
import { obtenerContenedoresFiltrados } from "../../redux/actions/contenedor";
import moment from "moment";
import { Link } from "react-router-dom";
const initDataForm = {
  numero_doc_precinto: "",
  condicion: "CERRADO",
  estado: "OK",

  idPiloto: "",
  idCamion: "",
  idContenedor: "",
};

const errorsInit = {
  ...initDataForm,
};

const Formulario = ({ agregarNuevoRfid, rfid, editar = true, editarRfid }) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [numero_precinto, setNumeroPrecinto] = useState("");
  const [nombre_completo, setNombreCompleto] = useState("");
  const [naviera, setNaviera] = useState("");
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { listaRfidsSelect } = state.rfid;
  const { listaPilotosSelect } = state.piloto;
  const { listaCamionesSelect } = state.camion;
  const { listaContenedoresSelect } = state.contenedor;
  useEffect(() => {
    dispatch(obtenerRfidsFiltrados());
    dispatch(obtenerPilotosFiltrados());
    dispatch(obtenerCamionesFiltrados());
    dispatch(obtenerContenedoresFiltrados());
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    let index = e.nativeEvent.target.selectedIndex;
    switch (e.target.name) {
      case "numero_doc_precinto":
        console.log("el numero del precinto es",e.nativeEvent.target[index].label)
        setNumeroPrecinto(e.nativeEvent.target[index].label);
        break;
      case "idPiloto":
        console.log("el nombre del pilto es",e.nativeEvent.target[index].label)
        setNombreCompleto(e.nativeEvent.target[index].label);
        break;
      case "idContenedor":
        console.log("el nombre del conenedor es",e.nativeEvent.target[index].label)
        setNaviera(e.nativeEvent.target[index].label);
        break;
      default:
        break;
    }

    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fecha = moment().format("YYYY-MM-DD");

    const hora = moment().format("HH:mm");
    const dataForm1 = {
      ...dataForm,
      viaje: "Viaje",
      posicion: "Inicial",
      fecha,
      hora,
      numero_precinto,
      nombre_completo,
      naviera,
    };
console.log("el que se acaba de enviar es:", dataForm1);
    if (isValid()) {
      // mandar la data al proveedor

      agregarNuevoRfid(dataForm1);

      // limpiar los campos
      setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                <h1 className="card-title">Iniciar Viaje</h1>
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="numero_doc_precinto">Precinto N</label>

                    <select
                      type="text"
                      className="form-control"
                      name="numero_doc_precinto"
                      value={dataForm.numero_doc_precinto}
                      onChange={handleChange}
                    >
                      {listaRfidsSelect?.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          label={item.label}
                        ></option>
                      ))}
                    </select>
                    {errors.numero_doc_precinto && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.numero_doc_precinto}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="condicion">Condicion</label>
                    <select
                      className="form-control"
                      name="condicion"
                      value={dataForm.condicion}
                      onChange={handleChange}
                    >
                      <option>CERRADO</option>
                      <option>ABIERTO</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="estado">Estado</label>
                    <select
                      className="form-control"
                      name="estado"
                      value={dataForm.estado}
                      onChange={handleChange}
                    >
                      <option>OK</option>
                      <option>MANIPULADO</option>
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="idPiloto">Piloto</label>

                    <select
                      type="text"
                      className="form-control"
                      name="idPiloto"
                      value={dataForm.idPiloto}
                      onChange={handleChange}
                    >
                      {listaPilotosSelect?.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          label={item.label}
                        ></option>
                      ))}
                    </select>
                    {errors.idPiloto && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.idPiloto}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="idCamion">Camion</label>

                    <select
                      type="text"
                      className="form-control"
                      name="idCamion"
                      value={dataForm.idCamion}
                      onChange={handleChange}
                    >
                      {listaCamionesSelect?.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          label={item.label}
                        ></option>
                      ))}
                    </select>
                    {errors.idCamion && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.idCamion}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="idContenedor">Contenedor</label>

                    <select
                      type="text"
                      className="form-control"
                      name="idContenedor"
                      value={dataForm.idContenedor}
                      onChange={handleChange}
                    >
                      {listaContenedoresSelect?.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          label={item.label}
                        ></option>
                      ))}
                    </select>
                    {errors.idContenedor && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.idContenedor}
                      </span>
                    )}
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" className="btn btn-primary">
                    Iniciar Viaje
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/rfids`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Formulario;
