import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { obtenerTag } from "../../redux/actions/tagcamion";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  empresa_trans: "",
  placa_vehiculo: "",
  licencia: "",
  nombre_completo: "",
  viaje: "",
};
const Card = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { tagcamion } = state.tagcamion;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }

  const [dataForm, setDataForm] = useState(initDataForm);

  useEffect(() => {
    dispatch(obtenerTag(id));
    //   setDataFormConte(initDataFormConte);
    setDataForm({ ...tagcamion });
  }, [id]);

  useEffect(() => {
    setDataForm({ ...tagcamion });
  }, [tagcamion]);

  if (!tagcamion) return <h1>Cargando ...</h1>;

  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/tagscamion`}>
        <FormattedMessage id="ir_lista" />
      </Link>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
            <div className="card-title d-flex flex-column">
              <span className="fw-bolder" style={{ color: "#0A5189" }}>
              <FormattedMessage id="tag_details" />
              </span>
            </div>

            <div className="card-title d-flex flex-row align-items-center gap-3">
              <img
                width={50}
                src={require("../../images/sidebarIcons/iconTagCamion.png")}
                style={{ filter: "invert(100%)" }}
                alt="User Avatar"
              />

              <div className="card-title d-flex flex-column">
                <span className="fw-bolder">{dataForm.numero_tag}</span>
                <span className="text-gray-400 fw-light fs-6"> <FormattedMessage id="tag_number" /></span>
              </div>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder"><FormattedMessage id={`tag_${dataForm?.estadoTag}` || "ASIGNADO"}/></span>
              <span className="text-gray-400 fw-light fs-6"> <FormattedMessage id="tag_status" /></span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.placa_vehiculo}</span>
              <span className="text-gray-400 fw-light fs-6">
              <FormattedMessage id="tag_vehicle" />
              </span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.fecha_asignado}</span>
              <span className="text-gray-400 fw-light fs-6">
              <FormattedMessage id="tag_date_assigned" />
              </span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.hora_asignado}</span>
              <span className="text-gray-400 fw-light fs-6">
              <FormattedMessage id="tag_time_assigned" />
                </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
