const initialState = {
  informacion:{}


};

export const dashboardReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "OBTENER_DASHBOARD":
      return {
        ...state,
        informacion: action.payload,

      };


    default:
      return state;
  }
};
