import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from 'react-toastify';

import { cargaMasiva, buscarEmprePorNombre } from "../../redux/actions/furgon";

import { useDispatch, useSelector } from "react-redux";
import Tabla from "../../components/TablaRfids/TablaUpload";

const UploadFileCamion = (props) => {
  
  const [mostrarButton, setMostrarButton] = useState(true);
 
  const [listaColumnas, setListaColumnas] = useState([]);
  const [listaCamion, setListaCamion] = useState([]);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, listaFurgonExists,listaFurgones } = state.furgon;
  const csvFileToArray = (string) => {
    //  console.log("el string", string)
    setListaColumnas(string.slice(0, string.indexOf("\r")).split(","));
    //  setListaRfids(string.slice(string.indexOf("\n") + 1).split("\n"));
    const csvHeader = string.slice(0, string.indexOf("\r")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\r\n");
    //console.log(csvHeader)
    const xxx = csvRows.filter((value) => {
      return value != "";
    });

    const array = xxx.map((i) => {
      const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        object["viaje"] = "libre";
      //  object["empresa_trans_id"]=buscarEmpresa()
        return object;
      }, {});
      return obj;
    });
   // console.log("solo uno",array);
    dispatch(buscarEmprePorNombre(array))
   // setListaCamion(array);
    // setArray(array);
  };
  const onDrop = useCallback((acceptedFiles) => {
    setMostrarButton(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const text = reader.result;
        console.log("antes de tarns",text)
        csvFileToArray(text);
        // Do whatever you want with the file contents
        //  const binaryStr = reader.result
     
      };
      reader.readAsText(file,'ISO-8859-1');
      //  reader.readAsArrayBuffer(file)
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (addOk) {
      props.history.push("/furgones");
    }
  }, [addOk]);


  useEffect(() => {
    if (listaFurgones.length > 0) {

      console.log("tiene le entrara", listaFurgones)
      setMostrarButton(true);
      setListaCamion(listaFurgones);
    //  listaColumnas.unshift("ID Camion Asignado");
    }
  }, [listaFurgones]);
  useEffect(() => {
    if (listaFurgonExists.length > 0) {
      setMostrarButton(false);
      setListaCamion(listaFurgonExists);
      toast("Estos Registros Ya existen")
      listaColumnas.unshift("ID Camion Asignado");
    }
  }, [listaFurgonExists]);
  const nuevoCamion = (camion) => {
    dispatch(cargaMasiva(camion));
    // agregar producto provider
  };
 

  return (
    <section className="content">
         <ToastContainer />
      <div className="container-fluid-sm p-2 m-2 ">
        <div
          className="accordion accordion-solid portlet_proy_sgmto"
          id="portlet_financiamiento_transfer"
        >
          <div className="card">
            <div className="card-header">
              <div className="form-group row">
               
                <div className="col-md-3">
                 
               
                </div>
              </div>
            </div>
            <div {...getRootProps()} className="upload-file">
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="font-weight-bold">Suelte Sus Archivos AQUI ...</p>
              ) : (
                <p>Suelte Sus Archivos AQUI, o clic para Elegir su archivo</p>
               
              )}
               <button
                   
                    className="inside-upload  "
                  >
                    <i className="fa-solid fa-file-arrow-up"></i> Seleccionar Archivo
                  </button>
            </div>
            {listaCamion.length > 0 && (
              <Tabla
                mostrarButton={mostrarButton}
                insertarLista={nuevoCamion}
                nombretabla="furgonupload"
                listaDatos={listaCamion}
                listaColumnas={listaColumnas}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default UploadFileCamion;
