import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { cerrarSesion } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";

import searchLupa from "../../images/gen021.svg";
import bellNotification from "../../images/gen007.svg";
import text1 from "../../images/gen005.svg";
import messageImport from "../../images/com012.svg";

const Navbar = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { usuario } = state.usuario;
  const [nombre, setNOmbre] = useState(localStorage.getItem("displayname"));
  const [emailF, setEmailF] = useState(localStorage.getItem("userLogin"));
  const [perfiluser, setperfilUser] = useState(localStorage.getItem("perfil"));
  const [nombreempresa, setNOmbreEmpresa] = useState(
    localStorage.getItem("nombreempresa")
  );

  useEffect(() => {
    if (usuario) {
      setNOmbre(usuario.name);
    }
  }, [usuario]);

  const close = (e) => {
    dispatch(cerrarSesion());
  };

  return (
    <div className="app-navbar justify-content-end bg-white pt-3 px-10 mb-10 shadow-sm">
      <div className="app-navbar-item">
        <div className="cursor-pointer d-flex gap-9 align-items-center">
          <img className="mb-2" src={searchLupa} alt="search" />
          <img className="mb-2" src={bellNotification} alt="search" />
          <img className="mb-2" src={text1} alt="search" />
          <img className="mb-2" src={messageImport} alt="search" />

          <Dropdown className="hide-dropdown-arrow">
            <Dropdown.Toggle
              className="text-white fw-bolder fs-4 mb-3 p-5"
              style={{
                backgroundColor: "#0A5189",
              }}
              id="dropdown-basic"
            >
              {usuario?.nombre
                ? usuario?.nombre
                : nombre.split("")[0] +
                  "" +
                  (nombreempresa ? nombreempresa.split("")[0] : "")}
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-5">
              <Dropdown.Item>
                <div className="d-flex gap-3">
                  <p
                    className="rounded p-5 text-white fw-bolder fs-4"
                    style={{ backgroundColor: "#0A5189" }}
                  >
                    {usuario?.nombre
                      ? usuario?.nombre
                      : nombre.split("")[0] +
                        "" +
                        (nombreempresa ? nombreempresa.split("")[0] : "")}
                  </p>

                  <div className="d-flex flex-column">
                    <span className="fw-bolder">
                      {usuario?.nombre ? usuario?.nombre : nombre}
                    </span>

                    <span className="fw-bolder">
                      {usuario?.nombre
                        ? usuario?.nombre
                        : nombreempresa
                        ? nombreempresa
                        : ""}
                    </span>

                    <span>{emailF}</span>
                  </div>
                </div>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  className="text-black"
                  activeClassName="active"
                  to="/usuario/profile"
                  exact
                >
                  <div className="fw-bold">
                    <FormattedMessage id="usuario_perfil" />
                  </div>
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  className="text-black"
                  activeClassName="active"
                  to="/emparejamientos"
                  exact
                >
                  <div className="fw-bold">
                    <FormattedMessage id="vinculacion" />
                  </div>
                </Link>
              </Dropdown.Item>

              {/*       <Dropdown.Item>
                <span className="fw-bold">Viaje</span>
              </Dropdown.Item>
 */}
              <Dropdown.Item>
                <Link
                  className="text-black"
                  activeClassName="active"
                  to="/"
                  exact
                >
                  <div className="fw-bold" onClick={close}>
                    <FormattedMessage id="cerrar_sesion" />
                  </div>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
