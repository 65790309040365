import React from "react";

import { eliminarPiloto } from "../../redux/actions/piloto";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTableUsuario = (props) => {
  const dispatch = useDispatch();

  const {_id, id_user, name, email, perfil,fecha_creacion,hora_creacion} = props.usuario;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{name} </td>
     
      <td>{email} </td>
      <td>{perfil} </td>
      <td>{fecha_creacion} </td>
      <td>{hora_creacion} </td>
      <td className="d-flex justify-content-around ">
      
      <Link className="btn btn btn-success" style={{marginTop:"0px"}} to={`/usuario/detalle/${_id}`}>
          Detalle
        </Link>

     
    
      </td>
    </tr>
  );
};

export default RowTableUsuario;
