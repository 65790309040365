import React from "react";

import { editarContenedor } from "../../redux/actions/contenedor";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTableCamion = (props) => {
  const dispatch = useDispatch();
  
  const { id,naviera, numero, medida, tipo, viaje } = props.contenedor;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{naviera} </td>
     
      <td>{numero} </td>
      <td>{medida} </td>
      <td>{tipo} </td>
      <td>{viaje} </td>
      <td className="d-flex justify-content-around ">
      

      <Link className="btn btn-success mr-1" style={{marginTop:"0px"}} to={`/contenedor/detalle/${id}`}>
          Detalle
        </Link>
        <Link className="btn btn-warning "style={{marginTop:"0px"}} to={`/contenedor/editar/${id}`}>
          Editar
        </Link>
       
      </td>
    </tr>
  );
};

export default RowTableCamion;
