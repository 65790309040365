import React, { useEffect } from "react";

import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import { obtenerVehiculos } from "../../redux/actions/vehiculo";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";



const Vehiculos = () => {

  const intl=useIntl();
  const listaColumnasx = [
    { accessor: "tipo_vehiculo", label: intl.formatMessage({id:"vehiculo_tipo"})},
    { accessor: "placa_vehiculo", label: intl.formatMessage({id:"vehiculo_placa"})},
    { accessor: "empresa_trans", label: intl.formatMessage({id:"vehiculo_empresatrans"})},  
    { accessor: "status", label: intl.formatMessage({id:"vehiculo_status"}) },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaVehiculos } = state.vehiculo;

  useEffect(() => {
    dispatch(obtenerVehiculos());
  }, []);

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("vehiculo")}

              <div className="card-title d-flex gap-5">
                <Link to="/vehiculo/uploadfile">
                  <button
                    className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                    style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  >
                    <FormattedMessage id="importar_archivo"/>
                  </button>
                </Link>

                {ModalAddGlinseal("vehiculo")}
              </div>
            </div>
            {listaVehiculos.length > 0 && (
              <App
                nombretabla="vehiculo"
                rows={listaVehiculos}
                columns={listaColumnasx}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Vehiculos;
