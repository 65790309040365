import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoCamion";

import { agregarCamion } from "../../redux/actions/camion";
import { useDispatch, useSelector } from "react-redux";

const AgregarCamion = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.camion;
  
  useEffect(() => {

    if (addOk) {

      props.history.push("/camiones");
    }
  }, [addOk]);

  const nuevoCamion = (camion) => {
    dispatch(agregarCamion(camion));
    // agregar producto provider
  };
  return (
    <div>
      <Formulario agregarNuevoCamion={nuevoCamion} />
    </div>
  );
};

export default AgregarCamion;