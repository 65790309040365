import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
const initDataForm = {
  naviera: "",
  numero: "",
  medida: "",
  tipo: "",
  numero_precintos: "",
};

const errorsInit = {
  ...initDataForm,
};

const NuevoContenedor = ({
  mensajeError,
  agregarNuevoContenedor,
  contenedor,
  editar = false,
  editarContenedor,
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);

  useEffect(() => {
    if (editar) {
      setDataForm({ ...contenedor });
    }
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].trim() === "-1" ||
          dataForm[key].length === 0
        ) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const fecha = moment().format("YYYY-MM-DD");
    const hora = moment().format("HH:mm");
    var dataForm1 = { ...dataForm };
    e.preventDefault();
    if (isValid()) {
      // mandar la data al proveedor
      if (editar) {
        dataForm1 = { ...dataForm1, fecha_edicion: fecha, hora_edicion: hora };
        editarContenedor(dataForm1);
      } else {
        dataForm1 = {
          ...dataForm1,
          viaje: "Libre",
          fecha_creacion: fecha,
          hora_creacion: hora,
        };
        agregarNuevoContenedor(dataForm1);
      }
      // limpiar los campos
      //  setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                {editar ? (
                  <h1 className="card-title">Editar Contenedor</h1>
                ) : (
                  <h1 className="card-title">Agregar Contenedor</h1>
                )}
              </div>
              <form
                onSubmit={handleSubmit}
                style={{ maxWidth: "50em", padding: "1rem" }}
              >
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="naviera">Naviera</label>
                    <input
                      type="text"
                      className="form-control"
                      name="naviera"
                      value={dataForm.naviera}
                      onChange={handleChange}
                    />
                    {errors.naviera && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.naviera}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="numero">Numero</label>
                    <input
                      type="text"
                      className="form-control"
                      name="numero"
                      value={dataForm.numero}
                      onChange={handleChange}
                    />
                    {errors.numero && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.numero}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="medida">Medida</label>
                    <select
                      className="form-control"
                      name="medida"
                      value={dataForm.medida}
                      onChange={handleChange}
                    >
                      <option value="-1">Seleccione</option>
                      <option>20 Pies</option>
                      <option>40 pies</option>
                      <option>53 pies</option>
                    </select>
                    {errors.medida && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.medida}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-8">
                    <label htmlFor="tipo">Tipo</label>
                    <select
                      className="form-control"
                      name="tipo"
                      value={dataForm.tipo}
                      onChange={handleChange}
                    >
                      <option value="-1">Seleccione</option>
                      <option>Dray (Seco)</option>
                      <option>Flat Rack</option>
                      <option>High Cube</option>
                      <option>Open Top</option>
                      <option>Plataforma</option>
                      <option>Frigorifico (Reefer)</option>
                      <option>Tanque (7 tipos)</option>
                    </select>
                    {errors.tipo && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.tipo}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="numero_precintos">Numero precintos</label>
                    <select
                      className="form-control"
                      name="numero_precintos"
                      value={dataForm.numero_precintos}
                      onChange={handleChange}
                    >
                      <option value="-1">Seleccione</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                    </select>
                    {errors.numero_precintos && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.numero_precintos}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  {mensajeError !== "" && <p>{mensajeError}</p>}
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar Contenedor" : "Agregar Contenedor"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/contenedores`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoContenedor;
