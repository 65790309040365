import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { obtenerRfid } from "../../redux/actions/rfid";
import PosicionRfids from "../Emparejamientos/ListaPosicionRfids";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  condicion: "",
  estado: "",
  numero_precinto: "",
  numero_precinto_sunat: "",
  TID: "",
  TID2: "",
  status: "",
  precintos: null,
};

const CardRfid = (props) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [perfiluser, setperfilUser] = useState(localStorage.getItem("perfil"));
  const [bg, setBg] = useState("bg-warning");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { rfid } = state.rfid;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }

  useEffect(() => {
    dispatch(obtenerRfid(id));
  }, []);

  useEffect(() => {
    if (rfid) {
      setDataForm({ ...rfid });
      if (rfid?.status === "TERMINADO") {
        setBg("bg-danger");
      }
    }
  }, [rfid]);

  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/rfids`}>
      <FormattedMessage id="Back" />
      </Link>

      <PosicionRfids
        param1="NOMOSTRAR"
        numero_doc_precinto={dataForm.numero_precinto || ""}
      />
    </>
  );
};

export default CardRfid;
