import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PopupViaje from "../../components/Modal";
import RFIDAbierto from "../../images/rfidsImg/rfidAbierto.svg";
import RFIDCerrado from "../../images/rfidsImg/rfidCerrado.svg";
import RFIDManipulado from "../../images/rfidsImg/rfidManipulado.svg";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  condicion: "",
  estado: "",
  numero_precinto: "",
  numero_precinto_sunat: "",
  TID: "",
  TID2: "",
  viaje: "",
  numero_declaracion: "",
};
const   CardRfid = (props) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [perfiluser, setperfilUser] = useState(localStorage.getItem("perfil"));
  const [mostrarPopUp, setMostrarPopUp] = useState(false);
  const emparejamientoPrecintos = props.emparejamiento;
  const keymodal = props.modalCerrar;
  const item = props.rfid;
  const params = useParams();

  let id = props?.match?.params?.id;

  if (!id) {
    id = props?.id;
  }
  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
   

    var numero_precinto_temp =
      props.rfid?.numero_precinto?.substring(0, 3) +
      "-" +
      props.rfid?.numero_precinto?.substring(
        props.rfid?.numero_precinto.length - 6
      );

    setDataForm({
      ...props.rfid,
      numero_precinto: numero_precinto_temp,
    });
  }, [props]);

  const conditionStatus = (params) => {
    switch (params) {
      case "ABIERTO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-warning">
            <FormattedMessage id={params} />
          </span>
        );

      case "CERRADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-primary">
            <FormattedMessage id={params} />
          </span>
        );

      case "OK":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-success">
            {params}
          </span>
        );

      case "MANIPULADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-danger">
            <FormattedMessage id={params} />
          </span>
        );

      case "VIAJE":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-primary">
            <FormattedMessage id={params} />
          </span>
        );

      case "TERMINADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-danger">
            <FormattedMessage id={params} />
          </span>
        );

      default:
        break;
    }
  };

  const conditionStatusImg = (params) => {
    if (params === "ABIERTO") {
      return <img width={50} src={RFIDAbierto} alt="RFIF ABIERTO" />;
    } else if (params === "CERRADO" && dataForm.estado === "OK") {
      return <img width={50} src={RFIDCerrado} alt="RFID CERRADO" />;
    } else if (params === "CERRADO" && dataForm.estado === "MANIPULADO") {
      return <img width={50} src={RFIDManipulado} alt="RFID CERRADO" />;
    } else if (params === "DOSESTADOS" && dataForm.estado === "MANIPULADO") {
      return <img width={50} src={RFIDManipulado} alt="RFID CERRADO" />;
    } else if (params === "DOSESTADOS" && dataForm.estado === "OK") {
      return <img width={50} src={RFIDAbierto} alt="RFID CERRADO" />;
    }
  };
  return (
    <div className="card fs-3 py-5 d-flex flex-row justify-content-between align-items-center">
      <div className="card-title d-flex flex-row align-items-center gap-3">
        {conditionStatusImg(dataForm.condicion)}

        <div className="card-title d-flex flex-column">
          <span className="fw-bolder">{dataForm?.numero_precinto}</span>
          <span className="text-gray-400 fw-light fs-6">{dataForm.clase}</span>
          {/* <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="rfid_precinto"/></span> */}
        </div>
      </div>

      {perfiluser == "superadmin" && (
        <>
          <div className="input-group flex-nowrap">
            <div className="input-group-prepend">
              <span className="input-group-text bg-danger" id="addon-wrapping">
                TID
              </span>
            </div>
            <input
              style={{ fontSize: 14 }}
              //  onChange={handleChange}
              readOnly
              value={dataForm.TID || ""}
              name="TID"
              id="TID"
              type="text"
              className="form-control"
              aria-label="TID"
              aria-describedby="addon-wrapping"
            />
          </div>
          <div className="input-group flex-nowrap">
            <div className="input-group-prepend">
              <span className="input-group-text bg-danger" id="addon-wrapping">
                TID2
              </span>
            </div>
            <input
              style={{ fontSize: 14 }}
              readOnly
              //  onChange={handleChange}
              value={dataForm.TID2 || ""}
              name="TID2"
              id="TID2"
              type="text"
              className="form-control"
              aria-label="TID2"
              aria-describedby="addon-wrapping"
            />
          </div>
        </>
      )}

      <div className="card-title d-flex flex-column align-items-center ">
        {conditionStatus(dataForm.condicion)}
        {(dataForm.condicion !== "ABIERTO" &&
          dataForm.condicion !== "CERRADO") && (
            <span className="text-white fw-light fs-6">
            <FormattedMessage id="rfid_condicion" />
          </span>
        )}
      
        {(dataForm.condicion == "ABIERTO" ||
          dataForm.condicion == "CERRADO") && (
          <span className="text-gray-400 fw-light fs-6">
            <FormattedMessage id="rfid_condicion" />
          </span>
        )}
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        {conditionStatus(dataForm.estado)}
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="rfid_integridad" />
        </span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        {conditionStatus(dataForm.status)}
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="rfid_status" />
        </span>
      </div>

      {/* <div className="card-title d-flex flex-column align-items-center">
        <span className="fw-bolder">XXXXXX</span>
        <span className="text-gray-400 fw-light fs-6">Código QR</span>
      </div> */}

      <div hidden className="input-group flex-nowrap ">
        <div className="input-group-prepend">
          <span className="input-group-text bg-danger" id="addon-wrapping">
            Numero Declaracion
          </span>
        </div>
        <input
          onChange={handleChange}
          value={dataForm.datos_emparejar?.numero_declaracion || ""}
          name="numero_declaracion"
          id="numero_declaracion"
          type="text"
          className="form-control"
          aria-label="numero_declaracion"
          aria-describedby="addon-wrapping"
        />
      </div>

      <div className="card-title d-flex flex-row gap-2 align-items-center">
        <Link to={`/emparejamiento/posicion/${item?._id}/${params.id}`}>
          <button className="btn btn-sm bg-secondary">
            <i className="bi bi-list-columns-reverse"></i>
          </button>
        </Link>

        {item?.status !== "TERMINADO" && (
          <PopupViaje
            rfid={item}
            mostrarPopUp={mostrarPopUp}
            key={keymodal + 1}
          />
        )}
      </div>
    </div>
  );
};

export default CardRfid;
