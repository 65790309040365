const initialState = {
    listaCamiones: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    camion: null,
    listaCamionesSelect:[],
    listaCamionExists:[]
  };
  
  export const camionesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_CAMIONES":
        return {
          ...state,
          listaCamiones: action.payload,
          listaCamionesSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          camion: null,
        };
      case "AGREGAR_CAMION":
        return {
          ...state,
          addOk: action.payload,
        };
        case "AGREGAR_CAMION_LISTA":
          return {
            ...state,
            addOk: action.payload,
            listaCamionExists: action.payload2,
  
          };
          case "BUSCAR_CAMION_LISTA":
            return {
              ...state,            
              listaCamiones: action.payload,
    
            };
      case "ELIMINAR_CAMION":
        return {
          ...state,
          deleteOk: action.payload,
        };
      case "OBTENER_CAMION":
        return {
          ...state,
          camion: action.payload,
        };
      case "EDITAR_CAMION":
        return {
          ...state,
          editOk: action.payload,
        };
  
      default:
        return state;
    }
  };
  