const initialState = {
  listaUsuarios: [],
  addOk: false,
  deleteOk: false,
  editOk: false,
  usuario: null,
  uid: null,
  email: null,
  errorUser: false,
  isAuthenticated: false,
  iniciarOk: false,
  detenerOk: false,
};
export const authReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        email: action.payload,
        isAuthenticated: action.payload2,
        //    uid: action.payload.uid,
        //    email: action.payload.email,
        //  isAuthenticated:action.payload.isAuthenticated
      };
    case "OBTENER_USUARIO":
      return {
        ...state,
        usuario: action.payload,
      };
    case "ERROR_USUARIO":
      return {
        ...state,
        errorUser: action.payload,
      };
    case "APAGAR_SERVICIO":
      return {
        ...state,
        detenerOk: action.payload,
        iniciarOk: action.payload2,
      };
    case "ENCENDER_SERVICIO":
      return {
        ...state,
        iniciarOk: action.payload,
        detenerOk: action.payload2
      };
    case "OBTENER_USUARIOS":
      return {
        ...state,
        listaUsuarios: action.payload,
        addOk: false,
        deleteOk: false,
        editOk: false,
        user: null,
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
      };

    default:
      return state;
  }
};
