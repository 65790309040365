import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { obtenerEmpresa } from "../../redux/actions/clienteempresa";

const initDataForm = {
  nombre: "",
  nit: "",
  nombre_contacto: "",
  telefono: "",
  email: "",
  idioma: "",
};
const Card = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { clienteempre } = state.clienteempre;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }

  const [dataForm, setDataForm] = useState(initDataForm);

  useEffect(() => {
    dispatch(obtenerEmpresa(id));
    //   setDataFormConte(initDataFormConte);
    setDataForm({ ...clienteempre });
  }, [id]);
  useEffect(() => {
    setDataForm({ ...clienteempre });
  }, [clienteempre]);
  if (!clienteempre) return <h1>Cargando ...</h1>;
  return (
    <>
      <Link className="btn btn-warning ml-2 mb-3" to={`/clienteempre`}>
        Ir a la Lista
      </Link>
      <div className="col-md-6">
        <div
          className="card card-widget widget-user-2"
          style={{ padding: "0em" }}
        >
          <div className="widget-user-header bg-warning">
            <div className="widget-user-image">
              <img
                className="img-circle elevation-2"
                src={require("../../images/driver.png")}
                alt="User Avatar"
              />
            </div>

            <h3 className="widget-user-username">{dataForm.nombre}</h3>
            <h5 className="widget-user-desc">{dataForm.nit}</h5>
          </div>

          <div className="card-body">
            <div className="input-group flex-nowrap">
              <div className="input-group-prepend">
                <span
                  className=" input-group-text btn-success bg-success"
                  id="addon-wrapping"
                >
                  Nombre Contacto
                </span>
              </div>
              <input
                readOnly
                //    onChange={handleChange}
                value={dataForm?.nombre_contacto || ""}
                type="text"
                className="form-control"
                aria-label="Username"
                aria-describedby="addon-wrapping"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <i className="fa fa-duotone fa-car"></i>
                </div>
              </div>
            </div>
            <div className="input-group flex-nowrap">
              <div className="input-group-prepend">
                <span
                  className="input-group-text bg-success"
                  id="addon-wrapping"
                >
                  Telefono
                </span>
              </div>
              <input
                readOnly
                //   onChange={handleChange}
                value={dataForm?.telefono || ""}
                type="text"
                className="form-control"
                aria-label="Username"
                aria-describedby="addon-wrapping"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <i className="fa fa-id-card"></i>
                </div>
              </div>
            </div>

            <div className="input-group flex-nowrap">
              <div className="input-group-prepend">
                <span
                  className="input-group-text bg-success"
                  id="addon-wrapping"
                >
                  Email
                </span>
              </div>
              <input
                readOnly
                //   onChange={handleChange}
                value={dataForm?.email || ""}
                type="text"
                className="form-control"
                aria-label="Username"
                aria-describedby="addon-wrapping"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <i className="fa fa-regular fa-id-badge"></i>
                </div>
              </div>
            </div>
            <div className="input-group flex-nowrap">
              <div className="input-group-prepend">
                <span
                  className="input-group-text bg-success"
                  id="addon-wrapping"
                >
                  Idioma
                </span>
              </div>
              <input
                readOnly
                //   onChange={handleChange}
                value={dataForm?.idioma || ""}
                type="text"
                className="form-control"
                aria-label="viaje"
                aria-describedby="addon-wrapping"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <i className="fa fa-regular fa-id-badge"></i>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
