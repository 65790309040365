import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "../reducer/authReducer";
import { rfidsReducer } from "../reducer/rfidsReducer";
import { pilotosReducer} from "../reducer/pilotosReducer";
import { camionesReducer} from "../reducer/camionesReducer";
import { contenedoresReducer} from "../reducer/contenedoresReducer";
import { rfidsViajesReducer} from "../reducer/viajesReducer";
import { usuariosReducer} from "../reducer/usuarioReducer";
import { empreTransReducer} from "../reducer/empreTransReducer";
import { tagCamionesReducer} from "../reducer/tagCamionReducer";
import { furgonesReducer} from "../reducer/furgonesReducer";
import { emparejamientoReducer} from "../reducer/emparejamientoReducer";
import { vehiculosReducer} from "../reducer/vehiculosReducer";
import { empreClienteReducer} from "../reducer/empreClienteReducer";
import { dashboardReducer} from "../reducer/dashboardReducer";
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducers = combineReducers({
  auth: authReducer,
  rfid: rfidsReducer,
  piloto:pilotosReducer,
  camion: camionesReducer,
  contenedor: contenedoresReducer,
  viajeRfid:rfidsViajesReducer,
  usuario: usuariosReducer,
  empresatrans:empreTransReducer,
  tagcamion:tagCamionesReducer,
  furgon: furgonesReducer,
  emparejamiento: emparejamientoReducer,
  vehiculo:vehiculosReducer,
  clienteempre: empreClienteReducer,
  dashboard: dashboardReducer

});

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
