import { useState } from "react";
import NameCircle from "../../helpers/NameCircle";
import moment from "moment";
import LupaVinculation from "../../images/lupaVincu.png";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const VinculacionEventos = ({
  tipo_vehiculo_nombre,
  datos,
  indice,
  precintoX,
  descripcionCierre,
  
  
}) => {
  const [show, setShow] = useState(false);
  const { fecha_edicion, nombreUsuario, tipo, } = datos;
  var { numero_precinto = "" } = datos;
  if (numero_precinto?.length > 11) {
    numero_precinto =
      numero_precinto?.slice(0, 3) + "-" + numero_precinto?.slice(-3);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fechaX = moment(fecha_edicion).format("YYYY-MM-DD HH:mm:ss");
  const fechaY = moment(fecha_edicion).format("YYYY-MM-DD");
  const horaX = moment(fecha_edicion).format("HH:mm:ss");

  const renderSwitch = (tipo, indice, tipo_vehiculo_nombre) => {
    switch (tipo) {
      default:
        return <div></div>;
      case "VinculacionPrecinto":
        return (
          <span className="fw-bold">
              <FormattedMessage id="vinculacion_evento"/> {indice}:  <FormattedMessage id="vinculacion_precinto_con"/> {" "}
            {tipo_vehiculo_nombre}
          </span>
        );
      case "CierrePrecinto":
        return (
          <span className="fw-bold"><FormattedMessage id="vinculacion_evento"/> {indice}: <FormattedMessage id="vinculacion_cierre_precinto"/> </span>
        );
        case "LecturaPrecinto":
          return (
            <span className="fw-bold"><FormattedMessage id="vinculacion_evento"/> {indice}: <FormattedMessage id="vinculacion_lectura_precinto"/> </span>
          );
      case "CierreEmparejamiento":
        return (
          <span className="fw-bold">
            <FormattedMessage id="vinculacion_evento"/> {indice}: <FormattedMessage id="vinculacion_cierre"/> 
          </span>
        );
    }
  };

  const conditionStatus = (params) => {
    switch (params) {
      case "ABIERTO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-warning">
           <FormattedMessage id= {params}/>  
          </span>
        );

      case "CERRADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-primary">
           <FormattedMessage id= {params}/>  
          </span>
        );

      case "OK":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-success">
           <FormattedMessage id= {params}/>  
          </span>
        );

      case "MANIPULADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-danger">
           <FormattedMessage id= {params}/>  
          </span>
        );

      case "TERMINADO":
        return (
          <span className="fw-bolder badge py-3 px-4 fs-3 badge-light-danger">
           <FormattedMessage id= {params}/>  
          </span>
        );

      default:
        break;
    }
  };

  return (
    <div className="timeline-item">
      <div className="d-flex gap-3 w-100">
        <div
          className="rounded-circle p-4 align-self-start"
          style={{ backgroundColor: "#E9F6FF" }}
        >
          <img src={LupaVinculation} alt="oh" width={25} />
        </div>

        <div className="timeline-content d-flex flex-column">
          {renderSwitch(tipo, indice, tipo_vehiculo_nombre)}

          <span className="text-muted"> <FormattedMessage id="vinculacion_datetime"/> : {fechaX}</span>

          <div className="border border-dark rounded-2 d-flex align-items-center justify-content-between px-10 py-4 mt-6">
            {numero_precinto ? <span> <FormattedMessage id="vinculacion_seal"/> : {numero_precinto}</span>: <span> <FormattedMessage id="vinculacion_cerrada"/> </span>}
            <div className="d-flex align-items-center gap-2">
              <span> <FormattedMessage id="vinculacion_participantes"/> :</span>
              <NameCircle name={nombreUsuario || ""} />
            </div>
            <span className="badge py-3 px-4 fs-7 badge-light-success">
            <FormattedMessage id="vinculacion_completado"/> 
            </span>
            {
              numero_precinto? <>   <Button variant="outline-dark" onClick={handleShow} size="sm">
              <FormattedMessage id="vinculacion_vermas"/> 
            </Button>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title> <FormattedMessage id="vinculacion_seal"/>  {numero_precinto}</Modal.Title>
              </Modal.Header>

              <Modal.Body className="fs-4 d-flex flex-column gap-5">
                <span><FormattedMessage id= "User"/>  : {nombreUsuario}</span>
                <span><FormattedMessage id= "rfid_condicion"/>  : {conditionStatus(precintoX?.condicion)}</span>
                <span><FormattedMessage id= "rfid_integridad"/>  : {conditionStatus(precintoX?.estado)}</span>
                <span><FormattedMessage id= "rfid_status"/>  : {precintoX?.status}</span>
                <span><FormattedMessage id= "DATE"/>  : {fechaY}</span>
                <span><FormattedMessage id= "TIME"/>  : {horaX}</span>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  className="text-white"
                  style={{ backgroundColor: "#0A5189" }}
                  onClick={handleClose}
                >
                <FormattedMessage id= "Close"/> 
                </Button>
              </Modal.Footer>
            </Modal></> : <>
            <Button variant="outline-dark" onClick={handleShow} size="sm">
            <FormattedMessage id="vinculacion_vermas"/> 
            </Button>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title><FormattedMessage id="rfid_column_precinto"/>  {numero_precinto}</Modal.Title>
              </Modal.Header>

              <Modal.Body className="fs-4 d-flex flex-column gap-5">
                <span><FormattedMessage id="vinculacion_descripcion"/> : {descripcionCierre}</span>
               
              </Modal.Body>

              <Modal.Footer>
                <Button
                  className="text-white"
                  style={{ backgroundColor: "#0A5189" }}
                  onClick={handleClose}
                >
                     <FormattedMessage id="Close"/> 
                </Button>
              </Modal.Footer>
            </Modal></> 
            }
           
         
         
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default VinculacionEventos;
