import React, { useEffect, useContext } from "react";
import Formulario from "../../components/Formulario/NuevoCamion";

import { editarCamion, obtenerCamion } from "../../redux/actions/camion";
import { useDispatch, useSelector } from "react-redux";

const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { camion, editOk } = state.camion;

  useEffect(() => {
    // console.log(props.match.params.id);
    dispatch(obtenerCamion(props?.match?.params?.id));
  }, []);

  useEffect(() => {
   
    console.log(editOk);
    if (editOk) {
      props.history.push("/camiones");
    }
  }, [editOk]);

  const edicion = (camion) => {
    // editar producto;
    dispatch(editarCamion(camion));
  };

  if (!camion) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar Camion</h1>
      <Formulario camion={camion} editar={true} editarCamion={edicion} />
    </div>
  );
};

export default Editar;
