import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment";
import { cerrarEmparejamiento } from "../../redux/actions/emparejamiento";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
const initDataForm = {
  id: "",
  nombre_usuario: "",
  descripcion: "",
};

const Modales = ({ mostrarPopUp, id, cerrarEmparejamientoProp }) => {
  const dispatch = useDispatch();
  const [nombreUsuario, setUid] = useState(localStorage.getItem("displayname"));
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) {
      setDataForm({ ...dataForm, id });
    }
  }, [show]);
  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };
  function aprobar(e) {
    e.preventDefault();
    
    dispatch(cerrarEmparejamiento(id, dataForm.descripcion,nombreUsuario));
    handleClose();
  }
  const handleClose = () => setShow(false);

  return (
    <>
      <Button
        onClick={handleShow}
        style={{
          color: "#0A5189",
          fontWeight: "bolder",
          backgroundColor: "#e9f6ff",
        }}
        size="sm"
      >
       <FormattedMessage id="Close" />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <FormattedMessage id="vinculacion_cerrar" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="2">
              <FormattedMessage id="vinculacion" />
              </Form.Label>
              <Col sm="12">
                <Form.Control plaintext readOnly value={dataForm.id} />
              </Col>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> <FormattedMessage id="vinculacion_descripcion" /></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={handleChange}
                value={dataForm.descripcion}
                name="descripcion"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {
            <Button variant="primary" onClick={handleClose}>
            <FormattedMessage id="Cancel" />
            </Button>
          }
          <Button variant="primary" onClick={(e) => aprobar(e)}>
          <FormattedMessage id="vinculacion_save" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modales;
