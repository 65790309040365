import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editarTag } from "../../redux/actions/tagcamion";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const initDataForm = {
  numero_tag: "",
  placa_vehiculo: "",
  id_vehiculo: "",
  estadoTag: "LIBRE",
};

const errorsInit = {
  ...initDataForm,
};

const ModalEditTagCamión = ({ datos }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [vehiculosTag, setVehiculosTag] = useState("");
  const { listaVehiculos } = state.vehiculo;
  const [errors, setErrors] = useState(errorsInit);
  const { editOk } = state.contenedor;
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    if (show) {
      delete datos.datos_asignacion;
      setDataForm({ ...datos });
    }
  }, [show, datos]);

  useEffect(() => {
    if (editOk) {
      history.push("/contenedores");
    }
  }, [editOk, history]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() === "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (isValid()) {
    const dataForm1 = { ...dataForm };
    dispatch(editarTag(dataForm1));
    // }
  };

  return (
    <>
      <Button
        className="btn-secondary fw-bolder"
        style={{ color: "#0A5189" }}
        onClick={handleShow}
      >
        Editar
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Camión</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="numero_tag">
                  Número Tag:
                </Form.Label>
                <Form.Control
                  name="numero_tag"
                  onChange={handleChange}
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.numero_tag || ""}
                />
              </Col>

              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="placa_vehiculo">
                  Vehículo:
                </Form.Label>

                <Form.Select
                  name="placa_vehiculo"
                  onChange={handleChange}
                  defaultValue={dataForm?.id_vehiculo || ""}
                >
                  {listaVehiculos?.map((item, index) => (
                    <option
                      key={index}
                      value={item.value}
                      label={item.label}
                    ></option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            Editar
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEditTagCamión;
