import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  editarEmpresaTrans,
  obtenerEmpresa, agregarEmpresa
} from "../../redux/actions/empresatrans";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  nombre: "",
  nit: "",
  nombre_contacto: "",
  telefono: "",
  email: "",
  direccion: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalEmpresaTrans = ({ id, empresa, editar = false }) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [respuestaServer, setRespuestaServer] = useState("");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, dataError, editOk } = state.empresatrans;
  const history = useHistory();
  // const { id } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    dispatch(obtenerEmpresa(id));
  }, [id]);

  useEffect(() => {
    if (editOk) {
      history.push("/empresatrans");
    }
  }, [editOk]);

  const edicion = (empresa) => {
    dispatch(editarEmpresaTrans(empresa));
  };

  useEffect(() => {
    if (addOk) {
      history.push("/empresatrans");
    }
  }, [addOk]);
  useEffect(() => {
    if (dataError?.code == 11000) {
      setRespuestaServer("Error de duplicidad" + dataError?.valorError);
    }
  }, [dataError?.message]);

  const nuevoEmpresa = (empresa) => {
    dispatch(agregarEmpresa(empresa));
  };

  useEffect(() => {
    if (editar) {
      setDataForm({ ...empresa });
    }
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id" && key !== "usuario") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const dataForm1 = { ...dataForm };
    e.preventDefault();
    if (isValid()) {
      if (editar) {
        edicion(dataForm);
      } else {
        nuevoEmpresa(dataForm1);
      }
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
      >
        {editar ? (
          <FormattedMessage id="Edit" />
        ) : (
          <FormattedMessage id="Add" />
        )}
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
          {editar ? (
          <FormattedMessage id="empresatrans_edit" />
        ) : (
          <FormattedMessage id="empresatrans_add" />
        )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={7}>
                <Form.Label className="mb-5" htmlFor="nombre">
                <FormattedMessage id="empresatrans_name" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="nombre"
                  value={dataForm.nombre}
                  onChange={handleChange}
                />

                {errors.nombre && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.nombre}
                  </span>
                )}
              </Col>

              <Col xs={5}>
                <Form.Label className="mb-5" htmlFor="nit">
                <FormattedMessage id="empresatrans_nit" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="nit"
                  value={dataForm.nit}
                  onChange={handleChange}
                />

                {errors.nit && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.nit}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="direccion">
                <FormattedMessage id="empresatrans_address" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="direccion"
                  value={dataForm.direccion}
                  onChange={handleChange}
                />

                {errors.direccion && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.direccion}
                  </span>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="email">
                <FormattedMessage id="empresatrans_email" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="email"
                  value={dataForm.email}
                  onChange={handleChange}
                />

                {errors.email && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.email}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="nombre_contacto">
                <FormattedMessage id="empresatrans_contact" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="nombre_contacto"
                  value={dataForm.nombre_contacto}
                  onChange={handleChange}
                />

                {errors.nombre_contacto && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.nombre_contacto}
                  </span>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="telefono">
                <FormattedMessage id="empresatrans_phone" />
                </Form.Label>

                <Form.Control
                  type="text"
                  className="bg-body-secondary border border-0"
                  name="telefono"
                  value={dataForm.telefono}
                  onChange={handleChange}
                />

                {errors.telefono && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.telefono}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
             <FormattedMessage id="Cancel" />
          </Button>
          {respuestaServer && (
            <span style={{ color: "red", fontSize: "14px" }}>
              {respuestaServer}
            </span>
          )}
          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
             {editar ? (
          <FormattedMessage id="Edit" />
        ) : (
          <FormattedMessage id="Add" />
        )}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEmpresaTrans;
