import React from "react";
import { FormattedMessage } from "react-intl";
import ActualizarImg from "../../images/actualizar.svg";

const Card = ({ dataForm, actualizarPosicionRfids }) => {
  return (
    <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
      <div className="card-title d-flex">
        <button
          onClick={actualizarPosicionRfids}
         className="btn"
        >
          <img src={ActualizarImg} alt="Actualizar" />
        </button>
        <span className="card-label fw-bolder" style={{ color: "#0A5189" }}>
          <FormattedMessage id="vinculacion" />
        </span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="vinculacion_origen" />
        </span>
        <span className="fw-bolder">{dataForm.origen?.toUpperCase()}</span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="vinculacion_destino" />
        </span>
        <span className="fw-bolder">{dataForm.destino?.toUpperCase()}</span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="vinculacion_tipo_vehiculo" />
        </span>
        <span className="fw-bolder">
          {dataForm.vehiculo?.tipo_vehiculo_nombre}
        </span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="vinculacion_piloto" />
        </span>
        <span className="fw-bolder">
          {dataForm.piloto_datos?.nombre_completo.toUpperCase()}
        </span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="vinculacion_precintos" />
        </span>
        <span className="fw-bolder">{dataForm.numero_precintos}</span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="text-gray-400 fw-light fs-6">
          <FormattedMessage id="vinculacion_fecha_registro" />
        </span>
        <span className="fw-bolder">{dataForm.fecha_creacion}</span>
        <span className="fw-bolder">{dataForm.hora_creacion}</span>
      </div>
    </div>
  );
};

export default Card;
