import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";
export const eliminarUsuario = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/usuario/eliminar/${id}`,
        {},

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok && rfidRec.data.data) {
        dispatch({
          type: "ELIMINAR_USUARIO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "ELIMINAR_USUARIO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const editarUsuario = (usuario) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      
      var rfidRec = await axios.post(
        URL + `/usuario/editar/${usuario._id}`,
        { usuario },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok) {
       
        dispatch({
          type: "EDITAR_USUARIO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "EDITAR_USUARIO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarUsuario = (usuario) => {
  return async (dispatch) => {
    try {
      console.log("a crear user", usuario);
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/auth/register",
        { usuario },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_USUARIO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_USUARIO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerUsuarios = (status) => {
  return async (dispatch) => {
    try {
      const rfids = [];

      var token = localStorage.getItem("token");
      const URL_FInal = URL + "/usuario/getall";
      var lista = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },

        params: {
          status,
        },
      });

      if (lista?.status === 200 && lista?.data?.ok) {
        lista.data.data.forEach((item) => {
          var fecha_creacion = moment(
            item.datos_creacion.fecha_creacion
          ).format("YYYY-MM-DD");
          var hora_creacion = moment(item.datos_creacion.fecha_creacion).format(
            "HH:mm:ss"
          );
          rfids.push({ ...item, fecha_creacion, hora_creacion });
        });
      }

      dispatch({
        type: "OBTENER_USUARIOS",
        payload: rfids,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerUsuario = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");

      const URL_FInal = URL + `/usuario/detalle/${id}`;

      var Objeto = await axios.get(`${URL_FInal}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      let objeto = {};

      if (Objeto?.status === 200 && Objeto.data.ok) {
        //   var fecha_creacion=moment( Objeto.data.data.datos_creacion?.fecha_creacion).format("YYYY-MM-DD");
        //   var hora_creacion = moment(Objeto.data.data.datos_creacion?.fecha_creacion).format("HH:mm:ss");
        objeto = {
          ...Objeto.data.data,
          //fecha_creacion, hora_creacion
        };
      }

      dispatch({
        type: "OBTENER_USUARIO",
        payload: objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarArrayUsuario = (usuarios) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/usuario/insertmany",
        { usuarios },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("respuesta, ", rfidRec);
      if (rfidRec?.status === 200 && rfidRec.data.ok) {
        dispatch({
          type: "AGREGAR_USUARIO_LISTA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_USUARIO_LISTA",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
/*

export const obtenerPilotosFiltrados = () => {
  return async (dispatch) => {
    try {
  
      let colec1 = db.collection("pilotos")  
        .where("viaje","==",false);
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();
      
      const pilotos = [];
      const listaPilotosSelect=[{label: "Seleccione un Piloto", value: 0, selected:true}];

      doc.forEach((item) => {
        
        pilotos.push({ id: item.id, ...item.data() });
        listaPilotosSelect.push({ key: item.id,label: item.data().nombre_completo, value: item.id, selected:false })
      }); 
      
      dispatch({
        type: "OBTENER_PILOTOS",
        payload: pilotos, payload2:listaPilotosSelect
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};


export const obtenerPilotos = () => {
  return async (dispatch) => {
    try {
      const pilotos = [];
      
    
      const doc = await db.collection("pilotos").get();
     // let colec1 = db.collection("rfids")
      //.where("viaje", "==", true);

   
      //console.log("aqui el doc",doc)
      
     doc.forEach((item) => pilotos.push({ id: item.id, ...item.data() }));
      dispatch({
        type: "OBTENER_PILOTOS",
        payload: pilotos,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarPiloto = (piloto) => {
  return async (dispatch) => {
    try {
      await db.collection("pilotos").add(piloto);
      dispatch({
        type: "AGREGAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const eliminarPiloto = (id) => {
  return async (dispatch) => {
    try {
      await db.collection("pilotos").doc(id).delete();
      dispatch({
        type: "ELIMINAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerUsuario = (id) => {
  return async (dispatch) => {
    try {
     // let id2=await localStorage.getItem("email");
    //  let id2="Miguel.carrionmoya@gmail.com";
   //   console.log("desde usuairos ini", id2);
        let colec1 = db.collection("usuarios")  
        .where("email","==",id);
      
      const doc1 = await colec1.get();
      
    
      const usuarios = [];
  

      doc1.forEach((item) => {
       
        usuarios.push({ id: item.id, ...item.data() });
       
      });
     const doc = await db.collection("usuarios").doc(usuarios[0].id).get();
      let usuario = {
        id: id,
        ...doc.data(),
      };
      dispatch({
        type: "OBTENER_USUARIO",
        payload: usuario,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarPiloto = (piloto) => {
  return async (dispatch) => {
    try {
     
      const pilotoUpdate = { ...piloto };
      delete pilotoUpdate.id;
    
      await db.collection("rfids").doc(piloto.id).update(pilotoUpdate);
      dispatch({
        type: "EDITAR_PILOTO",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};*/
