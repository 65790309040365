import React from "react";

import { editarCamion } from "../../redux/actions/camion";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTableFurgon = (props) => {
  const dispatch = useDispatch();

  const { id, empresa_trans,placa_furgon, chasis_cabezal, marca, modelo, viaje } = props.furgon;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{empresa_trans} </td>

      <td>{placa_furgon} </td>
      <td>{chasis_cabezal} </td>
      <td>{marca} </td>
      <td>{modelo} </td>
      
      <td className="d-flex justify-content-around ">


      <Link className="btn btn-success" style={{marginTop:"0px"}} to={`/furgon/detalle/${id}`}>
          Detalle
        </Link>
        <Link className="btn btn-warning" style={{marginTop:"0px"}} to={`/furgon/editar/${id}`}>
          Editar
        </Link>
      
      </td>
    </tr>
  );
};

export default RowTableFurgon;
