import React from "react";



const RowTable = (props) => {


  const {
   idRfid,
    numero_precinto,
    clase,
  //  condicion,
  //  estado,
    TID,
    TID2,
    DATE_RFID,
  tipo
  } = props.rfid;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      {idRfid && <td>{idRfid}</td>}
      <td>{tipo} </td>
      <td>{clase} </td>
      <td>{numero_precinto} </td>
      <td>{TID} </td>
      <td>{TID2} </td>
     
   


    </tr>
  );
};

export default RowTable;
