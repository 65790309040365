import React ,{useEffect}from "react";
import Tabla from "../TablaRfids";

import {  useSelector, useDispatch } from "react-redux";
import { obtenerRfidsViajeFiltrados } from "../../redux/actions/viajes";

const listaColumnas=[
 
          "Condicion",
          "Estado",
         "Fecha",
         "Hora",
         "Posicion"
];
const PosicionRfids = ({numero_doc_precinto}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaPosicionRfidsDetalle,addOk } = state.viajeRfid;

  
  useEffect(() => {

    if (addOk) {
      dispatch(obtenerRfidsViajeFiltrados(numero_doc_precinto));

    }
  }, [addOk]);


useEffect(() => {
  dispatch(obtenerRfidsViajeFiltrados(numero_doc_precinto));
}, [numero_doc_precinto]);
  return (
    <>
   
    
     
      {listaPosicionRfidsDetalle.length >=0 && <Tabla nombretabla="posicionrfid" listaDatos={listaPosicionRfidsDetalle} listaColumnas={listaColumnas} />}
    </>
  );
};

export default PosicionRfids;
