import React from "react";

import { terminarViajeOK } from "../../redux/actions/viajes";
import Popup from '../../components/Modal';
import { Link } from "react-router-dom";
import { useState } from "react";
//import Modalito from '../Modal'

const RowTable = (props) => {
  const [mostrarPopUp, setMostrarPopUp] = useState(false);

  const { id, numero_precinto, condicion, estado, fecha, hora, viaje, nombre_completo, naviera, datos_emparejar } = props.viaje;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{numero_precinto} </td>
      <td  >
   
   {condicion === "ABIERTO" ? <a className="btn btn-warning" style={{marginTop:"0px"}}>{condicion}</a>:<a className="btn btn-primary" style={{marginTop:"0px"}}>{condicion}</a>}
</td>
<td >
{estado === "OK" ? <a className="btn btn-success" style={{marginTop:"0px"}}>{estado}</a>:<a className="btn btn-danger" style={{marginTop:"0px"}}>{estado}</a>}
  
</td>
      <td>{fecha} ({hora}) </td>
      <td>{datos_emparejar?.numero_declaracion}</td>
      <td>{naviera}</td>
      <td className="d-flex justify-content-around ">
      {
          (viaje !== "Terminado") &&
          <Popup rfid={props.viaje} mostrarPopUp={mostrarPopUp} />

        }
        {
          
          (viaje !== "Terminado") ? (<Link style={{marginTop:"0px", marginLeft:"4px", paddingBottom:"0px"}} className="btn btn-warning" to={`/viaje/detalle/${id}`}>
            Detalle
          </Link>) : <Link style={{marginTop:"0px"}} className="btn btn-warning" to={`/viaje/detalle/${id}`}>
              Detalle
          </Link>
        }


    

      </td>
    </tr>
  );
};

export default RowTable;
