import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import {
  obtenerContenedores,
  eliminarContenedor,
} from "../../redux/actions/contenedor";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import { FormattedMessage, useIntl } from "react-intl";



const Contenedores = () => {
  const intl=useIntl();
  const listaColumnasx = [
    { accessor: "naviera", label: intl.formatMessage({id:"contenedor_naviera"}) },
    { accessor: "numero", label:  intl.formatMessage({id:"contenedor_numero"}) },
    { accessor: "medida", label:  intl.formatMessage({id:"contenedor_medida"})  },
    { accessor: "tipo", label:  intl.formatMessage({id:"contenedor_tipo"})  },
    { accessor: "viaje", label:  intl.formatMessage({id:"contenedor_status"})  },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaContenedores, deleteOk } = state.contenedor;

  useEffect(() => {
    dispatch(obtenerContenedores());
  }, []);

  useEffect(() => {
    if (deleteOk) {
      dispatch(obtenerContenedores());
    }
  }, [deleteOk]);

  const eliminarU = (id) => {
    dispatch(eliminarContenedor(id));
  };
  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            {" "}
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("contenedor")}

              <div className="card-title d-flex gap-5">
                <Link to="/contenedor/uploadfile">
                  <button
                    className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                    style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  >
                   <FormattedMessage id="importar_archivo" />
                  </button>
                </Link>

                {ModalAddGlinseal("contenedor")}
              </div>
            </div>
            {listaContenedores.length > 0 && (
              <App
                eliminarU={eliminarU}
                nombretabla="contenedor"
                rows={listaContenedores}
                columns={listaColumnasx}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contenedores;
