import { useEffect, useState } from "react";
import {  FormattedMessage,useIntl } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import {
  obtenerRfids2,
  eliminarRfid,
  deletemany,
} from "../../redux/actions/rfid";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import { Link } from "react-router-dom";
import IconExportar from "../../images/iconExportar.svg";
import IconImportar from "../../images/iconImportar.svg";
import App from "../../components/TablaRfids/App";
import * as XLSX from "xlsx";
import moment from "moment";
import { io } from "socket.io-client";
import { URL } from "../../services/VariablesEntorno";
function exportToExcel(data, fileName) {
  const posiciones = [];
  if (data.length > 0) {
    data.map((dato) => {
      if (dato.posicion.length > 0) {
       
        const numero_precinto=dato.numero_precinto
        const tid=dato.TID
        const tid2=dato.TID2
        dato.posicion.map((item) => {
          posiciones.push({ ...item, numero_precinto, tid, tid2 });
        });
      }
    });
  }

  const worksheet = XLSX.utils.json_to_sheet(data);
  const worksheet2 = XLSX.utils.json_to_sheet(posiciones);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Rfids");
  XLSX.utils.book_append_sheet(workbook, worksheet2, "Posicion");
  XLSX.writeFile(workbook, fileName + ".xlsx");
}




const Rfids = (props) => {
  const intl = useIntl();
  const listaColumnasy = [
    { accessor: "numero_precinto", label: intl.formatMessage({id:"rfid_column_precinto"}) },   
    { accessor: "estado", label: intl.formatMessage({id:"rfid_column_integridad"}) },
    { accessor: "fecha_creacion", label: intl.formatMessage({id:"rfid_column_fecharegistro"})},
    { accessor: "hora_creacion", label: intl.formatMessage({id:"rfid_column_horaregistro"}) },
  ];
  const listaColumnasz = [
    { accessor: "numero_precinto", label: intl.formatMessage({id:"rfid_column_precinto"}) },
    { accessor: "fecha_creacion", label: intl.formatMessage({id:"rfid_column_fecharegistro"})},
    { accessor: "hora_creacion", label: intl.formatMessage({id:"rfid_column_horaregistro"}) },
  ];
  const listaColumnasx = [
    { accessor: "numero_precinto", label: intl.formatMessage({id:"rfid_column_precinto"}) },
    { accessor: "condicion", label:  intl.formatMessage({id:"rfid_column_condicion"})},
    { accessor: "estado", label: intl.formatMessage({id:"rfid_column_integridad"}) },
    { accessor: "fecha_creacion", label: intl.formatMessage({id:"rfid_column_fecharegistro"})},
    { accessor: "hora_creacion", label: intl.formatMessage({id:"rfid_column_horaregistro"}) },
  ];
  const [listaRfidsF, setData] = useState([]);
  const state = useSelector((state) => state);
  const { listaRfids, deleteOk } = state.rfid;
  const dispatch = useDispatch();
  const [idempresa, setEmpe] = useState(localStorage.getItem("idempresa"));
  const [status, setStatus] = useState("STOCK");
  const [tipo, setTipo] = useState("TRESESTADOS");
  const [valueInicio, setValueInicio] = useState(0);
  const [listaColumnas, setListaColumnas] = useState(listaColumnasx);

  function handleExport() {
    exportToExcel(listaRfids, "RfidData");
  }
  function HandleDelete() {
    
    dispatch(deletemany(idempresa));
  }
  useEffect(() => {
    dispatch(obtenerRfids2(status, tipo));
  }, []);
  function callSocket(){
      
    var URL2 = URL.replace('api','');
    const socket = io(URL2); // Replace with your server URL
    socket.on(`api/rfids/${idempresa}`, (data) => {

      
      if (data?.ok) {
        var rfids = [];
        const filtrados = data?.data2.filter((item) => item.tipo === tipo);
       
        if (filtrados.length > 0) {
          filtrados.forEach((item) => {
            var fecha_creacion = moment(
              item.datos_creacion.fecha_creacion
            ).format("YYYY-MM-DD");
            var hora_creacion = moment(
              item.datos_creacion.fecha_creacion
            ).format("HH:mm:ss");

            rfids.push({
              ...item,
              fecha_creacion,
              hora_creacion,
            });
          });
          setData(rfids);
        }
      }
    });
   // actualizarLista();
    return () => {
      socket.disconnect();
    };
  }
  useEffect(() => {

 
  }, [tipo]);
  useEffect(() => {
    setData(listaRfids);
  }, [listaRfids]);
  useEffect(() => {
    if (tipo == "DOSESTADOS") {
      setListaColumnas(listaColumnasy);
    }
    if (tipo == "TRESESTADOS") {
      setListaColumnas(listaColumnasx);
    }
    if (tipo == "UNESTADO") {
      setListaColumnas(listaColumnasz);
    }
    if (valueInicio > 0) {
      dispatch(obtenerRfids2(status, tipo));
    }

    setValueInicio(valueInicio + 1);
  }, [status, tipo]);

  useEffect(() => {
    if (deleteOk) {
   
      dispatch(obtenerRfids2(status, tipo));
    }
  }, [deleteOk]);
  const actualizarLista = () => {
   
    dispatch(obtenerRfids2(status, tipo));
  };
  const eliminarU = (id) => {
    dispatch(eliminarRfid(id));
  };

  const handleChange = (e) => {
    if ([e.target.name] == "status") {
      setStatus(e.target.value);
    }
    if ([e.target.name] == "tipo") {
      setTipo(e.target.value);
    }
  };
  if (!listaRfids) return <p>Cargando ...</p>;

  const totalPrecinto = listaRfids.length;

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("rfid", totalPrecinto,actualizarLista)}

              <div className="card-title d-flex gap-10">
                <div className="d-flex gap-3 align-items-center">
                  <label
                    htmlFor="numero_precintos_total"
                    className="text-body-tertiary"
                  >
                    <FormattedMessage id="rfid_label_position" />
                  </label>
                  <select
                    className="form-select form-select-sm bg-secondary border border-0 fw-bolder text-black"
                    aria-label="estados"
                    name="status"
                    value={status}
                    onChange={handleChange}
                  >
                    <option value="STOCK"> <FormattedMessage id="rfid_status_position_stock" /></option>
                    <option value="VIAJE"><FormattedMessage id="rfid_status_position_viaje" /></option>
                    <option value="TERMINADO"><FormattedMessage id="rfid_status_position_terminado" /></option>
                  </select>
                </div>

                <div className="d-flex gap-3 align-items-center">
                  <label
                    htmlFor="numero_precintos_total"
                    className="text-body-tertiary"
                  >
                       <FormattedMessage id="rfid_label_tipo" />
                  </label>
                  <select
                    className="form-select form-select-sm bg-secondary border border-0 fw-bolder text-black"
                    name="tipo"
                    value={tipo}
                    onChange={handleChange}
                  >
                    <option value={"TRESESTADOS"}> <FormattedMessage id="rfid_tipo_tres_estados" /></option>
                    <option value={"DOSESTADOS"}> <FormattedMessage id="rfid_tipo_dos_estados" /></option>
                    <option value={"UNESTADO"}> <FormattedMessage id="rfid_tipo_un_estado" /></option>
                  </select>
                </div>
              </div>

              <div className="card-title d-flex gap-5">
          
                <button
                  className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                  style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  onClick={handleExport}
                >
                  <img src={IconImportar} width={20} alt="..." />
                  <span><FormattedMessage id="rfid_export" /></span>
                </button>
           

                <Link to="/rfid/uploadfile">
                  <button
                    className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                    style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  >
                    <img src={IconExportar} width={20} alt="..." />
                    <span><FormattedMessage id="rfid_import" /></span>
                  </button>
                </Link>

                {ModalAddGlinseal("rfid")}
              </div>
            </div>

        {listaRfidsF.length > 0 && (
              <App
                nombretabla="rfid"
                rows={listaRfidsF}
                columns={listaColumnas}
                eliminarU={eliminarU}
              />
            )} 
          </div>
        </div>
      </div>
    </>
  );
};

export default Rfids;