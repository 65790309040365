const initialState = {
    listaEmpTrans: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    empresatrans: null,
    listaEmpTransSelect:[],
    listaEmpresaExists:[],
    dataError: {}
  };
  
  export const empreTransReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_EMPRESAS":
        return {
          ...state,
          listaEmpTrans: action.payload,
          listaEmpTransSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          empresatrans: null,
          dataError:{}
        };
      case "AGREGAR_EMPRESA":
        return {
          ...state,
          addOk: action.payload,
          dataError: action.payload2
        };
        case "AGREGAR_EMPRESA_LISTA":
          return {
            ...state,
            addOk: action.payload,
            listaEmpresaExists: action.payload2,
  
          };

        
      case "OBTENER_EMPRESA":
        return {
          ...state,
          empresatrans: action.payload,
        };
        case "OBTENER_EMPRESAS_SELECT":
          return {
            ...state,
            listaEmpTransSelect: action.payload,
          };
      case "EDITAR_EMPRESA":
        return {
          ...state,
          editOk: action.payload,
        };
  
      default:
        return state;
    }
  };
  