import React, { useEffect, useState } from "react";
import Tabla from "../../components/TablaRfids";
import App from "../../components/TablaRfids/App";

import { useSelector, useDispatch } from "react-redux";
import { obtenerRfids2 } from "../../redux/actions/rfid";
const initDataForm = {
  status: "VIAJE",

};
const listaColumnasx = [
  { accessor: 'numero_precinto', label: 'Marchamo N' },
  { accessor: 'condicion', label: 'Condicion' },
  { accessor: 'estado', label: 'Estado' },
 // { accessor: 'nombre_piloto', label: 'Piloto' },
 // { accessor: 'placa_vehiculo', label: 'Vehiculo' },
 { accessor: 'fecha_creacion', label: 'Fecha Registro' },
  { accessor: 'hora_creacion', label: 'Hora Registro' },

]

const listaColumnas = [
  "Marchamo N",
  "Condicion",
  "Estado",
  "Fecha Registro",
  "N Declaracion",
  "Contenedor"
];
const Rfids = () => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [mostrarPopUp, setMostrarPopUp] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaRfids} = state.rfid;
 
  //const { editOk } = state.posicionRfid;
  //console.log("EL VALOR DEL EDIT OK", editOk);

 
  useEffect(() => {
  //  dispatch(obtenerRfidsViajeEstadoFiltrados(dataForm.estado));
  //dispatch(obtenerU)
  dispatch(obtenerRfids2(initDataForm.status))
  }, []);


  
 
  return (
    <>
    
     
      <h1>Lista Precintos en Camino</h1>
      {listaRfids.length >0 &&   <App nombretabla="rfidempa" rows={listaRfids} columns={listaColumnasx} />}
 

    </>
  );
};

export default Rfids;
