import React from "react";

import { editarEmpresaTrans } from "../../redux/actions/empresatrans";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTableEmpresaTrans = (props) => {
  const dispatch = useDispatch();
  
  const { id,nombre, nit, nombre_contacto, telefono, email } = props.empresa;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{nombre} </td>
     
      <td>{nit} </td>
  
      <td>{telefono} </td>
      <td>{email} </td>
      <td className="d-flex justify-content-around ">
      


      <Link className="btn btn-success mr-1" style={{marginTop:"0px"}} to={`/empresa/detalle/${id}`}>
          Detalle
        </Link>
        <Link className="btn btn-warning " style={{marginTop:"0px"}} to={`/empresa/editar/${id}`}>
          Editar
        </Link>
      </td>
    </tr>
  );
};

export default RowTableEmpresaTrans;
