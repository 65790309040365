const initialState = {
    listaPosicionRfids: [],
    listaPosicionDetalleRfids: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    posicion: null,
    listaPosicionRfidsSelect:[],
    rfid:null,
    terminado:false,
    abrirPopUp:false,
    listaPosicionRfidsDetalle:[]
  };
  
  export const rfidsViajesReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_POSICION_RFIDS":
        return {
          ...state,
          listaPosicionRfids: action.payload,
          listaPosicionRfidsSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          posicion: null,
        };
        case "OBTENER_POSICION_RFIDS_DETALLE":
          return {
            ...state,
            listaPosicionRfidsDetalle: action.payload,
            
            addOk: false,
            deleteOk: false,
            editOk: false,
            posicion: null,
          };
        case "OBTENER_POSICION_RFIDS_ESTA":
        return {
          ...state,
          listaPosicionDetalleRfids: action.payload,
          addOk: false,
          deleteOk: false,
          editOk: false,
          posicion: null,
         
        };
        case "LANZAR_POPUP":
          return {
            ...state,
            rfid: action.payload,
            abrirPopUp: action.payload2,
            listaPosicionRfids:action.payload3
          };
      case "AGREGAR_RFID":
        return {
          ...state,
          addOk: action.payload,
        };
        case "AGREGAR_RFID_VIAJE":
          return {
            ...state,
            addOk: action.payload,
          };
      case "ELIMINAR_RFID":
        return {
          ...state,
          deleteOk: action.payload,
        };
      case "OBTENER_VIAJE":
        return {
          ...state,
          posicion: action.payload,
        };
      case "EDITAR_VIAJE":
        return {
          ...state,
          editOk: action.payload,
        };
        case "TERMINAR_VIAJE":
          return {
            ...state,
            terminado: action.payload,
          
          };
          case "LIMPIAR_VARS":
            return {
              ...state,
              listaPosicionRfids: [],
              listaPosicionRfidsSelect:[],
              addOk: false,
              deleteOk: false,
              editOk: false,
              posicion: null,
              abrirPopUp:false
            };
      default:
        return state;
    }
  };
  