import React, { useState } from "react";

import { BrowserRouter as Router, Switch } from "react-router-dom";

import Rfids from "../views/Rfids";
import AgregarRfid from "../views/Rfids/Agregar";
import UploadFileRfid from "../views/Rfids/SubirFile";
import DetalleRfid from "../components/Rfid/CardRfid";

import Viajes from "../views/Viajes";
import RegistrarViajeRfid from "../views/Viajes/Registrar";

import RegistrarLecturaRfid from "../views/Viajes/RegistrarLectura";
import DetalleHisPosicionRfid from "../views/Viajes/DetalleHistorial";
import ViajesTerminados from "../views/Viajes/ViajesTerminados";

import Pilotos from "../views/Pilotos";
import AgregarPiloto from "../views/Pilotos/Agregar";
import UploadFilePiloto from "../views/Pilotos/SubirFile";
import EditarPiloto from "../views/Pilotos/Editar";
import DetallePiloto from "../components/Piloto/CardPiloto";

import Camiones from "../views/Camiones";
import AgregarCamion from "../views/Camiones/Agregar";
import UploadFileCamion from "../views/Camiones/SubirFile";
import EditarCamion from "../views/Camiones/Editar";
import DetalleCamion from "../components/Camion/CardCamion";

import Furgones from "../views/Furgones";
import AgregarFurgon from "../views/Furgones/Agregar";
import UploadFileFurgon from "../views/Furgones/SubirFile";
import EditarFurgon from "../views/Furgones/Editar";
import DetalleFurgon from "../components/Furgon/CardFurgon";

import Empresas from "../views/EmpTrans";
import AgregarEmpresas from "../views/EmpTrans/Agregar";
import UploadFileEmpresaTrans from "../views/EmpTrans/SubirFile";
import EditarEmpresa from "../views/EmpTrans/Editar";
import DetalleEmpresa from "../components/EmpresTrans/CardEmpresa";

import Tags from "../views/TagCamion";
import AgregarTag from "../views/TagCamion/Agregar";
import EditarTag from "../views/TagCamion/Editar";
import DetalleTag from "../components/TagVeh/Card";

import Contenedores from "../views/Contenedores";
import AgregarContenedor from "../views/Contenedores/Agregar";
import EditarContenedor from "../views/Contenedores/Editar";
import DetalleContenedor from "../components/Contenedor";
import UploadFileConte from "../views/Contenedores/SubirFile";

import Usuarios from "../views/Usuarios";
import DetalleUsuario from "../components/Usuario/CardUsuario";
import UsuarioNuevo from "../views/Usuarios/Agregar";
import EditarUsuario from "../views/Usuarios/Editar";
import UploadFileUsuario from "../views/Usuarios/SubirFile";

import Emparejamientos from "../views/Emparejamiento";
import AgregarEmparejamiento from "../views/Emparejamiento/Agregar";
import DetalleEmparejamiento from "../views/Emparejamiento/DetalleHistorial";
import DetallePosicionRfid from "../components/Emparejamientos/ListaPosicionRfids";
import ListaMarchamo from "../views/Emparejamiento/ListaMarchamo";
import DetallePrecinto from "../views/Emparejamiento/DetalleHistorialPrecinto";

import Vehiculos from "../views/Vehiculo";
import AgregarVehiculo from "../views/Vehiculo/Agregar";
import EditarVehiculo from "../views/Vehiculo/Editar";
import UploadFileVehiculo from "../views/Vehiculo/SubirFile";
import DetalleVehiculo from "../components/Vehiculo/Card";

import ClienteEmpresa from "../views/ClienteEmpresa";
import AgregarClienteEmpresa from "../views/ClienteEmpresa/Agregar";
import EditarClienteEmpre from "../views/ClienteEmpresa/Editar";
import DetalleClienteEmpre from "../components/ClienteEmpresa/Card";

import Configuraciones from "../views/Configuraciones/index";

import Login from "../views/Login";

import RutaPrivada from "./RutaPrivada";
import RutaPublica from "./RutaPublica";
import UserProfile from "../components/Usuario/UserProfile";

import Dashboard from "../views/Dashboard";

function App() {
  const iniObject = {};
  const [estasAutenticado, setEstasAutenticado] = useState(false);
  const [revisando, setRevisando] = useState(false);
  const [datosUser, setDatosUser] = useState(iniObject);
  var token = localStorage.getItem("token");

  if (revisando) return <h1>Cargando ....</h1>;

  return (
    <Router>
      <Switch>
        <RutaPublica
          estasAutenticado={estasAutenticado}
          exact
          path="/"
          component={Login}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/dashboard"
          component={Dashboard}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/config"
          component={Configuraciones}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/clienteempre/nuevo"
          component={AgregarClienteEmpresa}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/clienteempre"
          component={ClienteEmpresa}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/clienteempre/editar/:id"
          component={EditarClienteEmpre}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/clienteempre/detalle/:id"
          component={DetalleClienteEmpre}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/usuario/nuevo"
          component={UsuarioNuevo}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/usuario/editar/:id"
          component={EditarUsuario}
        />

        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/usuarios"
          component={Usuarios}
        />

        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/usuario/detalle/:id"
          component={DetalleUsuario}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/usuario/uploadfile"
          component={UploadFileUsuario}
        />

        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/usuario/profile"
          component={UserProfile}
        />

        <RutaPrivada
          token={token}
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/rfids"
          component={Rfids}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/rfid/nuevo"
          component={AgregarRfid}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/rfid/uploadfile"
          component={UploadFileRfid}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/rfid/detalle/:id"
          component={DetalleRfid}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/viajes"
          component={Viajes}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/viaje/nuevo"
          component={RegistrarViajeRfid}
        />

        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/viaje/registrarposicion"
          component={RegistrarLecturaRfid}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/viaje/detalle/:id"
          component={DetalleHisPosicionRfid}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/viajes/terminado"
          component={ViajesTerminados}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/pilotos"
          component={Pilotos}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/piloto/nuevo"
          component={AgregarPiloto}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/piloto/uploadfile"
          component={UploadFilePiloto}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/piloto/editar/:id"
          component={EditarPiloto}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/piloto/detalle/:id"
          component={DetallePiloto}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/camiones"
          component={Camiones}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/camion/nuevo"
          component={AgregarCamion}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/camion/editar/:id"
          component={EditarCamion}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/camion/uploadfile"
          component={UploadFileCamion}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/camion/detalle/:id"
          component={DetalleCamion}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/furgones"
          component={Furgones}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/furgon/nuevo"
          component={AgregarFurgon}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/furgon/editar/:id"
          component={EditarFurgon}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/furgon/uploadfile"
          component={UploadFileFurgon}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/furgon/detalle/:id"
          component={DetalleFurgon}
        />

        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/contenedores"
          component={Contenedores}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/contenedor/nuevo"
          component={AgregarContenedor}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/contenedor/detalle/:id"
          component={DetalleContenedor}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/contenedor/editar/:id"
          component={EditarContenedor}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/contenedor/uploadfile"
          component={UploadFileConte}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/posicion/detalle/:id"
          component={DetalleHisPosicionRfid}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/empresatrans"
          component={Empresas}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/empresatrans/nuevo"
          component={AgregarEmpresas}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/empresatrans/uploadfile"
          component={UploadFileEmpresaTrans}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/empresatrans/detalle/:id"
          component={DetalleEmpresa}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/empresatrans/editar/:id"
          component={EditarEmpresa}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/tagscamion"
          component={Tags}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/tag/nuevo"
          component={AgregarTag}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/tag/editar/:id"
          component={EditarTag}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/tag/detalle/:id"
          component={DetalleTag}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/emparejamientos"
          component={Emparejamientos}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/emparejamiento/nuevo"
          component={AgregarEmparejamiento}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/emparejamiento/detalle/:id"
          component={DetalleEmparejamiento}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/emparejamiento/detallePrecinto/:id/:id2"
          component={DetallePrecinto}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/emparejamiento/posicion/:id/:id2"
          component={DetallePosicionRfid}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/emparejamientos/listaPrecinto"
          component={ListaMarchamo}
        />
        <RutaPrivada
          datos={datosUser}
          estasAutenticado={estasAutenticado}
          exact
          path="/vehiculos"
          component={Vehiculos}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/vehiculo/nuevo"
          component={AgregarVehiculo}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/vehiculo/editar/:id"
          component={EditarVehiculo}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/vehiculo/detalle/:id"
          component={DetalleVehiculo}
        />
        <RutaPrivada
          estasAutenticado={estasAutenticado}
          exact
          path="/vehiculo/uploadfile"
          component={UploadFileVehiculo}
        />
      </Switch>
    </Router>
  );
}

export default App;
