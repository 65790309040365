import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoPiloto";

import { agregarPiloto } from "../../redux/actions/piloto";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

const AgregarPiloto = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk, mensajeError } = state.piloto;
  const history = useHistory();

  useEffect(() => {
    if (addOk) {
      history.push("/pilotos");
    }
  }, [addOk]);

  const nuevoPiloto = (piloto) => {
    dispatch(agregarPiloto(piloto));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Agregar Piloto</h1>
      <Formulario
        agregarNuevoPiloto={nuevoPiloto}
        mensajeError={mensajeError}
      />
    </div>
  );
};

export default AgregarPiloto;
