import { useDispatch, useSelector } from "react-redux";
import { DashboardWrapper } from "../../components/Dashboard/Dashboard";
import { useEffect } from "react";
import { obtenerDashboard } from "../../redux/actions/dashboard";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [miInfo, setMyInfo] = useState({});
  const { informacion } = state.dashboard;

  useEffect(() => {
    dispatch(obtenerDashboard());
  }, []);
  useEffect(() => {
    setMyInfo(informacion);
  }, [informacion]);
  return (
   
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              <h1>
       
                <FormattedMessage id="tablero" />
              </h1>
             
            </div>
            <DashboardWrapper informacion={miInfo} />
         
          </div>
        </div>
      </div>
 
  );
};
export default DashboardPage;
