import React, { useEffect, useContext } from "react";
import Formulario from "../../components/Formulario/NuevoTagCamion";

import { editarTag, obtenerTag } from "../../redux/actions/tagcamion";
import { useDispatch, useSelector } from "react-redux";


const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { tagcamion, editOk } = state.tagcamion;

  useEffect(() => {
    // console.log(props.match.params.id);
   
    dispatch(obtenerTag(props?.match?.params?.id));
  }, []);

  useEffect(() => {
   
   
    if (editOk) {
      props.history.push("/tagscamion");
    }
  }, [editOk]);

  const edicion = (tag) => {
    // editar producto;
   dispatch(editarTag(tag));
  };

  if (!tagcamion) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar TAG</h1>
      <Formulario tagcamion={tagcamion} editar={true} editarTag={edicion} numero_tag={tagcamion.numero_tag} />
    </div>
  );
};

export default Editar;
