import React, { useState } from "react";
import PopupEmpa from "../../components/Emparejamientos";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ModalEdit from "../../views/Pilotos/ModalEdit";
import ModalEditarVehiculo from "../../views/Vehiculo/ModalEditarVehiculo";
import ModalEditarContenedor from "../../views/Contenedores/ModalEditarContenedor";
import ModalEditEmpresas from "../../views/EmpTrans/ModalEditEmpresas";
import ModalEditUsuarios from "../../views/Usuarios/ModalEditUsuarios";
import ModalEditTagCamión from "../../views/TagCamion/ModalEditTagCamión";
import { FormattedMessage } from "react-intl";
import ModalUsuarios from "../../views/Usuarios/ModalUsuarios";
import ModalAddPilotos from "../../views/Pilotos/ModalAddPilotos";
import ModalTagCamion from "../../views/TagCamion/ModalTagCamion";
import ModalEmpresaTrans from "../EmpresTrans/ModalEmpresaTrans";
import ModalContenedor from "../Contenedor/ModalContenedor";
import ModalAddVehiculos from "../../views/Vehiculo/ModalAddVehiculos";
//import Modalito from '../Modal'

const RowTable = (props) => {
  const [mostrarPopUp, setMostrarPopUp] = useState(false);
  const [perfil, setPerfil] = useState(localStorage.getItem("perfil"));

  const row = props.row;
  const columns = props.columns;
  const nombretabla = props.nombretabla;
  const option = props.option;
  const eliminarU = props.eliminarU;
  const cerrarEmparejamiento = props.cerrarEmparejamiento;

  //const { indice } = props;
  const renderSwitch = (param) => {
    switch (param) {
      case "empresatrans":
        return (
          <div className="d-flex justify-content-evenly gap-3">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/empresatrans/detalle/${row._id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <ModalEmpresaTrans editar={true} empresa={row} />

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger"
                onClick={() => eliminarU(row._id)}
                // to={`/usuario/eliminar/${row._id}`}
              >
                <FormattedMessage id="table_eliminar" />
              </Button>
            )}
          </div>
        );

      case "clienteempresa":
        return (
          <>
            <Link
              className="btn btn-success mr-2 mb-3"
              style={{ marginTop: "0px" }}
              to={`/clienteempre/detalle/${row._id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>

            <Link
              className="btn btn-warning mb-3"
              style={{ marginTop: "0px" }}
              to={`/clienteempre/editar/${row._id}`}
            >
              <FormattedMessage id="table_editar" />
            </Link>

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger mb-3 ml-2"
                style={{ marginTop: "0px" }}
                onClick={() => eliminarU(row._id)}
              >
                Eliminar
              </Button>
            )}
          </>
        );

      case "tagcamion":
        return (
          <div className="d-flex gap-3">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/tag/detalle/${row._id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <ModalTagCamion tagcamion={row} editar={true} />

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger"
                onClick={() => eliminarU(row._id)}
                // to={`/usuario/eliminar/${row._id}`}
              >
                Eliminar
              </Button>
            )}
          </div>
        );

      case "usuarios":
        return (
          <div className="d-flex gap-3">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/usuario/detalle/${row._id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <ModalUsuarios usuario={row} editar={true} />

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger"
                onClick={() => eliminarU(row._id)}
              >
                Eliminar
              </Button>
            )}
          </div>
        );

      case "emparejamiento":
        return (
          <div className="d-flex gap-3">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/emparejamiento/detalle/${row.id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>

            {row.viaje === "VIAJE" && (
              <PopupEmpa
                id={row.id}
                mostrarPopUp={mostrarPopUp}
                cerrarEmparejamientoProp={cerrarEmparejamiento}
              />
            )}

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger"
                style={{ marginTop: "0px" }}
                onClick={() => eliminarU(row.id)}
              >
                Eliminar
              </Button>
            )}
          </div>
        );

      case "rfid":
        return (
          <div className="d-flex gap-3">
            <Link to={`/rfid/detalle/${row._id}`}>
              <button
                className="btn btn-sm text-white"
                style={{ backgroundColor: "#0A5189" }}
              >
                <FormattedMessage id="table_detalle" />
              </button>
            </Link>

            {perfil === "superadmin" && (
              <button
                onClick={() => eliminarU(row._id)}
                className="btn btn-sm btn-danger"
              >
                <FormattedMessage id="table_eliminar" />
              </button>
            )}
          </div>
        );

      case "rfidempa":
        return (
          <>
            <Link
              empaid={row.emparejamientoId}
              className="btn btn-warning"
              style={{ marginTop: "0px" }}
              to={`/emparejamiento/detallePrecinto/${row.numero_precinto}/${row.emparejamientoId}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
          </>
        );

      case "piloto":
        return (
          <div className="d-flex gap-3">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/piloto/detalle/${row.id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <ModalAddPilotos editar={true} datos={row} />

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger"
                onClick={() => eliminarU(row.id)}
              >
                Eliminar
              </Button>
            )}
          </div>
        );

      case "camion":
        return (
          <>
            <Link
              className="btn btn-success mr-2 mb-3"
              style={{ marginTop: "0px" }}
              to={`/camion/detalle/${row.id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <Link
              className="btn btn-warning mb-3"
              style={{ marginTop: "0px" }}
              to={`/camion/editar/${row.id}`}
            >
              <FormattedMessage id="table_editar" />
            </Link>
          </>
        );

      case "contenedor":
        return (
          <div className="d-flex gap-3">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/contenedor/detalle/${row.id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <ModalContenedor contenedor={row} editar={true} />

            {perfil === "superadmin" && (
              <Button
                className="btn btn-danger"
                onClick={() => eliminarU(row.id)}
              >
                Eliminar
              </Button>
            )}
          </div>
        );

      case "posicionrfid":
        return;

      case "viaje":
        return (
          <>
            <Link
              className="btn btn-success mr-1"
              style={{ marginTop: "0px" }}
              to={`/viaje/detalle/${row.id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
          </>
        );

      case "rfidupload":
        return;

      case "vehiculo":
        return (
          <div className="d-flex gap-3 border">
            <Link
              className="btn text-white"
              style={{ backgroundColor: "#0A5189" }}
              to={`/vehiculo/detalle/${row._id}`}
            >
              <FormattedMessage id="table_detalle" />
            </Link>
            <ModalAddVehiculos vehiculo={row} editar={true} />
          </div>
        );

      default:
        return;
    }
  };

  return (
    <tr key={row.id} className="align-middle">
      {columns.map((column, index) => {
        if (column.format) {
          return <td key={index}>{column.format(row[column.accessor])}</td>;
        }

        if (row[column.accessor] === "ABIERTO") {
          return (
            <td key={index} className="text-center">
              <span className="badge py-3 px-4 fs-7 badge-light-warning">
                <FormattedMessage id={row[column.accessor]} />
              </span>
            </td>
          );
        }

        if (row[column.accessor] === "CERRADO") {
          return (
            <td key={index} className="text-center">
              <span className="badge py-3 px-4 fs-7 badge-light-primary">
                <FormattedMessage id={row[column.accessor]} />
              </span>
            </td>
          );
        }

        if (row[column.accessor] === "OK") {
          return (
            <td key={index} className="text-center">
              <span className="badge py-3 px-4 fs-7 badge-light-success">
                {row[column.accessor]}
              </span>
            </td>
          );
        }

        if (row[column.accessor] === "MANIPULADO") {
          return (
            <td key={index} className="text-center">
              <span className="badge py-3 px-4 fs-7 badge-light-danger">
                <FormattedMessage id={row[column.accessor]} />
              </span>
            </td>
          );
        }
        if (row[column.accessor] === "VIAJE") {
          return (
            <td key={index} className="fw-bolder  text-center">
              <FormattedMessage id={row[column.accessor]} />
            </td>
          );
        }
        if (
          row[column.accessor] === "Administrador" ||
          row[column.accessor] === "UsuarioMovil" ||
          row[column.accessor] === "UsuarioWeb"
        ) {
          return (
            <td key={index} className="fw-bolder  text-center">
              <FormattedMessage id={row[column.accessor]} />
            </td>
          );
        }
        if (row[column.accessor] === "ASIGNADO") {
          return (
            <td key={index} className="fw-bolder  text-center">
              <FormattedMessage id={`tag_${row[column.accessor]}`} />
            </td>
          );
        }
        if (row[column.accessor] === "LIBRE") {
          return (
            <td key={index} className="fw-bolder  text-center">
              <FormattedMessage id={`${row[column.accessor]}`} />
            </td>
          );
        }
        return (
          <td key={index} className="fw-bolder text-center">
            {row[column.accessor]}
          </td>
        );
      })}
      <td>{renderSwitch(nombretabla)}</td>
    </tr>
  );
};

export default RowTable;
