import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { obtenerVehiculo } from "../../redux/actions/vehiculo";

const initDataForm = {
  empresa_trans: "",
  modelo: "",
  placa_vehiculo: "",
  chasis_cabezal: "",
  marca: "",
};
const Card = React.memo ((props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { vehiculo } = state.vehiculo;
  let id = props?.match?.params?.id;
  
  if (!id) {
    id = props.id;
  }

  const [dataForm, setDataForm] = useState(initDataForm);
  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(obtenerVehiculo(id));
  }, [id]);

  useEffect(() => {
    setDataForm({ ...vehiculo });
  }, [vehiculo]);

  return (
    <div className="card fs-3 py-5 d-flex flex-row justify-content-between align-items-center">
      <div className="card-title d-flex flex-row align-items-center gap-3 w-175px">
        <img
          width={50}
          src={require("../../images/Furgon.png")}
          alt="User Avatar"
        />

        <div className="card-title d-flex flex-column">
          <span className="fw-bolder">{dataForm.placa_vehiculo}</span>
          <span className="text-gray-400 fw-light fs-6">Furgon</span>
        </div>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="fw-bolder">{dataForm.empresa_trans || ""}</span>
        <span className="text-gray-400 fw-light fs-6">Empresa</span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="fw-bolder">{dataForm.marca || ""}</span>
        <span className="text-gray-400 fw-light fs-6">Marca</span>
      </div>

      <div className="card-title d-flex flex-column align-items-center">
        <span className="fw-bolder">{dataForm.modelo || ""}</span>
        <span className="text-gray-400 fw-light fs-6">Modelo</span>
      </div>

      {/* <div className="card-title d-flex flex-column align-items-center">
       
      </div>

      <div className="card-title d-flex flex-column align-items-center">
      
      </div> */}
    </div>
  );
});

export default Card;
