import React, { useEffect } from "react";
import Tabla from "../../components/TablaRfids";
import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import { obtenerFurgones } from "../../redux/actions/furgon";
const listaColumnasx = [
  { accessor: "empresa_trans", label: "Empresa Trans" },
  { accessor: "placa_furgon", label: "Placa del Furgon" },
  { accessor: "chasis_cabezal", label: "Chasis del Cabezal" },
  { accessor: "marca", label: "Marca" },
  { accessor: "modelo", label: "Modelo" },
];

const Furgones = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaFurgones } = state.furgon;

  useEffect(() => {
    dispatch(obtenerFurgones());
  }, []);

  return (
    <>
      <h1>Furgones</h1>
      {listaFurgones.length > 0 && (
        <App
          nombretabla="furgon"
          rows={listaFurgones}
          columns={listaColumnasx}
        />
      )}
    </>
  );
};

export default Furgones;
