import React, { useState } from "react";

import RowTableRfidUpload from "../RowTableUpload/Rfids";
import RowTableCamiondUpload from "../RowTableUpload/Camion";
import RowTableEmpresaUpload from "../RowTableUpload/EmpresaTrans";
import RowTablePilotoUpload from "../RowTableUpload/Piloto";
import RowTableFurgondUpload from "../RowTableUpload/Furgon";
import RowTableUsuariodUpload from "../RowTableUpload/Usuario";
import RowTableContenedordUpload from "../RowTableUpload/Contenedor";
import { FormattedMessage } from "react-intl";

const TablaSol = ({
  mostrarButton,
  listaDatos,
  listaColumnas,
  nombretabla,
  insertarLista,
}) => {
  const [listaFinal, setListaFinal] = useState([]);
  const subirdata = () => {
  console.log("la lista a subir", listaDatos)
    //setListaFinal({...listaDatos})
    insertarLista(listaDatos);
  };

  const renderSwitch = (param, item, index) => {
    switch (param) {
      case "contenedorupload":
        return (
          <RowTableContenedordUpload
            key={index}
            indice={index}
            contenedor={item}
          />
        );
      case "usuarioupload":
        return (
          <RowTableUsuariodUpload key={index} indice={index} usuario={item} />
        );
      case "rfidupload":
        return <RowTableRfidUpload key={index} indice={index} rfid={item} />;
      case "camionupload":
        return (
          <RowTableCamiondUpload key={index} indice={index} camion={item} />
        );
      case "empresaupload":
        return (
          <RowTableEmpresaUpload key={index} indice={index} empresa={item} />
        );
      case "pilotoupload":
        return (
          <RowTablePilotoUpload key={index} indice={index} piloto={item} />
        );
      case "furgonupload":
        return (
          <RowTableFurgondUpload key={index} indice={index} furgon={item} />
        );

      default:
        break;
    }
  };

  return (
    <div className="col">
      <div className="card">
        <div className="card-header">
          {mostrarButton ? (
            <button className="btn btn-primary my-3" onClick={subirdata}>
              <FormattedMessage id="subir_datos" />
              
            </button>
          ) : (
            <span className="badge badge-danger">
              Error con los siguientes Registros
            </span>
          )}
        </div>

        <div className="card-body">
          <table className="table table-striped">
            <thead className="table-dark text-center">
              <tr>
                <th scope="col">#</th>
                {listaColumnas.map((item, index) => (
                  <th scope="col" key={index} indice={index}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center">
              {listaDatos.map((item, index) =>
                renderSwitch(nombretabla, item, index)
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TablaSol;
