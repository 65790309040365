import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoTagCamion";

import { agregarTag } from "../../redux/actions/tagcamion";
import { useDispatch, useSelector } from "react-redux";

const AgregarTag = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { addOk } = state.tagcamion;

  useEffect(() => {
    if (addOk) {
      props.history.push("/tagscamion");
    }
  }, [addOk]);
  
  const nuevoTag = (tag) => {
    dispatch(agregarTag(tag));
    // agregar producto provider
  };
  return (
    <div>
      <Formulario agregarNuevoTag={nuevoTag} />
    </div>
  );
};

export default AgregarTag;
