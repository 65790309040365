import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";
export const agregarEmpresa = (empresa) => {
  return async (dispatch) => {
    try {
      const nombre = empresa.nombre;
      const direccion = empresa.direccion;
      const telefono = empresa.telefono;
      const email = empresa.email;
      const nombre_contacto = empresa.nombre_contacto;
      const nit = empresa.nit;

      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + "/empresatrans/create",
        { nombre, direccion, telefono, email, nombre_contacto, nit },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("reeee", rfidRec);
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "AGREGAR_EMPRESA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_EMPRESA",
        payload: false,
        payload2: rfidRec?.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerEmpresas = () => {
  return async (dispatch) => {
    try {
      const arrayLista = [];
      var token = localStorage.getItem("token");
      var lista = await axios.get(URL + "/empresatrans/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      console.log("lista", lista);

      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          //  console.log("dato fechas",item.datos_creacion.fecha_creacion)
          //  var fecha_creacion=moment( item.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
          //   var hora_creacion = moment(item.datos_creacion.fecha_creacion).format("HH:mm:ss");

          arrayLista.push({
            ...item,
            id: item._id,
            //  fecha_creacion, hora_creacion
          });
        });
      }

      dispatch({
        type: "OBTENER_EMPRESAS",
        payload: arrayLista,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerEmpresa = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var Objeto = await axios.get(`${URL}/empresatrans/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      let objeto = {};

      if (Objeto?.status === 200 && Objeto.data?.ok) {
        //  var fecha_creacion=moment( Objeto.data.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
        //  var hora_creacion = moment(Objeto.data.datos_creacion.fecha_creacion).format("HH:mm:ss");
        objeto = {
          ...Objeto.data.data,
          //fecha_creacion, hora_creacion
        };
      }
      dispatch({
        type: "OBTENER_EMPRESA",
        payload: objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerEmpresasFiltrados = () => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      const arrayLista = [
  //      { label: "Seleccione una Empresa", value: -1, selected: true },
      ];
      var lista = await axios.get(URL + "/empresatrans/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          //  console.log("dato fechas",item.datos_creacion.fecha_creacion)
          //  var fecha_creacion=moment( item.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
          // var hora_creacion = moment(item.datos_creacion.fecha_creacion).format("HH:mm:ss");

          //  arrayLista.push({...item,id:item._id, fecha_creacion, hora_creacion });
          arrayLista.push({
            key: item._id,
            label: item.nombre,
            value: item._id,
            selected: false,
          });
        });
      }

      dispatch({
        type: "OBTENER_EMPRESAS_SELECT",
        payload: arrayLista,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const eliminarEmpresaTrans = (id) => {
  return async (dispatch) => {
    try {
      //   await db.collection("empresatrans").doc(id).delete();
      dispatch({
        type: "ELIMINAR_EMPRESA",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerEmpresaPorNombre = (nombre) => {
  return async (dispatch) => {
    try {
      //  let colec1 = db.collection("empresatrans")
      //  .where("nombre", "==", nombre);
      // const doc = await colec1.get();
      //if(doc?.docs[0]?.id){

      let empresa = {
        //  id: doc?.docs[0]?.id
      };

      dispatch({
        type: "OBTENER_EMPRESA",
        payload: empresa,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const editarEmpresaTrans = (entidad) => {
  return async (dispatch) => {
    try {
      const nombre = entidad.nombre;
      const email = entidad.email;
      const direccion = entidad.direccion;
      const telefono = entidad.telefono;
      const nombre_contacto = entidad.nombre_contacto;
      const nit = entidad.nit;
      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + `/empresatrans/update/${entidad._id}`,
        { nombre, email, direccion, telefono, nombre_contacto, nit },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("resultado", rfidRec);
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "EDITAR_EMPRESA",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "EDITAR_EMPRESA",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (empresas) => {
  return async (dispatch) => {
    try {
      // await db.collection("rfids").add(rfid);
      dispatch({
        type: "AGREGAR_EMPRESA_LISTA",
        // payload: erroneos,
        //  payload2:empresaExisten
      });
    } catch (error) {
      console.log(error);
    }
  };
};
