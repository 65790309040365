/*
import moment from "moment";
export const obtenerCamionesParaTagFiltrados = (filtro) => {
  return async (dispatch) => {
    try {
      const camiones = [];
      const listaCamionesSelect = [
        { label: "Seleccione un Camion", value: -1, selected: true },
      ];
      let colec1 = db.collection("camiones").where("numero_tag", "==", "");
      if (filtro) {
        let colec2 = db
          .collection("camiones")
          .where("numero_tag", "==", filtro);
        const doc1 = await colec2.get();
        doc1.forEach((item) => {
          camiones.push({ id: item.id, ...item.data() });
          listaCamionesSelect.push({
            key: item.id,
            label: item.data().placa_cabezal,
            value: item.id,
            selected: false,
          });
        });
      }

      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();

      doc.forEach((item) => {
        camiones.push({ id: item.id, ...item.data() });
        listaCamionesSelect.push({
          key: item.id,
          label: item.data().placa_cabezal,
          value: item.id,
          selected: false,
        });
      });

      dispatch({
        type: "OBTENER_CAMIONES",
        payload: camiones,
        payload2: listaCamionesSelect,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerFurgonesFiltrados = () => {
  return async (dispatch) => {
    try {
      let colec1 = db.collection("furgones").where("viaje", "==", "Libre");
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();
      const doc = await colec1.get();

      const camiones = [];
      const listaCamionesSelect = [
        { label: "Seleccione un Furgon", value: -1, selected: true },
      ];

      doc.forEach((item) => {
        camiones.push({ id: item.id, ...item.data() });
        listaCamionesSelect.push({
          key: item.id,
          label: item.data().numero_tag,
          value: item.id,
          selected: false,
        });
      });

      dispatch({
        type: "OBTENER_CAMIONES",
        payload: camiones,
        payload2: listaCamionesSelect,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};

export const obtenerFurgon = (id) => {
  return async (dispatch) => {
    try {
      const doc = await db.collection("furgones").doc(id).get();
      let furgon = {
        id: id,
        ...doc.data(),
      };

      dispatch({
        type: "OBTENER_FURGON",
        payload: furgon,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerFurgones = () => {
  return async (dispatch) => {
    try {
      const camiones = [];

      const doc = await db.collection("furgones").get();

      doc.forEach((item) => camiones.push({ id: item.id, ...item.data() }));
      dispatch({
        type: "OBTENER_FURGONES",
        payload: camiones,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarFurgon = (camion) => {
  return async (dispatch) => {
    try {
      await db.collection("furgones").add(camion);
      
      dispatch({
        type: "AGREGAR_FURGON",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarFurgon = (camion) => {
  return async (dispatch) => {
    try {
     
      const camionUpdate = { ...camion };
      delete camionUpdate.id;

      await db.collection("furgones").doc(camion.id).update(camionUpdate);
      dispatch({
        type: "EDITAR_FURGON",
        payload: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (camion) => {
  return async (dispatch) => {
    try {
      const camionExisten = [];
      for (const domi of camion) {
        let colec1 = db
          .collection("furgones")
          .where("placa_furgon", "==", domi.placa_furgon);
        const doc = await colec1.get();
        if (doc?.docs[0]?.id) {
          camionExisten.push({ ...domi, idFurgon: doc?.docs[0]?.id });
        }
      }

      camion = camion.filter(
        (value) =>
          !camionExisten.find((y) => y.placa_furgon == value.placa_furgon)
      );

      let erroneos = false;
      var batch = db.batch();
      const fecha = moment().format("YYYY-MM-DD");
      const hora = moment().format("HH:mm");
      const usuario = {};
      usuario["uid"] = localStorage.getItem("useruid");
      usuario["email"] = localStorage.getItem("email");
      camion.forEach((doc) => {
        doc.fecha_creacion = fecha;
        doc.hora_creacion = hora;
        doc.usuario = usuario;
        erroneos = true;

        var docRef = db.collection("furgones").doc(); //automatically generate unique id
        batch.set(docRef, doc);
      });
      batch.commit();
      // await db.collection("rfids").add(rfid);
      dispatch({
        type: "AGREGAR_FURGON_LISTA",
        payload: erroneos,
        payload2: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const buscarEmprePorNombre = (camion) => {
  return async (dispatch) => {
    try {
      const camionExisten = [];
      let nombreMod = "";
      for (const domi of camion) {
        nombreMod = domi.empresa_trans;
        nombreMod = nombreMod.replace("/", "");

        let colec1 = db
          .collection("empresatrans")
          .where("nombre", "==", nombreMod);
        const doc = await colec1.get();
        //    if(doc?.docs[0]?.id){

        camionExisten.push({ ...domi, id_empresa_trans: doc?.docs[0]?.id });
        //   }
      }
     
      dispatch({
        type: "BUSCAR_EMPRESA_LISTA",

        payload: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};*/
