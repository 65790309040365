import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { obtenerEmpresa } from "../../redux/actions/empresatrans";
import Empresa from "../../images/sidebarIcons/empresasIcon.svg";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  nombre: "",
  nit: "",
  nombre_contacto: "",
  telefono: "",
  email: "",
  direccion: "",
};
const CardEmpresa = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { empresatrans } = state.empresatrans;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }
  const [dataFormConte, setDataFormConte] = useState(initDataForm);
  const handleChange = (e) => {
    setDataFormConte({ ...dataFormConte, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    dispatch(obtenerEmpresa(id));
  }, [id]);
  useEffect(() => {
    setDataFormConte({ ...empresatrans });
  }, [empresatrans]);
  if (!empresatrans) return <h1>Cargando ...</h1>;
  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/empresatrans`}>
       <FormattedMessage id="ir_lista" />
      </Link>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
            <div className="card-title d-flex flex-column">
              <span className="fw-bolder" style={{ color: "#0A5189" }}>
              <FormattedMessage id="empresatrans_details" />
              </span>
            </div>

            <div className="card-title d-flex flex-row align-items-center gap-3">
              <img
                width={50}
                src={Empresa}
                alt="User Avatar"
                style={{ filter: "invert(100%)" }}
              />

              <div className="card-title d-flex flex-column">
                <span className="fw-bolder">{dataFormConte?.nombre}</span>
                <span className="text-gray-400 fw-light fs-6">
                <FormattedMessage id="empresatrans_name" />
                </span>
              </div>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">
                {dataFormConte?.nombre_contacto}
              </span>
              <span className="text-gray-400 fw-light fs-6">
              <FormattedMessage id="empresatrans_contact" />
              </span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataFormConte?.telefono || ""}</span>
              <span className="text-gray-400 fw-light fs-6">  <FormattedMessage id="empresatrans_phone" /></span>
            </div>

            <div className="card-title d-flex flex-column">
              <span className="fw-bolder">{dataFormConte?.email}</span>
              <span className="text-gray-400 fw-light fs-6 text-center">
              <FormattedMessage id="empresatrans_email" />
              </span>
            </div>

            <div className="card-title d-flex flex-column">
              <span className="fw-bolder">{dataFormConte?.direccion}</span>
              <span className="text-gray-400 fw-light fs-6 text-center">
              <FormattedMessage id="empresatrans_address" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardEmpresa;
