import React from "react";

import { terminarViajeOK } from "../../redux/actions/viajes";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//import Modalito from '../Modal'

const RowTable = (props) => {
  const dispatch = useDispatch();

  const {
    id,
    numero_precinto,
    condicion,
    estado,
    fecha_creacion,
    hora_creacion,
    viaje,
  } = props.rfid;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      <td>{numero_precinto}</td>
      <td>
        {condicion === "ABIERTO" ? (
          <p className="badge py-3 px-4 fs-7 badge-light-warning">
            {condicion}
          </p>
        ) : (
          <p className="badge py-3 px-4 fs-7 badge-light-primary">
            {condicion}
          </p>
        )}
      </td>
      <td>
        {estado === "OK" ? (
          <p className="badge py-3 px-4 fs-7 badge-light-success">{estado}</p>
        ) : (
          <p className="badge py-3 px-4 fs-7 badge-light-danger">{estado}</p>
        )}
      </td>

      <td>{fecha_creacion}</td>
      <td>{hora_creacion}</td>
      <td className="d-flex justify-content-around">
        {condicion === "POR APROBAR" && (
          <button className="btn btn-warning mt-1">Aprobar</button>
        )}
        {viaje !== "Terminado" ? (
          <Link
            className="btn btn-warning"
            style={{ marginTop: "0px" }}
            to={`/rfid/detalle/${id}`}
          >
            Detalle
          </Link>
        ) : (
          <Link
            className="btn btn-warning"
            style={{ marginTop: "0px" }}
            to={`/posicion/detalle/${id}`}
          >
            Detalle
          </Link>
        )}
      </td>
    </tr>
  );
};

export default RowTable;
