import React, { useState, useEffect } from "react";
/* import VirtualizedSelect from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css"; */
import { obtenerPilotosFiltrados } from "../../redux/actions/piloto";
import { useHistory } from "react-router-dom";

import {
  obtenerTipoVehiculo,
  obtenerVehiculosPorTipo,
} from "../../redux/actions/vehiculo";
import { obtenerContenedoresFiltrados } from "../../redux/actions/contenedor";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { agregarEmparejamiento } from "../../redux/actions/emparejamiento";
import { Stack, Button, Modal, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  origen: "",
  destino: "",
  piloto: "",
  numero_precintos: "1",
  tipo_vehiculo: "",
  placa_vehiculo: "",
};

const initVehDataForm = {
  placa_furgon: "",
  placa_camioneta: "",
  placa_trailer: "",
  placa_aco_chasis: "",
  placa_aco_furgon: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalEmparejamientos = ({
  contenedor,
  editar = false,
  editarContenedor,
}) => {
  const ArrConte = [];
  const [nombreUsuario, setUid] = useState(localStorage.getItem("displayname"));
  const [vehDataForm, seVehtDataForm] = useState(initVehDataForm);
  const [tipo_vehiculo_nombre, setTipoVehNom] = useState("");
  const [placa_vehiculo, setPlaca] = useState("");
  const [numero_precintos_total, setNumeroTotalPrecintos] = useState("1");
  const [vehiculo_datos, setVehiculoDatos] = useState({});
  const [piloto_datos, setPilotoDatos] = useState({});
  const [contenedor_datos, setContenedor] = useState([]);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const history = useHistory();
  const { addOk } = state.emparejamiento;

  const {
    camioneta,
    furgon,
    trailer,
    semichasis,
    semifurgon,
    chasis,
    acochasis,
    acofurgon,
  } = state.vehiculo;
  //const { listaCamionesSelect } = state.camion;

  const { listaPilotosSelect } = state.piloto;
  const { listaContenedoresSelect } = state.contenedor;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };

  useEffect(() => {
    dispatch(obtenerTipoVehiculo());
    dispatch(obtenerPilotosFiltrados("LIBRE"));
    dispatch(obtenerVehiculosPorTipo());
    dispatch(obtenerContenedoresFiltrados());
  }, []);

  useEffect(() => {
    if (addOk) {
      history.push("/emparejamientos");
    }
  }, [addOk]);

  const nuevo = (emp) => {
    dispatch(agregarEmparejamiento(emp));
  };
  const renderSwitchV = (param) => {
    switch (param) {
      case "Seleccione un Tipo":
        return <div></div>;

      default:
        return <div></div>;

      case "Chasis":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                  Chasis Cabezal:
                </label>

                <select
                  className="form-select"
                  name="placa_vehiculo"
                  value={dataForm.placa_vehiculo}
                  onChange={handleChange}
                  //  onChange={(selectValue) => handleChange4(selectValue)}
                >
                  {chasis?.map((item) => (
                    <option value={item.id}>{item.placa_vehiculo}</option>
                  ))}
                </select>

                {errors.placa_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_vehiculo}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="contenedor">
                  Contenedor:
                </label>

                <select
                  className="form-select"
                  name="contenedor"
                  value={dataForm.contenedor}
                  onChange={handleChange}
                  //  onChange={(selectValue) => handleChange3(selectValue)}
                >
                  {listaContenedoresSelect?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

                {errors.contenedor && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.contenedor}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Furgon":
        return (
          <div className="col">
            <div className="form-label">
              <label className="my-5" htmlFor="placa_vehiculo">
                Placa Furgon:
              </label>

              <select
                className="form-select"
                name="placa_vehiculo"
                value={dataForm.placa_vehiculo}
                //  onChange={(selectValue) => handleChange5(selectValue)}
                onChange={handleChange}
              >
                {furgon?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.placa_vehiculo}
                  </option>
                ))}
              </select>

              {errors.placa_vehiculo && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_vehiculo}
                </span>
              )}
            </div>
          </div>
        );

      case "Semi Acoplado Chasis":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_trailer">
                  Placa Trailer
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_trailer"
                  value={vehDataForm.placa_trailer}
                  onChange={handleChange}
                >
                  {trailer?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>

                {errors.placa_trailer && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_trailer}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_semi_chasis">
                  Placa Semi
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_semi_chasis"
                  value={vehDataForm.placa_semi_chasis}
                  onChange={handleChange}
                >
                  {semichasis?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>

                {errors.placa_semi_chasis && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_semi_chasis}
                  </span>
                )}
              </div>
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="contenedor">
                  Contenedor
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="contenedor"
                  value={vehDataForm.contenedor}
                  onChange={handleChange}
                >
                  {listaContenedoresSelect?.map((item, index) => (
                    <option
                      key={index}
                      data-precintos={item.numero_precintos}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>

                {errors.contenedor && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.contenedor}
                  </span>
                )}
              </div>
            </div>
          </>
        );

      case "Semi Acoplado Furgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_trailer">
                  Placa Trailer
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_trailer"
                  value={vehDataForm.placa_trailer}
                  onChange={handleChange}
                >
                  {trailer?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>

                {errors.placa_trailer && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.placa_trailer}
                  </span>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_semi_furgon">
                  Semi Furgon
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_semi_furgon"
                  value={vehDataForm.placa_semi_furgon}
                  onChange={handleChange}
                >
                  {semifurgon?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_semi_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi_furgon}
                </span>
              )}
            </div>
          </>
        );

      case "Camioneta":
        return (
          <>
            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_vehiculo">
                  Placa Camioneta
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_vehiculo"
                  value={vehDataForm.placa_vehiculo}
                  onChange={handleChange}
                >
                  {camioneta?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_vehiculo && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_vehiculo}
                </span>
              )}
            </div>
          </>
        );

      case "Chasis AcoChasis":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_chasis">
                  Chasis Cabezal
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_chasis"
                  value={vehDataForm.placa_chasis}
                  onChange={handleChange}
                >
                  {chasis?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_chasis}
                </span>
              )}
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="contenedor">
                  Contenedor
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="contenedor"
                  value={vehDataForm.contenedor}
                  onChange={handleChange}
                >
                  {listaContenedoresSelect?.map((item, index) => (
                    <option
                      data-precintos={item.numero_precintos}
                      key={index}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>

              {errors.contenedor && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor}
                </span>
              )}
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_chasis">
                  Acoplado Chasis
                </label>

                <select
                  className="form-select"
                  name="placa_aco_chasis"
                  value={vehDataForm.placa_aco_chasis}
                  onChange={handleChange}
                >
                  {acochasis?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_aco_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_chasis}
                </span>
              )}
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="contenedor2">
                  Contenedor
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="contenedor2"
                  value={vehDataForm.contenedor2}
                  onChange={handleChange}
                >
                  {listaContenedoresSelect?.map((item, index) => (
                    <option
                      data-precintos={item.numero_precintos}
                      key={index}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>

              {errors.contenedor2 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor2}
                </span>
              )}
            </div>
          </>
        );

      case "Furgon AcoChasis":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_furgon">
                  Placa Furgon
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_furgon"
                  value={vehDataForm.placa_furgon}
                  onChange={handleChange}
                >
                  {furgon?.map((item, index) => (
                    <option
                      data-precintos={item.numero_precintos}
                      key={index}
                      value={item.id}
                    >
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_chasis">
                  Acoplado Chasis
                </label>

                <select
                  className="form-select"
                  name="placa_aco_chasis"
                  value={vehDataForm.placa_aco_chasis}
                  onChange={handleChange}
                >
                  {acochasis?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_aco_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_chasis}
                </span>
              )}
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="contenedor2">
                  Contenedor
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="contenedor2"
                  value={vehDataForm.contenedor2}
                  onChange={handleChange}
                >
                  {listaContenedoresSelect?.map((item, index) => (
                    <option
                      data-precintos={item.numero_precintos}
                      key={index}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>

              {errors.contenedor2 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor2}
                </span>
              )}
            </div>
          </>
        );

      case "Chasis AcoFurgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_chasis">
                  Chasis Cabezal
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="placa_chasis"
                  value={vehDataForm.placa_chasis}
                  onChange={handleChange}
                >
                  {chasis?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_chasis}
                </span>
              )}
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="contenedor">
                  Contenedor:
                </label>

                <select
                  data-live-search="true"
                  className="form-select"
                  name="contenedor"
                  value={vehDataForm.contenedor}
                  onChange={handleChange}
                >
                  {listaContenedoresSelect?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

                {errors.contenedor && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.contenedor}
                  </span>
                )}
              </div>
            </div>

            <div className="col">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_furgon">
                  Acoplado Furgon:
                </label>

                <select
                  className="form-select"
                  name="placa_aco_furgon"
                  value={vehDataForm.placa_aco_furgon}
                  onChange={handleChange}
                >
                  {acofurgon?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_aco_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_furgon}
                </span>
              )}
            </div>
          </>
        );

      case "Furgon AcoFurgon":
        return (
          <>
            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_furgon">
                  Placa Furgon
                </label>

                <select
                  data-live-search="true"
                  className="form-control"
                  name="placa_furgon"
                  value={vehDataForm.placa_furgon}
                  onChange={handleChange}
                >
                  {furgon?.map((item, index) => (
                    <option
                      data-precintos={item.numero_precintos}
                      key={index}
                      value={item.id}
                    >
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>

            <div className="col-6">
              <div className="form-label">
                <label className="my-5" htmlFor="placa_aco_furgon">
                  Acoplado Furgon
                </label>

                <select
                  className="form-control"
                  name="placa_aco_furgon"
                  value={vehDataForm.placa_aco_furgon}
                  onChange={handleChange}
                >
                  {acofurgon?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.placa_vehiculo}
                    </option>
                  ))}
                </select>
              </div>

              {errors.placa_aco_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_furgon}
                </span>
              )}
            </div>
          </>
        );
    }
  };
  const handleChange2 = (selectValue) => {
    setDataForm({ ...dataForm, piloto: selectValue.target.value });
    let index = selectValue.target.selectedIndex;
    const piloto_datos = {};
    piloto_datos["idPiloto"] = selectValue.target.value;
    piloto_datos["nombre_completo"] = selectValue.target[index].label;
    setPilotoDatos(piloto_datos);
  };

  const handleChange3 = (selectValue) => {
    seVehtDataForm({ ...vehDataForm, contenedor: selectValue.value });
    let index = selectValue.target.selectedIndex;
    const contenedor = {};
    contenedor["id"] = selectValue.target.value;
    contenedor["numero"] = selectValue.target[index].label;
    setContenedor((contenedor_datos) => [...contenedor_datos, contenedor]);
  };

  const handleChange4 = (selectValue) => {
    seVehtDataForm({ ...vehDataForm, chasis_cabezal: selectValue.value });
    const index = selectValue.target.selectedIndex;
    const vehiculo_datos1 = {};
    vehiculo_datos1["id"] = selectValue.target.value;
    vehiculo_datos1["chasis_cabezal"] = selectValue.target[index].label;

    setVehiculoDatos({ ...vehiculo_datos, Chasis: vehiculo_datos1 });
    setPlaca(selectValue.target[index].label);
  };

  const handleChange5 = (selectValue) => {
    seVehtDataForm({ ...vehDataForm, placa_furgon: selectValue.value });
    const index = selectValue.target.selectedIndex;
    const vehiculo_datos2 = {};
    vehiculo_datos2["id"] = selectValue.target.value;
    vehiculo_datos2["placa_furgon"] = selectValue.target[index].label;

    setVehiculoDatos({ ...vehiculo_datos, Furgon: vehiculo_datos2 });
    setPlaca(selectValue.target[index].label);
  };

  const handleChange = (e) => {
    if ([e.target.name] == "numero_precintos_total") {
      let index = e.nativeEvent.target.selectedIndex;
      setNumeroTotalPrecintos(e.nativeEvent.target[index].label);
      return;
    }
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });

    if ([e.target.name] == "tipo_vehiculo") {
      let index = e.nativeEvent.target.selectedIndex;
      setTipoVehNom(e.nativeEvent.target[index].label);
      seVehtDataForm(initVehDataForm);
      setVehiculoDatos({});
      setContenedor([]);
      console.log( e.nativeEvent.target[index]?.label)
      if (
        e.nativeEvent.target[index]?.label == "Chasis" ||
        e.nativeEvent.target[index]?.label == "Semi Acoplado Chasis" ||
        e.nativeEvent.target[index]?.label == "Furgon AcoChasis"
      ) {
        setDataForm({
          ...dataForm,
          [e.target.name]: e.target.value,
          contenedor: "-1", placa_vehiculo:""
        });
      } else {
        
        const dataForm1=dataForm;
        delete dataForm1.contenedor;
        setDataForm({
          ...dataForm1,
          [e.target.name]: e.target.value, placa_vehiculo:""
         
        });
      }
    }
    if ([e.target.name] == "placa_vehiculo") {
      let index = e.nativeEvent.target.selectedIndex;

      const vehiculo_datos2 = {};
      vehiculo_datos2["id"] = e.target.value;
      vehiculo_datos2[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos2 });
    }
    if ([e.target.name] == "contenedor" || [e.target.name] == "contenedor2") {
      let index = e.target.selectedIndex;
      const contenedor = {};

      contenedor["id"] = e.target.value;
      contenedor["numero"] = e.target[index].label;
      setContenedor((contenedor_datos) => [...contenedor_datos, contenedor]);
      /*  let numPrecintos = parseInt(
        e.target[index].getAttribute("data-precintos")
      );
      let numPrecintosPrev = parseInt(numero_precintos_total);

      setNumeroTotalPrecintos((numPrecintos + numPrecintosPrev).toString()); */
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;
console.log("isvlid",dataForm)
    for (let key in dataForm) {
      if (key !== "numero_precintos") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() == "-1"
        ) {
          console.log("isvlidsss",key)
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const datos_creacion = { nombreUsuario };
    console.log(vehiculo_datos);
    var vehiculo = {
      ...vehiculo_datos,
      tipo_vehiculo_nombre,
      id: dataForm?.placa_vehiculo,
    };
    //   var dataForm1 = { ...dataForm, ...vehDataForm, contenedor_datos };
    var dataForm1 = { ...dataForm, contenedor_datos };
    e.preventDefault();

    if (isValid()) {
      // mandar la data al proveedor
      if (editar) {
        editarContenedor(dataForm1);
      } else {
        dataForm1 = {
          ...dataForm1,
          viaje: "Stock",
          datos_creacion,
          piloto_datos,
          vehiculo,
        };
        nuevo(dataForm1);
      }
      // limpiar los campos
      // setDataForm(initDataForm);
    }
  };

  return (
    <>
      <Button
        className="btn text-white"
        style={{ backgroundColor: "#0A5189" }}
        onClick={handleShow}
        size="sm"
      >
        <FormattedMessage id="Add" />
      </Button>

      <Modal show={show} onHide={HandleCloseReload} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="vinculacion_add" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="origen">
                  <FormattedMessage id="vinculacion_origen" />
                </Form.Label>

                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="origen"
                  value={dataForm.origen}
                  onChange={handleChange}
                />

                {errors.origen && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.origen}
                  </span>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="destino">
                  <FormattedMessage id="vinculacion_destino" />
                </Form.Label>

                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="destino"
                  value={dataForm.destino}
                  onChange={handleChange}
                />

                {errors.destino && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.destino}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="piloto">
                  <FormattedMessage id="vinculacion_piloto" />
                </Form.Label>

                <Form.Select
                  name="piloto"
                  value={dataForm.piloto}
                  onChange={handleChange2}
                >
                  {listaPilotosSelect?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>

                {errors.piloto && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.piloto}
                  </span>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="tipo_vehiculo">
                  <FormattedMessage id="vinculacion_tipo_vehiculo" />
                </Form.Label>

                <Form.Select
                  name="tipo_vehiculo"
                  value={dataForm.tipo_vehiculo}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione un Tipo</option>
                  <option>Chasis</option>
                  <option>Furgon</option>
                  <option>Semi Acoplado Chasis</option>
                  <option>Semi Acoplado Furgon</option>
                  <option>Camioneta</option>
                  <option>Chasis AcoChasis</option>
                  <option>Chasis AcoFurgon</option>
                  <option>Furgon AcoFurgon</option>
                  <option>Furgon AcoChasis</option>
                </Form.Select>

                {errors.tipo_vehiculo && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.tipo_vehiculo}
                  </span>
                )}
              </Col>
              {renderSwitchV(tipo_vehiculo_nombre)}
            </Row>

            <Row>
              <Col>
                <Form.Label className="my-5" htmlFor="numero_precintos">
                  N° Precinto:
                </Form.Label>

                <Form.Select
                  name="numero_precintos"
                  value={dataForm.numero_precintos}
                  onChange={handleChange}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                </Form.Select>

                {errors.numero_precintos && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.numero_precintos}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            <FormattedMessage id="cancelar" />
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            <FormattedMessage id="Add" />
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEmparejamientos;
