import React, { useEffect } from "react";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import { obtenerUsuarios, eliminarUsuario } from "../../redux/actions/usuario";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";



const Usuarios = () => {
  const intl=useIntl();

  const listaColumnasx = [
    { accessor: "email", label: intl.formatMessage({id:"user_account"})},
    { accessor: "name", label: intl.formatMessage({id:"user_fullname"}) },
   
    { accessor: "perfil", label: intl.formatMessage({id:"user_perfil"}) },
    { accessor: "nombreempresa", label: "Empresa" },
    //{ accessor: "fecha_creacion", label: "Fecha Registro" },
   // { accessor: "hora_creacion", label: "Hora Registro" },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaUsuarios } = state.usuario;

  useEffect(() => {
    dispatch(obtenerUsuarios());
  }, []);

  const eliminarU = (id) => {
    dispatch(eliminarUsuario(id));
    dispatch(obtenerUsuarios());
    // agregar producto provider
  };

  if (!listaUsuarios) return <p>Cargando ...</p>;

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("usuarios")}

              <div className="card-title d-flex gap-5">
                <Link to="/usuario/uploadfile">
                  <button
                    className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                    style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  >
                    <FormattedMessage  id="importar_archivo"/>
              
                  </button>
                </Link>

                {ModalAddGlinseal("usuarios")}
              </div>
            </div>
            {listaUsuarios.length > 0 && (
              <App
                nombretabla="usuarios"
                rows={listaUsuarios}
                columns={listaColumnasx}
                eliminarU={eliminarU}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Usuarios;
