import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { obtenerPiloto } from "../../redux/actions/piloto";
import { Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
const initDataForm = {
  identificacion: "",
  nombre: "",
  licencia: "",
  empresa_trans: "",
};
const Modales = ({ id }) => {
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { piloto } = state.piloto;

  useEffect(() => {
    if (show) {
      dispatch(obtenerPiloto(id));
    }
  }, [show]);
  useEffect(() => {
    if (piloto) {
      setDataForm({ ...piloto });
    }
  }, [piloto]);

  return (
    <>
      <Button
        onClick={handleShow}
        style={{
          color: "#0A5189",
          fontWeight: "bolder",
          backgroundColor: "#e9f6ff",
        }}
        size="sm"
      >
       <FormattedMessage id="driver_info" />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <img width={50} src={require("../../images/driver.png")} alt="..." />
          <Modal.Title>  <FormattedMessage id="driver_info" /></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column>  <FormattedMessage id="driver_dni" /></Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm.identificacion}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column><FormattedMessage id="driver_fullname" /></Form.Label>
              <Col sm="6">
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm.nombre_completo}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column><FormattedMessage id="driver_license" /></Form.Label>
              <Col sm="6">
                <Form.Control plaintext readOnly value={dataForm.licencia} />
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column><FormattedMessage id="empresa_trans" /></Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  readOnly
                  value={dataForm.empresa_trans}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Stack
          direction="horizontal"
          className="m-8 mt-0 d-flex justify-content-end"
        >
          <Button
            style={{ backgroundColor: "#0A5189" }}
            className="text-white"
            variant="secondary"
            onClick={handleClose}
          >
            <FormattedMessage id="Close" />
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default Modales;
