import React, { useState } from "react";

import RowTable from "../RowTablaSol";
import RowTablePiloto from "../RowTablaSol/RowTablePiloto";
import RowTableCamion from "../RowTablaSol/RowTableCamion";
import RowTableContenedor from "../RowTablaSol/RowTableContenedor";
import RowTablePosicionRFID from "../RowTablaSol/RowTablePosicionRFID";
import RowTableEmpreTrans from "../RowTablaSol/RowTableEmpreTrans";
import RowTableViaje from "../RowTablaSol/RowTableViaje";
import RowTableTagCamion from "../RowTablaSol/RowTableTagCamion";
import RowTableFurgon from "../RowTablaSol/RowTableFurgon";
import RowTableRfidUpload from "../RowTableUpload/Rfids";
import RowTableUsuario from "../RowTablaSol/RowTableUsuario";
import { FormattedMessage } from "react-intl";

const TablaSol = ({ listaDatos, listaColumnas, nombretabla,numero_doc_precinto,tipo }) => {

  const[nombreEmpresa, setNOmbreE]=useState(localStorage.getItem("nombreempresa"));
  const[perfil, setPerfil]=useState(localStorage.getItem("perfil"));
 if(tipo!=="TRESESTADOS"){
  listaColumnas.shift();
 }
  
  const renderSwitch = (param, item, index) => {
    switch (param) {
      case "rfid":
        return <RowTable key={index} indice={index} rfid={item} />;

      case "piloto":
        return <RowTablePiloto key={item.id} indice={index} piloto={item} />;

      case "camion":
        return <RowTableCamion key={item.id} indice={index} camion={item} />;

      case "contenedor":
        return (
          <RowTableContenedor key={item.id} indice={index} contenedor={item} />
        );

      case "lecturas":
        return (
          <RowTablePosicionRFID perfil={perfil} tipo={tipo}
          key={index} indice={index} posicion={item} id={numero_doc_precinto} nombreEmpresa={nombreEmpresa} />
        );

      case "empresatrans":
        return (
          <RowTableEmpreTrans key={item.id} indice={index} empresa={item} />
        );

      case "tagcamion":
        return (
          <RowTableTagCamion key={item._id} indice={index} tagcamion={item} />
        );

      case "viaje":
        return <RowTableViaje key={index} indice={index} viaje={item} />;

      case "rfidupload":
        return <RowTableRfidUpload key={index} indice={index} rfid={item} />;

      case "furgon":
        return <RowTableFurgon key={item.id} indice={index} furgon={item} />;

      case "usuarios":
        return <RowTableUsuario key={index} indice={index} usuario={item} />;

      default:
        break;
    }
  };

  return (
    <div id="kt_app_content" className="flex-column-fluid">
      <div
        id="kt_app_content_container"
        className="app-container container-fluid"
      >
        <div className="card">
          <div className="card-header">
            <h1
              className="card-title"
              style={{ fontWeight: "bold", color: "#0A5189" }}
            >
               <FormattedMessage id={nombretabla.toString().toUpperCase()} /> 
            </h1>
          </div>

          <div className="card-body">
            <table className="table border border-0">
              <thead>
                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0 text-uppercase">
                  <th scope="col">#</th>
                  {listaColumnas.map((item, index) => (
                    <th scope="col" key={index} indice={index}>
                      {item}
                    </th>
                  ))}

                  <th scope="col" className="text-center">
                  <FormattedMessage id="table_acciones" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {listaDatos
                  .slice(0)
                  .reverse()
                  .map((item, index) => renderSwitch(nombretabla, item, index))}
              </tbody>
            </table>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default TablaSol;
