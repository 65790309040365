import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoRfid";

import { agregarRfid } from "../../redux/actions/rfid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const AgregarRfid = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state);
  const { addOk, mensajeError } = state.rfid;

  useEffect(() => {
    if (addOk) {
      history.push("/rfids");
    }
  }, [addOk]);

  const nuevoRfid = (rfid) => {
    dispatch(agregarRfid(rfid));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Agregar RFID</h1>
      <Formulario agregarNuevoRfid={nuevoRfid} mensajeError={mensajeError} />
    </div>
  );
};

export default AgregarRfid;
