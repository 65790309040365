import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { obtenerUsuario } from "../../redux/actions/usuario";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  _id: "",
  name: "",
  email: "",
  perfil: "",
  activo: "",
  fecha_registro: "",
  hora_registro: "",
  foto_image: "",
};

const Card = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { usuario } = state.usuario;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }

  const [dataForm, setDataForm] = useState(initDataForm);

  useEffect(() => {
    dispatch(obtenerUsuario(id));
  }, [id]);
  useEffect(() => {
    if (usuario) {
      setDataForm({ ...usuario });
    }
  }, [usuario]);
  if (!usuario) return <h1>Cargando ...</h1>;
  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/usuarios`}>
      <FormattedMessage id="ir_lista"/>
      </Link>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
            <div className="card-title d-flex flex-column">
              <span className="fw-bolder" style={{ color: "#0A5189" }}>
              <FormattedMessage id="user_details"/>  
              </span>
            </div>

            <div className="card-title d-flex flex-row align-items-center gap-3">
              <img
                width={50}
                src={
                  require("../../images/sidebarIcons/usuarioIcon.svg").default
                }
                style={{ filter: "invert(100%)" }}
                alt="User Avatar"
              />

              <div className="card-title d-flex flex-column">
                <span className="fw-bolder">{dataForm?.name}</span>
                <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="user_fullname"/> </span>
              </div>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.email}</span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="user_account"/> </span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder"><FormattedMessage id={dataForm?.perfil || "Administrador"}/> </span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="user_perfil"/> </span>
            </div>
            <div className="card-title d-flex flex-column align-items-center">
            <span className="fw-bolder"><FormattedMessage id={dataForm?.activo || "ACTIVO"} /> </span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="user_position"/> </span>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
