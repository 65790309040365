import { Table } from "./Table";

export default function App({
  rows,
  columns,
  nombretabla,
  option,
  eliminarU,
  cerrarEmparejamiento,
}) {
  return (
    <Table
      cerrarEmparejamiento={cerrarEmparejamiento}
      rows={rows}
      columns={columns}
      nombretabla={nombretabla}
      option={option}
      eliminarU={eliminarU}
    />
  );
}
