// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .App-content {
    display: flex;
    flex: 1 1;
  }
  

  /* MainContent.css */
  .MainContent {
    flex: 1 1;
    padding: 10px;
  }
  /* Define specific styles for dark theme */
  .light-theme {
    background-color: #ffffff;
   color: black
  }
  
  .dark-theme {
    background-color: #121212;
   color:white
  }
 
  `, "",{"version":3,"sources":["webpack://./src/rutas/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,SAAO;EACT;;;EAGA,oBAAoB;EACpB;IACE,SAAO;IACP,aAAa;EACf;EACA,0CAA0C;EAC1C;IACE,yBAAyB;GAC1B;EACD;;EAEA;IACE,yBAAyB;GAC1B;EACD","sourcesContent":["/* App.css */\n.App {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n  }\n  \n  .App-content {\n    display: flex;\n    flex: 1;\n  }\n  \n\n  /* MainContent.css */\n  .MainContent {\n    flex: 1;\n    padding: 10px;\n  }\n  /* Define specific styles for dark theme */\n  .light-theme {\n    background-color: #ffffff;\n   color: black\n  }\n  \n  .dark-theme {\n    background-color: #121212;\n   color:white\n  }\n \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
