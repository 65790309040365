import moment from "moment";
import axios from "axios";
import { URL } from "../../services/VariablesEntorno";

export const obtenerTipoVehiculo = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OBTENER_TIPO_VEHICULO",
        //    payload: listaTipoVehiculoSelect,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerCamionesParaTagFiltrados = (filtro) => {
  return async (dispatch) => {
    try {
      // const doc = await db.collection("solicitudes").where("hDespegue", ">=", hDespegueX).where("hAterrizaje","<=",hAterrizajeX).get();

      dispatch({
        type: "OBTENER_CAMIONES",
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerVehiculosFiltrados = () => {
  return async (dispatch) => {
    try {
      const arrayLista = [{label: "Seleccione una Vehiculo", value: -1, selected:true}];
      var token = localStorage.getItem("token");
      var lista = await axios.get(URL + "/vehiculo/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          //  console.log("dato fechas",item.datos_creacion.fecha_creacion)
          //    var fecha_creacion=moment( item.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
          //  var hora_creacion = moment(item.datos_creacion.fecha_creacion).format("HH:mm:ss");
          arrayLista.push({ key: item._id,label: item.placa_vehiculo, value: item._id, selected:false })
        });
      }
    
      dispatch({
        type: "OBTENER_VEHICULOS",
            payload: arrayLista
        //   payload2: listaCamionesSelect,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerVehiculosSinTagSelect = (numero_tag) => {
  return async (dispatch) => {
    try {
  
      const arrayLista = [{label: "Seleccione una Vehiculo", value: -1, selected:true}];
      var token = localStorage.getItem("token");
      var lista = await axios.get(URL + "/vehiculo/getsintag", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }, params:{
          numero_tag
        }
      });
      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          //  console.log("dato fechas",item.datos_creacion.fecha_creacion)
          //    var fecha_creacion=moment( item.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
          //  var hora_creacion = moment(item.datos_creacion.fecha_creacion).format("HH:mm:ss");
          arrayLista.push({ key: item._id,label: item.placa_vehiculo, value: item._id, selected:false })
        });
      }
    
      dispatch({
        type: "OBTENER_VEHICULOS",
            payload: arrayLista
        //   payload2: listaCamionesSelect,
      });
    } catch (error) {
      console.log("no hay datos en firebase", error);
    }
  };
};
export const obtenerCamion = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OBTENER_CAMION",
        //   payload: camion,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerAcoplado = (id) => {
  return async (dispatch) => {
    try {
      var Solicitud = await axios.get(`${URL}/vehiculo/detalle/${id}`);
      console.log("el vehiculo", Solicitud);
      let Objeto = {};

      if (Solicitud?.status === 200) {
        var fecha_creacion = moment(
          Solicitud.data.data.datos_creacion.fecha_creacion
        ).format("YYYY-MM-DD");
        var hora_creacion = moment(
          Solicitud.data.data.datos_creacion.fecha_creacion
        ).format("HH:mm:ss");
        Objeto = {
          ...Solicitud.data.data,
          fecha_creacion,
          hora_creacion,
        };
      }

      dispatch({
        type: "OBTENER_ACOPLADO",
        payload: Objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerCamiones = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OBTENER_CAMIONES",
        //     payload: camiones,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerVehiculos = () => {
  return async (dispatch) => {
    try {
      const arrayLista = [];
      var token = localStorage.getItem("token");
      var lista = await axios.get(URL + "/vehiculo/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
 

      if (lista?.status === 200 && lista?.data.ok) {
        lista.data.data.forEach((item) => {
          //  console.log("dato fechas",item.datos_creacion.fecha_creacion)
          //    var fecha_creacion=moment( item.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
          //  var hora_creacion = moment(item.datos_creacion.fecha_creacion).format("HH:mm:ss");

          arrayLista.push({
            ...item,
          //  id: item._id,
            //   fecha_creacion, hora_creacion
          });
        });
      }

      dispatch({
        type: "OBTENER_VEHICULOS",
        payload: arrayLista,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerVehiculosPorTipo = () => {
  return async (dispatch) => {
    try {
      const vehiculo = [];
      var token = localStorage.getItem("token");
      const camioneta = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const semichasis = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const chasis = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const furgon = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const semifurgon = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const trailer = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const acochasis = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      const acofurgon = [
        { placa_vehiculo: "Seleccione un Vehiculo", value: -1, selected: true },
      ];
      // const doc =[];// await db.collection("vehiculos").get();
      var doc = await axios.get(URL + "/vehiculo/getall", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (doc?.status === 200 && doc?.data.ok) {
      doc.data.data.forEach((item) => {
       
        switch (item.tipo_vehiculo) {
          case "Camioneta":
            camioneta.push({ id: item._id, ...item });
            break;
          case "Chasis":
            chasis.push({ id: item._id, ...item });
            break;
          case "Furgon":
            furgon.push({ id: item._id, ...item });
            break;
          case "Trailer":
            trailer.push({ id: item._id, ...item });
            break;
          case "Semi Chasis":
            semichasis.push({ id: item._id, ...item });
            break;
          case "Semi Furgon":
            semifurgon.push({ id: item._id, ...item });
            break;
          case "Acoplado Furgon":
            acofurgon.push({ id: item._id, ...item });
            break;
          case "Acoplado Chasis":
            acochasis.push({ id: item._id, ...item });
            break;
          default:
            break;
        }
      });
    }
      dispatch({
        type: "OBTENER_VEHICULO_POR_TIPO",
        payload: vehiculo,
        payload1: camioneta,
        payload2: semichasis,
        payload3: chasis,
        payload4: furgon,
        payload5: semifurgon,
        payload6: trailer,
        payload7: acochasis,
        payload8: acofurgon,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerFurgon = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "OBTENER_FURGON",
        //    payload: furgon,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const obtenerVehiculo = (id) => {
  return async (dispatch) => {
    try {
      var token = localStorage.getItem("token");
      var Solicitud = await axios.get(`${URL}/vehiculo/detalle/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      
      let Objeto = {};

      if (Solicitud?.status === 200) {
        //   var fecha_creacion=moment( Solicitud.data.data.datos_creacion.fecha_creacion).format("YYYY-MM-DD");
        //  var hora_creacion = moment(Solicitud.data.data.datos_creacion.fecha_creacion).format("HH:mm:ss");
        Objeto = {
          ...Solicitud.data.data,
          //fecha_creacion, hora_creacion
        };
      }
   
      dispatch({
        type: "OBTENER_VEHICULO",
        payload: Objeto,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const agregarVehiculo = (entidad) => {
  return async (dispatch) => {
    try {
      console.log("la entidad veh", entidad)
      const tipo_vehiculo = entidad.tipo_vehiculo;
      const placa_vehiculo = entidad.placa_vehiculo;
      const marca = entidad.marca;
      const modelo = entidad.modelo;
      const id_empresa_trans = entidad.id_empresa_trans;
      const empresa_trans=entidad.empresa_trans;
      const estado = "LIBRE";
      const numero_tag=entidad.numero_tag;
      const numero_tag_id=entidad.numero_tag_id;
      const numero_precintos=entidad.numero_precintos;

      var token = localStorage.getItem("token");

      var rfidRec = await axios.post(
        URL + "/vehiculo/create",
        {
          tipo_vehiculo,
          placa_vehiculo,
          marca,
          modelo,
          id_empresa_trans,
          empresa_trans,
          estado,
          numero_tag,
          numero_tag_id,
          numero_precintos
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("respuesta", rfidRec)
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
        dispatch({
          type: "AGREGAR_VEHICULO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "AGREGAR_VEHICULO",
        payload: false,
        payload2: rfidRec?.data.data
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const editarVehiculo = (vehiculo) => {
  return async (dispatch) => {
    try {
      console.log("vehiculo a editar", vehiculo);
      var token = localStorage.getItem("token");
      var rfidRec = await axios.post(
        URL + `/vehiculo/update/${vehiculo._id}`,
        { vehiculo },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("resuesta11111", rfidRec)
    
      if (rfidRec?.status === 200 && rfidRec?.data.ok) {
     
        dispatch({
          type: "EDITAR_VEHICULO",
          payload: true,
        });
        return;
      }
      dispatch({
        type: "EDITAR_VEHICULO",
        payload: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const cargaMasiva = (camion) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "AGREGAR_CAMION_LISTA",
        //    payload: erroneos,
        //    payload2: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const buscarEmprePorNombre = (camion) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "BUSCAR_CAMION_LISTA",

        //  payload: camionExisten,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
