import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { obtenerPiloto } from "../../redux/actions/piloto";
import { FormattedMessage } from "react-intl";

const initDataForm = {
  empresa_trans: "",
  identificacion: "",
  licencia: "",
  nombre_completo: "",
  viaje: "",
};
const CardPiloto = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { piloto } = state.piloto;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }

  const [dataForm, setDataForm] = useState(initDataForm);

  useEffect(() => {
    dispatch(obtenerPiloto(id));
    //   setDataFormConte(initDataFormConte);
    setDataForm({ ...piloto });
  }, [id]);
  useEffect(() => {
    setDataForm({ ...piloto });
  }, [piloto]);
  if (!piloto) return <h1>Cargando ...</h1>;
  return (
    <>
      <Link className="btn btn-warning ms-9 mb-5" to={`/pilotos`}>
      <FormattedMessage id="ir_lista" />
      </Link>

      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
            <div className="card-title d-flex flex-column">
              <span className="fw-bolder" style={{ color: "#0A5189" }}>
              <FormattedMessage id="driver_detalle" />
              </span>
            </div>

            <div className="card-title d-flex flex-row align-items-center gap-3">
              <img
                width={50}
                src={require("../../images/driver.png")}
                alt="User Avatar"
              />

              <div className="card-title d-flex flex-column">
                <span className="fw-bolder">{dataForm.nombre_completo}</span>
                <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="driver_fullname" /></span>
              </div>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.empresa_trans || ""}</span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="empresa_trans" /></span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">
                {dataForm?.identificacion || ""}
              </span>
              <span className="text-gray-400 fw-light fs-6">
              <FormattedMessage id="driver_dni" />
              </span>
            </div>

            <div className="card-title d-flex flex-column align-items-center">
              <span className="fw-bolder">{dataForm?.licencia || ""}</span>
              <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="driver_license" /></span>
            </div>

            <div className="card-title d-flex flex-column">
              <span className="fw-bolder">{dataForm?.viaje || ""}</span>
              <span className="text-gray-400 fw-light fs-6 text-center">
              <FormattedMessage id="position" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardPiloto;
