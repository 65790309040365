import React from "react";



const RowTable = (props) => {


  const {
   numero,
    medida,
  //  condicion,
  //  estado,
    naviera,
    tipo,
    numero_precintos
  
  } = props.contenedor;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      {numero && <td>{numero}</td>}
      <td>{medida} </td>
      <td>{naviera} </td>
      <td>{tipo} </td>
      <td>{numero_precintos} </td>
   


    </tr>
  );
};

export default RowTable;
