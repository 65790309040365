import { useEffect, useState } from "react";
import { editarUsuario } from "../../redux/actions/usuario";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const initDataForm = {
  name: "",
  password: "",
  email: "",
  perfil: "",
  activo: "ACTIVO",
  idempresa: "",
};

const initDataFormErr = {
  name: "",
  password: "",
  email: "",
  perfil: "",
  idempresa: "",
};

const errorsInit = {
  ...initDataFormErr,
};

const ModalEditUsuarios = ({ datos }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const history = useHistory();
  const state = useSelector((state) => state);
  const { editOk } = state.usuario;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { listaEmpClienteSelect } = state.clienteempre;
  const HandleCloseReload = () => {
    handleClose();
  };
  useEffect(() => {
    if (editOk) {
      history.push("/usuarios");
    }
  }, [editOk]);
  useEffect(() => {
    if (show) {
      delete datos.datos_edicion;
      setDataForm({ ...datos });
    }
  }, [show, datos]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  //   const isValid = () => {
  //     const localErrors = { ...errorsInit };
  //     let respuesta = true;

  //     for (let key in dataForm) {
  //       if (key !== "activo") {
  //         if (
  //           dataForm[key].trim() === "" ||
  //           dataForm[key].length === 0 ||
  //           dataForm[key] === -1
  //         ) {
  //           localErrors[key] = "Campo Requerido";
  //           respuesta = false;
  //         }
  //       }
  //     }

  //     setErrors({ ...localErrors });

  //     return respuesta;
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (isValid()) {
    const dataForm1 = { ...dataForm };
    dispatch(editarUsuario(dataForm1));
    // }
  };

  return (
    <>
      <Button
        className="btn-secondary fw-bolder"
        style={{ color: "#0A5189" }}
        onClick={handleShow}
      >
        Editar
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Usuario</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={12}>
                <Form.Label className="mb-5" htmlFor="email">
                  Usuario:
                </Form.Label>
                <Form.Control
                  readOnly={true}
                  className="bg-body-secondary border border-0"
                  name="email"
                  defaultValue={dataForm?.email || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="name">
                  Nombre Completo:
                </Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="name"
                  value={dataForm?.name || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="password">
                  Password:
                </Form.Label>
                <Form.Control
                  className="bg-body-secondary border border-0"
                  name="password"
                  value={dataForm?.password || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="perfil">
                  Perfil:
                </Form.Label>
                <Form.Select
                  name="perfil"
                  value={dataForm.perfil || ""}
                  onChange={handleChange}
                >
                  <option value="-1">Seleccione</option>
                  <option>Administrador</option>
                  <option>UsuarioWeb</option>
                  <option>UsuarioMovil</option>
                </Form.Select>
              </Col>

              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="activo">
                  Estado:
                </Form.Label>
                <Form.Select
                  name="activo"
                  value={dataForm.activo || ""}
                  onChange={handleChange}
                >
                  <option>ACTIVO</option>
                  <option>INACTIVO</option>
                </Form.Select>
              </Col>
              <Col xs={6}>
                <Form.Label className="my-5" htmlFor="idempresa">
                  Empresa:
                </Form.Label>

                <Form.Select
                  name="idempresa"
                  value={dataForm.idempresa || ""}
                  onChange={handleChange}
                >
                
                  {listaEmpClienteSelect.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>

                {errors.idempresa && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.idempresa}
                  </span>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            onClick={handleSubmit}
            size="sm"
            style={{ backgroundColor: "#0A5189" }}
          >
            Editar
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEditUsuarios;
