import useEffect from "react";
import Formulario from "../../components/Formulario/NuevoEmpreTrans";

import {
  editarEmpresaTrans,
  obtenerEmpresa,
} from "../../redux/actions/empresatrans";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

const Editar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { empresatrans, editOk } = state.empresatrans;
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    dispatch(obtenerEmpresa(id));
  }, []);

  useEffect(() => {
    if (editOk) {
      history.push("/empresatrans");
    }
  }, [editOk]);

  const edicion = (empresa) => {
    // editar producto;
    dispatch(editarEmpresaTrans(empresa));
  };

  if (!empresatrans) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Editar Empresa Transporte</h1>
      <Formulario
        empresa={empresatrans}
        editar={true}
        editarEmpresa={edicion}
      />
    </div>
  );
};

export default Editar;
