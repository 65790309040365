import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { eliminarPiloto, obtenerPilotos } from "../../redux/actions/piloto";
import App from "../../components/TablaRfids/App";
import { Link } from "react-router-dom";
import { CardTitle, ModalAddGlinseal } from "../../helpers";
import { FormattedMessage, useIntl } from "react-intl";



const Pilotos = () => {
  const intl=useIntl();
  const listaColumnasx = [
    { accessor: "identificacion", label: intl.formatMessage({id:"driver_dni"}) },
    { accessor: "nombre_completo", label: intl.formatMessage({id:"driver_fullname"})  },
    { accessor: "licencia", label: intl.formatMessage({id:"driver_license"}) },
    { accessor: "empresa_trans", label: intl.formatMessage({id:"transport_company_combo"})  },
    { accessor: "viaje", label: intl.formatMessage({id:"position"})  },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaPilotos, deleteOk } = state.piloto;

  useEffect(() => {
    dispatch(obtenerPilotos());
  }, []);

  useEffect(() => {
    if (deleteOk) {
      dispatch(obtenerPilotos());
    }
  }, [deleteOk]);

  const eliminarU = (id) => {
    dispatch(eliminarPiloto(id));
  };

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("piloto")}

              <div className="card-title d-flex gap-5">
                <Link to="/piloto/uploadfile">
                  <button
                    className="btn btn-sm fw-bolder d-flex gap-2 align-items-center"
                    style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                  >
                       <FormattedMessage id="importar_archivo" />
                  </button>
                </Link>

                {ModalAddGlinseal("piloto")}
              </div>
            </div>
            {listaPilotos.length > 0 && (
              <App
                nombretabla="piloto"
                eliminarU={eliminarU}
                rows={listaPilotos}
                columns={listaColumnasx}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pilotos;
