import React, { useState, useEffect } from "react";
/* import VirtualizedSelect from "react-virtualized-select";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css"; */
import { obtenerPilotosFiltrados } from "../../redux/actions/piloto";

import {
  obtenerTipoVehiculo,
  obtenerVehiculosPorTipo,
} from "../../redux/actions/vehiculo";
import { obtenerContenedoresFiltrados } from "../../redux/actions/contenedor";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const initDataForm = {
  origen: "",
  destino: "",
  piloto: "",
  numero_precintos: "",
  tipo_vehiculo: "",
};
const initVehDataForm = {
  placa_furgon: "",
  placa_camioneta: "",
  placa_trailer: "",
  placa_aco_chasis: "",
  placa_aco_furgon: "",
};
const errorsInit = {
  ...initDataForm,
};

const NuevoEmparejamiento = ({
  agregarNuevoEmparejamiento,
  contenedor,
  editar = false,
  editarContenedor,
}) => {
  const ArrConte = [];
  const [vehDataForm, seVehtDataForm] = useState(initVehDataForm);
  const [tipo_vehiculo_nombre, setTipoVehNom] = useState("");
  const [placa_vehiculo, setPlaca] = useState("");
  const [numero_precintos_total, setNumeroTotalPrecintos] = useState("1");
  const [vehiculo_datos, setVehiculoDatos] = useState({});
  const [piloto_datos, setPilotoDatos] = useState({});
  const [contenedor_datos, setContenedor] = useState([]);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const {
    camioneta,
    furgon,
    trailer,
    semichasis,
    semifurgon,
    chasis,
    acochasis,
    acofurgon,
  } = state.vehiculo;
  //const { listaCamionesSelect } = state.camion;

  const { listaPilotosSelect } = state.piloto;
  const { listaContenedoresSelect } = state.contenedor;

  useEffect(() => {
    if (editar) {
      setDataForm({ ...contenedor });
    }
  }, []);

  useEffect(() => {
    dispatch(obtenerTipoVehiculo());

    dispatch(obtenerPilotosFiltrados("LIBRE"));
    dispatch(obtenerVehiculosPorTipo());
    dispatch(obtenerContenedoresFiltrados());
  }, []);

  const handleChange2 = (selectValue) => {
    setDataForm({ ...dataForm, piloto: selectValue.value });
    const piloto_datos = {};
    piloto_datos["idPiloto"] = selectValue.value;
    piloto_datos["nombre_completo"] = selectValue.label;
    setPilotoDatos(piloto_datos);
  };

  const handleChange3 = (selectValue) => {
    seVehtDataForm({ ...vehDataForm, contenedor: selectValue.value });
    const contenedor = {};
    contenedor["id"] = selectValue.value;
    contenedor["numero"] = selectValue.label;
    setContenedor((contenedor_datos) => [...contenedor_datos, contenedor]);
    //setContenedor(contenedor_datos);
  };

  const handleChange4 = (selectValue) => {
    seVehtDataForm({ ...vehDataForm, chasis_cabezal: selectValue.value });
    const vehiculo_datos1 = {};
    vehiculo_datos1["id"] = selectValue.value;
    vehiculo_datos1["chasis_cabezal"] = selectValue.label;

    setVehiculoDatos({ ...vehiculo_datos, Chasis: vehiculo_datos1 });
    setPlaca(selectValue.label);
  };

  const handleChange5 = (selectValue) => {
    seVehtDataForm({ ...vehDataForm, placa_furgon: selectValue.value });
    const vehiculo_datos2 = {};
    vehiculo_datos2["id"] = selectValue.value;
    vehiculo_datos2["placa_furgon"] = selectValue.label;

    setVehiculoDatos({ ...vehiculo_datos, Furgon: vehiculo_datos2 });
    setPlaca(selectValue.label);
  };

  const handleChange = (e) => {
    if ([e.target.name] == "numero_precintos_total") {
      let index = e.nativeEvent.target.selectedIndex;
      setNumeroTotalPrecintos(e.nativeEvent.target[index].label);
      return;
    }
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });

    if ([e.target.name] == "tipo_vehiculo") {
      let index = e.nativeEvent.target.selectedIndex;
      setTipoVehNom(e.nativeEvent.target[index].label);
      seVehtDataForm(initVehDataForm);
      setVehiculoDatos({});
      setContenedor([]);
    }
    if ([e.target.name] == "contenedor" || [e.target.name] == "contenedor2") {
      let index = e.nativeEvent.target.selectedIndex;
      const contenedor = {};

      contenedor["id"] = e.target.value;
      contenedor["numero"] = e.nativeEvent.target[index].label;
      //ArrConte.push(contenedor);
      setContenedor((contenedor_datos) => [...contenedor_datos, contenedor]);
      let numPrecintos = parseInt(
        e.nativeEvent.target[index].getAttribute("data-precintos")
      );
      let numPrecintosPrev = parseInt(numero_precintos_total);

      setNumeroTotalPrecintos((numPrecintos + numPrecintosPrev).toString());
    }
    if ([e.target.name] == "placa_furgon") {
      seVehtDataForm({ ...vehDataForm, [e.target.name]: e.target.value });
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos2 = {};
      vehiculo_datos2["id"] = e.target.value;
      vehiculo_datos2[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, Furgon: vehiculo_datos2 });
      setPlaca(e.nativeEvent.target[index].label);

      //setDataForm({...dataForm, numero_precintos:e.nativeEvent.target[index].getAttribute('data-precintos')});
      let preci = e.nativeEvent.target[index].getAttribute("data-precintos");
      if (preci) {
        setNumeroTotalPrecintos(preci);
      }
    }
    if ([e.target.name] == "placa_trailer") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, Trailer: vehiculo_datos1 });
      setPlaca(e.nativeEvent.target[index].label);
    }
    if ([e.target.name] == "placa_semi_chasis") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, SemiChasis: vehiculo_datos1 });
    }
    if ([e.target.name] == "placa_semi_furgon") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, SemiFurgon: vehiculo_datos1 });
    }
    if ([e.target.name] == "chasis_cabezal") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, Chasis: vehiculo_datos1 });
      setPlaca(e.nativeEvent.target[index].label);
    }
    if ([e.target.name] == "placa_chasis") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, Chasis: vehiculo_datos1 });
      setPlaca(e.nativeEvent.target[index].label);
    }
    if ([e.target.name] == "placa_aco_chasis") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, AcoChasis: vehiculo_datos1 });
    }
    if ([e.target.name] == "placa_aco_furgon") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, AcoFurgon: vehiculo_datos1 });
    }
    if ([e.target.name] == "placa_remolque") {
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, Remolque: vehiculo_datos1 });
    }
    if ([e.target.name] == "placa_camioneta") {
      seVehtDataForm({ ...vehDataForm, [e.target.name]: e.target.value });
      let index = e.nativeEvent.target.selectedIndex;
      const vehiculo_datos1 = {};
      vehiculo_datos1["id"] = e.target.value;
      vehiculo_datos1[e.target.name] = e.nativeEvent.target[index].label;
      setVehiculoDatos({ ...vehiculo_datos, Camioneta: vehiculo_datos1 });
      setPlaca(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "numero_precintos") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          console.log("XXX", key);
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const fecha = moment().format("YYYY-MM-DD");
    const hora = moment().format("HH:mm:ss");
    const usuario = {};
    usuario["uid"] = localStorage.getItem("useruid");
    usuario["email"] = localStorage.getItem("email");
    const datos_creacion = { fecha, hora, usuario };

    var vehiculo = { ...vehiculo_datos, tipo_vehiculo_nombre, placa_vehiculo };
    var dataForm1 = { ...dataForm, ...vehDataForm, contenedor_datos };
    delete dataForm1.placa_furgon;
    delete dataForm1.tipo_vehiculo;
    delete dataForm1.piloto;
    delete dataForm1.vehiculo;
    delete dataForm1.chasis_cabezal;
    delete dataForm1.contenedor;
    delete dataForm1.contenedor2;
    delete dataForm1.placa_camioneta;
    delete dataForm1.placa_trailer;
    delete dataForm1.placa_aco_chasis;
    delete dataForm1.placa_aco_furgon;
    delete dataForm1.placa_semi_furgon;
    e.preventDefault();

    if (isValid()) {
      // mandar la data al proveedor
      if (editar) {
        dataForm1 = { ...dataForm1, fecha_edicion: fecha, hora_edicion: hora };
        editarContenedor(dataForm1);
      } else {
        dataForm1 = {
          ...dataForm1,
          viaje: "Stock",
          datos_creacion,
          piloto_datos,
          vehiculo,

          numero_precintos: numero_precintos_total,
        };
        agregarNuevoEmparejamiento(dataForm1);
      }
      // limpiar los campos
      // setDataForm(initDataForm);
    }
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "Seleccione un Tipo":
        return <div></div>;
      default:
        return <div></div>;
      case "Chasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="chasis_cabezal">Chasis Cabezal</label>

              {/*  <select
                type="text"
                className="form-control"
                name="chasis_cabezal"
                value={vehDataForm.chasis_cabezal}
                onChange={handleChange}
              >
               
                {chasis?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
                </select>*/}
        {/*       <VirtualizedSelect
                value={vehDataForm.chasis_cabezal}
                onChange={(selectValue) => handleChange4(selectValue)}
                options={chasis?.map((item, index) => ({
                  label: item.placa_vehiculo,
                  value: item.id,
                }))}
              ></VirtualizedSelect> */}
              {errors.chasis_cabezal && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.chasis_cabezal}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="contenedor">Contenedor</label>
        {/*       <VirtualizedSelect
                value={vehDataForm.contenedor}
                onChange={(selectValue) => handleChange3(selectValue)}
                options={listaContenedoresSelect?.map((item, index) => ({
                  label: item.label,
                  value: item.value,
                }))}
              ></VirtualizedSelect> */}

              {errors.contenedor && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor}
                </span>
              )}
            </div>
          </>
        );

      case "Semi Acoplado Chasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_trailer">Placa Trailer</label>

              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_trailer"
                value={vehDataForm.placa_trailer}
                onChange={handleChange}
              >
                {trailer?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_trailer && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_trailer}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="placa_semi_chasis">Placa Semi</label>

              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_semi_chasis"
                value={vehDataForm.placa_semi_chasis}
                onChange={handleChange}
              >
                {semichasis?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_semi_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi_chasis}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="contenedor">Contenedor</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="contenedor"
                value={vehDataForm.contenedor}
                onChange={handleChange}
              >
                {listaContenedoresSelect?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.value}
                    label={item.label}
                  ></option>
                ))}
              </select>
              {errors.contenedor && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor}
                </span>
              )}
            </div>
          </>
        );

      case "Semi Acoplado Furgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_trailer">Placa Trailer</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_trailer"
                value={vehDataForm.placa_trailer}
                onChange={handleChange}
              >
                {trailer?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_trailer && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_trailer}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="placa_semi_furgon">Semi Furgon</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_semi_furgon"
                value={vehDataForm.placa_semi_furgon}
                onChange={handleChange}
              >
                {console.log(semifurgon)}
                {semifurgon?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_semi_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_semi_furgon}
                </span>
              )}
            </div>
          </>
        );
      case "Camioneta":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_camioneta">Placa Camioneta</label>

              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_camioneta"
                value={vehDataForm.placa_camioneta}
                onChange={handleChange}
              >
                {camioneta?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_camioneta && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_camioneta}
                </span>
              )}
            </div>
          </>
        );
      case "Furgon AcoChasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_furgon"
                value={vehDataForm.placa_furgon}
                onChange={handleChange}
              >
                {furgon?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="placa_aco_chasis">Acoplado Chasis</label>

              <select
                type="text"
                className="form-control"
                name="placa_aco_chasis"
                value={vehDataForm.placa_aco_chasis}
                onChange={handleChange}
              >
                {acochasis?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>

              {errors.placa_aco_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_chasis}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="contenedor2">Contenedor</label>

              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="contenedor2"
                value={vehDataForm.contenedor2}
                onChange={handleChange}
              >
                {listaContenedoresSelect?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.value}
                    label={item.label}
                  ></option>
                ))}
              </select>

              {errors.contenedor2 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor2}
                </span>
              )}
            </div>
          </>
        );
      case "Chasis AcoFurgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_chasis">Chasis Cabezal</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_chasis"
                value={vehDataForm.placa_chasis}
                onChange={handleChange}
              >
                {chasis?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_chasis}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="contenedor">Contenedor</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="contenedor"
                value={vehDataForm.contenedor}
                onChange={handleChange}
              >
                {listaContenedoresSelect?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.value}
                    label={item.label}
                  ></option>
                ))}
              </select>
              {errors.contenedor && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="placa_aco_furgon">Acoplado Furgon</label>

              <select
                type="text"
                className="form-control"
                name="placa_aco_furgon"
                value={vehDataForm.placa_aco_furgon}
                onChange={handleChange}
              >
                {acofurgon?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>

              {errors.placa_aco_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_furgon}
                </span>
              )}
            </div>
          </>
        );

      case "Furgon AcoFurgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_furgon"
                value={vehDataForm.placa_furgon}
                onChange={handleChange}
              >
                {furgon?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="placa_aco_furgon">Acoplado Furgon</label>

              <select
                type="text"
                className="form-control"
                name="placa_aco_furgon"
                value={vehDataForm.placa_aco_furgon}
                onChange={handleChange}
              >
                {acofurgon?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>

              {errors.placa_aco_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_furgon}
                </span>
              )}
            </div>
          </>
        );
      case "Chasis AcoChasis":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_chasis">Chasis Cabezal</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_chasis"
                value={vehDataForm.placa_chasis}
                onChange={handleChange}
              >
                {chasis?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>
              {errors.placa_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_chasis}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="contenedor">Contenedor</label>
              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="contenedor"
                value={vehDataForm.contenedor}
                onChange={handleChange}
              >
                {listaContenedoresSelect?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.value}
                    label={item.label}
                  ></option>
                ))}
              </select>
              {errors.contenedor && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor}
                </span>
              )}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="placa_aco_chasis">Acoplado Chasis</label>

              <select
                type="text"
                className="form-control"
                name="placa_aco_chasis"
                value={vehDataForm.placa_aco_chasis}
                onChange={handleChange}
              >
                {acochasis?.map((item, index) => (
                  <option
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
              </select>

              {errors.placa_aco_chasis && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_aco_chasis}
                </span>
              )}
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="contenedor2">Contenedor</label>

              <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="contenedor2"
                value={vehDataForm.contenedor2}
                onChange={handleChange}
              >
                {listaContenedoresSelect?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.value}
                    label={item.label}
                  ></option>
                ))}
              </select>

              {errors.contenedor2 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.contenedor2}
                </span>
              )}
            </div>
          </>
        );
      case "Furgon":
        return (
          <>
            <div className="form-group col-md-4">
              <label htmlFor="placa_furgon">Placa Furgon</label>
              {/*  <select
                data-live-search="true"
                type="text"
                className="form-control"
                name="placa_furgon"
                value={vehDataForm.placa_furgon}
                onChange={handleChange}
              >
                {furgon?.map((item, index) => (
                  <option
                    data-precintos={item.numero_precintos}
                    key={index}
                    value={item.id}
                    label={item.placa_vehiculo}
                  ></option>
                ))}
                </select>*/}
           {/*    <VirtualizedSelect
                value={vehDataForm.placa_furgon}
                onChange={(selectValue) => handleChange5(selectValue)}
                options={furgon?.map((item, index) => ({
                  label: item.placa_vehiculo,
                  value: item.id,
                }))}
              ></VirtualizedSelect> */}
              {errors.placa_furgon && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errors.placa_furgon}
                </span>
              )}
            </div>
          </>
        );
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                {editar ? (
                  <h1 className="card-title">Editar Emparejamiento</h1>
                ) : (
                  <h1 className="card-title">Agregar Emparejamiento</h1>
                )}
              </div>
              <form
                onSubmit={handleSubmit}
                style={{ maxWidth: "50em", padding: "1rem" }}
              >
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="origen">Origen</label>
                    <input
                      type="text"
                      className="form-control"
                      name="origen"
                      value={dataForm.origen}
                      onChange={handleChange}
                    />
                    {errors.origen && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.origen}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="destino">Destino</label>
                    <input
                      type="text"
                      className="form-control"
                      name="destino"
                      value={dataForm.destino}
                      onChange={handleChange}
                    />
                    {errors.destino && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.destino}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="piloto">Piloto</label>
               {/*      <VirtualizedSelect
                      //   name="piloto"
                      value={dataForm.piloto}
                      onChange={(selectValue) => handleChange2(selectValue)}
                      options={
                        listaPilotosSelect?.map((item, index) => ({
                          label: item.label,
                          value: item.value,
                        }))
                        //   <option
                        // key={index}
                        //   value={item.value}
                        //     label={item.label}
                        //  ></option>
                      }
                    ></VirtualizedSelect> */}
                    {errors.piloto && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.piloto}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="tipo_vehiculo">Tipo Vehiculo</label>
                    <select
                      className="form-control"
                      name="tipo_vehiculo"
                      value={dataForm.tipo_vehiculo}
                      onChange={handleChange}
                    >
                      <option>Seleccione un Tipo</option>
                      <option>Chasis</option>
                      <option>Furgon</option>
                      <option>Semi Acoplado Chasis</option>
                      <option>Semi Acoplado Furgon</option>
                      <option>Camioneta</option>
                      <option>Chasis AcoChasis</option>
                      <option>Chasis AcoFurgon</option>
                      <option>Furgon AcoFurgon</option>
                      <option>Furgon AcoChasis</option>
                    </select>
                    {errors.tipo_vehiculo && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.tipo_vehiculo}
                      </span>
                    )}
                  </div>
                  {renderSwitch(tipo_vehiculo_nombre)}

                  <div className="form-group col-md-4">
                    <label htmlFor="numero_precintos_total">N Precintos</label>
                    <select
                      className="form-control"
                      name="numero_precintos_total"
                      value={numero_precintos_total}
                      onChange={handleChange}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                    </select>
                    {errors.numero_precintos_total && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.numero_precintos_total}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    {editar
                      ? "Editar Emparejamiento"
                      : "Agregar Emparejamiento"}
                  </button>
                  <Link
                    className="btn btn-warning ml-2"
                    to={`/emparejamientos`}
                  >
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoEmparejamiento;
