import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";

const initDataForm = {
  numero_precinto: "",
  TID: "",
  TID2: "",
};

const errorsInit = {
  ...initDataForm,
};

const NuevoRfid = ({
  agregarNuevoRfid,
  rfid,
  editar = false,
  editarRfid,
  mensajeError,
}) => {
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const state = useSelector((state) => state);
  const { yaExiste } = state.rfid;

  useEffect(() => {
    if (yaExiste) {
      toast("El Rfid Ya Existe");
    }
  }, [yaExiste]);

  useEffect(() => {
    if (editar) {
      setDataForm({ ...rfid });
    }
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    const fecha = moment().format("YYYY-MM-DD");
    const hora = moment().format("HH:mm");

    const dataForm1 = {
      ...dataForm,
      viaje: "Stock",
      estado: "OK",
      condicion: "ABIERTO",
      fecha_creacion: fecha,
      hora_creacion: hora,
    };
    e.preventDefault();
    if (isValid()) {
      // mandar la data al proveedor
      if (editar) {
        editarRfid(dataForm);
      } else {
        agregarNuevoRfid(dataForm1);
      }
      // limpiar los campos
      //  setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                <h1 className="card-title">Agregar Marchamo RFID</h1>
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="numero_precinto">Precinto N</label>
                    <input
                      type="text"
                      className="form-control"
                      name="numero_precinto"
                      value={dataForm.numero_precinto}
                      onChange={handleChange}
                    />
                    {errors.numero_precinto && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.numero_precinto}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="TID">TID </label>
                    <input
                      type="text"
                      className="form-control"
                      name="TID"
                      value={dataForm.TID}
                      onChange={handleChange}
                    />
                    {errors.TID && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.TID}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="TID2">TID 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="TID2"
                      value={dataForm.TID2}
                      onChange={handleChange}
                    />
                    {errors.TID2 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.TID2}
                      </span>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  {mensajeError}
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar Rfid" : "Agregar Rfid"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/rfids`}>
                    Cancelar
                  </Link>
                </div>

                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NuevoRfid;
