import { useState, useEffect, useMemo } from "react";
import { sortRows, filterRows, paginateRows } from "./helpers";
import Pagination from "./Paginations";
import RowTable from "../Rfid/RowNewTable";

import arrowNeutro from "../../images/arr039.svg";
import arrowUp from "../../images/arr003.svg";
import arrowDown from "../../images/arr004.svg";
import { FormattedMessage } from "react-intl";

export const Table = ({
  columns,
  rows,
  nombretabla,
  option,
  eliminarU,
  cerrarEmparejamiento,
}) => {
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({ order: "asc", orderBy: "id" });
  const [numberPageUser, setNumberPageUser] = useState(() => {
    const saveNumberPage = localStorage.getItem("pageUserData");

    if (saveNumberPage) {
      return JSON.parse(saveNumberPage);
    } else {
      return 5;
    }
  });

  useEffect(() => {
    localStorage.setItem("pageUserData", JSON.stringify(numberPageUser));
  }, [numberPageUser]);

  const rowsPerPage = numberPageUser;

  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );

  const sortedRows = useMemo(
    () => sortRows(filteredRows, sort),
    [filteredRows, sort]
  );

  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);
  const count = filteredRows.length;
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleSearch = (value, accessor) => {
    setActivePage(1);

    if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [accessor]: value,
      }));
    } else {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters[accessor];

        return updatedFilters;
      });
    }
  };

  const handleSort = (accessor) => {
    setActivePage(1);
    setSort((prevSort) => ({
      order:
        prevSort.order === "asc" && prevSort.orderBy === accessor
          ? "desc"
          : "asc",
      orderBy: accessor,
    }));
  };

  // const clearAll = () => {
  //   setSort({ order: "asc", orderBy: "id" });
  //   setActivePage(1);
  //   setFilters({});
  // };

  return (
    <div className="card-body">
      <div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300">
          <thead>
            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0 text-uppercase">
              {columns.map((column, index) => {
                const sortIcon = () => {
                  if (column.accessor === sort.orderBy) {
                    if (sort.order === "asc") {
                      return (
                        <div
                          className="rounded p-2 ms-2"
                          style={{
                            backgroundColor: "#E9F6FF",
                            color: "#0A5189",
                          }}
                        >
                          <img src={arrowUp} alt="up" />
                        </div>
                      );
                    }
                    return (
                      <div
                        className="rounded p-2 ms-2"
                        style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                      >
                        <img src={arrowDown} alt="down" />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="rounded p-2 ms-2"
                        style={{ backgroundColor: "#E9F6FF", color: "#0A5189" }}
                      >
                        <img src={arrowNeutro} alt="neutro" />
                      </div>
                    );
                  }
                };

                return (
                  <th key={index}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center position-relative">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute translate-middle-y top-50 ms-4">
                          <i className="bi bi-search"></i>
                        </i>

                        <input
                          className="form-control bg-body-secondary border border-0 ps-11"
                          key={`${column.accessor}-search`}
                          value={filters[column.accessor]}
                          placeholder={`${column.label}`}
                          title={`${column.label}`}
                          onChange={(event) =>
                            handleSearch(event.target.value, column.accessor)
                          }
                          type="search"
                        />
                      </div>

                      <span onClick={() => handleSort(column.accessor)}>
                        {sortIcon()}
                      </span>
                    </div>
                  </th>
                );
              })}

              <th className="d-flex align-items-center justify-content-center fw-light">
              <FormattedMessage id="table_acciones" />
              </th>
            </tr>
          </thead>

          <tbody>
            {calculatedRows.map((row, index) => {
              return (
                <RowTable
                  cerrarEmparejamiento={cerrarEmparejamiento}
                  eliminarU={eliminarU}
                  key={index}
                  indice={index}
                  row={row}
                  columns={columns}
                  nombretabla={nombretabla}
                  option={option}
                />
              );
            })}
          </tbody>
        </table>

        {count > 0 ? (
          <Pagination
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
            numberPageUser={numberPageUser}
            setNumberPageUser={setNumberPageUser}
          />
        ) : (
          <p>No data found</p>
        )}

        {/* <div>
                  <p>
                    <button
                      className="btn text-white"
                      style={{ backgroundColor: "#0A5189" }}
                      onClick={clearAll}
                    >
                      Limpiar Todo
                    </button>
                  </p>
                </div> */}
      </div>
    </div>
  );
};
