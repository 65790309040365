import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { obtenerContenedor } from "../../redux/actions/contenedor";
import Naviera from "../../images/sidebarIcons/contenedorIcon.svg";
import { useLocation, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const initDataFormConte = {
  naviera: "",
  tipo: "",
  numero: "",
  medida: "",
  estado: "",
  viaje: "",
};

const Contenedor = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { contenedor } = state.contenedor;
  let id = props?.match?.params?.id;
  if (!id) {
    id = props.id;
  }

  const [dataForm, setDataForm] = useState(initDataFormConte);
  const location = useLocation();

  useEffect(() => {
    dispatch(obtenerContenedor(id));
  }, [id, dispatch]);

  useEffect(() => {
    setDataForm({ ...contenedor });
  }, [contenedor]);

  return (
    <>
      {location.pathname === `/contenedor/detalle/${id}` ? (
        <>
          <Link className="btn btn-warning ms-9 mb-5" to={`/contenedores`}>
            <FormattedMessage id="ir_lista" />
          </Link>

          <div id="kt_app_content" className="flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <div className="card fs-3 py-10 mb-10 d-flex flex-row align-items-center justify-content-around">
                <div className="card-title d-flex flex-column">
                  <span className="fw-bolder" style={{ color: "#0A5189" }}>
                  <FormattedMessage id="contenedor_details" />
                  </span>
                </div>

                <div className="card-title d-flex flex-row align-items-center gap-3">
                  <img
                    width={50}
                    src={Naviera}
                    alt="User Avatar"
                    style={{ filter: "invert(100%)" }}
                  />

                  <div className="card-title d-flex flex-column">
                    <span className="fw-bolder">{dataForm?.naviera}</span>
                    <span className="text-gray-400 fw-light fs-6">
                    <FormattedMessage id="contenedor_naviera" />
                    </span>
                  </div>
                </div>

                <div className="card-title d-flex flex-column align-items-center">
                  <span className="fw-bolder">{dataForm?.numero}</span>
                  <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="contenedor_numero" /></span>
                </div>

                <div className="card-title d-flex flex-column align-items-center">
                  <span className="fw-bolder">{dataForm?.medida}</span>
                  <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="contenedor_medida" /></span>
                </div>

                <div className="card-title d-flex flex-column align-items-center">
                  <span className="fw-bolder">{dataForm?.tipo}</span>
                  <span className="text-gray-400 fw-light fs-6"><FormattedMessage id="contenedor_tipo" /></span>
                </div>

                <div className="card-title d-flex flex-column">
                  <span className="fw-bolder">  <FormattedMessage id={dataForm?.viaje || "LIBRE"} /></span>
                  <span className="text-gray-400 fw-light fs-6 text-center">
                  <FormattedMessage id="contenedor_status" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="card fs-3 py-5 d-flex flex-row justify-content-between">
          <div className="card-title d-flex flex-row align-items-center gap-3">
            <img
              width={50}
              src={Naviera}
              alt="User Avatar"
              style={{ filter: "invert(100%)" }}
            />

            <div className="card-title d-flex flex-column">
              <span className="fw-bolder">{dataForm.naviera}</span>
              <span className="text-gray-400 fw-light fs-6">Contenedor</span>
            </div>
          </div>

          <div className="card-title d-flex flex-column align-items-center">
            <span className="fw-bolder">{dataForm.numero || ""}</span>
            <span className="text-gray-400 fw-light fs-6">Numero</span>
          </div>

          <div className="card-title d-flex flex-column align-items-center">
            <span className="fw-bolder">{dataForm.medida || ""}</span>
            <span className="text-gray-400 fw-light fs-6">Medida</span>
          </div>

          <div className="card-title d-flex flex-column align-items-center">
            <span className="fw-bolder">{dataForm.tipo || ""}</span>
            <span className="text-gray-400 fw-light fs-6">Tipo</span>
          </div>

          <div className="card-title d-flex flex-column align-items-center">
            <span className="fw-bolder">{dataForm.viaje || ""}</span>
            <span className="text-gray-400 fw-light fs-6">Estado</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Contenedor;
