import React, { useState, useEffect } from "react";
import { obtenerRfid, obtenerRfidsViajeFiltrados } from "../../redux/actions/rfid";
import { useDispatch, useSelector } from "react-redux";
import { terminarViajeOK } from "../../redux/actions/viajes";
import moment from "moment"
import PosicionRfids from "../Viajes/ListaPosicionRfids";
import Contenedor from "../Contenedor";


const initDataForm = {
    numero_doc_precinto: "",
    condicion: "CERRADO",
    estado: "OK",
    posicion: ""

};

const errorsInit = {
    ...initDataForm,
};

const NuevoPosicionRfid = ({
    agregarNuevoRfidViaje,
    numero_precinto_viaje,
    editar = false,
    editarRfid,
}) => {
    const [dataForm, setDataForm] = useState(initDataForm);
    const [errors, setErrors] = useState(errorsInit);
    const [numero_precinto, setNumeroPrecinto] = useState("");

    const [mostrar, setMostrar] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { rfid } = state.rfid;



    useEffect(() => {

        setMostrar(false);
        dispatch(obtenerRfid(numero_precinto_viaje))

        // dispatch(obtenerRfidsViajeFiltrados());
        if (numero_precinto_viaje) {
            setMostrar(true);
            console.log("aqui vien el rfid", rfid);
            setDataForm({ ...rfid, numero_doc_precinto: numero_precinto_viaje });
        }


    }, []);
    useEffect(() => {

        if (dataForm.numero_doc_precinto) {

            setDataForm({ ...rfid, numero_doc_precinto: numero_precinto_viaje });
            // dispatch(obtenerRfid(dataForm.numero_doc_precinto))
            // var el=document.getElementById("numero_doc_precinto"); 
            // var el = document.getElementById('gender');
            //var text = el.options[el.selectedIndex].value;

            // this.props.history.push(`/rfid/detalle/${dataForm.numero_precinto}`);
        }
    }, [numero_precinto_viaje]);



    const handleChange = (e) => {

        setDataForm({ ...dataForm, [e.target.name]: e.target.value });

        setErrors({ ...errorsInit });
    };

    const isValid = () => {
        const localErrors = { ...errorsInit };
        let respuesta = true;

        for (let key in dataForm) {
            if (key !== "id") {
                if (dataForm[key].trim() === "" || dataForm[key].length === 0) {
                    localErrors[key] = "campo requerido";
                    respuesta = false;
                }
            }
        }

        setErrors({ ...localErrors });

        return respuesta;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fecha = moment().format("YYYY-MM-DD");

        const hora = moment().format("HH:mm");
        const dataForm1 = { ...dataForm, viaje: "viaje", fecha, hora, numero_precinto };
        if (isValid()) {
            // mandar la data al proveedor

            agregarNuevoRfidViaje(dataForm1);

            // limpiar los campos
          
            setDataForm({ ...initDataForm, numero_precinto: dataForm1.numero_doc_precinto });
         

        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row col-md-12" >
                    <div className="form-group col-md-3">

                        <label htmlFor="numero_doc_precinto">Precinto N</label>


                        <input
                            type="text"
                            className="form-control"
                            name="numero_precinto"
                            value={dataForm.numero_precinto}
                            onChange={handleChange}
                        />
                        {errors.numero_precinto && (
                            <span style={{ color: "red", fontSize: "14px" }}>
                                {errors.numero_precinto}
                            </span>
                        )}
                        <div className="form-group">
                            <label htmlFor="estado">Estado</label>
                            <select className="form-control" name="estado" value={dataForm.estado} onChange={handleChange}>
                                <option>OK</option>
                                <option>MANIPULADO</option>

                            </select>

                        </div>
                    </div>

                    <div className="form-group col-md-3">
                        <label htmlFor="condicion">Condicion</label>
                        <select className="form-control" name="condicion" value={dataForm.condicion} onChange={handleChange}>

                            <option>CERRADO</option>
                            <option>ABIERTO</option>

                        </select>
                        <div className="form-group">
                            <label htmlFor="posicion">Posicion</label>
                            <input
                                type="text"
                                className="form-control"
                                name="posicion"
                                value={dataForm.posicion}
                                onChange={handleChange}
                            />
                            {errors.posicion && (
                                <span style={{ color: "red", fontSize: "14px" }}>
                                    {errors.posicion}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="form-group col-md-5 ml-2">

                        {
                            mostrar && <Contenedor id={rfid?.idContenedor} />
                        }


                    </div>




                </div>


                <div className="form-row">
                    {
                        <button type="submit" className="btn btn-primary mb-2">
                            Iniciar Viaje
                        </button>
                    }
                    <button
                        onClick={() => dispatch(terminarViajeOK(rfid))}
                        className="btn btn-danger ml-2 mb-2">
                        Terminar Viaje
                        </button>


                </div>


            </form >
            {
                mostrar && <PosicionRfids numero_doc_precinto={dataForm.numero_doc_precinto} />
            }
        </>
    );
};

export default NuevoPosicionRfid;
