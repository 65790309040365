import React from "react";

import { store } from "./redux/store/store";
import { Provider } from "react-redux";

import Rutas from "./rutas";

function App() {
  return (
    <Provider store={store}>
      <Rutas />
    </Provider>
  );
}

export default App;
