import React, { useEffect, useState } from "react";
import PosicionRfids from "../../components/Viajes/ListaPosicionRfids";
import Popup from "../../components/Piloto/PopUpPiloto";

import { useDispatch, useSelector } from "react-redux";
import CardRfid from "../../components/Rfid/CardRfid";
import Contenedor from "../../components/Contenedor";
import { obtenerRfid } from "../../redux/actions/rfid";
import CardCamion from "../../components/Camion/CardCamion";
import CardFurgon from "../../components/Furgon/CardFurgon";
import { Link } from "react-router-dom";
const initDataForm = {
  idPiloto: "",
  idCamion: "",
  idContenedor: "",
  viaje: "",
};

const DetalleHisPosicionRfid = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { rfid } = state.rfid;
  const [dataForm, setDataForm] = useState(initDataForm);
  const [esperaPi, setEsperaPi] = useState(false);
  const numero_precinto = props?.match.params.id;
  useEffect(() => {
    dispatch(obtenerRfid(numero_precinto));
  }, []);
  useEffect(() => {
    if (rfid) {
      if (rfid.id) {
        setDataForm({ ...rfid });
        setEsperaPi(true)
      }
    }
  }, [rfid]);

  if (!rfid) return <p>Cargando ...</p>;
  return (
    <div>
      <h1>Detalle Viaje RFID</h1>
      <div className="row">
        <div className="col-md-4">
          <CardRfid id={numero_precinto} />
        {
          esperaPi && (<Popup id={dataForm.idPiloto} />)
        }  
          {rfid?.viaje == "Terminado" ? (
            <Link
              className="btn btn-warning ml-2 mb-3"
              to={`/viajes/terminado`}
            >
              Ir a la Lista
            </Link>
          ) : (
            <Link className="btn btn-warning ml-2 mb-3" to={`/viajes`}>
              Ir a la Lista
            </Link>
          )}
        </div>
        <div className="col-md-4">
          {dataForm.idCamion == "" ? (
            <p>Cargando ...</p>
          ) : (
            <CardFurgon id={dataForm.idCamion} />
          )}
        </div>
        <div className="col-md-4">
          {dataForm.idContenedor == "" ? (
            <p>Cargando ...</p>
          ) : (
            <Contenedor id={dataForm.idContenedor} />
          )}
        </div>
      </div>

      <PosicionRfids numero_doc_precinto={numero_precinto} />
    </div>
  );
};

export default DetalleHisPosicionRfid;
