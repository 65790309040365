import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editarContenedor } from "../../redux/actions/contenedor";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const initDataForm = {
  naviera: "",
  numero: "",
  medida: "",
  tipo: "",
  numero_precintos: "",
};

const errorsInit = {
  ...initDataForm,
};

const ModalEditarContenedor = ({ datos }) => {
  const [show, setShow] = useState(false);
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { editOk } = state.contenedor;
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleCloseReload = () => {
    handleClose();
  };
  
  useEffect(() => {
    if (editOk) {
      history.push("/contenedores");
    }
  }, [editOk, history]);

  useEffect(() => {
    if (show) {
      delete datos.datos_creacion;
      delete datos.__v;
      delete datos.datos_edicion;
      setDataForm({ ...datos });
    }
  }, [show, datos]);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "id") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key].trim() === "-1"
        ) {
          localErrors[key] = "Campo Requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      const dataForm1 = { ...dataForm };
      dispatch(editarContenedor(dataForm1));
    }
  };

  return (
    <>
      <Button
        className="btn-secondary fw-bolder"
        style={{ color: "#0A5189" }}
        onClick={handleShow}
      >
        Editar
      </Button>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Editar Contenedor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="naviera">
                  Naviera:
                </Form.Label>
                <Form.Control
                  name="naviera"
                  onChange={handleChange}
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.naviera || ""}
                />
              </Col>

              <Col xs={6}>
                <Form.Label className="mb-5" htmlFor="numero_precintos">
                  N° Precintos:
                </Form.Label>
                <Form.Select
                  name="numero_precintos"
                  onChange={handleChange}
                  className="form-select"
                  value={dataForm?.numero_precintos || ""}
                >
                  <option value="-1">Seleccione</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col xs={5}>
                <Form.Label className="my-5" htmlFor="tipo">
                  Tipo:
                </Form.Label>
                <Form.Select
                  name="tipo"
                  onChange={handleChange}
                  className="form-select"
                  value={dataForm?.tipo || ""}
                >
                  <option value="-1">Seleccione</option>
                  <option>Dray (Seco)</option>
                  <option>Flat Rack</option>
                  <option>High Cube</option>
                  <option>Open Top</option>
                  <option>Plataforma</option>
                  <option>Frigorifico (Reefer)</option>
                  <option>Tanque (7 tipos)</option>
                </Form.Select>
              </Col>

              <Col xs={4}>
                <Form.Label className="my-5" htmlFor="numero">
                  Número:
                </Form.Label>

                <Form.Control
                  name="numero"
                  onChange={handleChange}
                  className="bg-body-secondary border border-0"
                  defaultValue={dataForm?.numero || ""}
                />
              </Col>

              <Col xs={3}>
                <Form.Label className="my-5" htmlFor="medida">
                  Medida:
                </Form.Label>

                <Form.Select
                  name="medida"
                  onChange={handleChange}
                  className="form-select"
                  value={dataForm?.medida || ""}
                >
                  <option value="-1">Seleccione</option>
                  <option>20 Pies</option>
                  <option>40 pies</option>
                  <option>53 pies</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Stack direction="horizontal" className="mb-8 mx-auto gap-3">
          <Button
            className="fw-bolder"
            size="sm"
            onClick={HandleCloseReload}
            style={{
              backgroundColor: "#e9f6ff",
              color: "#0A5189",
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit}
            style={{ backgroundColor: "#0A5189" }}
          >
            Editar Contenedor
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ModalEditarContenedor;
