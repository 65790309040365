// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fondoBarco {
  background: rgb(11, 83, 138);
  background: linear-gradient(
    90deg,
    rgba(11, 83, 138, 1) 0%,
    rgba(11, 83, 138, 1) 100%
  );
  overflow-x: hidden;
}

.barcoLogin {
  animation: 2s ease-in-out 0s 1 mostrarImagen;
}

@keyframes mostrarImagen {
  0% {
    opacity: 0;
    transform: translateX(100rem);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0rem);
  }
}

.hover__sidelink > a:hover {
  background-color: #062f4e;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  border-radius: 10px;
}

.hide-dropdown-arrow .dropdown-toggle::after {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B;;;;GAIC;EACD,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE;IACE,UAAU;IACV,6BAA6B;EAC/B;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,2BAA2B;EAC7B;AACF;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,yCAAyC;EACzC,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".fondoBarco {\n  background: rgb(11, 83, 138);\n  background: linear-gradient(\n    90deg,\n    rgba(11, 83, 138, 1) 0%,\n    rgba(11, 83, 138, 1) 100%\n  );\n  overflow-x: hidden;\n}\n\n.barcoLogin {\n  animation: 2s ease-in-out 0s 1 mostrarImagen;\n}\n\n@keyframes mostrarImagen {\n  0% {\n    opacity: 0;\n    transform: translateX(100rem);\n  }\n  50% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n    transform: translateX(0rem);\n  }\n}\n\n.hover__sidelink > a:hover {\n  background-color: #062f4e;\n  cursor: pointer;\n  transition: background-color 0.3s ease-in;\n  border-radius: 10px;\n}\n\n.hide-dropdown-arrow .dropdown-toggle::after {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
