const initialState = {
    listaUsuarios: [],
    addOk: false,
    deleteOk: false,
    editOk: false,
    usuario: null,
    listaUsuariosSelect:[]
  };
  
  export const usuariosReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
      case "OBTENER_USUARIOS":
        return {
          ...state,
          listaUsuarios: action.payload,
          listaUsuariosSelect:action.payload2,
          addOk: false,
          deleteOk: false,
          editOk: false,
          usuario: null,
        };
      case "AGREGAR_USUARIO":
        return {
          ...state,
          addOk: action.payload,
        };
      
      case "ELIMINAR_USUARIO":
        return {
          ...state,
          deleteOk: action.payload,
        };
      case "OBTENER_USUARIO":
        return {
          ...state,
          usuario: action.payload,
        };
      case "EDITAR_USUARIO":
        return {
          ...state,
          editOk: action.payload,
        };
        case "AGREGAR_USUARIO_LISTA":
          return {
            ...state,
            addOk: action.payload,
          
  
          };
      default:
        return state;
    }
  };
  