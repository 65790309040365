import React, { useEffect, useState } from "react";
import App from "../../components/TablaRfids/App";
import { useSelector, useDispatch } from "react-redux";
import { CardTitle, ModalAddGlinseal } from "../../helpers";

import {
  eliminarEmparejamiento,
  obtenerEmparejamientos,
  cerrarEmparejamiento,
} from "../../redux/actions/emparejamiento";
import { FormattedMessage, useIntl } from "react-intl";



const Vinculaciones = () => {
  const intl=useIntl();
  const listaColumnasx = [
    { accessor: "origen", label: intl.formatMessage({id:"vinculacion_origen"}) },
    { accessor: "destino", label: intl.formatMessage({id:"vinculacion_destino"}) },
    { accessor: "tipo_vehiculo", label: intl.formatMessage({id:"vinculacion_tipo_vehiculo"})  },
    { accessor: "nombre_piloto", label: intl.formatMessage({id:"vinculacion_piloto"})  },
    { accessor: "placa_vehiculo", label: intl.formatMessage({id:"vinculacion_placa_vehiculo"})  },
    // { accessor: "numero_precintos", label: "N Precintos" },
    { accessor: "fecha_creacion", label: intl.formatMessage({id:"vinculacion_fecha_registro"}) },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { listaEmparejamientos, editOk, deleteOk } = state.emparejamiento;
  const [viaje, setViaje] = useState("VIAJE");

  const handleChange = (e) => {
    setViaje(e.target.value);
  };

  useEffect(() => {
    dispatch(obtenerEmparejamientos(viaje));
  }, [viaje]);

  useEffect(() => {
    if (editOk) {
      dispatch(obtenerEmparejamientos(viaje));
    }
  }, [editOk]);

  useEffect(() => {
    if (deleteOk) {
      dispatch(obtenerEmparejamientos(viaje));
    }
  }, [deleteOk]);

  const eliminarU = (id) => {
    dispatch(eliminarEmparejamiento(id));
  };

  const cerrarEmparejamientoX = (id, descripcion) => {
    dispatch(cerrarEmparejamiento(id, descripcion));
  };

  return (
    <>
      <div id="kt_app_content" className="flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="card">
            <div className="card-header py-5 ps-20 pe-10">
              {CardTitle("emparejamiento")}

              <div className="card-title d-flex gap-10">
                <div className="d-flex gap-3 align-items-center">
                  <label
                    htmlFor="numero_precintos_total"
                    className="text-body-tertiary"
                  >
                      <FormattedMessage id="vincu_label_position" />
                  </label>
                  <select
                    className="form-select form-select-sm bg-secondary border border-0 fw-bolder text-black"
                    aria-label="estados"
                    name="viaje"
                    value={viaje}
                    onChange={handleChange}
                  >
                    <option value="VIAJE"> <FormattedMessage id="vincu_status_viaje" /></option>
                    <option value="TERMINADO"><FormattedMessage id="vincu_status_terminado" /></option>
                  </select>
                </div>
              </div>

              <div className="card-title d-flex gap-5">
                {ModalAddGlinseal("emparejamiento")}
              </div>
            </div>

            {listaEmparejamientos.length > 0 && (
              <App
                cerrarEmparejamiento={cerrarEmparejamientoX}
                eliminarU={eliminarU}
                nombretabla="emparejamiento"
                rows={listaEmparejamientos}
                columns={listaColumnasx}
                option={viaje}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Vinculaciones;
