import React, { useEffect } from "react";
import Formulario from "../../components/Formulario/NuevoPosicionRfid";

import { agregarRfidViaje } from "../../redux/actions/rfid";
import { useDispatch, useSelector } from "react-redux";


const RegistrarPosicionRfid = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { editOk } = state.rfid;
 
  const numero_precinto=props?.match.params.id


  const nuevoRfid = (rfid) => {
    dispatch(agregarRfidViaje(rfid));
    // agregar producto provider
  };
  return (
    <div>
      <h1>Registrar Posicion RFID</h1>
      <Formulario agregarNuevoRfidViaje={nuevoRfid} numero_precinto_viaje={numero_precinto}/>
     
    </div>
  );
};

export default RegistrarPosicionRfid;