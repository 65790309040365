const initialState = {
  listaEmparejamientos: [],
  addOk: false,
  deleteOk: false,
  editOk: false,
  emparejamiento: null,
  listaEmparejamientosSelect: [],
  listaEmparejamientosExists: [],
};

export const emparejamientoReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "OBTENER_EMPAREJAMIENTOS":
      return {
        ...state,
        listaEmparejamientos: action.payload,

        addOk: false,
        deleteOk: false,
        editOk: false,
        emparejamiento: null,
      };

    case "AGREGAR_EMPAREJAMIENTO":
      return {
        ...state,
        addOk: action.payload,
      };
    case "AGREGAR_EMPRESA_LISTA":
      return {
        ...state,
        addOk: action.payload,
        listaEmpresaExists: action.payload2,
      };
    case "CERRAR_VIAJE_PRECINTO":
      return {
        ...state,
        editOk: action.payload,
      };
    case "CERRAR_EMPAREJAMIENTO":
      return {
        ...state,
        editOk: action.payload,
      };
    case "ELIMINAR_EMPAREJAMIENTO":
      return {
        ...state,
        deleteOk: action.payload,
      };
    case "OBTENER_EMPAREJAMIENTO":
      return {
        ...state,
        emparejamiento: action.payload,
        editOk: false,
      };

    default:
      return state;
  }
};
