import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { obtenerTagsCamionFiltrados } from "../../redux/actions/tagcamion";
import { obtenerEmpresasFiltrados } from "../../redux/actions/clienteempresa";
import { Link } from "react-router-dom";

const initDataForm = {
  name: "",
  password: "",
  email: "",
  perfil: "",
  activo: "ACTIVO",
  idempresa: "",
};
const initDataFormErr = {
  name: "",
  password: "",
  email: "",
  perfil: "",
  idempresa: "",
};

const errorsInit = {
  ...initDataFormErr,
};

const Nuevo = ({
  mensajeError,
  agregarNuevo,
  usuario,
  editar = false,
  Editar,
}) => {
  const [perfiluser, setperfilUser] = useState(localStorage.getItem("perfil"));
  const [idempresaX, setIdEmpresa] = useState(
    localStorage.getItem("idempresa")
  );
  const [nombreempresa, setNombreEmpre] = useState(
    localStorage.getItem("nombreempresa")
  );
  const [dataForm, setDataForm] = useState(initDataForm);
  const [errors, setErrors] = useState(errorsInit);
  const [soloLectura, setSoloLectura] = useState(false);
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { listaEmpClienteSelect } = state.clienteempre;

  useEffect(() => {
    if (editar) {
      setDataForm({ ...usuario });
      setSoloLectura(true);
    } else {
      if (perfiluser != "superadmin") {
        setDataForm({ ...dataForm, idempresa: idempresaX });
      }
    }

    dispatch(obtenerEmpresasFiltrados());
  }, []);

  const handleChange = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    if ([e.target.name] == "idempresa" && e.target.value != -1) {
      let index = e.nativeEvent.target.selectedIndex;
      setNombreEmpre(e.nativeEvent.target[index].label);
    }
    setErrors({ ...errorsInit });
  };

  const isValid = () => {
    const localErrors = { ...errorsInit };
    let respuesta = true;

    for (let key in dataForm) {
      if (key !== "activo") {
        if (
          dataForm[key].trim() === "" ||
          dataForm[key].length === 0 ||
          dataForm[key] == -1
        ) {
          localErrors[key] = "campo requerido";
          respuesta = false;
        }
      }
    }

    setErrors({ ...localErrors });

    return respuesta;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(isValid());
    if (isValid()) {
      // mandar la data al proveedor
      if (editar) {
        console.log("nombre empresa", nombreempresa);
        const dataForm1 = { ...dataForm, nombreempresa };
        Editar(dataForm1);
      } else {
        const dataForm1 = { ...dataForm, nombreempresa };
        agregarNuevo(dataForm1);
      }
      // limpiar los campos
      //  setDataForm(initDataForm);
    }
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <div
              className="card card-primary shadow-lg p-3 mb-5 bg-white rounded"
              style={{ padding: "0em" }}
            >
              <div className="card-header">
                {editar ? (
                  <h1 className="card-title">Editar Usuario</h1>
                ) : (
                  <h1 className="card-title">Agregar Usuario</h1>
                )}
              </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: "50em" }}>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="email">Usuario</label>
                    <input
                      type="text"
                      readOnly={soloLectura}
                      className="form-control"
                      name="email"
                      value={dataForm.email || ""}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="name">Nombre Completo</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={dataForm.name || ""}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.name}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="password">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      value={dataForm.password || ""}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.password}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="perfil">Perfil</label>
                    <select
                      type="text"
                      className="form-control"
                      name="perfil"
                      value={dataForm.perfil || ""}
                      onChange={handleChange}
                    >
                      <option value="-1">Seleccione</option>
                      <option>Administrador</option>
                      <option>UsuarioWeb</option>
                      <option>UsuarioMovil</option>
                    </select>
                    {errors.perfil && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.perfil}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="activo">Estado</label>
                    <select
                      type="text"
                      className="form-control"
                      name="activo"
                      value={dataForm.activo || ""}
                      onChange={handleChange}
                    >
                      <option>ACTIVO</option>
                      <option>INACTIVO</option>
                    </select>
                    {errors.activo && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.activo}
                      </span>
                    )}
                  </div>
                  {perfiluser === "superadmin" && (
                    <div className="form-group col-md-4">
                      <label htmlFor="idempresa">Empresa</label>
                      <select
                        type="text"
                        className="form-control"
                        name="idempresa"
                        value={dataForm.idempresa || ""}
                        onChange={handleChange}
                      >
                        {listaEmpClienteSelect?.map((item, index) => (
                          <option
                            key={index}
                            value={item.value}
                            label={item.label}
                          ></option>
                        ))}
                      </select>
                      {errors.idempresa && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors.idempresa}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="card-footer">
                  {mensajeError !== "" && <p>{mensajeError}</p>}
                  <button type="submit" className="btn btn-primary">
                    {editar ? "Editar Usuario" : "Agregar Usuario"}
                  </button>
                  <Link className="btn btn-warning ml-2" to={`/usuarios`}>
                    Cancelar
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nuevo;
