import React from "react";



const RowTable = (props) => {


  const {
   idCamion,
    placa_cabezal,
    chasis_cabezal,
    marca,
    modelo, 
    id_empresa_trans
  
  } = props.camion;
  const { indice } = props;

  return (
    <tr>
      <th scope="row">{indice + 1}</th>
      {idCamion && <td>{idCamion}</td>}
    
      <td>
    {placa_cabezal}
      </td>
      <td>
        {chasis_cabezal}
      </td>
      <td>
        {marca}
      </td>
      <td>
        {modelo}
      </td>
      <td>
        {id_empresa_trans}
      </td>
    </tr>
  );
};

export default RowTable;
